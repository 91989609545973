import { TFunction } from 'i18next';

export const listingTypeString = (t: TFunction, numberOfRooms: number | null, type: string): string => {
  const roomCountTranslation = numberOfRooms && numberOfRooms >= 4 ? 'listing.withRoomsOpenEnded' : 'listing.withRooms';
  return t(`enums.listingType.${type}`) + ' ' + (numberOfRooms ? t(roomCountTranslation, { numberOfRooms }) : '');
};
export const listingLocationString = (t: TFunction, city: string, district: string | null): string => {
  return t('listing.in') + ' ' + city + (district && district !== 'OTHER' ? ' ' + t(`enums.district.${district}`) : '');
};
export const listingAddressString = (
  t: TFunction,
  street: string,
  houseNumber: string | null,
  postalCode: string,
  city: string | null,
  district: string | null
): string => {
  return (
    `${street}${houseNumber ? ` ${houseNumber}` : ''}, ` +
    `${postalCode}${city ? ' ' + city : ''}` +
    `${district && district !== 'OTHER' ? ', ' + t(`enums.district.${district}`) : ''}`
  );
};
