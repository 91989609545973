import {
  ActionCreator,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  GetListingByUUID_listingByUUID_coordinates,
  GetListingByUUID_listingByUUID_images,
  GetListingByUUID_listingByUUID_landlord
} from '../../../../graphql/types/GetListingByUUID';
import { TUMLocation, HousingType, ListingType } from '../../../../graphql/types/globalTypes';
import { FileStatus } from '../../../controls/file-upload-item/file-upload-item';

export type LocalImageDescriptor = {
  status: FileStatus;
  error?: string;
  origin: 'server' | 'client';
  markedForDeletion?: boolean;
} & Omit<GetListingByUUID_listingByUUID_images, '__typename'>;

type FileStatusUpdatePayload = { id: string; status: FileStatus; error?: string; newId?: string; isPreview?: boolean };
type UpdateImageDescriptionPayload = { id: string; description: string };

export interface ListingFormDataState {
  id: string | null;
  uuid: string | null;
  landlord: GetListingByUUID_listingByUUID_landlord | null;
  type: ListingType;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  tumLocation: TUMLocation;
  rent: number;
  availableFrom: string;
  squareMeter: number;
  housingType: HousingType | null;
  floor: number | null;
  parkingSpace: boolean;
  isActive: boolean;
  isListingPublic: boolean;
  seekingStudents: boolean;
  seekingProfessors: boolean;
  seekingIncomings: boolean;
  seekingDoctoralStudents: boolean;
  seekingPostDoctoralStudents: boolean;
  seekingGuestResearchers: boolean;
  seekingTumEmployees: boolean;

  modifiedAt: string | null;
  verifiedAt: string | null;
  district: string | null;
  numberOfRooms: number | null;
  incidentalCosts: number | null;
  incidentalCostsCustomLabel: string | null;
  oneTimeCosts: number | null;
  oneTimeCostsLabel: string | null;
  parkingSpaceCosts: number | null;
  deposit: number | null;
  availableUntil: string | null;
  expirationDate: string | null;
  publicationDate: string | null;
  furtherEquipment: string | null;
  furtherEquipmentEn: string | null;

  tags: string[];
  images: LocalImageDescriptor[];
  incidentalCostsTypes: string[];

  coordinates: GetListingByUUID_listingByUUID_coordinates | null;
}

export const initialListingFormDataState: ListingFormDataState = {
  id: null,
  uuid: null,
  landlord: null,
  type: ListingType.SHARED_APARTMENT,
  street: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  tumLocation: TUMLocation.MUNICH,
  rent: 0,
  squareMeter: 0,
  parkingSpace: false,
  housingType: null,
  floor: null,
  availableFrom: '',
  isActive: false,
  isListingPublic: false,
  seekingStudents: true,
  seekingProfessors: true,
  seekingIncomings: true,
  seekingDoctoralStudents: true,
  seekingPostDoctoralStudents: true,
  seekingGuestResearchers: true,
  seekingTumEmployees: true,
  modifiedAt: null,
  verifiedAt: null,
  district: null,
  numberOfRooms: null,
  incidentalCosts: null,
  incidentalCostsCustomLabel: null,
  oneTimeCosts: null,
  oneTimeCostsLabel: null,
  parkingSpaceCosts: null,
  deposit: null,
  availableUntil: null,
  expirationDate: null,
  publicationDate: null,
  furtherEquipment: null,
  furtherEquipmentEn: null,
  tags: [],
  images: [],
  incidentalCostsTypes: [],
  coordinates: null
};

export const slice = createSlice({
  name: 'ListingFormData',
  reducers: {
    setUuid: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.uuid = action.payload;
    },
    setLandlord: (
      state: ListingFormDataState,
      action: PayloadAction<GetListingByUUID_listingByUUID_landlord | null>
    ) => {
      state.landlord = action.payload;
    },
    setType: (state: ListingFormDataState, action: PayloadAction<ListingType>) => {
      state.type = action.payload;
    },
    setStreet: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.street = action.payload;
    },
    setHouseNumber: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.houseNumber = action.payload;
    },
    setPostalCode: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.postalCode = action.payload;
    },
    setCity: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setTUMLocation: (state: ListingFormDataState, action: PayloadAction<TUMLocation>) => {
      state.tumLocation = action.payload;
    },
    setRent: (state: ListingFormDataState, action: PayloadAction<number>) => {
      state.rent = action.payload;
    },
    setAvailableFrom: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.availableFrom = action.payload;
    },
    setSquareMeter: (state: ListingFormDataState, action: PayloadAction<number>) => {
      state.squareMeter = action.payload;
    },
    setIsActive: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setIsListingPublic: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.isListingPublic = action.payload;
    },
    setSeekingStudents: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingStudents = action.payload;
    },
    setSeekingProfessors: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingProfessors = action.payload;
    },
    setSeekingIncomings: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingIncomings = action.payload;
    },
    setSeekingDoctoralStudents: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingDoctoralStudents = action.payload;
    },
    setSeekingPostDoctoralStudents: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingPostDoctoralStudents = action.payload;
    },
    setSeekingGuestResearchers: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingGuestResearchers = action.payload;
    },
    setSeekingTumEmployees: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingTumEmployees = action.payload;
    },
    setModifiedAt: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.modifiedAt = action.payload;
    },
    setVerifiedAt: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.verifiedAt = action.payload;
    },
    setDistrict: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.district = action.payload;
    },
    setNumberOfRooms: (state: ListingFormDataState, action: PayloadAction<number>) => {
      state.numberOfRooms = action.payload;
    },
    setIncidentalCosts: (state: ListingFormDataState, action: PayloadAction<number>) => {
      state.incidentalCosts = action.payload;
    },
    setDeposit: (state: ListingFormDataState, action: PayloadAction<number>) => {
      state.deposit = action.payload;
    },
    setAvailableUntil: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.availableUntil = action.payload;
    },
    setExpirationDate: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.expirationDate = action.payload;
    },
    setPublicationDate: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.publicationDate = action.payload;
    },
    setFurtherEquipment: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.furtherEquipment = action.payload;
    },
    setFurtherEquipmentEn: (state: ListingFormDataState, action: PayloadAction<string>) => {
      state.furtherEquipmentEn = action.payload;
    },
    setTags: (state: ListingFormDataState, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setSeekingFlags: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.seekingStudents = action.payload;
      state.seekingProfessors = action.payload;
      state.seekingIncomings = action.payload;
      state.seekingDoctoralStudents = action.payload;
      state.seekingPostDoctoralStudents = action.payload;
      state.seekingGuestResearchers = action.payload;
      state.seekingTumEmployees = action.payload;
    },
    resetForm: (state: ListingFormDataState) => {
      Object.assign(state, initialListingFormDataState);
    },
    setListingFormData: (state: ListingFormDataState, action: PayloadAction<ListingFormDataState>) => {
      Object.assign(state, action.payload);
    },
    setHousingType: (state: ListingFormDataState, action: PayloadAction<HousingType | null>) => {
      state.housingType = action.payload;
    },
    setFloor: (state: ListingFormDataState, action: PayloadAction<number | null>) => {
      state.floor = action.payload;
    },
    setParkingSpace: (state: ListingFormDataState, action: PayloadAction<boolean>) => {
      state.parkingSpace = action.payload;
    },
    setIncidentalCostsCustomLabel: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.incidentalCostsCustomLabel = action.payload;
    },
    setOneTimeCosts: (state: ListingFormDataState, action: PayloadAction<number | null>) => {
      state.oneTimeCosts = action.payload;
    },
    setOneTimeCostsLabel: (state: ListingFormDataState, action: PayloadAction<string | null>) => {
      state.oneTimeCostsLabel = action.payload;
    },
    setParkingSpaceCosts: (state: ListingFormDataState, action: PayloadAction<number | null>) => {
      state.parkingSpaceCosts = action.payload;
    },
    setIncidentalCostsTypes: (state: ListingFormDataState, action: PayloadAction<string[]>) => {
      state.incidentalCostsTypes = action.payload;
    },
    setCoordinates: (
      state: ListingFormDataState,
      action: PayloadAction<GetListingByUUID_listingByUUID_coordinates | null>
    ) => {
      state.coordinates = action.payload;
    },
    removeImage: (state: ListingFormDataState, action: PayloadAction<string>) => {
      const imageToDeleteIndex = state.images?.findIndex((image) => image.id === action.payload);
      if (imageToDeleteIndex >= 0) {
        state.images.splice(imageToDeleteIndex, 1);
        state.images = [...state.images];
      }
    },
    setPreviewImage: (state: ListingFormDataState, action: PayloadAction<string>) => {
      const currentPreviewImage = state.images.find((image) => image.isPreview);
      if (currentPreviewImage && currentPreviewImage.id !== action.payload) {
        currentPreviewImage.isPreview = false;
      }
      const newPreviewImage = state.images.find((image) => image.id === action.payload);
      newPreviewImage && (newPreviewImage.isPreview = true);
      // IMPROVEMENT: This should probably be a deep copy
      state.images = [...state.images];
    },
    updateImageStatus: (state: ListingFormDataState, action: PayloadAction<FileStatusUpdatePayload>) => {
      const imageToUpdate = state.images.find((image) => image.id === action.payload.id);
      if (imageToUpdate) {
        imageToUpdate.status = action.payload.status;
        imageToUpdate.error = action.payload.error;
        // assign new id when backend upload was finished
        if (action.payload.newId) {
          imageToUpdate.id = action.payload.newId;
        }
      }
      // IMPROVEMENT: This should probably be a deep copy
      state.images = [...state.images];
    },
    addImage: (state: ListingFormDataState, action: PayloadAction<LocalImageDescriptor>) => {
      const newImages = state.images ? [...state.images] : [];
      newImages.push(action.payload);
      state.images = newImages;
    },
    updateImageDescription: (state: ListingFormDataState, action: PayloadAction<UpdateImageDescriptionPayload>) => {
      const imageToUpdate = state.images.find((image) => image.id === action.payload.id);
      if (imageToUpdate) {
        imageToUpdate.description = action.payload.description;
      }
      // IMPROVEMENT: This should probably be a deep copy
      state.images = [...state.images];
    },
    updateImageDescriptionEN: (state: ListingFormDataState, action: PayloadAction<UpdateImageDescriptionPayload>) => {
      const imageToUpdate = state.images.find((image) => image.id === action.payload.id);
      if (imageToUpdate) {
        imageToUpdate.descriptionEn = action.payload.description;
      }
      // IMPROVEMENT: This should probably be a deep copy
      state.images = [...state.images];
    },
    markImageForDeletion: (state: ListingFormDataState, action: PayloadAction<string>) => {
      const imageToMarkForDeletion = state.images.find((image) => image.id === action.payload);
      if (imageToMarkForDeletion) {
        imageToMarkForDeletion.markedForDeletion = true;
      }
      // IMPROVEMENT: This should probably be a deep copy
      state.images = [...state.images];
    }
  },
  initialState: initialListingFormDataState
});

export const setUuid: ActionCreatorWithPayload<string | null> = slice.actions.setUuid;
export const setLandlord: ActionCreatorWithPayload<GetListingByUUID_listingByUUID_landlord | null> =
  slice.actions.setLandlord;
export const setType: ActionCreatorWithPayload<ListingType> = slice.actions.setType;
export const setStreet: ActionCreatorWithPayload<string> = slice.actions.setStreet;
export const setHouseNumber: ActionCreatorWithPayload<string> = slice.actions.setHouseNumber;
export const setPostalCode: ActionCreatorWithPayload<string> = slice.actions.setPostalCode;
export const setCity: ActionCreatorWithPayload<string> = slice.actions.setCity;
export const setTUMLocation: ActionCreatorWithPayload<TUMLocation> = slice.actions.setTUMLocation;
export const setRent: ActionCreatorWithPayload<number> = slice.actions.setRent;
export const setAvailableFrom: ActionCreatorWithPayload<string> = slice.actions.setAvailableFrom;
export const setSquareMeter: ActionCreatorWithPayload<number> = slice.actions.setSquareMeter;
export const setIsActive: ActionCreatorWithPayload<boolean> = slice.actions.setIsActive;
export const setIsListingPublic: ActionCreatorWithPayload<boolean> = slice.actions.setIsListingPublic;
export const setSeekingStudents: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingStudents;
export const setSeekingProfessors: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingProfessors;
export const setSeekingIncomings: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingIncomings;
export const setSeekingDoctoralStudents: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingDoctoralStudents;
export const setSeekingPostDoctoralStudents: ActionCreatorWithPayload<boolean> =
  slice.actions.setSeekingPostDoctoralStudents;
export const setSeekingGuestResearchers: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingGuestResearchers;
export const setSeekingTumEmployees: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingTumEmployees;
export const setModifiedAt: ActionCreatorWithPayload<string> = slice.actions.setModifiedAt;
export const setVerifiedAt: ActionCreatorWithPayload<string | null> = slice.actions.setVerifiedAt;
export const setDistrict: ActionCreatorWithPayload<string | null> = slice.actions.setDistrict;
export const setNumberOfRooms: ActionCreatorWithPayload<number> = slice.actions.setNumberOfRooms;
export const setIncidentalCosts: ActionCreatorWithPayload<number> = slice.actions.setIncidentalCosts;
export const setDeposit: ActionCreatorWithPayload<number> = slice.actions.setDeposit;
export const setAvailableUntil: ActionCreatorWithPayload<string | null> = slice.actions.setAvailableUntil;
export const setExpirationDate: ActionCreatorWithPayload<string> = slice.actions.setExpirationDate;
export const setPublicationDate: ActionCreatorWithPayload<string> = slice.actions.setPublicationDate;
export const setFurtherEquipment: ActionCreatorWithPayload<string> = slice.actions.setFurtherEquipment;
export const setFurtherEquipmentEn: ActionCreatorWithPayload<string> = slice.actions.setFurtherEquipmentEn;
export const setTags: ActionCreatorWithPayload<string[]> = slice.actions.setTags;
export const setSeekingFlags: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingFlags;
export const setHousingType: ActionCreatorWithPayload<HousingType | null> = slice.actions.setHousingType;
export const setFloor: ActionCreatorWithPayload<number | null> = slice.actions.setFloor;
export const setParkingSpace: ActionCreatorWithPayload<boolean> = slice.actions.setParkingSpace;
export const setIncidentalCostsCustomLabel: ActionCreatorWithPayload<string | null> =
  slice.actions.setIncidentalCostsCustomLabel;
export const setOneTimeCosts: ActionCreatorWithPayload<number | null> = slice.actions.setOneTimeCosts;
export const setOneTimeCostsLabel: ActionCreatorWithPayload<string | null> = slice.actions.setOneTimeCostsLabel;
export const setParkingSpaceCosts: ActionCreatorWithPayload<number | null> = slice.actions.setParkingSpaceCosts;
export const setIncidentalCostsTypes: ActionCreatorWithPayload<string[]> = slice.actions.setIncidentalCostsTypes;
export const setCoordinates: ActionCreatorWithPayload<GetListingByUUID_listingByUUID_coordinates | null> =
  slice.actions.setCoordinates;
export const resetListingForm: ActionCreatorWithoutPayload = slice.actions.resetForm;
export const setListingFormData: ActionCreatorWithPayload<ListingFormDataState> = slice.actions.setListingFormData;
export const addImage: ActionCreatorWithPayload<LocalImageDescriptor> = slice.actions.addImage;
export const removeImage: ActionCreatorWithPayload<string> = slice.actions.removeImage;
export const setPreviewImage: ActionCreatorWithPayload<string> = slice.actions.setPreviewImage;
export const updateImageStatus: ActionCreatorWithPayload<FileStatusUpdatePayload> = slice.actions.updateImageStatus;
export const updateImageDescription: ActionCreatorWithPayload<UpdateImageDescriptionPayload> =
  slice.actions.updateImageDescription;
export const updateImageDescriptionEN: ActionCreatorWithPayload<UpdateImageDescriptionPayload> =
  slice.actions.updateImageDescriptionEN;
export const markImageForDeletion: ActionCreatorWithPayload<string> = slice.actions.markImageForDeletion;

export const listingFormDataReducer = slice.reducer;

export const listingActionsResettingDirtyState = [slice.actions.resetForm, slice.actions.setListingFormData];

// These actions are mostly automatic without user input
const actionsTriggeringDirtyStateExclusions: ActionCreator<unknown>[] = [
  ...listingActionsResettingDirtyState,
  slice.actions.setIsActive,
  slice.actions.setCoordinates
];

export const listingActionsTriggeringDirtyState = Object.values(slice.actions).filter(
  (value: ActionCreator<unknown>) => actionsTriggeringDirtyStateExclusions.indexOf(value) === -1
);
