import { Transition } from 'history';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useBlocker } from 'react-router-dom';
import {
  ConfirmationDialog,
  ConfirmationDialogProps
} from '../../../components/building-blocks/confirmation-dialog/confirmation-dialog';
import { GlobalState } from '../../../redux/store';

export interface FormBlockingDialogProps {
  onResetForm: () => void;
  currentlyEditedItemUUID: string | null;
}

export const FormDataDiscardDialog: FC<
  Pick<ConfirmationDialogProps, 'onPrimaryActionClick' | 'onSecondaryActionClick' | 'open'>
> = (props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationDialog
      title={t('modals.confirmationAbort.title')}
      headline={t('modals.confirmationAbort.headline')}
      text={t('modals.confirmationAbort.text')}
      primaryActionLabel={t('actions.discard')}
      open={props.open}
      onPrimaryActionClick={props.onPrimaryActionClick}
      onCloseClick={props.onSecondaryActionClick || (() => ({}))}
    />
  );
};

export const FormBlockingDialog: FC<FormBlockingDialogProps> = ({ onResetForm, currentlyEditedItemUUID }) => {
  const isAnyFormDirty = useSelector<GlobalState, boolean>((state) => state.formsDirtyState.isAnyFormDirty);
  const [showBlocker, setShowBlocker] = useState(false);
  const [transition, setTransition] = useState<Transition>();

  const blockerFunction = useCallback(
    (tx: Transition) => {
      const path = tx.location.pathname;
      if (
        (currentlyEditedItemUUID && path.indexOf(currentlyEditedItemUUID) > -1) ||
        path.indexOf('preview') > -1 ||
        path.indexOf('new') > -1
      ) {
        // Do not block
        setShowBlocker(false);
        tx.retry();
      } else {
        setShowBlocker(true);
        setTransition(tx);
      }
    },
    [currentlyEditedItemUUID, showBlocker]
  );

  useBlocker(blockerFunction, isAnyFormDirty && !showBlocker);

  return (
    <FormDataDiscardDialog
      open={showBlocker}
      onPrimaryActionClick={() => {
        setShowBlocker(false);
        onResetForm();
        transition && transition.retry();
      }}
      onSecondaryActionClick={() => {
        setShowBlocker(false);
      }}
    />
  );
};

export default FormBlockingDialog;
