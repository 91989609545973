import { CustomMultiSelectItem } from '../../../../utils/types';
import { ListingSortOrder, ListingType, TagCategory } from '../../../../graphql/types/globalTypes';

// Type definitions
export type SortingItem = CustomMultiSelectItem<ListingSortOrder>;
export type CityItem = CustomMultiSelectItem<string>;
export type ListingTypeItem = CustomMultiSelectItem<ListingType>;
export type AvailabilityItem = CustomMultiSelectItem<Availability>;
export type RoomItem = CustomMultiSelectItem<number>;
export type DistrictItem = CustomMultiSelectItem<string>;
export type RentType = CustomMultiSelectItem<boolean | null>;
export type AnyItem = { id: null; text: string };

export const anyItem: AnyItem = { id: null, text: 'main.any' };
export const getResponsivePlaceholderItem = (key: string, showAny?: boolean): AnyItem =>
  !showAny ? { id: null, text: key } : anyItem;

export const sortingItems = [
  { id: ListingSortOrder.MOST_RECENT, text: 'sorting.mostRecent' },
  { id: ListingSortOrder.LAST_MODIFIED, text: 'sorting.lastModified' },
  { id: ListingSortOrder.RENT_ASCENDING, text: 'sorting.rentAscending' },
  { id: ListingSortOrder.RENT_DESCENDING, text: 'sorting.rentDescending' }
];

export const listingTypeItems = [
  { id: ListingType.APARTMENT, text: 'enums.listingType.APARTMENT' },
  { id: ListingType.HOUSE, text: 'enums.listingType.HOUSE' },
  {
    id: ListingType.SHARED_APARTMENT,
    text: 'enums.listingType.SHARED_APARTMENT'
  }
];

export const rentTypeItems: RentType[] = [
  anyItem,
  { id: false, text: 'views.listings.filter.timeLimit' },
  { id: true, text: 'views.listings.filter.openEnded' }
];

export enum ListingVisibility {
  PUBLIC = 'PUBLIC',
  NOT_PUBLIC = 'NOT_PUBLIC'
}

export const ORDERED_TAG_CATEGORIES = [TagCategory.FURNISHING, TagCategory.SANITARY, TagCategory.OTHER];

export enum Availability {
  NOW,
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER
}

export const availabilityItems = [
  { id: Availability.NOW, text: 'enums.availability.NOW' },
  { id: Availability.JANUARY, text: 'enums.availability.JANUARY' },
  { id: Availability.FEBRUARY, text: 'enums.availability.FEBRUARY' },
  { id: Availability.MARCH, text: 'enums.availability.MARCH' },
  { id: Availability.APRIL, text: 'enums.availability.APRIL' },
  { id: Availability.MAY, text: 'enums.availability.MAY' },
  { id: Availability.JUNE, text: 'enums.availability.JUNE' },
  { id: Availability.JULY, text: 'enums.availability.JULY' },
  { id: Availability.AUGUST, text: 'enums.availability.AUGUST' },
  { id: Availability.SEPTEMBER, text: 'enums.availability.SEPTEMBER' },
  { id: Availability.OCTOBER, text: 'enums.availability.OCTOBER' },
  { id: Availability.NOVEMBER, text: 'enums.availability.NOVEMBER' },
  { id: Availability.DECEMBER, text: 'enums.availability.DECEMBER' }
];
