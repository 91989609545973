import { FC, Children, isValidElement } from 'react';
import styles from './action-bar.module.scss';

export const ActionBarLeft: FC = ({ children }) => {
  return <div className={styles.ActionBarLeft}>{children}</div>;
};

export const ActionBarCenter: FC = ({ children }) => {
  return <div className={styles.ActionBarCenter}>{children}</div>;
};

export const ActionBarRight: FC = ({ children }) => {
  return <div className={styles.ActionBarRight}>{children}</div>;
};

export const ActionBar: FC = ({ children }) => {
  Children.forEach(children, (child) => {
    if (
      !(
        isValidElement(child) &&
        (child.type === ActionBarLeft || child.type === ActionBarRight || child.type === ActionBarCenter)
      )
    ) {
      throw new Error('Only ActionBarLeft or ActionBarRight allowed as direct children of ActionBar');
    }
  });

  return (
    <div className={styles.ActionBar} role="region">
      <div className="global-content-wrapper">{children}</div>
    </div>
  );
};

export default ActionBar;
