import { FetchResult } from '@apollo/client';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GlobalState } from '../../../../../redux/store';
import { useUserPermission } from '../../../../../utils/hooks/user-data';
import { FormStateValidationFunction } from '../../../../../utils/validation/validation-utils';
import {
  useDeleteMultipleListingImages,
  useManagementType,
  usePostNewListingMutations,
  useUpdateListingMutations
} from '../listing-edit/listing-edit.hooks';
import { ListingFormDataState, resetListingForm, setIsActive } from '../listing.state';

export const useSaveFunction = (
  validateState: FormStateValidationFunction,
  refetchListing?: () => Promise<unknown>
): ((shouldPublish: boolean) => () => Promise<unknown>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const { id, isActive, images } = formState;

  const { isLandlord } = useUserPermission();
  const [managementType] = useManagementType(formState);
  const [postListingSave, postListingPublish] = usePostNewListingMutations();
  const [updateListingSave, updateListingPublish] = useUpdateListingMutations(isLandlord);
  const deleteMultipleListingImages = useDeleteMultipleListingImages();

  const locallyActivateListing = (): Promise<unknown> => {
    return new Promise((resolve) => {
      dispatch(setIsActive(true));
      resolve({});
    });
  };

  const saveFunction = useCallback(
    (shouldPublish: boolean) => (): Promise<unknown> => {
      const shouldActivate = shouldPublish && !isActive;
      return new Promise((resolve) =>
        // validate the form first
        validateState(
          // callback after form validation
          () => {
            if (id) {
              // we are in edit mode of an existing listing

              // find images marked for deletion
              const imagesMarkedForDeletionIds = images
                .filter((image) => image.markedForDeletion)
                .map((image) => image.id);

              // either save or publish the listing depending on which action was triggered
              const updateSaveOrPublish = (): Promise<unknown> => {
                if (shouldActivate) {
                  return locallyActivateListing().then(updateListingPublish);
                }
                return updateListingSave();
              };

              // delete marked images first, then proceed saving/publishing process
              deleteMultipleListingImages({ variables: { ids: imagesMarkedForDeletionIds } }).then(() =>
                updateSaveOrPublish().then(refetchListing).then(resolve)
              );
            } else {
              // we are in creation mode of a new listing

              // function to reset the form and navigate to the view mode of the created listing
              const resetAndRedirectIfSuccessful = (result: FetchResult): void => {
                if (result.data && result.data.addListing) {
                  const newListingUuid = result.data.addListing;
                  dispatch(resetListingForm());
                  navigate(`/${isLandlord ? 'account' : 'listings'}/${newListingUuid}/view`);
                }
              };

              // either save or publish the listing depending on which action was triggered,
              // then navigate to the respective view
              if (shouldActivate) {
                locallyActivateListing()
                  .then(() => {
                    postListingPublish().then(resetAndRedirectIfSuccessful);
                  })
                  .then(resolve);
              } else {
                postListingSave().then(resetAndRedirectIfSuccessful).then(resolve);
              }
            }
          },
          { managementType }
        )
      );
    },
    [
      updateListingSave,
      updateListingPublish,
      postListingSave,
      postListingPublish,
      refetchListing,
      id,
      images,
      dispatch,
      resetListingForm,
      formState,
      managementType,
      isActive
    ]
  );

  return saveFunction;
};
