import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import path from 'path-browserify';
import { Tag } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { GlobalState } from '../../../../redux/store';
import { listingLocationString, listingTypeString } from '../../../../utils/listings';
import { BreadcrumbNavigation } from '../../../building-blocks/breadcrumb-navigation/breadcrumb-navigation';
import { BackNavigation } from '../../../controls/back-navigation/back-navigation';
import { ListingView } from './listing-view/listing-view';
import { ListingFormDataState, resetListingForm } from './listing.state';
import { useDropdownItemsFromEnumsOrConstants, useFetchListing } from './listing.hooks';
import { useUserPermission } from '../../../../utils/hooks/user-data';
import { ListingEdit } from './listing-edit/listing-edit';
import { useDeleteMultipleListingImages } from './listing-edit/listing-edit.hooks';
import { ListingSaveBarEdit } from './listing-bottom-bar/listing-save-bar-edit';
import { useFormValidation } from '../../../../utils/validation/validation-utils';
import { listingValidationSchema } from './listing.validation';
import ListingSaveBarView from './listing-bottom-bar/listing-save-bar-view';
import { LinkSVGIcon, ViewListSVGIcon } from '@react-md/material-icons';
import { copyURLtoClipboard } from '../../../../utils/actions';
import { ConfirmationDialog } from '../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { LandlordFormDataState } from '../../landlord/landlord-view/landlord-view.state';
import styles from './listing.module.scss';
import { useResponsiveBreakpoints } from '../../../../utils/hooks/responsive.hooks';

export const Listing: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { uuid, viewMode, landlordUUID } = useParams();
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const landlordFormState = useSelector<GlobalState, LandlordFormDataState>((state) => state.landlordFormData);
  const userPermissions = useUserPermission();
  const breakPoints = useResponsiveBreakpoints();

  const [openModal, setOpenModal] = useState(false);

  const deleteMultipleListingImages = useDeleteMultipleListingImages();
  const deleteUnsavedImages = useCallback(() => {
    const locallyAddedImageIds = images.filter((image) => image.origin === 'client').map((image) => image.id);
    deleteMultipleListingImages({ variables: { ids: locallyAddedImageIds } });
  }, [formState.images]);

  // This hook loads a listing from the uuid given in the url, if it is not the creation of a new listing
  const { refetch } = useFetchListing();

  const { numberOfRooms, type, city, tumLocation, district, isActive, images, uuid: formUUID, id } = formState;

  const selectableItems = useDropdownItemsFromEnumsOrConstants(tumLocation);

  const listingTitle = useMemo(() => {
    return `${listingTypeString(t, numberOfRooms, type)} ${listingLocationString(t, city, district)}`;
  }, [t, numberOfRooms, type, city, district]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { duplicate } = location.state || { duplicate: 0 };

  const isCreationMode = useMemo(() => {
    return uuid === undefined;
  }, [uuid]);

  const isPreviewMode = useMemo(() => {
    return viewMode === 'preview';
  }, [viewMode]);

  const onResetForm = useCallback(() => {
    if (isCreationMode) {
      const imageIds = images.map((image) => image.id);
      deleteMultipleListingImages({ variables: { ids: imageIds } });
    } else {
      deleteUnsavedImages();
    }
    dispatch(resetListingForm());
  }, [isCreationMode, images]);

  // If we are in creation mode and an id is still set, we need to reset the form.
  // This can happen by updating an existing listing, then using the back navigation
  // and then creating a new one.
  useEffect(() => {
    if (isCreationMode && id !== null) {
      deleteUnsavedImages();
      dispatch(resetListingForm());
    }
  }, [isCreationMode, id]);

  // This effect cleans up unsaved images from an existing listing
  useEffect(() => {
    if (uuid && formUUID && uuid !== formUUID) {
      deleteUnsavedImages();
    }
  }, [uuid, formUUID]);

  const formValidationUtils = useFormValidation(listingValidationSchema, formState);

  const isLoaded = uuid === undefined || uuid === formState.uuid;

  return (
    <>
      {breakPoints.isDesktop && (
        <BreadcrumbNavigation
          resolver={[
            // default route label
            null,
            // show landlord name if this view is nested, otherwise render abbreviated ID
            landlordUUID
              ? () =>
                  landlordFormState.firstName
                    ? `${landlordFormState.firstName} ${landlordFormState.lastName}`
                    : `${formState.landlord?.firstName || ''} ${formState.landlord?.lastName || ''}`
              : (segment) => {
                  return segment === 'new' || segment === 'preview'
                    ? t('views.listings.newListing')
                    : `ID ${uuid.slice(0, 8)}`;
                },
            // default route label when landlord is given, empty otherwise
            landlordUUID ? null : () => null,
            // render abbreviated id in case this view is nested
            landlordUUID
              ? (segment) => {
                  return segment === 'new' || segment === 'preview'
                    ? t('views.listings.newListing')
                    : `ID ${uuid.slice(0, 8)}`;
                }
              : null,
            // omit last level in case this is nested
            landlordUUID ? () => null : null
          ]}
        />
      )}
      <BackNavigation
        headline={listingTitle}
        subHeadline={isCreationMode ? t('views.listings.newListing') : duplicate === 1 ? t('views.listings.copy') : ''}
        navigateTo={isCreationMode ? '..' : './../..'}
        tag={
          userPermissions.hasAdministrativeFunction || userPermissions.isLandlord ? (
            <Tag
              className={classNames({
                [styles.TagInactive]: !isActive,
                [styles.TagActive]: isActive
              })}
            >
              {t(isActive ? 'listing.active' : 'listing.inactive')}
            </Tag>
          ) : undefined
        }
      />
      <Routes>
        <Route
          path="/"
          element={
            <>
              {viewMode === 'new' || viewMode === 'edit' ? (
                <>
                  <ListingEdit
                    formState={formState}
                    uuid={uuid}
                    isCreationMode={isCreationMode}
                    userPermissions={userPermissions}
                    selectableItems={selectableItems}
                    onResetForm={onResetForm}
                    formValidationUtils={formValidationUtils}
                  />
                  <ListingSaveBarEdit
                    validateState={formValidationUtils.validateState}
                    isCreationMode={isCreationMode}
                    refetchListing={refetch}
                    showActionModal={() => userPermissions.isLandlord && setOpenModal(true)}
                  />
                </>
              ) : (
                <>
                  <ListingView
                    formState={formState}
                    onResetForm={onResetForm}
                    isCreationMode={isCreationMode}
                    isPreviewMode={isPreviewMode}
                    userPermissions={userPermissions}
                    tagsForCategories={selectableItems.tagsForCategories}
                    listingTitle={listingTitle}
                    isLoaded={isLoaded}
                  />
                  <ListingSaveBarView
                    formState={formState}
                    userPermissions={userPermissions}
                    uuid={uuid}
                    refetch={refetch}
                    isCreationMode={isCreationMode}
                    showActionModal={() => userPermissions.isLandlord && setOpenModal(true)}
                  ></ListingSaveBarView>
                </>
              )}
              {openModal && (
                <ConfirmationDialog
                  className={classNames(styles.SaveBarModal, { [styles.Activated]: isActive })}
                  open={true}
                  title={t(`views.listings.modal.${!isActive ? 'deactivateHeadline' : 'activateHeadline'}`)}
                  text={t(`views.listings.modal.${!isActive ? 'deactivateInfo' : 'activateInfo'}`)}
                  headline={t(`views.listings.modal.${!isActive ? 'deactivateTitle' : 'activateTitle'}`)}
                  primaryActionLabel={t(`actions.${!isActive ? 'backToOverview' : 'showListing'}`)}
                  onPrimaryActionClick={() => {
                    navigate('../..');
                  }}
                  onCloseClick={() => {
                    setOpenModal(false);
                  }}
                  secondaryActionLabel={!isActive ? undefined : t('actions.copyLink')}
                  onSecondaryActionClick={
                    !isActive
                      ? undefined
                      : () =>
                          copyURLtoClipboard(
                            path.join(process.env.REACT_APP_PUBLIC_URL || '', `listings/${uuid}/view`),
                            t
                          )
                  }
                  primaryButtonIcon={ViewListSVGIcon}
                  secondaryButtonIcon={LinkSVGIcon}
                />
              )}
            </>
          }
        />
      </Routes>
    </>
  );
};

export default Listing;
