import { useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { GET_NUMBER_OF_NEW_LANDLORDS } from '../../graphql/queries/landlord';
import { GET_NUMBER_OF_NEW_SEARCH_REQUESTS } from '../../graphql/queries/search-requests';
import { NumberOfNewLandlords } from '../../graphql/types/NumberOfNewLandlords';
import { NumberOfNewSearchRequests } from '../../graphql/types/NumberOfNewSearchRequests';
import { setNumberOfNewLandlords, setNumberOfNewSearchRequests } from '../../redux/administration.state';

export const useFetchNumberOfNewLandlords = (): (() => Promise<unknown>) => {
  const dispatch = useDispatch();

  // Get the total number of landlords with filter
  const { refetch: fetchNumberOfNewLandlords } = useQuery<NumberOfNewLandlords>(GET_NUMBER_OF_NEW_LANDLORDS, {
    fetchPolicy: 'no-cache',
    skip: true
  });

  return () =>
    fetchNumberOfNewLandlords().then((data) => dispatch(setNumberOfNewLandlords(data.data.numberOfNewLandlords)));
};

export const useFetchNumberOfNewSearchRequests = (): (() => Promise<unknown>) => {
  const dispatch = useDispatch();

  // Get the total number of search requests
  const { refetch: fetchNumberOfNewSearchRequests } = useQuery<NumberOfNewSearchRequests>(
    GET_NUMBER_OF_NEW_SEARCH_REQUESTS,
    {
      fetchPolicy: 'no-cache',
      skip: true
    }
  );

  return () =>
    fetchNumberOfNewSearchRequests().then((data) => {
      const numberOfNewSearchRequests = data.data.numberOfNewSearchRequests;
      dispatch(setNumberOfNewSearchRequests(numberOfNewSearchRequests));
    });
};
