import { ActionCreator, ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LandlordProfileDataState {
  showFirstName: boolean;
  showLastName: boolean;
  showEmail: boolean;
  showPhone: boolean;
  showCompanyName: boolean;
}

export const initialLandlordProfileDataState: LandlordProfileDataState = {
  showFirstName: true,
  showLastName: true,
  showEmail: true,
  showPhone: true,
  showCompanyName: true
};

export const slice = createSlice({
  name: 'LandlordFormData',
  reducers: {
    setShowFirstName: (state: LandlordProfileDataState, action: PayloadAction<boolean>) => {
      state.showFirstName = action.payload;
    },
    setShowLastName: (state: LandlordProfileDataState, action: PayloadAction<boolean>) => {
      state.showLastName = action.payload;
    },
    setShowEmail: (state: LandlordProfileDataState, action: PayloadAction<boolean>) => {
      state.showEmail = action.payload;
    },
    setShowPhone: (state: LandlordProfileDataState, action: PayloadAction<boolean>) => {
      state.showPhone = action.payload;
    },
    setShowCompany: (state: LandlordProfileDataState, action: PayloadAction<boolean>) => {
      state.showCompanyName = action.payload;
    },
    resetLandlordProfileData: (state: LandlordProfileDataState) => {
      Object.assign(state, initialLandlordProfileDataState);
    },
    setLandlordProfileData: (state: LandlordProfileDataState, action: PayloadAction<LandlordProfileDataState>) => {
      Object.assign(state, action.payload);
    }
  },
  initialState: initialLandlordProfileDataState
});

export const setShowFirstName: ActionCreatorWithPayload<boolean> = slice.actions.setShowFirstName;
export const setShowLastName: ActionCreatorWithPayload<boolean> = slice.actions.setShowLastName;
export const setShowEmail: ActionCreatorWithPayload<boolean> = slice.actions.setShowEmail;
export const setShowPhone: ActionCreatorWithPayload<boolean> = slice.actions.setShowPhone;
export const setShowCompany: ActionCreatorWithPayload<boolean> = slice.actions.setShowCompany;
export const setLandlordProfileData: ActionCreatorWithPayload<LandlordProfileDataState> =
  slice.actions.setLandlordProfileData;

export const landlordProfileDataReducer = slice.reducer;

export const landlordProfileDataActionsResettingDirtyState = [
  slice.actions.resetLandlordProfileData,
  slice.actions.setLandlordProfileData
];

const actionsTriggeringDirtyStateExclusions: ActionCreator<unknown>[] = [
  ...landlordProfileDataActionsResettingDirtyState
];

export const landlordProfileDataActionsTriggeringDirtyState = Object.values(slice.actions).filter(
  (value: ActionCreator<unknown>) => actionsTriggeringDirtyStateExclusions.indexOf(value) === -1
);
