import { gql } from '@apollo/client';

export const POST_ADD_COMMENT = gql`
  mutation AddComment($searchRequest: ID!, $content: String!) {
    addSearchRequestComment(searchRequest: $searchRequest, content: $content)
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    deleteSearchRequestComment(id: $id)
  }
`;

export const PUT_UPDATE_COMMENT = gql`
  mutation UpdateComment($searchRequestComment: SearchRequestCommentInput!) {
    updateSearchRequestComment(searchRequestComment: $searchRequestComment)
  }
`;

export const GET_COMMENTS = gql`
  query GetComments($searchRequestId: ID!, $limit: Int) {
    searchRequestCommentsForSearchRequest(searchRequestId: $searchRequestId, limit: $limit) {
      id
      searchRequest
      authorEmail
      authorName
      content
      modifiedAt
    }
  }
`;
