import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './rent-composition.module.scss';
import { formatIntegerCurrency } from '../../../utils/intl/currency';
import classNames from 'classnames';

export interface RentCompositionProps {
  rent: number;
  incidentalCosts: number;
  incidentalCostsTypes: (string | null)[];
  parkingSpaceCosts: number;
  fullRent: number;
  mode?: 'view' | 'edit';
  className?: string;
}

export const RentComposition: FC<RentCompositionProps> = ({
  incidentalCosts,
  incidentalCostsTypes,
  rent,
  fullRent,
  parkingSpaceCosts,
  className,
  mode = 'edit'
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.RentComposition, className, { [styles.RentCompositionEditMode]: mode === 'edit' })}
    >
      <h3 className={styles.RentCompositionHeader}>
        {t(mode === 'edit' ? 'rent.copositionHeading' : 'rent.copositionHeadingAlt')}
      </h3>
      <div className={styles.RentCompositionRow}>
        <span>{t('rent.rent')}</span>
        <span>{formatIntegerCurrency(rent)}</span>
      </div>
      <div className={styles.RentCompositionRow}>
        <div>
          <span id="incidentalCostsLabel">
            {mode === 'view' ? '+ ' : ''}
            {t('rent.incidentalCosts')}
          </span>
          <ul
            aria-labelledby="incidentalCostsLabel"
            className={classNames(styles.IncidentalCostsTypes, {
              [styles.IncidentalCostsTypesEditMode]: mode === 'edit'
            })}
          >
            {incidentalCostsTypes.map((type, index) => type && <li key={index}>{type}</li>)}
          </ul>
        </div>
        <span>{formatIntegerCurrency(incidentalCosts)}</span>
      </div>
      {parkingSpaceCosts > 0 && (
        <div className={styles.RentCompositionRow}>
          <span>
            {mode === 'view' ? '+ ' : ''}
            {t('rent.parkingSpaceCosts')}
          </span>
          <span>{formatIntegerCurrency(parkingSpaceCosts)}</span>
        </div>
      )}
      <div className={styles.RentCompositionResultRow}>
        <span>{t('rent.fullRent')}</span>
        <span>{formatIntegerCurrency(fullRent)}</span>
      </div>
    </div>
  );
};

export default RentComposition;
