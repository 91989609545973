import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThumbUpIcon } from '../../../../../assets/svg/thumb_up.svg';
import styles from './listing-view.module.scss';

export interface TargetGroups {
  seekingStudents: boolean;
  seekingProfessors: boolean;
  seekingIncomings: boolean;
  seekingDoctoralStudents: boolean;
  seekingPostDoctoralStudents: boolean;
  seekingGuestResearchers: boolean;
  seekingTumEmployees: boolean;
}

interface TargetGroupCardProps {
  targets: TargetGroups;
}

export const TargetGroupCard: FC<TargetGroupCardProps> = ({ targets }) => {
  const { t } = useTranslation();

  const targetGroupIcon = <ThumbUpIcon title={t('views.listings.viewSections.targetGroupsAlt')} />;

  return (
    <ul className={styles.TargetGroups}>
      {targets.seekingStudents && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.STUDENTS')}</span>
        </li>
      )}

      {targets.seekingProfessors && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.PROFESSORS')}</span>
        </li>
      )}
      {targets.seekingIncomings && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.INCOMINGS')}</span>
        </li>
      )}
      {targets.seekingDoctoralStudents && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.DOCTORAL_STUDENTS')}</span>
        </li>
      )}
      {targets.seekingPostDoctoralStudents && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.POST_DOCTORAL_STUDENTS')}</span>
        </li>
      )}
      {targets.seekingGuestResearchers && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.GUEST_RESEARCHERS')}</span>
        </li>
      )}
      {targets.seekingTumEmployees && (
        <li>
          {targetGroupIcon}
          <span>{t('enums.targetGroup.TUM_EMPLOYEES')}</span>
        </li>
      )}
    </ul>
  );
};

export default TargetGroupCard;
