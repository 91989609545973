import * as ReactDOM from 'react-dom';
import './index.scss';
import App from './app';
import * as React from 'react';
import './i18n';
import './image-lazy-loading';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { defaultStore } from './redux/store';
import TUMApolloProvider from './graphql/tum-apollo-provider';

ReactDOM.render(
  <Provider store={defaultStore}>
    <React.Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <TUMApolloProvider>
          <App />
        </TUMApolloProvider>
      </BrowserRouter>
    </React.Suspense>
  </Provider>,
  document.getElementById('root')
);
