import { Tag } from 'carbon-components-react';
import classNames from 'classnames';
import {
  Children,
  FC,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { StyleableProps } from '../../../utils/types';
import { AnchorNavigation, AnchorNavItem } from '../../controls/anchor-navigation/anchor-navigation';
import styles from './content-view.module.scss';

export interface ContentViewSectionProps {
  title: string;
  anchorId: string;
  navTitle?: string;
  tagCount?: number;
  showRequiredToolTip?: boolean;
  requireAll?: boolean;
  suffix?: ReactNode;
}

export interface RequiredFormItemProps {
  isEditMode: boolean;
}

export const RequiredFormItem: FC<StyleableProps & RequiredFormItemProps> = ({ children, className, isEditMode }) => (
  <div className={classNames(isEditMode && styles.RequiredFormItem, className)}>{children}</div>
);

export const RequiredMarker: FC = ({ children }) => <span className={styles.RequiredMarker}>{children}</span>;

export const ContentViewSection = forwardRef<
  HTMLHeadingElement,
  PropsWithChildren<ContentViewSectionProps & StyleableProps>
>(({ children, title, anchorId, tagCount, showRequiredToolTip, requireAll, className, suffix }, ref) => {
  const { t } = useTranslation();
  return (
    <section
      className={classNames(className, {
        [styles.RequireAll]: showRequiredToolTip && requireAll
      })}
    >
      <div>
        <h2 id={anchorId} ref={ref}>
          {title}
          {tagCount !== undefined && tagCount > 0 && <Tag size="sm">{tagCount}</Tag>}
          {suffix !== undefined && suffix}
        </h2>
        {showRequiredToolTip && <span className={styles.RequiredToolTip}>{t('formFields.requiredFields')}</span>}
      </div>
      {children}
    </section>
  );
});

const checkChildrenType = (children: ReactNode): children is ReactElement<ContentViewSectionProps>[] => {
  /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
  children &&
    children !== null &&
    Children.forEach(children, (child) => {
      // We can check for equality with child.type === ContentViewSection, however this causes
      // problems when hot-reloading in development. Therefore we check for the name.
      //
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // if (!isValidElement(child) || child.type.name !== 'ContentViewSection') {
      //   throw new Error('Only ContentViewSection allowed as direct children of ContentView');
      // }
    });
  /* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */

  return children !== null;
};

const observerOptions = {
  rootMargin: '10px',
  threshold: [0, 0.25, 0.5, 0.75, 1]
};

export interface ContentViewProps {
  className?: string;
}

export const ContentView: FC<ContentViewProps> = ({ children, className }) => {
  const sectionsRef = useRef<HTMLDivElement>(null);

  const [headings, setHeadings] = useState<Element[]>();
  const [sections, setSections] = useState<Element[]>();
  const [activeHeading, setActiveHeading] = useState<number>();

  // this effect sets the required html elements as soon as the parent ref is available
  useEffect(() => {
    if (sectionsRef && sectionsRef.current) {
      // get all section headings
      const sections = sectionsRef.current.querySelectorAll('section');
      const headings = sectionsRef.current.querySelectorAll('h2[id]');

      setHeadings(Array.from(headings));
      setSections(Array.from(sections));
    }
  }, [sectionsRef, setHeadings, setSections]);

  // here we create a callback whenever the headings change
  const intersectionCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      // check first, if any section is visible
      const isAnyVisible = entries.find((entry) => entry.isIntersecting);
      if (isAnyVisible && headings) {
        // find the heading which has the smallest absolute distance to the top of the viewport
        let index = 0;
        let smallestTopDistance = Math.abs(headings[0].getBoundingClientRect().top);
        for (let i = 1; i < headings.length; i++) {
          const currentTopDistance = Math.abs(headings[i].getBoundingClientRect().top);
          if (currentTopDistance < smallestTopDistance) {
            smallestTopDistance = currentTopDistance;
            index = i;
          }
        }

        // remove fragment hash from url
        const noHashURL = window.location.href.replace(/#.*$/, `#${headings[index].id}`);
        window.history.replaceState('', document.title, noHashURL);
        setActiveHeading(index);
      }
    },
    [headings]
  );

  // this effect registers the sections with the intersection observer
  useEffect(() => {
    if (sections) {
      const observer = new IntersectionObserver(intersectionCallback, observerOptions);
      sections.forEach((section) => observer.observe(section));
      return () => {
        sections.forEach((section) => observer.unobserve(section));
      };
    }
  }, [sections, intersectionCallback]);

  let navItems: AnchorNavItem[] = [];
  if (checkChildrenType(children)) {
    navItems = children
      .map(
        (child) =>
          child && {
            id: child.props.anchorId,
            label: child.props.navTitle || child.props.title,
            tagCount: child.props.tagCount
          }
      )
      .filter(Boolean);
  }

  return (
    <div className={classNames(styles.ContentView, className)}>
      <AnchorNavigation items={navItems} selected={activeHeading} />
      <div ref={sectionsRef}>{children}</div>
    </div>
  );
};

export default ContentView;
