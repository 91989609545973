import { ActionCreatorWithPayload, AnyAction, createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import {
  landlordProfileDataActionsResettingDirtyState,
  landlordProfileDataActionsTriggeringDirtyState
} from '../components/views/account/account-view.state';
import {
  landlordActionsTriggeringDirtyState,
  landlordActionsResettingDirtyState
} from '../components/views/landlord/landlord-view/landlord-view.state';
import {
  listingActionsTriggeringDirtyState,
  listingActionsResettingDirtyState
} from '../components/views/listings/listing/listing.state';
import {
  searchRequestFormActionsResettingDirtyState,
  searchRequestFormActionsTriggeringDirtyState
} from '../components/views/search-requests/search-request/search-request.state';

export interface FormsDirtyState {
  isAnyFormDirty: boolean;
}

export const initialFormsDirtyState: FormsDirtyState = {
  isAnyFormDirty: false
};

const slice = createSlice({
  name: 'UserState',
  reducers: {
    setIsAnyFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isAnyFormDirty = action.payload;
    }
  },
  initialState: initialFormsDirtyState
});

export const setIsAnyFormDirty: ActionCreatorWithPayload<boolean> = slice.actions.setIsAnyFormDirty;

export const formsDirtyStateReducer = slice.reducer;

const dirtyStateTriggeringActions = [
  ...listingActionsTriggeringDirtyState,
  ...landlordActionsTriggeringDirtyState,
  ...searchRequestFormActionsTriggeringDirtyState,
  ...landlordProfileDataActionsTriggeringDirtyState
];
const dirtyStateClearingActions = [
  ...listingActionsResettingDirtyState,
  ...landlordActionsResettingDirtyState,
  ...searchRequestFormActionsResettingDirtyState,
  ...landlordProfileDataActionsResettingDirtyState
];
/**
 * Logs all actions and states after they are dispatched.
 */
export const formDirtyStateMiddleWare = () => (next: Dispatch<AnyAction>) => <A extends AnyAction>(action: A): A => {
  if (dirtyStateTriggeringActions.find((actionCreator) => actionCreator.match(action))) {
    next(setIsAnyFormDirty(true));
  } else if (dirtyStateClearingActions.find((actionCreator) => actionCreator.match(action))) {
    next(setIsAnyFormDirty(false));
  }
  const result = next(action);
  return result;
};
