import { FC, useEffect, useMemo, useState } from 'react';
import styles from './user-info.module.scss';
import classNames from 'classnames';
import { Checkbox, Dropdown, FormGroup, Modal, Toggle } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { UserState } from '../../../redux/user.state';
import { getApiBaseUrl } from '../../../utils/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createPortal } from 'react-dom';
import {
  ListingLocations,
  ListingTargets,
  useListingLocations,
  useListingTargets,
  useSubscriptionMappings,
  useUpdateSubscriptionMappings
} from './user-info.hooks';
import { useUserPermission } from '../../../utils/hooks/user-data';
import { logout } from '../../../redux/store';
import { ProfileDialog } from '../../views/profile-dialog/profile-dialog';
import { Param, START_VIEW_PATH } from '../../../utils/constants';
import { setIsAnyFormDirty } from '../../../redux/forms.state';
import { AccountCircleSVGIcon } from '@react-md/material-icons';
import { useUrlSearchParams } from 'use-url-search-params';

export interface UserInfoProps {
  name: string;
  role: string;
}

interface SettingsDialogProps {
  onClose: () => void;
}

const SettingsModal: FC<SettingsDialogProps> = (props) => {
  const { t } = useTranslation();
  const [loadSettings, setLoadSettings] = useState(false);
  const {
    updateLandlordRegistered,
    updateListingPublished,
    updateSearchRequest,
    updateRequestUpdated,
    updateSearchRequestComments
  } = useUpdateSubscriptionMappings();

  const subscriptionSettings = useSubscriptionMappings();
  const { targets: listingTargets, refetchListingTargets, updateListingTargets } = useListingTargets();
  const { locations: listingLocations, refetchListingLocations, updateListingLocations } = useListingLocations();

  const {
    seekingStudents,
    seekingIncomings,
    seekingDoctoralStudents,
    seekingGuestResearchers,
    seekingPostDoctoralStudents,
    seekingProfessors,
    seekingTumEmployees
  } = listingTargets;

  const seekingFlags = [
    seekingStudents,
    seekingProfessors,
    seekingIncomings,
    seekingDoctoralStudents,
    seekingPostDoctoralStudents,
    seekingGuestResearchers,
    seekingTumEmployees
  ];

  const {
    seekingFreising,
    seekingGarching,
    seekingHeilbronn,
    seekingMunich,
    seekingStraubing,
    seekingGarmischPartenkirchen
  } = listingLocations;

  const seekingLocationFlags = [
    seekingFreising,
    seekingGarching,
    seekingHeilbronn,
    seekingMunich,
    seekingStraubing,
    seekingGarmischPartenkirchen
  ];

  const seekingAll = seekingFlags.every((flag) => flag);
  const seekingNone = seekingFlags.every((flag) => !flag);

  const seekingLocationsAll = seekingLocationFlags.every((flag) => flag);
  const seekingLocationsNone = seekingLocationFlags.every((flag) => !flag);

  if (!loadSettings) {
    subscriptionSettings
      .refetch()
      .then(() => refetchListingTargets().then(() => refetchListingLocations().then(() => setLoadSettings(true))));
  }

  return (
    <Modal
      className={styles.ModalSettings}
      passiveModal={true}
      size={'sm'}
      open={loadSettings}
      onRequestClose={props.onClose}
      modalLabel={t('header.modalSettings.modalHeadline')}
      iconDescription={t('actions.close')}
    >
      <div className="bx--modal-custom-content">
        <h3>{t('header.modalSettings.contentHeadline')}</h3>
        <div className={styles.Settings}>
          <div>
            <h4>{t('header.modalSettings.landlords')}</h4>
            <Toggle
              id={'landlord-registered'}
              data-testid={'LandlordRegistered'}
              labelText={t('header.modalSettings.landlordRegistered')}
              labelA={t('actions.off')}
              labelB={t('actions.on')}
              toggled={subscriptionSettings.landlordRegistered}
              onToggle={(checked) =>
                updateLandlordRegistered({
                  variables: { subscribe: checked }
                }).then(() => subscriptionSettings.landlordRefetch())
              }
            />

            <h4>{t('header.modalSettings.searchRequests')}</h4>
            <Toggle
              id={'search-requests'}
              data-testid={'SearchRequests'}
              labelText={t('header.modalSettings.searchRequestsSeekers')}
              labelA={t('actions.off')}
              labelB={t('actions.on')}
              toggled={subscriptionSettings.searchRequest}
              onToggle={(checked) =>
                updateSearchRequest({ variables: { subscribe: checked } }).then(() =>
                  subscriptionSettings.searchRefetch()
                )
              }
            />
            <Toggle
              id={'update-request'}
              data-testid={'UpdateRequest'}
              labelText={t('header.modalSettings.updateRequest')}
              labelA={t('actions.off')}
              labelB={t('actions.on')}
              toggled={subscriptionSettings.requestUpdated}
              onToggle={(checked) =>
                updateRequestUpdated({ variables: { subscribe: checked } }).then(() =>
                  subscriptionSettings.updatedRefetch()
                )
              }
            />
            <Toggle
              id={'new-comments'}
              data-testid={'NewComments'}
              labelText={t('header.modalSettings.newComments')}
              labelA={t('actions.off')}
              labelB={t('actions.on')}
              toggled={subscriptionSettings.newComments}
              onToggle={(checked) =>
                updateSearchRequestComments({
                  variables: { subscribe: checked }
                }).then(() => subscriptionSettings.commentsRefetch())
              }
            />
          </div>
          <div>
            <h4>{t('header.modalSettings.listings')}</h4>
            <Toggle
              id={'listings-published'}
              data-testid={'ListingsPublished'}
              labelText={t('header.modalSettings.listingsPublished')}
              labelA={t('actions.off')}
              labelB={t('actions.on')}
              toggled={subscriptionSettings.listingPublished}
              onToggle={(checked) =>
                updateListingPublished({ variables: { subscribe: checked } }).then(() =>
                  subscriptionSettings.listingRefetch()
                )
              }
            />

            <FormGroup
              key="targetGroups"
              name="targetGroups"
              legendText={t('header.modalSettings.listingTargets') as string}
              disabled={!subscriptionSettings.listingPublished}
            >
              <Checkbox
                id="listingTargets"
                value="listingTargetsAll"
                checked={seekingAll}
                indeterminate={!seekingAll && !seekingNone}
                labelText={t('formFields.all') as string}
                onChange={(checked) => {
                  const newListingTargets: ListingTargets = Object.assign({}, listingTargets);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  Object.keys(listingTargets).forEach((key) => (newListingTargets[key] = checked));
                  updateListingTargets({
                    variables: { targets: newListingTargets }
                  }).then(() => refetchListingTargets());
                }}
              />
              <Checkbox
                id="listingTargetsStudents"
                checked={seekingStudents}
                labelText={t('enums.targetGroup.STUDENTS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingStudents: !seekingStudents } }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsProfessors"
                checked={seekingProfessors}
                labelText={t('enums.targetGroup.PROFESSORS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingProfessors: !seekingProfessors } }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsIncomings"
                checked={seekingIncomings}
                labelText={t('enums.targetGroup.INCOMINGS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingIncomings: !seekingIncomings } }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsDoctoralStudents"
                checked={seekingDoctoralStudents}
                labelText={t('enums.targetGroup.DOCTORAL_STUDENTS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingDoctoralStudents: !seekingDoctoralStudents } }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsPostDoctoralStudents"
                checked={seekingPostDoctoralStudents}
                labelText={t('enums.targetGroup.POST_DOCTORAL_STUDENTS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: {
                      targets: { ...listingTargets, seekingPostDoctoralStudents: !seekingPostDoctoralStudents }
                    }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsGuestResearchers"
                checked={seekingGuestResearchers}
                labelText={t('enums.targetGroup.GUEST_RESEARCHERS') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingGuestResearchers: !seekingGuestResearchers } }
                  }).then(() => refetchListingTargets())
                }
              />
              <Checkbox
                id="listingTargetsTumEmployees"
                checked={seekingTumEmployees}
                labelText={t('enums.targetGroup.TUM_EMPLOYEES') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingTargets({
                    variables: { targets: { ...listingTargets, seekingTumEmployees: !seekingTumEmployees } }
                  }).then(() => refetchListingTargets())
                }
              />
            </FormGroup>

            <FormGroup
              key="locations"
              name="locations"
              legendText={t('header.modalSettings.listingLocations') as string}
              disabled={!subscriptionSettings.listingPublished}
            >
              <Checkbox
                id="listingLocations"
                value="listingLocationsAll"
                checked={seekingLocationsAll}
                indeterminate={!seekingLocationsAll && !seekingLocationsNone}
                labelText={t('formFields.all') as string}
                onChange={(checked) => {
                  const newListingLocations: ListingLocations = Object.assign({}, listingLocations);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  Object.keys(listingLocations).forEach((key) => (newListingLocations[key] = checked));
                  updateListingLocations({
                    variables: { locations: newListingLocations }
                  }).then(() => refetchListingLocations());
                }}
              />
              <Checkbox
                id="listingLocationsFreising"
                checked={seekingFreising}
                labelText={t('enums.city.FREISING') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: { locations: { ...listingLocations, seekingFreising: !seekingFreising } }
                  }).then(() => refetchListingLocations())
                }
              />
              <Checkbox
                id="listingLocationsGarching"
                checked={seekingGarching}
                labelText={t('enums.city.GARCHING') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: { locations: { ...listingLocations, seekingGarching: !seekingGarching } }
                  }).then(() => refetchListingLocations())
                }
              />
              <Checkbox
                id="listingLocationsHeilbronn"
                checked={seekingHeilbronn}
                labelText={t('enums.city.HEILBRONN') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: { locations: { ...listingLocations, seekingHeilbronn: !seekingHeilbronn } }
                  }).then(() => refetchListingLocations())
                }
              />
              <Checkbox
                id="listingLocationsMunich"
                checked={seekingMunich}
                labelText={t('enums.city.MUNICH') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: { locations: { ...listingLocations, seekingMunich: !seekingMunich } }
                  }).then(() => refetchListingLocations())
                }
              />
              <Checkbox
                id="listingLocationsStraubing"
                checked={seekingStraubing}
                labelText={t('enums.city.STRAUBING') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: { locations: { ...listingLocations, seekingStraubing: !seekingStraubing } }
                  }).then(() => refetchListingLocations())
                }
              />
              <Checkbox
                id="listingLocationsGarmischPartenkirchen"
                checked={seekingGarmischPartenkirchen}
                labelText={t('enums.city.GARMISCH_PARTENKIRCHEN') as string}
                className={styles.SeekingDetail}
                onChange={() =>
                  updateListingLocations({
                    variables: {
                      locations: { ...listingLocations, seekingGarmischPartenkirchen: !seekingGarmischPartenkirchen }
                    }
                  }).then(() => refetchListingLocations())
                }
              />
            </FormGroup>
          </div>
        </div>
      </div>
    </Modal>
  );
};

/**
 * Adapted Dropdown from Carbon React for user info and user actions
 * @param props The user info properties
 * @constructor
 */
export const UserInfo: FC<UserInfoProps> = (props: UserInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<UserState, void, AnyAction>>();
  const [openSettings, setOpenSettings] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [params, setParams] = useUrlSearchParams({}, {});

  useEffect(() => {
    if (params[Param.SETTINGS] && !openSettings) {
      setOpenSettings(true);
    }
  }, [params[Param.SETTINGS]]);

  const permissions = useUserPermission();
  const { hasAdministrativeFunction, isLandlord } = permissions;

  const userInfoMenuItems = useMemo(() => {
    return [
      ...(hasAdministrativeFunction ? [{ id: 'userinfo-settings', text: t('actions.settings') }] : []),
      ...(isLandlord ? [{ id: 'userinfo-profile', text: t('actions.profile') }] : []),
      { id: 'userinfo-logout', text: t('actions.logout') }
    ];
  }, [permissions]);

  const modalSettings =
    openSettings &&
    createPortal(
      <SettingsModal
        onClose={() => {
          setOpenSettings(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setParams({ [Param.SETTINGS]: undefined });
        }}
      />,
      document.body
    );

  return (
    <div className={classNames(styles.UserInfo)}>
      <Dropdown
        id="userMenu"
        data-test-id="UserDropdown"
        className={styles.DropDownMenu}
        type="inline"
        titleText={props.role}
        label={
          <>
            {props.name}
            <AccountCircleSVGIcon />
          </>
        }
        items={userInfoMenuItems}
        itemToString={(item) => (item ? item.text : '')}
        selectedItem={null}
        onChange={({ selectedItem }) => {
          if (selectedItem?.id === 'userinfo-logout') {
            dispatch(setIsAnyFormDirty(false));
            fetch(`${getApiBaseUrl()}api/logout`).then(() => {
              dispatch(logout());
              navigate(START_VIEW_PATH);
            });
          } else if (selectedItem?.id === 'userinfo-settings') {
            setOpenSettings(true);
            setParams({ [Param.SETTINGS]: 1 });
          } else if (selectedItem?.id === 'userinfo-profile') {
            setOpenProfile(true);
          }
        }}
      />
      {modalSettings}
      {openProfile && <ProfileDialog onCloseModal={() => setOpenProfile(false)} />}
    </div>
  );
};
