import { FC, ReactElement, useEffect, useState } from 'react';
import { Tabs, Tab, Tag } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export interface TabNavigationTab {
  /** translation key of the tab label */
  labelTranslationKey: string;
  /** the routing path to navigate to */
  path: string;
  /** a number to be display besides the label */
  tagCount?: number;
  /** flag indicating if the tab is disabled */
  disabled?: boolean;
}

export interface TabNavigationProps {
  /** the tabs to be displayed in the navigation */
  tabs: TabNavigationTab[];
}

interface TabLabelProps {
  label: string;
  count?: number;
}

// small utility component for tab text content
const TabLabel: FC<TabLabelProps> = ({ label, count }): ReactElement => {
  return (
    <>
      <span>{label}</span>
      {count !== undefined && count !== 0 && <Tag>{count}</Tag>}
    </>
  );
};

export const TabNavigation: FC<TabNavigationProps> = ({ tabs }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(-1);

  // see which of the tabs matches the current path, in order to
  // set state of the matching tab to active/selected
  useEffect(() => {
    const matchingPathIndex = tabs.findIndex((tab) => pathname.startsWith(tab.path));
    setSelectedTabIndex(matchingPathIndex);
  }, [pathname, tabs]);

  return (
    <>
      <nav className="global-content-wrapper">
        <Tabs selected={selectedTabIndex}>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={<TabLabel label={t(tab.labelTranslationKey)} count={tab.tagCount} />}
              onClick={() => navigate(tab.path)}
              // we don't need tab content, since it is rendered by the router
              renderContent={() => null}
              disabled={tab.disabled}
              className={index === selectedTabIndex ? 'bx--tabs--scrollable__nav-item--active' : undefined}
            />
          ))}
        </Tabs>
      </nav>
      <div className="bx--tabs--border" />
    </>
  );
};

export default TabNavigation;
