import { SearchRequestSortOrder } from '../../../graphql/types/globalTypes';
import { CustomMultiSelectItem, DefaultMultiSelectItem } from '../../../utils/types';
import { anyItem } from '../listings/listings-list/listings-list.filter-types';

export enum TargetGroup {
  GUEST_RESEARCHERS = 'GUEST_RESEARCHERS',
  PROFESSORS = 'PROFESSORS',
  INCOMINGS = 'INCOMINGS',
  STUDENTS = 'STUDENTS',
  POST_DOCTORAL_STUDENTS = 'POST_DOCTORAL_STUDENTS',
  DOCTORAL_STUDENTS = 'DOCTORAL_STUDENTS',
  TUM_EMPLOYEES = 'TUM_EMPLOYEES'
}

export enum AffiliationType {
  GUEST_RESEARCHER = 'GUEST_RESEARCHER',
  PROFESSOR = 'PROFESSOR',
  INCOMING = 'INCOMING',
  STUDENT = 'STUDENT',
  POST_DOCTORAL_STUDENT = 'POST_DOCTORAL_STUDENT',
  DOCTORAL_STUDENT = 'DOCTORAL_STUDENT',
  TUM_EMPLOYEE = 'TUM_EMPLOYEE'
}

export enum Role {
  STUDY_GUIDANCE = 'STUDY_GUIDANCE',
  EQUAL_OPPORTUNITIES = 'EQUAL_OPPORTUNITIES',
  DUAL_CAREER = 'DUAL_CAREER',
  GLOBAL_AND_ALUMNI = 'GLOBAL_AND_ALUMNI',
  GRADUATE_SCHOOL = 'GRADUATE_SCHOOL',
  LIFE_SCIENCES = 'LIFE_SCIENCES',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SERVICE_POINT_HEILBRONN = 'SERVICE_POINT_HEILBRONN',
  LOCATION_GARMISCH_PARTENKIRCHEN = 'LOCATION_GARMISCH_PARTENKIRCHEN'
}

export type SortingItem = CustomMultiSelectItem<SearchRequestSortOrder>;
export type OpenStateItem = CustomMultiSelectItem<boolean | null>;

export const affiliationTypeItems: DefaultMultiSelectItem[] = [
  { id: AffiliationType.DOCTORAL_STUDENT, text: `enums.targetGroup.${TargetGroup.DOCTORAL_STUDENTS}` },
  { id: AffiliationType.GUEST_RESEARCHER, text: `enums.targetGroup.${TargetGroup.GUEST_RESEARCHERS}` },
  { id: AffiliationType.INCOMING, text: `enums.targetGroup.${TargetGroup.INCOMINGS}` },
  { id: AffiliationType.POST_DOCTORAL_STUDENT, text: `enums.targetGroup.${TargetGroup.POST_DOCTORAL_STUDENTS}` },
  { id: AffiliationType.PROFESSOR, text: `enums.targetGroup.${TargetGroup.PROFESSORS}` },
  { id: AffiliationType.STUDENT, text: `enums.targetGroup.${TargetGroup.STUDENTS}` },
  { id: AffiliationType.TUM_EMPLOYEE, text: `enums.targetGroup.${TargetGroup.TUM_EMPLOYEES}` }
];

export const roleItems: DefaultMultiSelectItem[] = [
  { id: Role.DUAL_CAREER, text: `enums.role.${Role.DUAL_CAREER}` },
  { id: Role.EQUAL_OPPORTUNITIES, text: `enums.role.${Role.EQUAL_OPPORTUNITIES}` },
  { id: Role.GLOBAL_AND_ALUMNI, text: `enums.role.${Role.GLOBAL_AND_ALUMNI}` },
  { id: Role.STUDY_GUIDANCE, text: `enums.role.${Role.STUDY_GUIDANCE}` },
  { id: Role.GRADUATE_SCHOOL, text: `enums.role.${Role.GRADUATE_SCHOOL}` },
  { id: Role.LIFE_SCIENCES, text: `enums.role.${Role.LIFE_SCIENCES}` }
];

export const sortingItems: SortingItem[] = [
  { id: SearchRequestSortOrder.MOST_RECENT, text: 'sorting.mostRecent' },
  { id: SearchRequestSortOrder.LAST_MODIFIED, text: 'sorting.lastModified' },
  { id: SearchRequestSortOrder.UNREAD_EMAIL_COUNT, text: 'sorting.unreadEmailCount' }
];

export const openStateItems: OpenStateItem[] = [
  anyItem,
  { id: true, text: 'enums.openState.OPEN' },
  { id: false, text: 'enums.openState.CLOSED' }
];

export const departments = [
  'ARCHITECTURE',
  'CHEMISTRY',
  'ELECTRICAL_AND_COMPUTER_ENGINEERING',
  'INFORMATICS',
  'CIVIL_GEO_AND_ENVIRONMENTAL_ENGINEERING',
  'AEROSPACE_AND_GEODESY',
  'MECHANICAL_ENGINEERING',
  'MATHEMATICS',
  'MEDICINE',
  'PHYSICS',
  'SPORT_AND_HEALTH_SCIENCES',
  'EDUCATION',
  'GOVERNANCE',
  'LIFE_SCIENCES',
  'MANAGEMENT',
  'LIBRARY',
  'LANGUAGE_CENTER',
  'CENTER_OF_TEACHING_AND_LEARNING',
  'GRADUATE_SCHOOL',
  'OTHER'
];

export const countries = [
  'AFGHANISTAN',
  'AEGYPTEN',
  'ALBANIEN',
  'ALGERIEN',
  'ANDORRA',
  'ANGOLA',
  'ANTIGUA_UND_BARBUDA',
  'AEQUATORIALGUINEA',
  'ARGENTINIEN',
  'ARMENIEN',
  'ASERBAIDSCHAN',
  'AETHIOPIEN',
  'AUSTRALIEN',
  'BAHAMAS',
  'BAHRAIN',
  'BANGLADESCH',
  'BARBADOS',
  'BELGIEN',
  'BELIZE',
  'BENIN',
  'BHUTAN',
  'BOLIVIEN',
  'BOSNIEN_UND_HERZEGOWINA',
  'BOTSUANA',
  'BRASILIEN',
  'BRUNEI_DARUSSALAM',
  'BULGARIEN',
  'BURKINA_FASO',
  'BURUNDI',
  'CHILE',
  'CHINA',
  'COOKINSELN',
  'COSTA_RICA',
  'COTE_IVOIRE',
  'DAENEMARK',
  'DEUTSCHLAND',
  'DOMINICA',
  'DOMINIKANISCHE_REPUBLIK',
  'DSCHIBUTI',
  'ECUADOR',
  'EL_SALVADOR',
  'ERITREA',
  'ESTLAND',
  'FIDSCHI',
  'FINNLAND',
  'FRANKREICH',
  'GABUN',
  'GAMBIA',
  'GEORGIEN',
  'GHANA',
  'GRENADA',
  'GRIECHENLAND',
  'GUATEMALA',
  'GUINEA',
  'GUINEA_BISSAU',
  'GUYANA',
  'HAITI',
  'HONDURAS',
  'INDIEN',
  'INDONESIEN',
  'IRAK',
  'IRAN',
  'IRLAND',
  'ISLAND',
  'ISRAEL',
  'ITALIEN',
  'JAMAIKA',
  'JAPAN',
  'JEMEN',
  'JORDANIEN',
  'KAMBODSCHA',
  'KAMERUN',
  'KANADA',
  'KAP_VERDE',
  'KASACHSTAN',
  'KATAR',
  'KENIA',
  'KIRGISISTAN',
  'KIRIBATI',
  'KOLUMBIEN',
  'KOMOREN',
  'KONGO',
  'KONGO_DEMOKRATISCHE_REPUBLIK',
  'KOREA_DEMOKRATISCHE_VOLKSREPUBLIK',
  'KOREA_REPUBLIK',
  'KOSOVO',
  'KROATIEN',
  'KUBA',
  'KUWAIT',
  'LAOS',
  'LESOTHO',
  'LETTLAND',
  'LIBANON',
  'LIBERIA',
  'LIBYEN',
  'LIECHTENSTEIN',
  'LITAUEN',
  'LUXEMBURG',
  'MADAGASKAR',
  'MALAWI',
  'MALAYSIA',
  'MALEDIVEN',
  'MALI',
  'MALTA',
  'MAROKKO',
  'MARSHALLINSELN',
  'MAURETANIEN',
  'MAURITIUS',
  'MAZEDONIEN',
  'MEXIKO',
  'MIKRONESIEN',
  'MOLDAU',
  'MONACO',
  'MONGOLEI',
  'MONTENEGRO',
  'MOSAMBIK',
  'MYANMAR',
  'NAMIBIA',
  'NAURU',
  'NEPAL',
  'NEUSEELAND',
  'NICARAGUA',
  'NIEDERLANDE',
  'NIGER',
  'NIGERIA',
  'NIUE',
  'NORWEGEN',
  'OMAN',
  'OESTERREICH',
  'PAKISTAN',
  'PALAU',
  'PANAMA',
  'PAPUA_NEUGUINEA',
  'PARAGUAY',
  'PERU',
  'PHILIPPINEN',
  'POLEN',
  'PORTUGAL',
  'RUANDA',
  'RUMAENIEN',
  'RUSSISCHE_FOEDERATION',
  'SALOMONEN',
  'SAMBIA',
  'SAMOA',
  'SAN_MARINO',
  'SAO_TOME_UND_PRINCIPE',
  'SAUDI_ARABIEN',
  'SCHWEDEN',
  'SCHWEIZ',
  'SENEGAL',
  'SERBIEN',
  'SEYCHELLEN',
  'SIERRA_LEONE',
  'SIMBABWE',
  'SINGAPUR',
  'SLOWAKEI',
  'SLOWENIEN',
  'SOMALIA',
  'SPANIEN',
  'SRI_LANKA',
  'ST_KITTS_UND_NEVIS',
  'ST_LUCIA',
  'ST_VINCENT_UND_DIE_GRENADINEN',
  'SUEDAFRIKA',
  'SUDAN',
  'SUEDSUDAN',
  'SURINAME',
  'SWASILAND',
  'SYRIEN',
  'TADSCHIKISTAN',
  'TANSANIA',
  'THAILAND',
  'TIMOR_LESTE',
  'TOGO',
  'TONGA',
  'TRINIDAD_UND_TOBAGO',
  'TSCHAD',
  'TSCHECHISCHE_REPUBLIK',
  'TUNESIEN',
  'TUERKEI',
  'TURKMENISTAN',
  'TUVALU',
  'UGANDA',
  'UKRAINE',
  'UNGARN',
  'URUGUAY',
  'USBEKISTAN',
  'VANUATU',
  'VATIKANSTADT',
  'VENEZUELA',
  'VEREINIGTE_ARABISCHE_EMIRATE',
  'VEREINIGTE_STAATEN',
  'VEREINIGTES_KOENIGREICH',
  'VIETNAM',
  'WEISSRUSSLAND',
  'ZENTRALAFRIKANISCHE_REPUBLIK',
  'ZYPERN'
];
