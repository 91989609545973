import { AddSVGIcon } from '@react-md/material-icons';
import { Button, Tag } from 'carbon-components-react';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContentView, ContentViewSection } from '../../building-blocks/content-view/content-view';
import { SaveBar } from '../../building-blocks/save-bar/save-bar';
import { useLandlordUserData } from '../landlord-contact-data/landlord-contact-data.hooks';
import styles from './account-view.module.scss';
import { ListingCardContainer } from '../../building-blocks/listing-card-container/listing-card-container';
import { useResponsiveBreakpoints } from '../../../utils/hooks/responsive.hooks';
import { useDocumentTitle } from '../../../utils/hooks/document-title';
import { useSelector } from 'react-redux';
import LandlordContactData from '../landlord-contact-data/landlord-contact-data';
import { GlobalState } from '../../../redux/store';
import { UserState } from '../../../redux/user.state';

export const AccountView: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [activeListings, setActiveListings] = useState(0);
  const [inactiveListings, setInactiveListings] = useState(0);
  const userState = useSelector<GlobalState, UserState>((state) => state.user);

  const breakpoints = useResponsiveBreakpoints();
  useDocumentTitle(t('navigation.mySpace'));

  const {
    landlordProfileData,
    getLandLordQuery: { loading, data: userData, refetch }
  } = useLandlordUserData(userState.user?.id ?? '');

  // Fetch listings for landlord
  const listings = userData?.landlord?.listings;

  useEffect(() => {
    if (userData?.landlord?.listings) {
      const countActive = listings?.filter((listing) => listing?.isActive).length || 0;
      setActiveListings(countActive);
      setInactiveListings((listings?.length || 0) - countActive);
    }
  }, [userData?.landlord?.listings]);

  const createNewButton = (
    <Button
      onClick={() => {
        navigate('./new');
      }}
      className={styles.CreateNewButton}
      renderIcon={AddSVGIcon}
    >
      {t('actions.createNewListing')}
    </Button>
  );

  return (
    <>
      <div className={styles.Greeting}>
        <div className="global-content-wrapper">
          <h1>
            {t('main.greeting', {
              title: '',
              name: userState.user?.name || ''
            })}
          </h1>

          {!breakpoints.isMobile && (
            <Button
              kind={'secondary'}
              size="field"
              onClick={() => {
                navigate('./new');
              }}
              className={styles.CreateNewButton}
              renderIcon={AddSVGIcon}
            >
              {t('actions.createNewListing')}
            </Button>
          )}
        </div>
      </div>
      <ContentView className={classNames(styles.AccountContent, 'global-content-wrapper-block')}>
        <ContentViewSection
          title={t('views.account.myListings')}
          anchorId="listings"
          suffix={
            <>
              {activeListings > 0 ? (
                <Tag className={styles.ActiveTag}>{t('views.account.active', { count: activeListings })}</Tag>
              ) : undefined}
              {inactiveListings > 0 ? (
                <Tag className={styles.InactiveTag}>{t('views.account.inactive', { count: inactiveListings })}</Tag>
              ) : undefined}
            </>
          }
        >
          <ListingCardContainer
            listings={listings}
            emptyText={t('views.account.createListingInfoText') as string}
            refetch={refetch}
            editable={true}
          />
          {breakpoints.isMobile ? (
            <SaveBar trigger={null} className={styles.MobileCreateNewBar}>
              {createNewButton}
            </SaveBar>
          ) : (
            createNewButton
          )}
        </ContentViewSection>
        <ContentViewSection title={t('views.account.contactData')} anchorId="contact">
          <LandlordContactData
            landlordId={userState.user?.id}
            landlordProfileData={landlordProfileData}
            refetch={refetch}
            loading={loading}
            userData={userData}
          />
        </ContentViewSection>
      </ContentView>
    </>
  );
};

export default AccountView;
