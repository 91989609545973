import { FC } from 'react';
import styles from './anchor-navigation.module.scss';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Tag } from 'carbon-components-react';

export interface AnchorNavItem {
  label: string;
  id: string;
  tagCount?: number;
}

export interface AnchorNavigationProps {
  selected?: number;
  items: AnchorNavItem[];
}

export const AnchorNavigation: FC<AnchorNavigationProps> = ({ selected, items }) => {
  const { hash } = useLocation();

  return (
    <nav className={styles.AnchorNavigation}>
      {items.map((item, index) => {
        const linkTo = `#${item.id}`;
        const isSelected = selected !== undefined ? selected === index : hash === linkTo;

        return (
          <a
            key={index}
            href={linkTo}
            data-testid="AnchorNavLink"
            className={classNames(styles.AnchorNavigationItem, {
              [styles.AnchorNavigationItem__selected]: isSelected
            })}
          >
            <span>{item.label}</span>
            {item.tagCount !== undefined && item.tagCount > 0 && <Tag size="sm">{item.tagCount}</Tag>}
          </a>
        );
      })}
    </nav>
  );
};

export default AnchorNavigation;
