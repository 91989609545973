import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../../../utils/intl/dates-and-times';
import { formatFloorSpace } from '../../../../../utils/intl/misc';
import ContentView, { ContentViewSection } from '../../../../building-blocks/content-view/content-view';
import { ListingFormDataState } from '../listing.state';
import styles from './listing-view.module.scss';
import { FormBlockingDialog } from '../../../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { UserPermissions } from '../../../../../utils/hooks/user-data';
import { ListingActionBar } from '../listing-action-bar/listing-action-bar';
import { ViewMode } from '../../../../building-blocks/view-mode-switcher/view-mode-switcher';
import LeafletMap from '../../../../controls/map/map';
import { Point } from 'leaflet';
import { listingAddressString } from '../../../../../utils/listings';
import RentComposition from '../../../../controls/rent-composition/rent-composition';
import { formatCurrency } from '../../../../../utils/intl/currency';
import { CostToolTip } from '../listing-edit/listing-edit-cost-tooltip/listing-edit-cost-tooltip';
import { renderTagIcon } from './listing-view.tag-icons';
import { TagsForCategories, useEnvironmentalInfo, useFetchListingImages } from '../listing.hooks';
import { ORDERED_TAG_CATEGORIES, PoiCategory, TransportationType } from '../listing.constants';
import { TagCategory } from '../../../../../graphql/types/globalTypes';
import { ListingInfoPanel, ListingInfoPanelSkeleton } from '../../../../controls/listing-info-panel/listing-info-panel';
import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react';
import ImageGallery from '../../../../controls/image-gallery/image-gallery';
import { createPortal } from 'react-dom';
import { ListingViewContactCard } from './listing-view.contact-card';
import { ReactComponent as NoPhotographyIcon } from '../../../../../assets/svg/no_photography.svg';
import placeholderImage from '../../../../../assets/images/listing-placeholder-clean.jpg';
import { useResponsiveBreakpoints } from '../../../../../utils/hooks/responsive.hooks';
import { useUrlSearchParams } from 'use-url-search-params';
import { Param } from '../../../../../utils/constants';
import {
  DirectionsBusSVGIcon,
  DirectionsWalkSVGIcon,
  ImageSVGIcon,
  SchoolSVGIcon,
  ShoppingCartSVGIcon
} from '@react-md/material-icons';
import { ReactComponent as TumIcon } from '../../../../../assets/svg/tum-logo2.svg';
import { ReactComponent as BakeryIcon } from '../../../../../assets/svg/bakery.svg';
import { ReactComponent as PharmacyIcon } from '../../../../../assets/svg/pharmacy.svg';
import { TargetGroupCard } from './listing-view.target-groups';
import { getImagePath } from '../../../../../utils/listing-images';

interface DisclaimerProps {
  icon?: ReactNode;
  title: string;
  subtitle?: string;
}

export const Disclaimer: FC<DisclaimerProps> = (props) => (
  <p className={styles.PreviewDisclaimer}>
    {props.icon}
    <span className={styles.PreviewDisclaimerTitle}>{props.title}</span>
    {props.subtitle && (
      <>
        <br />
        <span className={styles.PreviewDisclaimerSubtitle}>{props.subtitle}</span>
      </>
    )}
  </p>
);

export const EnvInfo: FC<PoiListing> = (poi) => {
  const { t } = useTranslation();

  return (
    <p className={styles.EnvInfo}>
      {poi.category === PoiCategory.TUM ? (
        <TumIcon aria-hidden="true" />
      ) : poi.category === PoiCategory.TRANSPORT ? (
        <>
          <span className="sr-only">{t('aria.distanceTransport')}</span>
          <DirectionsBusSVGIcon />
        </>
      ) : poi.category === PoiCategory.PHARMACY ? (
        <>
          <span className="sr-only">{t('aria.distancePharmacy')}</span>
          <PharmacyIcon />
        </>
      ) : poi.category === PoiCategory.SCHOOL ? (
        <>
          <span className="sr-only">{t('aria.distanceSchool')}</span>
          <SchoolSVGIcon />
        </>
      ) : poi.category === PoiCategory.SUPERMARKET ? (
        <>
          <span className="sr-only">{t('aria.distanceSupermarket')}</span>
          <ShoppingCartSVGIcon />
        </>
      ) : poi.category === PoiCategory.BAKERY ? (
        <>
          <span className="sr-only">{t('aria.distanceBakery')}</span>
          <BakeryIcon />
        </>
      ) : (
        ''
      )}
      <span className={styles.EnvInfoTitle}>
        <span className={styles.EnvInfoName} title={poi.name}>
          {poi.name.length > 0 ? poi.name : t(`enums.poiCategory.${poi.category}`)}
        </span>
        {poi.address && poi.category !== PoiCategory.TUM && (
          <span className={styles.EnvInfoAddress} title={poi.address}>
            {poi.address}
          </span>
        )}
      </span>
      <span className={styles.EnvInfoDuration}>
        {poi.type === TransportationType.PUBLIC_TRANSPORT ? (
          <>
            <span className="sr-only">{t('aria.distanceTransport')}</span>
            <DirectionsBusSVGIcon />
          </>
        ) : (
          <>
            <span className="sr-only">{t('aria.distanceByFoot')}</span>
            <DirectionsWalkSVGIcon />
          </>
        )}
        <span>{poi.duration}</span>
      </span>
    </p>
  );
};

export interface ListingViewProps {
  formState: ListingFormDataState;
  userPermissions: UserPermissions;
  tagsForCategories: TagsForCategories;
  isCreationMode: boolean;
  isPreviewMode: boolean;
  onResetForm: () => void;
  listingTitle: string;
  isLoaded: boolean;
}

export interface PoiListing {
  coordinates: Point;
  category: PoiCategory;
  name: string;
  address: string | null;
  duration: string;
  type: TransportationType | null;
}

export const ListingView: FC<ListingViewProps> = ({
  formState,
  onResetForm,
  userPermissions,
  isCreationMode,
  isPreviewMode,
  tagsForCategories,
  listingTitle,
  isLoaded
}) => {
  const { t, i18n } = useTranslation();
  const { hasAdministrativeFunction, isAnonymous, isSeeker } = userPermissions;
  const [params] = useUrlSearchParams({}, {});
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const breakpoints = useResponsiveBreakpoints();

  const { imageData, refetch } = useFetchListingImages();
  const { tumPoi, envPoi } = useEnvironmentalInfo();

  const renderedImages = useMemo(() => {
    return imageData
      ? imageData.listingImagesForListing.map((image) => ({
          path: getImagePath(
            image.id,
            image.modifiedAt,
            isGalleryOpen ? undefined : 1280,
            params[Param.SHARE] ? params[Param.SHARE].toString() : undefined
          ),
          description: image.description,
          descriptionEN: image.descriptionEn
        }))
      : formState.images.map((image) => ({
          path: '',
          description: image.description,
          descriptionEN: image.descriptionEn
        }));
  }, [imageData, isGalleryOpen]);

  const dirtyFilteredFormImages = formState.images.filter(
    (image) => !image.markedForDeletion && !image.id.startsWith('local_')
  );

  // images were changed if there are images that are marked for deletion or just local
  const didImagesChange = isPreviewMode && dirtyFilteredFormImages.length !== renderedImages.length;

  // effect to refetch images after saving
  useEffect(() => {
    // refetch images if the current set of loaded images does not match the reloaded formState
    if (didImagesChange && dirtyFilteredFormImages.length === formState.images.length && refetch) {
      refetch();
    }
  }, [formState, didImagesChange, dirtyFilteredFormImages, refetch]);

  return (
    <>
      {hasAdministrativeFunction && (
        <ListingActionBar
          viewMode={ViewMode.VIEW}
          isCreationMode={isCreationMode}
          isActive={formState.isActive}
          city={formState.city}
          type={formState.type}
        />
      )}
      <div className="global-content-wrapper-block">
        <div className={styles.GalleryContainer}>
          {isLoaded ? (
            renderedImages.length === 0 && imageData ? (
              <>
                <img src={placeholderImage} alt={t('listing.altNoImages')} className={styles.GalleryPlaceHolder} />
                {!isPreviewMode && (
                  <Disclaimer icon={<NoPhotographyIcon />} title={t('views.listings.disclaimer.disclaimerNoImages')} />
                )}
              </>
            ) : imageData ? (
              <ImageGallery
                images={renderedImages}
                className={classNames(styles.Gallery, {
                  [styles.GalleryPlaceHolder]: imageData === undefined || imageData.listingImagesForListing.length === 0
                })}
                onOpenGallery={() => {
                  setIsGalleryOpen(true);
                }}
                paused={isGalleryOpen}
              ></ImageGallery>
            ) : (
              <Disclaimer icon={<ImageSVGIcon />} title={t('views.listings.disclaimer.disclaimerImagesLoading')} />
            )
          ) : (
            <Disclaimer icon={<ImageSVGIcon />} title={t('views.listings.disclaimer.disclaimerImagesLoading')} />
          )}
          {didImagesChange && (
            <Disclaimer
              icon={renderedImages.length === 0 ? <NoPhotographyIcon /> : undefined}
              title={t('views.listings.disclaimer.previewDisclaimerTitle')}
              subtitle={t('views.listings.disclaimer.previewDisclaimerSubtitle')}
            />
          )}
        </div>
        <div className={styles.FixedInfoPanel}>
          {isLoaded ? (
            <ListingInfoPanel
              size="lg"
              availableFrom={formState.availableFrom}
              numberOfRooms={formState.numberOfRooms}
              squareMeter={formState.squareMeter}
              totalRent={(formState.incidentalCosts || 0) + (formState.parkingSpaceCosts || 0) + formState.rent}
              className={styles.InfoPanelContent}
            ></ListingInfoPanel>
          ) : (
            <ListingInfoPanelSkeleton className={styles.InfoPanelContent} size="lg" />
          )}
        </div>
        <ContentView className={styles.Content}>
          <ContentViewSection
            navTitle={t('views.listings.sections.object')}
            title={t('views.listings.viewSections.object')}
            anchorId="object"
            className={styles.ListingSectionCard}
          >
            <div className={styles.TwoColumnSection}>
              <div>
                <div className={styles.PropertyRow}>
                  <span>{t('views.listings.listingType')}:</span>
                  <span>{t(`enums.listingType.${formState.type}`)}</span>
                </div>
                {formState.housingType && (
                  <div className={styles.PropertyRow}>
                    <span>{t('formFields.housingType')}:</span>
                    <span>{t(`enums.housingType.${formState.housingType}`)}</span>
                  </div>
                )}
                <div className={styles.PropertyRow}>
                  <span>{t('formFields.numberOfRooms')}:</span>
                  <span>{formState.numberOfRooms}</span>
                </div>
                <div className={styles.PropertyRow}>
                  <span>{t('formFields.livingSpace')}:</span>
                  <span>{formatFloorSpace(formState.squareMeter)}</span>
                </div>
              </div>
              <div>
                {formState.floor !== null && formState.floor !== 0 && (
                  <div className={styles.PropertyRow}>
                    <span>{t('formFields.floor')}:</span>
                    <span>{formState.floor}</span>
                  </div>
                )}
                <div className={styles.PropertyRow}>
                  <span>{t('formFields.moveInDate')}:</span>
                  <span>{formatDate(formState.availableFrom)}</span>
                </div>
                <div className={styles.PropertyRow}>
                  <span>{t('formFields.moveOutDate')}:</span>
                  <span>{formatDate(formState.availableUntil)}</span>
                </div>
              </div>
            </div>
          </ContentViewSection>
          <ContentViewSection
            navTitle={t('views.listings.viewSections.targetGroups')}
            title={
              isAnonymous || isSeeker
                ? t('views.listings.viewSections.targetGroupsAlt')
                : t('views.listings.viewSections.targetGroups')
            }
            anchorId="targetGroups"
            className={classNames(styles.ListingSectionCard)}
          >
            <TargetGroupCard
              targets={{
                seekingDoctoralStudents: formState.seekingDoctoralStudents,
                seekingGuestResearchers: formState.seekingGuestResearchers,
                seekingIncomings: formState.seekingIncomings,
                seekingPostDoctoralStudents: formState.seekingPostDoctoralStudents,
                seekingProfessors: formState.seekingProfessors,
                seekingStudents: formState.seekingStudents,
                seekingTumEmployees: formState.seekingTumEmployees
              }}
            />
          </ContentViewSection>
          <ContentViewSection
            navTitle={t('views.listings.sections.location')}
            title={t('views.listings.viewSections.location')}
            anchorId="location"
            className={styles.ListingSectionCard}
          >
            <p className={styles.Address}>
              {listingAddressString(
                t,
                formState.street,
                formState.houseNumber,
                formState.postalCode,
                formState.city,
                formState.district
              )}
            </p>
            <LeafletMap
              height={breakpoints.isDesktop ? '23rem' : breakpoints.isTablet ? '21rem' : '20rem'}
              markers={formState.coordinates ? [new Point(formState.coordinates.x, formState.coordinates.y)] : null}
              centerToMarker={true}
              zoom={18}
              className={styles.Map}
              poi={envPoi.concat(tumPoi)}
            />
            {(envPoi?.length > 0 || tumPoi?.length > 0) && (
              <div className={styles.EnvironmentalInfo}>
                {envPoi?.length > 0 && (
                  <>
                    <h3>{t('views.listings.viewSubSections.environmentalInfo')}:</h3>
                    <div className={styles.EnvInfoContainer}>
                      {envPoi.map((poi, index) => (
                        <EnvInfo
                          key={'env_' + index}
                          coordinates={poi.coordinates}
                          category={poi.category}
                          name={poi.name}
                          address={poi.address}
                          duration={poi.duration}
                          type={poi.type}
                        />
                      ))}
                    </div>
                  </>
                )}
                {tumPoi?.length > 0 && (
                  <>
                    <h3>{t('views.listings.viewSubSections.tumLocations')}:</h3>
                    <div className={styles.TumContainer}>
                      {tumPoi.map((poi, index) => (
                        <EnvInfo
                          key={'tum_' + index}
                          coordinates={poi.coordinates}
                          category={poi.category}
                          name={poi.name}
                          address={poi.address}
                          duration={poi.duration}
                          type={poi.type}
                        />
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}
          </ContentViewSection>
          <ContentViewSection
            navTitle={t('views.listings.sections.cost')}
            title={t('views.listings.viewSections.cost')}
            anchorId="cost"
            className={styles.ListingSectionCard}
          >
            <div
              className={classNames(styles.TwoColumnSection, {
                [styles.TwoColumnSection__WrapMobile]: !breakpoints.isDesktop
              })}
            >
              <RentComposition
                rent={formState.rent}
                incidentalCosts={formState.incidentalCosts || 0}
                incidentalCostsTypes={[
                  ...formState.incidentalCostsTypes.map((type) => t(`enums.incidentalCostsTypes.${type}`)),
                  formState.incidentalCostsCustomLabel
                ]}
                parkingSpaceCosts={formState.parkingSpaceCosts || 0}
                fullRent={formState.rent + (formState.incidentalCosts || 0) + (formState.parkingSpaceCosts || 0)}
                className={styles.RentOverview}
                mode="view"
              />
              <div className={styles.OneTimeCostsOverview}>
                <h3>
                  {t('formFields.deposit')}
                  <CostToolTip infoText={t('tooltips.depositTooltip')} />
                </h3>
                <div className={styles.PropertyRow}>
                  <span>{t('formFields.deposit')}:</span>
                  <span>{formatCurrency(formState.deposit || 0)}</span>
                </div>
                <h3>
                  {t('formFields.oneTimeCosts')}
                  <CostToolTip infoText={t('tooltips.oneTimeCostsTooltip')} />
                </h3>
                <div className={styles.PropertyRow}>
                  <span>
                    {formState.oneTimeCosts && formState.oneTimeCostsLabel
                      ? formState.oneTimeCostsLabel
                      : t('formFields.oneTimeCosts')}
                    :
                  </span>
                  <span>{formatCurrency(formState.oneTimeCosts || 0)}</span>
                </div>
              </div>
            </div>
          </ContentViewSection>
          <ContentViewSection
            navTitle={t('views.listings.sections.configuration')}
            title={t('views.listings.viewSections.configuration')}
            anchorId="configuration"
            className={styles.ListingSectionCard}
          >
            <p className={styles.FurtherEquipment}>
              {i18n.language === 'de'
                ? formState.furtherEquipment || formState.furtherEquipmentEn
                : formState.furtherEquipmentEn || formState.furtherEquipment}
            </p>
            <ul className={styles.TagsList}>
              {ORDERED_TAG_CATEGORIES.map((category) => (
                <li key={category}>
                  <span className={styles.TagCategoryLabel}>{t(`enums.tagCategory.${category}`)}</span>
                  <ul className={category === TagCategory.OTHER ? styles.MiscTags : undefined}>
                    {tagsForCategories.get(category)?.map((tag, index) => {
                      const isDisabled = formState.tags.indexOf(tag.id) === -1;
                      return (
                        <li
                          key={index}
                          className={classNames(styles.ListingTag, {
                            [styles.ListingTagDisabled]: isDisabled
                          })}
                        >
                          {renderTagIcon(tag.id, !isDisabled, t)}
                          <span>
                            {/* Use inactive label for flat sharing tag in disabled state */}
                            {t(`enums.tags.${tag.id}`)}
                          </span>
                          <span className={styles.visuallyHidden}>
                            {isDisabled ? t('enums.tags.no') : t('enums.tags.yes')}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          </ContentViewSection>
          <ContentViewSection
            navTitle={t('views.listings.sections.contact')}
            title={t('views.listings.viewSections.contact')}
            anchorId="contact"
            className={classNames(styles.ListingSectionCard, styles.ContactCard)}
          >
            <ListingViewContactCard
              formState={formState}
              listingTitle={listingTitle}
              userPermissions={userPermissions}
              shareToken={params[Param.SHARE] ? params[Param.SHARE].toString() : undefined}
            />
          </ContentViewSection>
        </ContentView>

        <FormBlockingDialog onResetForm={onResetForm} currentlyEditedItemUUID={formState.uuid} />

        {isGalleryOpen &&
          createPortal(
            <ComposedModal
              size="lg"
              open={true}
              onClose={() => {
                setIsGalleryOpen(false);
              }}
              className={styles.GalleryModal}
            >
              <ModalHeader className={styles.GalleryModalHeader}>
                <h2>{listingTitle}</h2>
              </ModalHeader>
              <ModalBody className={styles.GalleryModalBody}>
                <ImageGallery
                  images={renderedImages}
                  className={classNames(styles.Gallery, styles.GalleryFullScreen, {
                    [styles.GalleryPlaceHolder]:
                      imageData === undefined || imageData.listingImagesForListing.length === 0
                  })}
                  showDescription
                  showBullets
                  isFullscreen
                ></ImageGallery>
              </ModalBody>
            </ComposedModal>,

            document.body
          )}
      </div>
    </>
  );
};

export default ListingView;
