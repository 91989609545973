import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './listing-info-panel.module.scss';
import { checkIfDateIsNow, formatDate } from '../../../utils/intl/dates-and-times';
import { EventSVGIcon, LocalOfferSVGIcon, RoundedCornerSVGIcon } from '@react-md/material-icons';
import { ReactComponent as MeetingRoom } from '../../../assets/svg/meeting-room.svg';
import classNames from 'classnames';
import { SkeletonText } from 'carbon-components-react';
import { StyleableProps } from '../../../utils/types';

interface InfoItemProps {
  icon: ReactNode;
  title: string;
  subtitle: string;
}

const InfoItem: FC<InfoItemProps> = (props) => {
  return (
    <div className={styles.infoIcon}>
      {props.icon}
      <span className={styles.title}>
        {props.title.length === 0 && <SkeletonText width={'50px'} />}
        {props.title}
      </span>
      <span className={styles.subtitle}>{props.subtitle}</span>
    </div>
  );
};

export interface ListingInfoPanelProps {
  availableFrom: string;
  squareMeter: number;
  numberOfRooms: number | null;
  totalRent: number;
  className?: string;
  size?: 'default' | 'lg';
  hideIcons?: boolean;
}

export const ListingInfoPanel: FC<ListingInfoPanelProps> = (props) => {
  const { t } = useTranslation();

  const availableFrom = useMemo(() => {
    return checkIfDateIsNow(props.availableFrom) ? t('enums.availability.NOW') : formatDate(props.availableFrom);
  }, [props.availableFrom, t]);

  return (
    <div
      className={classNames(styles.InfoIconContainer, props.className, {
        [styles.InfoIconContainerLarge]: props.size === 'lg',
        [styles.InfoIconContainerNoIcons]: props.hideIcons === true
      })}
    >
      <InfoItem icon={<EventSVGIcon />} title={availableFrom} subtitle={t('listing.available')} />
      <InfoItem
        icon={<RoundedCornerSVGIcon />}
        title={props.squareMeter.toString() + ' m²'}
        subtitle={t('listing.squareMeter')}
      />
      {props.numberOfRooms && (
        <InfoItem icon={<MeetingRoom />} title={props.numberOfRooms.toString()} subtitle={t('listing.rooms')} />
      )}
      <InfoItem icon={<LocalOfferSVGIcon />} title={props.totalRent.toString() + ' €'} subtitle={t('listing.rent')} />
    </div>
  );
};

export default ListingInfoPanel;

export const ListingInfoPanelSkeleton: FC<StyleableProps & { size?: 'default' | 'lg'; hideIcons?: boolean }> = ({
  className,
  size,
  hideIcons
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        styles.InfoIconContainer,
        styles.InfoIconContainerSkeleton,
        { [styles.InfoIconContainerLarge]: size === 'lg', [styles.InfoIconContainerNoIcons]: hideIcons === true },
        className
      )}
    >
      <InfoItem icon={<EventSVGIcon />} title={''} subtitle={t('listing.available')} />
      <InfoItem icon={<RoundedCornerSVGIcon />} title={''} subtitle={t('listing.squareMeter')} />
      <InfoItem icon={<MeetingRoom />} title={''} subtitle={t('listing.rooms')} />
      <InfoItem icon={<LocalOfferSVGIcon />} title={''} subtitle={t('listing.rent')} />
    </div>
  );
};
