import { TabNavigationTab } from './components/controls/tab-navigation/tab-navigation';
import { useMemo, useEffect } from 'react';
import { GlobalState } from './redux/store';
import { useSelector } from 'react-redux';
import { UserState } from './redux/user.state';
import { useUserPermission } from './utils/hooks/user-data';
import { AdministrationState } from './redux/administration.state';
import { useFetchNumberOfNewLandlords, useFetchNumberOfNewSearchRequests } from './utils/hooks/administration.hooks';
import { IS_DEV_MODE } from './utils/constants';

export const useNavigationTabs = (): TabNavigationTab[] => {
  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const { numberOfNewLandlords, numberOfNewSearchRequests } = useSelector<GlobalState, AdministrationState>(
    (state) => state.administrationState
  );

  const fetchNumberOfNewLandlords = useFetchNumberOfNewLandlords();
  const fetchNumberOfNewSearchRequests = useFetchNumberOfNewSearchRequests();

  const permissions = useUserPermission();
  const { hasAdministrativeFunction, isAdmin, isLandlord, isSeeker, isAnonymous } = permissions;

  useEffect(() => {
    if (userState.csrf !== null && userState.csrf.length > 0 && hasAdministrativeFunction) {
      fetchNumberOfNewLandlords();
      fetchNumberOfNewSearchRequests();
    }
  }, [userState]);

  const navigationTabs = useMemo(() => {
    const tabs: TabNavigationTab[] = [
      {
        labelTranslationKey: 'navigation.listings',
        path: '/listings'
      }
    ];

    if (hasAdministrativeFunction) {
      tabs.push({
        labelTranslationKey: 'navigation.landlord',
        path: '/landlord',
        tagCount: numberOfNewLandlords
      });
      tabs.push({
        labelTranslationKey: 'navigation.inquiries',
        path: '/search-requests',
        tagCount: numberOfNewSearchRequests
      });
    }

    if (isAdmin) {
      tabs.push({
        labelTranslationKey: 'navigation.admin',
        path: '/admin'
      });
    }

    if (isLandlord) {
      tabs.push({
        labelTranslationKey: 'navigation.mySpace',
        path: '/account'
      });
    }

    if (isSeeker) {
      tabs.push({
        labelTranslationKey: 'navigation.mySpace',
        path: '/seeker'
      });
    }

    if (isSeeker || isAnonymous || hasAdministrativeFunction) {
      tabs.push({
        labelTranslationKey: 'navigation.tips',
        path: '/tips'
      });
    }

    if (IS_DEV_MODE) {
      tabs.push({
        labelTranslationKey: 'navigation.components',
        path: '/components'
      });
    }

    return tabs;
  }, [numberOfNewLandlords, numberOfNewSearchRequests, userState, permissions]);

  return navigationTabs;
};
