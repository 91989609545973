import { FC, useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal, TextInput } from 'carbon-components-react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { verifyEmail } from '../../../utils/validation/input-validation';
import TagInput from '../../controls/tag-input/tag-input';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog';
import MailEditor from '../mail-editor/mail-editor';
import styles from './send-mail-dialog.module.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_MAIL_TEMPLATE } from '../../../graphql/queries/mail';
import { GetMailTemplate } from '../../../graphql/types/GetMailTemplate';
import { useGenericQueryErrorToast } from '../../../utils/hooks/query-result-toast';
import sanitizeHtml from 'sanitize-html';
import { sanitizeOptions } from '../../../utils/sanitize';
import { useBodyScrollBlocker } from '../../../utils/hooks/body-scroll-blocker';

interface SendMailInfo {
  mailSubject: string;
  mailCCAddresses: string[];
  mailContent: string;
  mailAttachments: string[];
}

export interface SendMailDialogProps {
  receiver: string;
  open: boolean;
  onClose: () => void;
  onSendMail: (mailInfo: SendMailInfo) => void;
  initialSubject?: string;
}

export const SendMailDialog: FC<SendMailDialogProps> = ({ receiver, open, onClose, onSendMail, initialSubject }) => {
  const { t } = useTranslation();

  const [mailSubject, setMailSubject] = useState(initialSubject || '');
  const [mailCCAddresses, setMailCCAddresses] = useState<string[]>([]);
  const [mailContent, setMailContent] = useState<string>('');
  const [isDirty, setIsDirty] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [mailAttachments, setMailAttachments] = useState<string[]>([]);

  const [getMailTemplate, { data: templateData, error }] = useLazyQuery<GetMailTemplate>(GET_MAIL_TEMPLATE);
  useGenericQueryErrorToast(error);

  useEffect(() => {
    if (templateData && templateData.emailTemplate) {
      const template = templateData.emailTemplate;
      setMailSubject(t(template.subject));
      setMailAttachments(template.attachments);
      setMailContent(sanitizeHtml(t(template.content, { interpolation: { escapeValue: false } }), sanitizeOptions));
      setIsDirty(true);
    }
  }, [templateData]);

  const resetForm = useCallback(() => {
    setMailSubject('');
    setMailCCAddresses([]);
    setMailContent('');
    setIsDirty(false);
  }, [setMailSubject, setMailCCAddresses, setMailContent]);

  useBodyScrollBlocker(open);

  return createPortal(
    open && (
      <>
        <Modal
          className={styles.SendMailDialog}
          passiveModal={true}
          size={'lg'}
          open={open}
          onRequestClose={() => {
            if (isDirty) {
              setIsConfirmationDialogOpen(true);
            } else {
              onClose && onClose();
              resetForm();
            }
          }}
          modalLabel={t('modals.sendMail.title')}
          selectorPrimaryFocus={'input'}
          iconDescription={t('actions.close')}
        >
          <Form className={styles.Meta}>
            <span className={styles.RequiredToolTip}>{t('formFields.requiredFields')}</span>
            <label htmlFor="mailReceiver">{t('formFields.mailReceiver')}</label>
            <input id="mailReceiver" readOnly value={receiver} />
            <label className={styles.required} htmlFor="mailSubject">
              {t('formFields.mailSubject')}
            </label>
            <TextInput
              id="mailSubject"
              labelText={''}
              value={mailSubject}
              placeholder={t('formFields.mailSubjectPlaceholder')}
              onChange={(e) => {
                setMailSubject(e.target.value);
                setIsDirty(true);
              }}
            />
            <label htmlFor="mailCC">{t('formFields.mailCC')}</label>
            <TagInput
              id="mailCC"
              initialTags={[]}
              tags={mailCCAddresses}
              placeHolder={t('formFields.emailAddress')}
              validateTag={(tag) => verifyEmail(tag)}
              onChange={(addresses) => {
                setMailCCAddresses(addresses);
                addresses.length > 0 && setIsDirty(true);
              }}
            />
          </Form>
          <MailEditor
            placeholder={t('formFields.placeholder.emailContent')}
            htmlContent={mailContent}
            onContentChange={(content, hasText) => {
              setMailContent(content);
              hasText && setIsDirty(true);
            }}
            attachments={mailAttachments}
            setMailAttachments={setMailAttachments}
            onApplyTemplate={() => {
              getMailTemplate();
            }}
          />
          <div className="bx--modal-custom-footer">
            <Button
              kind={'primary'}
              disabled={mailContent.length === 0 || mailSubject.length === 0}
              onClick={() => {
                onSendMail({
                  mailCCAddresses,
                  mailContent,
                  mailSubject,
                  mailAttachments
                });
                resetForm();
              }}
            >
              {t('actions.send')}
            </Button>
          </div>
        </Modal>
        )
        <ConfirmationDialog
          title={t('modals.confirmationAbort.title')}
          headline={t('modals.confirmationAbort.headline')}
          text={t('modals.confirmationAbort.text')}
          primaryActionLabel={t('actions.discard')}
          open={isConfirmationDialogOpen}
          onPrimaryActionClick={() => {
            setIsConfirmationDialogOpen(false);
            resetForm();
            onClose && onClose();
          }}
          onCloseClick={() => {
            setIsConfirmationDialogOpen(false);
          }}
        />
      </>
    ),
    document.body
  );
};

export default SendMailDialog;
