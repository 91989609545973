import { FC, useState } from 'react';
import { AddSVGIcon, RemoveSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './menu.module.scss';

export type MenuItem = {
  titleKey: string;
  linkTo: string;
  subMenuItems?: MenuItem[];
};

type MenuItemProps = MenuItem & {
  id?: string;
  isSelected?: boolean;
  isSubItem?: boolean;
};

const EXPANSION_TRANSITION_DURATION = 250;

const MenuItemComponent: FC<MenuItemProps> = (props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(props.isSelected);
  return (
    <li
      className={classNames(styles.MenuItem, {
        [styles.MenuItem__active]: props.isSelected,
        [styles.MenuItem__subItem]: props.isSubItem,
        [styles.MenuItem__open]: isOpen
      })}
      id={props.isSelected && !props.isSubItem && !props.children ? 'currentActiveNavItem' : undefined}
    >
      {!props.isSubItem ? (
        <Link to={props.linkTo} className={props.children ? styles.DividerRight : undefined}>
          {t(props.titleKey)}
        </Link>
      ) : (
        <a href={props.linkTo} className={props.children ? styles.DividerRight : undefined}>
          {t(props.titleKey)}
        </a>
      )}
      {props.children && (
        <>
          <Button
            kind="secondary"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            renderIcon={isOpen ? RemoveSVGIcon : AddSVGIcon}
            iconDescription={t(!isOpen ? 'actions.expand' : 'actions.minimize')}
            tooltipAlignment="end"
            hasIconOnly
            aria-label={t(!isOpen ? 'actions.expand' : 'actions.minimize')}
            aria-expanded={isOpen}
            aria-controls={props.id}
            className={styles.ExpansionButton}
          ></Button>

          <AnimateHeight id={props.id} duration={EXPANSION_TRANSITION_DURATION} height={isOpen ? 'auto' : 0}>
            {props.children}
          </AnimateHeight>
        </>
      )}
    </li>
  );
};

interface MenuProps {
  items: MenuItem[];
  id: string;
  open?: boolean;
}

const Menu: FC<MenuProps> = (props) => {
  const { pathname, hash } = useLocation();
  const fullPath = pathname + hash;

  return (
    <nav className={styles.Menu}>
      <AnimateHeight id={props.id} duration={EXPANSION_TRANSITION_DURATION} height={props.open ? 'auto' : 0}>
        <ul className={styles.FirstLevel}>
          {props.items.map((item, index) => {
            const isSelected = pathname.startsWith(item.linkTo);

            return (
              <MenuItemComponent key={index} id={`subMenu_${index}`} {...item} isSelected={isSelected}>
                {item.subMenuItems && item.subMenuItems.length > 0 && (
                  <ul>
                    {item.subMenuItems?.map((subMenuItem, subMenuIndex) => {
                      const isSubMenuSelected = fullPath.startsWith(subMenuItem.linkTo);
                      return (
                        <MenuItemComponent
                          key={subMenuIndex}
                          {...subMenuItem}
                          isSubItem
                          isSelected={isSubMenuSelected}
                        ></MenuItemComponent>
                      );
                    })}
                  </ul>
                )}
              </MenuItemComponent>
            );
          })}
        </ul>
      </AnimateHeight>
    </nav>
  );
};

export default Menu;
