import { MutationFunctionOptions, useMutation } from '@apollo/client';
import { PostAddFavorite, PostAddFavoriteVariables } from '../../../graphql/types/PostAddFavorite';
import { DeleteFavorite, DeleteFavoriteVariables } from '../../../graphql/types/DeleteFavorite';
import { DELETE_FAVORITE, POST_ADD_FAVORITE } from '../../../graphql/queries/favorites';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import { UserState } from '../../../redux/user.state';
import {
  DeleteSeekerSearchRequest,
  DeleteSeekerSearchRequestVariables
} from '../../../graphql/types/DeleteSeekerSearchRequest';
import {
  UpdateSeekerSearchRequest,
  UpdateSeekerSearchRequestVariables
} from '../../../graphql/types/UpdateSeekerSearchRequest';
import {
  DELETE_SEEKER_SEARCH_REQUEST,
  PUT_UPDATE_SEEKER_SEARCH_REQUEST
} from '../../../graphql/queries/seeker-search-requests';

export type AddFavoriteMutation = (
  options?: MutationFunctionOptions<PostAddFavorite, PostAddFavoriteVariables>
) => Promise<unknown>;
export type DeleteFavoriteMutation = (
  options?: MutationFunctionOptions<DeleteFavorite, DeleteFavoriteVariables>
) => Promise<unknown>;

export const useAddAndDeleteFavorites = (
  listingId: string | null
): {
  addFavorite: AddFavoriteMutation;
  deleteFavorite: DeleteFavoriteMutation;
} => {
  const user = useSelector<GlobalState, UserState>((state) => state.user);
  const emailAddress = user.user?.email || '';

  const variables = listingId ? { listingId, emailAddress } : undefined;

  const [addFavorite] = useMutation<PostAddFavorite, PostAddFavoriteVariables>(POST_ADD_FAVORITE, {
    variables
  });
  const [deleteFavorite] = useMutation<DeleteFavorite, DeleteFavoriteVariables>(DELETE_FAVORITE, {
    variables
  });

  return {
    addFavorite,
    deleteFavorite
  };
};

export type UpdateSeekerSearchRequestMutation = (
  options?: MutationFunctionOptions<UpdateSeekerSearchRequest, UpdateSeekerSearchRequestVariables>
) => Promise<unknown>;
export type DeleteSeekerSearchRequestMutation = (
  options?: MutationFunctionOptions<DeleteSeekerSearchRequest, DeleteSeekerSearchRequestVariables>
) => Promise<unknown>;

export const useUpdateAndDeleteSearchRequest = (): {
  updateSeekerSearchRequest: UpdateSeekerSearchRequestMutation;
  deleteSeekerSearchRequest: DeleteSeekerSearchRequestMutation;
} => {
  const [updateSeekerSearchRequest] = useMutation<UpdateSeekerSearchRequest, UpdateSeekerSearchRequestVariables>(
    PUT_UPDATE_SEEKER_SEARCH_REQUEST
  );
  const [deleteSeekerSearchRequest] = useMutation<DeleteSeekerSearchRequest, DeleteSeekerSearchRequestVariables>(
    DELETE_SEEKER_SEARCH_REQUEST
  );
  return {
    updateSeekerSearchRequest,
    deleteSeekerSearchRequest
  };
};
