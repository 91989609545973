import { FC } from 'react';
import styles from './tips.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { sanitizeOptions } from '../../../utils/sanitize';
import { useDocumentTitle } from '../../../utils/hooks/document-title';

export const Tips: FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('views.tips.headline'));

  return (
    <div className={classNames(styles.Tips, 'global-content-wrapper-block')}>
      <section>
        <h2>{t('views.tips.headlineGeneralTips')}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              t('views.tips.contentGeneralTips', { interpolation: { escapeValues: false } }),
              sanitizeOptions
            )
          }}
        />
        <h2>{t('views.tips.headlineFalseAds')}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              t('views.tips.contentFalseAds', { interpolation: { escapeValues: false } }),
              sanitizeOptions
            )
          }}
        />
      </section>
    </div>
  );
};

export default Tips;
