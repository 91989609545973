import { ApolloQueryResult, FetchResult, MutationFunctionOptions, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { POST_SEND_MAIL } from '../../../../../graphql/queries/mail';
import {
  DELETE_COMMENT,
  GET_COMMENTS,
  POST_ADD_COMMENT,
  PUT_UPDATE_COMMENT
} from '../../../../../graphql/queries/search-requests-comments';
import { AddComment, AddCommentVariables } from '../../../../../graphql/types/AddComment';
import { DeleteComment, DeleteCommentVariables } from '../../../../../graphql/types/DeleteComment';
import { GetComments, GetCommentsVariables } from '../../../../../graphql/types/GetComments';
import { SendMail, SendMailVariables } from '../../../../../graphql/types/SendMail';
import { UpdateComment, UpdateCommentVariables } from '../../../../../graphql/types/UpdateComment';
import { GlobalState } from '../../../../../redux/store';
import { useQueryResultToast } from '../../../../../utils/hooks/query-result-toast';
import { SearchRequestsFormDataState } from '../search-request.state';

export const useCommentRequests = (): {
  addComment: (options?: MutationFunctionOptions<AddComment, AddCommentVariables>) => Promise<unknown>;
  deleteComment: (options?: MutationFunctionOptions<DeleteComment, DeleteCommentVariables>) => Promise<unknown>;
  updateComment: (options?: MutationFunctionOptions<UpdateComment, UpdateCommentVariables>) => Promise<unknown>;
} => {
  const [addComment, { data: addCommentData, error: addCommentError }] = useMutation<AddComment, AddCommentVariables>(
    POST_ADD_COMMENT
  );

  const [deleteComment, { data: deleteCommentData, error: deleteCommentError }] = useMutation<
    DeleteComment,
    DeleteCommentVariables
  >(DELETE_COMMENT);

  const [updateComment, { data: updateCommentData, error: updateCommentError }] = useMutation<
    UpdateComment,
    UpdateCommentVariables
  >(PUT_UPDATE_COMMENT);

  useQueryResultToast(addCommentData, addCommentError, {
    success: {
      titleKey: 'notifications.commentCreateSuccess'
    },
    error: {
      titleKey: 'notifications.commentCreateError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(deleteCommentData, deleteCommentError, {
    success: {
      titleKey: 'notifications.commentDeleteSuccess'
    },
    error: {
      titleKey: 'notifications.commentDeleteError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(updateCommentData, updateCommentError, {
    success: {
      titleKey: 'notifications.commentUpdateSuccess'
    },
    error: {
      titleKey: 'notifications.commentUpdateError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return {
    addComment,
    deleteComment,
    updateComment
  };
};

export const useSearchRequestComments = (): {
  refetchComments: (variables?: Partial<GetCommentsVariables> | undefined) => Promise<ApolloQueryResult<GetComments>>;

  data: GetComments | undefined;
} => {
  const { id } = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);

  const { refetch: refetchComments, data } = useQuery<GetComments, GetCommentsVariables>(GET_COMMENTS, {
    variables: {
      searchRequestId: id || ''
    },
    fetchPolicy: 'no-cache',
    skip: id === null
  });

  return {
    refetchComments,
    data
  };
};

export const useSendMail = (): ((
  options?: MutationFunctionOptions<SendMail, SendMailVariables> | undefined
) => Promise<FetchResult<SendMail>>) => {
  const [sendMail, { data, error }] = useMutation<SendMail, SendMailVariables>(POST_SEND_MAIL);
  useQueryResultToast(data, error, {
    success: {
      titleKey: 'notifications.mailSendSuccess'
    },
    error: {
      titleKey: 'notifications.mailSendError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return sendMail;
};
