/**
 * Create logger
 */
export const logger = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (e: any): void => {
    /* eslint-disable-next-line no-console */
    console.error('[frontend] ', e);
  }
};
