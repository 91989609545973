import { FC } from 'react';
import styles from './listing-card-container.module.scss';
import { GetLandLordByUUID_landlordByUUID_listings } from '../../../graphql/types/GetLandLordByUUID';
import { GetLandLordById_landlord_listings } from '../../../graphql/types/GetLandLordById';
import { ListingCard, ListingCardSkeleton, ListingProps } from '../../controls/listing-card/listing-card';
import { GetListings_listings } from '../../../graphql/types/GetListings';
import { StyleableProps } from '../../../utils/types';
import { GetFavorites_favorites } from '../../../graphql/types/GetFavorites';

export interface ListingCardContainerProps {
  listings?:
    | GetLandLordByUUID_landlordByUUID_listings[]
    | (GetLandLordById_landlord_listings | null)[]
    | (GetListings_listings | null)[]
    | (GetFavorites_favorites | null)[]
    | null;
  emptyText?: string;
  editable: boolean;
  refetch?: () => void;
  headingLevel?: 'h2' | 'h3';
  favorable?: boolean;
  favorites?: string[];
}

export const ListingCardContainerSkeleton: FC<StyleableProps & { count: number }> = ({ count }) => {
  const children = [];
  for (let i = 0; i < count; i++) {
    children.push(<ListingCardSkeleton key={i} />);
  }
  return <div className={styles.ListingContainer}>{children}</div>;
};

export const ListingCardContainer: FC<ListingCardContainerProps> = (props: ListingCardContainerProps) => {
  // Cast listings
  const listings = props.listings as ListingProps[];

  const isFavorite = (id: string): boolean => {
    return props.favorites ? props.favorites.indexOf(id) !== -1 : false;
  };

  return (
    <>
      {listings && listings.length > 0 ? (
        <div className={styles.ListingContainer} role="list">
          {listings.map((listing) => {
            if (listing) {
              return (
                <ListingCard
                  isFavorite={isFavorite(listing.uuid)}
                  favorable={props.favorable}
                  editable={props.editable}
                  key={listing.uuid}
                  id={listing.id}
                  uuid={listing.uuid}
                  totalRent={listing.totalRent}
                  type={listing.type}
                  isActive={listing.isActive}
                  isBlocked={listing.isBlocked}
                  numberOfRooms={listing.numberOfRooms}
                  street={listing.street}
                  houseNumber={listing.houseNumber}
                  postalCode={listing.postalCode}
                  city={listing.city}
                  district={listing.district}
                  availableFrom={listing.availableFrom}
                  squareMeter={listing.squareMeter}
                  tags={listing.tags}
                  previewImage={listing.previewImage}
                  refetch={props.refetch}
                  images={listing.images}
                  headingLevel={props.headingLevel}
                />
              );
            }
            return <></>;
          })}
        </div>
      ) : (
        <p className={styles.NoListingText}>{props.emptyText}</p>
      )}
    </>
  );
};
