import { FC, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import {
  Button,
  Checkbox,
  Dropdown,
  Link,
  Modal,
  RadioButton,
  RadioButtonGroup,
  TextInput
} from 'carbon-components-react';
import styles from './register-dialog.module.scss';
import { DialogBackNavigation } from '../../controls/dialog-back-navigation/dialog-back-navigation';
import classNames from 'classnames';
import { useUrlSearchParams } from 'use-url-search-params';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import {
  LandlordFormDataState,
  resetForm,
  setCity,
  setCompanyName,
  setContactType,
  setEmail,
  setFirstName,
  setGender,
  setHouseNumber,
  setLastName,
  setPassword,
  setPasswordConfirmed,
  setPostalCode,
  setPrivacyPolicyAccepted,
  setStreet,
  setTelephoneNumber
} from '../landlord/landlord-view/landlord-view.state';
import { ContactType, Gender } from '../../../graphql/types/globalTypes';
import {
  clearUpPhoneNumber,
  houseNumberCharacterInputHandler,
  postalCodeCharacterInputHandler
} from '../../../utils/validation/input-validation';
import { registerAuthValidationSchema, registerValidationSchema } from './register-dialog.validation';
import { DefaultMultiSelectItem } from '../../../utils/types';
import { useMutation } from '@apollo/client';
import { POST_REGISTER_LANDLORD } from '../../../graphql/queries/landlord';
import { PostRegisterLandlord, PostRegisterLandlordVariables } from '../../../graphql/types/PostRegisterLandlord';
import { useQueryResultNotificationError } from '../../../utils/hooks/query-result-notification';
import { Param } from '../../../utils/constants';

export interface RegisterDialogProps {
  open: boolean;
  closeModal: () => void;
}

export const RegisterDialog: FC<RegisterDialogProps> = (props: RegisterDialogProps) => {
  enum View {
    AuthData,
    PersonalData,
    Success
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setParams] = useUrlSearchParams({}, {});
  const [view, setView] = useState<View>(View.AuthData);
  const [error, setError] = useState<boolean>(false);
  const formState = useSelector<GlobalState, LandlordFormDataState>((state) => state.landlordFormData);
  const validationSchema = view === View.AuthData ? registerAuthValidationSchema : registerValidationSchema;
  const { getValidationPropsForField, validateState } = useFormValidation(validationSchema, formState, {
    showToasts: false,
    scrollToInvalidField: false
  });

  const [createLandlord, { error: creationError }] = useMutation<PostRegisterLandlord, PostRegisterLandlordVariables>(
    POST_REGISTER_LANDLORD,
    {
      variables: {
        firstName: formState.firstName || '',
        email: formState.email || '',
        phone: formState.phone || '',
        city: formState.city || '',
        postalCode: formState.postalCode || '',
        companyName: formState.companyName,
        contactType: formState.contactType,
        gender: formState.gender,
        houseNumber: formState.houseNumber || '',
        lastName: formState.lastName,
        password: formState.password || '',
        street: formState.street || '',
        privacyPolicyAccepted: formState.privacyPolicyAccepted
      }
    }
  );

  const errorNotification = useQueryResultNotificationError(creationError);

  useEffect(() => {
    if (creationError) {
      setError(true);
    }
  }, [creationError]);

  const contactTypeItems = Object.keys(ContactType).map((key) => ({
    text: t(`enums.contactType.${key}`),
    id: key
  }));

  const salutationItems: DefaultMultiSelectItem[] = [
    { id: Gender.MALE, text: t(`enums.salutation.${Gender.MALE}`) },
    { id: Gender.FEMALE, text: t(`enums.salutation.${Gender.FEMALE}`) },
    { id: Gender.DIVERSE, text: '-' }
  ];

  const translatedInputLabelProps = useCallback(
    (key: string, optionalPlaceholder?: string) => ({
      labelText: t(key) as string,
      placeholder: optionalPlaceholder === '-' ? '-' : t(optionalPlaceholder || key)
    }),
    [t]
  );

  const disableRegister = (): boolean => {
    return (
      (formState.contactType === ContactType.PRIVATE ? false : !formState.companyName) ||
      !formState.firstName ||
      !formState.lastName ||
      !formState.phone ||
      !formState.street ||
      !formState.houseNumber ||
      !formState.postalCode ||
      !formState.city ||
      !formState.privacyPolicyAccepted
    );
  };

  useEffect(() => {
    // Always start with the auth data view if dialog is opened
    if (props.open) {
      setView(View.AuthData);
    }
  }, [props.open]);

  // Close dialog and remove register param from url
  const onClose = (): void => {
    props.closeModal();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setParams({ [Param.REGISTER]: undefined });
  };

  return createPortal(
    <Modal
      className={styles.RegisterDialog}
      passiveModal={true}
      size={'sm'}
      open={props.open}
      onRequestClose={onClose}
      modalLabel={t(`views.registerLandlord.${view === View.Success ? 'successModalTitle' : 'register'}`)}
      modalHeading={view === View.Success ? null : t('views.registerLandlord.forLandlords')}
      selectorPrimaryFocus={'.bx--modal-container'}
      iconDescription={t('actions.close')}
      preventCloseOnClickOutside={true}
    >
      {view === View.AuthData && (
        <form className={styles.content}>
          <p className={styles.Explanation}>
            <Trans i18nKey="views.registerLandlord.onlyRegisterSeekers">
              Bitte registrieren Sie sich nur, wenn Sie <strong>Immobilienangebote anbieten</strong> wollen.
            </Trans>
          </p>
          <span className={styles.RequiredToolTip}>{t('formFields.requiredFields')}</span>
          <TextInput
            id="email-address"
            value={formState.email || ''}
            labelText={t('formFields.emailAddress') as string}
            required={true}
            helperText={t('formFields.helperText.email')}
            placeholder={t('formFields.placeholder.emailAddress')}
            {...getValidationPropsForField('email', (event) => {
              dispatch(setEmail(event.target.value));
              setError(false);
            })}
          />
          <TextInput
            id="new-password"
            type="password"
            value={formState.password || ''}
            labelText={t('formFields.password') as string}
            placeholder={t('formFields.placeholder.password')}
            required={true}
            {...getValidationPropsForField('password', (event) => {
              dispatch(setPassword(event.target.value));
            })}
          />
          <TextInput
            id="new-password-confirmed"
            type="password"
            value={formState.passwordConfirmed || ''}
            labelText={t('formFields.passwordConfirmation') as string}
            placeholder={t('formFields.placeholder.password')}
            required={true}
            {...getValidationPropsForField('passwordConfirmed', (event) => {
              dispatch(setPasswordConfirmed(event.target.value));
            })}
          />
          <p className={styles.Explanation}>
            <Trans i18nKey="views.registerLandlord.explanation">
              Zum <strong>Inserieren</strong> ist die Anmeldung mit Ihrer <strong>Email-Adresse</strong> nötig. Mit der
              TUM-Kennung ist ausschließlich das Finden von Immobilienangeboten und dessen Vorteile möglich.
            </Trans>
          </p>
          <p>
            <Link href="https://living.tum.de/privacy-policy" target="_blank">
              {t('main.privacyPolicy')}
            </Link>
            <br />
            <br />
            {t('views.registerLandlord.alreadyRegistered')}?{' '}
            <Link href={'?login=1'}>{t('views.registerLandlord.loginWithExistingAccount')}</Link>
          </p>
        </form>
      )}
      {view === View.PersonalData && (
        <form className={styles.content}>
          <span className={styles.RequiredToolTip}>{t('formFields.requiredFields')}</span>
          <DialogBackNavigation
            headline={t('views.registerLandlord.personalData')}
            info={t('views.registerLandlord.personalDataInfo')}
            onBackClick={() => setView(View.AuthData)}
          />

          <RadioButtonGroup
            name="ProfileType"
            valueSelected={formState.contactType}
            className={styles.ContactTypeSelection}
            onChange={(value) => dispatch(setContactType(value as ContactType))}
          >
            {contactTypeItems.map((item) => (
              <RadioButton id={item.id} key={item.id} value={item.id} labelText={item.text} />
            ))}
          </RadioButtonGroup>
          {formState?.contactType === ContactType.PRIVATE ? null : (
            <TextInput
              id="company"
              value={formState.companyName || ''}
              {...translatedInputLabelProps(
                formState.contactType === ContactType.COMPANY ? 'formFields.company' : 'formFields.publicInstitution',
                formState.contactType === ContactType.COMPANY
                  ? 'formFields.nameOfCompany'
                  : 'formFields.nameOfPublicInstitution'
              )}
              {...getValidationPropsForField('companyName', (event) => {
                dispatch(setCompanyName(event.target.value));
              })}
            />
          )}
          <div className={styles.SubGrid}>
            <Dropdown
              id="salutation"
              items={salutationItems}
              label={''}
              itemToString={(item) => (item ? item.text : '')}
              titleText={t('formFields.salutation') as string}
              selectedItem={formState.gender ? salutationItems.find((item) => item.id === formState.gender) : null}
              {...getValidationPropsForField(
                'salutation',
                ({ selectedItem }: { selectedItem: DefaultMultiSelectItem }) => {
                  selectedItem && dispatch(setGender(selectedItem.id as Gender));
                }
              )}
            />
            <TextInput
              id="lastName"
              value={formState.lastName || ''}
              {...translatedInputLabelProps('formFields.lastName')}
              {...getValidationPropsForField('lastName', (event) => {
                dispatch(setLastName(event.target.value));
              })}
            />
            <TextInput
              id="firstName"
              value={formState.firstName || ''}
              {...translatedInputLabelProps('formFields.firstName')}
              {...getValidationPropsForField('firstName', (event) => {
                dispatch(setFirstName(event.target.value));
              })}
            />
          </div>

          <TextInput
            id="tel"
            value={formState.phone || ''}
            {...translatedInputLabelProps('formFields.telephoneNumber')}
            {...getValidationPropsForField('phone', (event) => {
              dispatch(setTelephoneNumber(event.target.value));
            })}
          />
          <div className={classNames(styles.SubGrid, styles.street)}>
            <TextInput
              id="street"
              value={formState.street || ''}
              {...translatedInputLabelProps('formFields.street')}
              {...getValidationPropsForField('street', (event) => {
                dispatch(setStreet(event.target.value));
              })}
            />
            <TextInput
              id="house-number"
              value={formState.houseNumber || ''}
              onKeyDown={houseNumberCharacterInputHandler}
              {...translatedInputLabelProps('formFields.houseNumberAbbreviation', '-')}
              {...getValidationPropsForField('houseNumber', (event) => {
                dispatch(setHouseNumber(event.target.value));
              })}
            />
          </div>
          <div className={classNames(styles.SubGrid, styles.city)}>
            <TextInput
              id="postal-code"
              onKeyDown={postalCodeCharacterInputHandler}
              value={formState.postalCode || ''}
              {...translatedInputLabelProps('formFields.postalCode', 'formFields.postalCodeAbbreviation')}
              {...getValidationPropsForField('postalCode', (event) => {
                dispatch(setPostalCode(event.target.value));
              })}
            />
            <TextInput
              id="city"
              value={formState.city || ''}
              {...translatedInputLabelProps('formFields.place')}
              {...getValidationPropsForField('city', (event) => {
                dispatch(setCity(event.target.value));
              })}
            />
          </div>
          <Checkbox
            id="agreement"
            labelText={
              <>
                <Trans i18nKey="views.registerLandlord.agreement">
                  Ich stimme der Speicherung und Verarbeitung meiner Daten in Übereinstimmung mit der
                  {
                    <Link href="https://living.tum.de/privacy-policy" target="_blank">
                      TUM Living Datenschutzerkärung
                    </Link>
                  }{' '}
                  zu.
                </Trans>
                <span className={styles.requiredMarker}>*</span>
              </>
            }
            onChange={(isChecked) => dispatch(setPrivacyPolicyAccepted(isChecked))}
          />
          {error && <div>{errorNotification}</div>}
        </form>
      )}
      {view === View.Success && (
        <div className={classNames(styles.content, styles.SuccessView)}>
          <strong>{t('views.registerLandlord.successTitle')}</strong>
          <p>{t('views.registerLandlord.successContent')}</p>
        </div>
      )}
      <div className={classNames('bx--modal-custom-footer', { [styles.alignLeft]: view === View.Success })}>
        {view === View.Success && (
          <Button kind={'secondary'} onClick={onClose}>
            {t('actions.close')}
          </Button>
        )}
        {view === View.AuthData && (
          <>
            <p className={styles.RegistrationStepLabel}>
              {t('views.registerLandlord.registrationStep', { current: 1, max: 2 })}
            </p>
            <Button
              kind={'primary'}
              disabled={!formState.email || !formState.password || !formState.passwordConfirmed}
              onClick={() => {
                validateState(() => {
                  setView(View.PersonalData);
                });
              }}
            >
              {t('actions.next')}
            </Button>
          </>
        )}
        {view === View.PersonalData && (
          <>
            <p className={styles.RegistrationStepLabel}>
              {t('views.registerLandlord.registrationStep', { current: 2, max: 2 })}
            </p>
            <Button
              kind={'primary'}
              disabled={disableRegister()}
              onClick={() => {
                if (formState.phone) {
                  // user is allowed to input anything, we afterwords clean up disallowed characters
                  dispatch(setTelephoneNumber(clearUpPhoneNumber(formState.phone)));
                }

                validateState(() => {
                  createLandlord()
                    .then(() => {
                      setView(View.Success);
                      dispatch(resetForm());
                    })
                    .catch(() => false);
                });
              }}
            >
              {t('actions.register')}
            </Button>
          </>
        )}
      </div>
    </Modal>,
    document.body
  );
};

export default RegisterDialog;
