import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { ToastNotification } from 'carbon-components-react';
import { FormikErrors, yupToFormErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { TOAST_DEFAULT_PROPERTIES } from '../hooks/query-result-toast';
import * as Yup from 'yup';

export interface FormValidationFailOptions {
  showToasts?: boolean;
  scrollToInvalidField?: boolean;
}

export const handleFormValidationFail = (
  setFormErrors: (formErrors: FormikErrors<unknown>) => void,
  t: (key: string) => string,
  options?: FormValidationFailOptions
) => (errors: Yup.ValidationError): void => {
  const formErrors = yupToFormErrors(errors);
  setFormErrors(formErrors);
  if (options === undefined || options.showToasts === undefined || options.showToasts) {
    toast(
      () => (
        <ToastNotification
          kind="error"
          title={t('notifications.genericFormValidationError') as string}
          subtitle={t('notifications.genericFormValidationErrorSubtitle') as string}
          caption=""
        />
      ),
      TOAST_DEFAULT_PROPERTIES
    );
  }
  if (options === undefined || options.scrollToInvalidField === undefined || options.scrollToInvalidField) {
    scrollToInvalidField();
  }
};

const scrollToInvalidField = (): void => {
  const element = document.querySelector('[data-invalid="true"]');
  if (element) {
    const headerOffset = 45;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition - headerOffset;

    window.scrollBy({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

export interface ValidationProps {
  invalid: boolean;
  invalidText: string | false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (event: any) => void;
}

export type FormStateValidationFunction = (onSuccess: () => void, context?: unknown) => void;
export type FormValidationUtils<T> = {
  formErrorState: [FormikErrors<T>, Dispatch<SetStateAction<FormikErrors<T>>>];
  validateState: FormStateValidationFunction;
  clearFormError: (fieldName: string) => void;
  clearAllErrors: () => void;
  getValidationPropsForField: (
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrappedOnChange?: ((event: any) => void) | undefined
  ) => ValidationProps;
};

export function useFormValidation<T>(
  validationSchema: Yup.AnySchema,
  formState: T,
  options?: FormValidationFailOptions
): FormValidationUtils<T> {
  const formErrorState = useState<FormikErrors<T>>({});
  const [formErrors, setFormErrors] = formErrorState;
  const { t } = useTranslation();

  // Method to clear form errors. Mostly used in components where the valid state is not on the
  // same component as the onchange method
  const clearFormError = useCallback(
    (fieldName: string) => {
      if (fieldName in formErrors) {
        // Remove Error
        const newErrors = Object.assign({}, formErrors);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete newErrors[fieldName];
        setFormErrors(newErrors);
      }
    },
    [formErrors]
  );

  const clearAllErrors = useCallback(() => {
    setFormErrors({});
  }, [formErrors]);

  // Extracts the error message and invalid state from the form errors, if there are any.
  // Also wraps the onChange method to clear errors on change
  const getValidationPropsForField = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fieldName: string, wrappedOnChange?: (event: any) => void) => {
      return {
        invalid: fieldName in formErrors,
        invalidText:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fieldName in formErrors && t(formErrors[fieldName] as string),

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange: (event: any) => {
          clearFormError(fieldName);
          wrappedOnChange && wrappedOnChange(event);
        }
      };
    },
    [formErrors]
  );

  // Validates the state with the given schema. When successful, it calls the callback and resets the errors.
  // When not successful, it sets the newly generated errors
  const validateState = useCallback(
    (onSuccess: () => void, context?: unknown) => {
      validationSchema
        .validate(formState, {
          abortEarly: false,
          context
        })
        .then(() => {
          onSuccess();
          setFormErrors({});
        })
        .catch(handleFormValidationFail(setFormErrors, t, options));
    },
    [validationSchema, setFormErrors, t, formState]
  );

  return {
    formErrorState,
    validateState,
    clearFormError,
    clearAllErrors,
    getValidationPropsForField
  };
}
