import { FC, useMemo, useState } from 'react';
import { GetListings } from '../../../../../graphql/types/GetListings';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'carbon-components-react';
import styles from './listings-list-table.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatDateTimeWithToday } from '../../../../../utils/intl/dates-and-times';
import { formatCurrency } from '../../../../../utils/intl/currency';
import { formatFloorSpace } from '../../../../../utils/intl/misc';
import TableOverflowMenu from '../../../../building-blocks/table-overflow-menu/table-overflow-menu';
import { useShareTableEntry } from '../../../../../utils/hooks/actions-hooks';
import { useMutation } from '@apollo/client';
import { POST_DUPLICATE_LISTING } from '../../../../../graphql/queries/listings';
import { PostDuplicateListing, PostDuplicateListingVariables } from '../../../../../graphql/types/PostDuplicateListing';
import { useQueryResultToast } from '../../../../../utils/hooks/query-result-toast';
import Verification from '../../../../controls/verification/verification';
import classNames from 'classnames';
import { useUrlSearchParams } from 'use-url-search-params';

const TABLE_HEADERS = [
  'views.listings.tableHeaders.onlineSince',
  'views.listings.tableHeaders.location',
  'views.listings.tableHeaders.kind',
  'views.listings.tableHeaders.availableBy',
  'views.listings.tableHeaders.rooms',
  'views.listings.tableHeaders.space',
  'views.listings.tableHeaders.rent',
  'views.listings.tableHeaders.status',
  ''
];

export interface ListingsListTableProps {
  data: GetListings | undefined;
}

export const ListingsListTable: FC<ListingsListTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const getShareLink = useShareTableEntry();
  const [params] = useUrlSearchParams({}, {});

  const [duplicate, { data: duplicateData, error }] = useMutation<PostDuplicateListing, PostDuplicateListingVariables>(
    POST_DUPLICATE_LISTING
  );

  useQueryResultToast(duplicateData, error, {
    success: {
      titleKey: 'notifications.genericActionSuccess'
    },
    error: {
      titleKey: 'notifications.genericActionError'
    }
  });

  const dateTimeTranslations = useMemo(
    () => ({
      today: t('datesAndTimes.today'),
      timeSuffix: t('datesAndTimes.timeSuffix')
    }),
    [t]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map((header, index) => (
            <TableHeader key={index}>{header.length > 0 && t(header)}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.listings.map((row) => {
            if (!row) return undefined;

            const cityDisplayName = row.city;
            const typeDisplayName = t(`enums.listingType.${row.type}`);
            return (
              <TableRow
                key={row.uuid}
                onClick={() => {
                  !isMenuOpen &&
                    navigate(`./${row.uuid}/view`, {
                      state: {
                        params
                      }
                    });
                }}
              >
                <TableCell>
                  <span
                    className={classNames(styles.bullet, {
                      [styles.bulletInactive]: !row.isActive
                    })}
                  >
                    ●
                  </span>{' '}
                  <span>
                    <time dateTime={row.publicationDate ? new Date(row.publicationDate).toISOString() : ''}>
                      {formatDateTimeWithToday(row.publicationDate, dateTimeTranslations)}
                    </time>
                  </span>
                </TableCell>
                <TableCell>{cityDisplayName}</TableCell>
                <TableCell>{typeDisplayName}</TableCell>
                <TableCell>{formatDate(row.availableFrom)}</TableCell>
                <TableCell>{row.numberOfRooms + ''}</TableCell>
                <TableCell>{formatFloorSpace(row.squareMeter) + ''}</TableCell>
                <TableCell>{formatCurrency(row.totalRent)}</TableCell>
                <TableCell>
                  <Verification verified={row.verifiedAt !== null} />
                </TableCell>
                <TableCell>
                  <TableOverflowMenu
                    row={row}
                    onClose={() => setIsMenuOpen(false)}
                    onOpen={() => setIsMenuOpen(true)}
                    onDuplicate={() =>
                      duplicate({ variables: { listingId: row.id } }).then((result) => {
                        if (result.data && !result.errors) {
                          const duplicateListingUUID = result.data.duplicateListing;
                          navigate(`./${duplicateListingUUID}/edit`, { state: { duplicate: 1 } });
                        }
                      })
                    }
                    getShareByMailContent={() => ({
                      mailSubject: t('sharingMail.listing.sharingMailSubject', {
                        city: cityDisplayName,
                        type: typeDisplayName
                      }),
                      mailBody: t('sharingMail.listing.sharingMailBody', {
                        link: getShareLink(row.uuid)
                      })
                    })}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ListingsListTable;
