import styles from './language-switcher.module.scss';
import { Button, ButtonSet } from 'carbon-components-react';
import { logger } from '../../../utils/logger';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FC } from 'react';

export const LanguageSwitcher: FC = () => {
  const { i18n } = useTranslation();

  const onLanguageChange = (lng: string): void => {
    i18n.changeLanguage(lng).catch((e) => logger.error(e));
    localStorage.setItem('i18nextLng', lng);
  };

  return (
    <nav className={styles.LanguageSwitcher}>
      <div className="global-content-wrapper">
        <ButtonSet className={styles.LanguageButtonSet}>
          <Button
            kind="secondary"
            size="small"
            onClick={() => onLanguageChange('de')}
            className={classNames(styles.LanguageButton, {
              [styles.LanguageButton_active]: i18n.language === 'de'
            })}
          >
            de
          </Button>
          <Button
            kind="secondary"
            size="small"
            onClick={() => onLanguageChange('en')}
            className={classNames(styles.LanguageButton, {
              [styles.LanguageButton_active]: i18n.language === 'en'
            })}
          >
            en
          </Button>
        </ButtonSet>
      </div>
    </nav>
  );
};
