import { Availability, DistrictItem, ORDERED_TAG_CATEGORIES } from './listings-list.filter-types';
import { TagCategory } from '../../../../graphql/types/globalTypes';
import { CustomMultiSelectItem } from '../../../../utils/types';
import { FILTER_SEPARATOR, Param } from '../../../../utils/constants';
import { checkIfDateIsNow } from '../../../../utils/intl/dates-and-times';

/**
 * Converts the given availability to a Date
 * @param availability The availability to convert
 */
export const dateForAvailability = (availability: Availability): Date => {
  const now = new Date();
  const date = new Date();
  now.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);
  if (availability !== Availability.NOW) {
    date.setMonth(availability - 1, 1);
  }
  if (date < now && now.getMonth() + 1 !== availability) {
    date.setFullYear(date.getFullYear() + 1);
  }
  return date;
};

/**
 * Converts the given date to the Availability enum
 * @param date The date to convert
 */
export const getAvailabilityFromDate = (date: string | null): Availability | null => {
  if (date !== null) {
    const convertedDate = new Date(date);
    convertedDate.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    if (checkIfDateIsNow(date)) {
      return Availability.NOW;
    }
    return convertedDate.getMonth() + 1;
  }
  return null;
};

/**
 * Returns the array with the boolean or null values for the verification or activation state
 */
export const getStateForParameter = (
  param1: string | null,
  param2: string | null,
  filterState: (boolean | null)[] | null
): (boolean | null)[] | null => {
  let result = null;
  // is checked/active
  if (param1 && param1 === '1') {
    // is also unchecked/inactive
    if (param2 && param2 === '1') {
      result = filterState ? [...filterState, false, true] : [false, true];
    } else {
      result = filterState ? [...filterState, true] : [true];
    }
  }
  // is only unchecked/inactive
  if (param1 === null && param2 && param2 === '1') {
    result = filterState ? [...filterState, false] : [false];
  }
  return result;
};

/**
 * Get the tags from the url
 * @param params
 * @param tagsForCategories
 */
export const getTagsFromUrl = (
  params: unknown,
  tagsForCategories: Map<TagCategory, CustomMultiSelectItem<string>[]>
): string[] => {
  const tags: string[] = [];
  ORDERED_TAG_CATEGORIES.forEach((orderedCategory) => {
    const category = tagsForCategories.get(orderedCategory);
    if (category) {
      category.forEach((tag) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (params[tag.id] === '1') {
          tags.push(tag.id);
        }
      });
    }
  });
  return tags;
};

// Extracts the approval items from the url and updates the approval filter state
export const getDistrictItemsByParam = (
  params: unknown,
  districtItems: CustomMultiSelectItem<string>[]
): DistrictItem[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ids = String(params[Param.DISTRICTS]).split(FILTER_SEPARATOR);
  const urlItems: Set<DistrictItem> = new Set<DistrictItem>([]);
  ids.forEach((id) => {
    const item = districtItems.find((item) => item.id === id);
    if (item) {
      urlItems.add(item);
    }
  });
  return Array.from(urlItems);
};
