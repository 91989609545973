import { RichUtils, Modifier, EditorState, SelectionState, DraftDecorator, ContentState, ContentBlock } from 'draft-js';
import { getEntityRange, getSelectionText } from 'draftjs-utils';
import { FC } from 'react';

/**
 * Plugin to make links work better in draft-js editor.
 * Credits to bengotow https://gist.github.com/bengotow/63462490660da6bfea8d92b3124e09ee
 * Credits to jpuri https://github.com/jpuri/react-draft-wysiwyg
 */

/* istanbul ignore file */
interface URLData {
  url: string | null;
  explicit: boolean;
}

/*
Function you can call from your toolbar or "link button" to manually linkify
the selected text with an "explicit" flag that prevents autolinking from
changing the URL if the user changes the link text.
*/
export function editorStateSettingExplicitLink(editorState: EditorState, urlOrNull: string): EditorState {
  return editorStateSettingLink(editorState, editorState.getSelection(), {
    url: urlOrNull,
    explicit: true
  });
}

/*
Returns editor state with a link entity created / updated to hold the link @data
for the range specified by @selection
*/
export function editorStateSettingLink(
  editorState: EditorState,
  selection: SelectionState,
  data: URLData
): EditorState {
  const contentState = editorState.getCurrentContent();
  const entityKey = getCurrentLinkEntityKey(editorState);

  let nextEditorState = editorState;

  if (!entityKey) {
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', data);
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    nextEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    nextEditorState = RichUtils.toggleLink(nextEditorState, selection, entityKey);
  } else {
    nextEditorState = EditorState.set(editorState, {
      currentContent: editorState.getCurrentContent().replaceEntityData(entityKey, data)
    });
    // this is a hack that forces the editor to update
    // https://github.com/facebook/draft-js/issues/1047
    nextEditorState = EditorState.forceSelection(nextEditorState, editorState.getSelection());
  }

  return nextEditorState;
}

export function insertOrUpdateLink(editorState: EditorState, linkTitle: string, linkTarget: string): EditorState {
  let entityKey = getCurrentLinkEntityKey(editorState);
  let selection = editorState.getSelection();

  if (entityKey) {
    const entityRange = getEntityRange(editorState, entityKey);
    const isBackward = selection.getIsBackward();
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start
      });
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end
      });
    }
  }
  entityKey = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', {
      url: linkTarget,
      explicit: false
    })
    .getLastCreatedEntityKey();

  const contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${linkTitle.length > 0 ? linkTitle : linkTarget}`,
    editorState.getCurrentInlineStyle(),
    entityKey
  );
  return EditorState.push(editorState, contentState, 'insert-characters');
}

/*
Returns the entityKey for the link entity the user is currently within.
*/
export function getCurrentLinkEntityKey(editorState: EditorState): string | null {
  const contentState = editorState.getCurrentContent();
  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const block = contentState.getBlockForKey(startKey);
  const linkKey = block.getEntityAt(Math.min(block.getText().length - 1, startOffset));

  if (linkKey) {
    const linkInstance = contentState.getEntity(linkKey);
    if (linkInstance.getType() === 'LINK') {
      return linkKey;
    }
  }
  return null;
}

export function getCurrentLink(
  editorState: EditorState
): {
  url: string;
  text: string;
} {
  const entityKey = getCurrentLinkEntityKey(editorState);
  if (entityKey) {
    return {
      url: editorState.getCurrentContent().getEntity(entityKey).getData().url,
      text: getEntityRange(editorState, entityKey).text || ''
    };
  }
  return {
    url: '',
    text: getSelectionText(editorState)
  };
}

const createLinkifyPlugin = (): {
  decorators: DraftDecorator[];
  onChange: (state: EditorState) => EditorState;
} => {
  const Link: FC<{
    contentState: ContentState;
    entityKey: string;
    data: {
      url: string;
    };
  }> = (props) => {
    const data = props.data || props.contentState.getEntity(props.entityKey).getData();
    const { url } = data;
    if (!url) {
      return <span>{props.children}</span>;
    }
    return (
      <a href={url} title={url}>
        {props.children}
      </a>
    );
  };

  function findLinkEntities(
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
  ): void {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (!entityKey) return;

      const entity = contentState.getEntity(entityKey);
      return entity.getType() === 'LINK' && entity.getData().url;
    }, callback);
  }

  return {
    decorators: [
      {
        strategy: findLinkEntities,
        component: Link
      }
    ],
    onChange: (editorState): EditorState => {
      /**
       * This is a no-op for now. Originally it used to linkify manually typed URLs, but caused some problems.
       * If it is necessary to restore the original state, it can be found in the gist mentioned at the top of the file.
       */
      return editorState;
    }
  };
};

export default createLinkifyPlugin;
