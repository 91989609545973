import { FC, useEffect, useState } from 'react';
import { Button, Form, FormGroup, InlineNotification, Link } from 'carbon-components-react';
import styles from './search-request.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import {
  resetSearchRequestsForm,
  SearchRequestsFormDataState,
  setRole
} from '../../search-requests/search-request/search-request.state';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../../redux/store';
import { useFormValidation } from '../../../../utils/validation/validation-utils';
import { searchRequestValidationSchema } from '../../search-requests/search-request/search-request.validation';
import SearchRequestEditContact from '../../search-requests/search-request/search-request-edit/search-request-edit-contact/search-request-edit-contact';
import SearchRequestEditInfo from '../../search-requests/search-request/search-request-edit/search-request-edit-info/search-request-edit-info';
import { getApiBaseUrl } from '../../../../utils/api';
import { useUrlSearchParams } from 'use-url-search-params';
import { Role } from '../../search-requests/search-requests-types';
import { logger } from '../../../../utils/logger';
import { Param } from '../../../../utils/constants';

export const SearchRequest: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [successView, setSuccessView] = useState(false);
  const [error, setError] = useState(false);
  const [params] = useUrlSearchParams({}, {});
  const [dept, setDept] = useState<Role | null>(null);

  const extSearchRequestValidationSchema = searchRequestValidationSchema.omit(['role', 'assignee']);
  const formState = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);
  const { clearFormError, clearAllErrors, getValidationPropsForField, validateState } = useFormValidation(
    extSearchRequestValidationSchema,
    formState
  );

  useEffect(() => {
    // Change form language if a language parameter is given
    if (params[Param.LANG] && params[Param.LANG] === 'en') {
      i18n.changeLanguage(params[Param.LANG].toString()).catch((e) => logger.error(e));
    }
  }, [params[Param.LANG]]);

  useEffect(() => {
    // Set the role by the given department from the url
    if (params[Param.DEPT] && Object.values(Role).includes(params[Param.DEPT] as Role)) {
      setDept(params[Param.DEPT] as Role);
      dispatch(setRole(params[Param.DEPT] as Role));
    }
    // Reset the role if it is lost while resetting the form
    if (formState.role.length === 0 && dept) {
      dispatch(setRole(dept));
    }
  }, [params[Param.DEPT], formState.role]);

  if (successView) {
    return (
      <div className={styles.SearchRequestSuccess}>
        <p>{t('views.searchRequests.successText')}</p>
        <p>
          <Trans i18nKey="views.searchRequests.successInfo">
            Ihre Anfrage ist bei unserer TUM Living Verwaltung eingegangen. Wir versuchen unser Bestes, um eine passende
            Immobilie für Sie zu finden. Gerne können Sie auch selbst nach passenden Angeboten auf
            {
              <Link href="https://living.tum.de" target="_blank">
                living.tum.de
              </Link>
            }
            suchen.
          </Trans>
        </p>
        <Button kind="primary" onClick={() => setSuccessView(false)}>
          {t('views.searchRequests.newSearchRequest')}
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.SearchRequest}>
      <Form
        id="search-requests-data"
        onSubmit={(event) => {
          // prevent submit since we have custom validation logic and requests
          event.preventDefault();
          event.stopPropagation();

          validateState(async () => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { id, uuid, isActive, isNew, assignee, observers, showResultToast, ...variables } = formState;

            const response = await fetch(`${getApiBaseUrl()}api/search-request`, {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
              },
              body: JSON.stringify(variables)
            });

            if (response.ok) {
              dispatch(resetSearchRequestsForm());
              setSuccessView(true);
              setError(false);
            } else {
              setError(true);
            }
          });
        }}
      >
        <FormGroup className={styles.FormGroup} legendText={t('views.searchRequests.sections.contact') as string}>
          <SearchRequestEditContact
            formState={formState}
            getValidationPropsForField={getValidationPropsForField}
            clearFormError={clearFormError}
          />
        </FormGroup>
        <FormGroup
          className={styles.FormGroup}
          legendText={t('views.searchRequests.sections.searchInformation') as string}
        >
          <SearchRequestEditInfo
            formState={formState}
            getValidationPropsForField={getValidationPropsForField}
            clearFormError={clearFormError}
          />
        </FormGroup>
        {error && (
          <div className={styles.SearchRequestError}>
            <InlineNotification
              kind="error"
              title={t('views.searchRequests.errorTitle') as string}
              subtitle={t('views.searchRequests.errorSubtitle') as string}
              hideCloseButton={true}
            />
          </div>
        )}
        <div className={styles.SubmitBar}>
          <Button
            kind={'secondary'}
            size="default"
            onClick={() => {
              dispatch(resetSearchRequestsForm());
              clearAllErrors();
              setError(false);
            }}
          >
            {t('actions.reset')}
          </Button>
          <Button type="submit" form="search-requests-data">
            {t('actions.send')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SearchRequest;
