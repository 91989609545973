import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateTime, formatDateTimeWithToday } from '../intl/dates-and-times';

export const useDateTimeFormat = (): {
  formatDateTimeToday: (date: Date | string | null) => string;
  formatDateTimeWithSuffix: (date: Date | string | null) => string;
} => {
  const { t } = useTranslation();

  const dateTimeTranslations = useMemo(
    () => ({
      today: t('datesAndTimes.today'),
      timeSuffix: t('datesAndTimes.timeSuffix')
    }),
    [t]
  );

  const formatDateTimeToday = (date: Date | string | null): string =>
    formatDateTimeWithToday(date, dateTimeTranslations);
  const formatDateTimeWithSuffix = (date: Date | string | null): string => formatDateTime(date, dateTimeTranslations);

  return {
    formatDateTimeToday,
    formatDateTimeWithSuffix
  };
};
