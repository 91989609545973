import { exportTable } from '../../../../utils/actions';
import { ApolloClient } from '@apollo/client';
import { TFunction } from 'i18next';
import { formatDate } from '../../../../utils/intl/dates-and-times';
import {
  GetSearchRequestsExport,
  GetSearchRequestsExportVariables
} from '../../../../graphql/types/GetSearchRequestsExport';
import { GET_SEARCH_REQUESTS_EXPORT } from '../../../../graphql/queries/search-requests';

/**
 * Exports the search requests list to csv file
 * @param t The translation function to translate headlines and types
 * @param apolloClient The apollo client to query the export data
 * @param filterProps The current set filter properties
 */
export const exportCsvData = (
  t: TFunction,
  apolloClient: ApolloClient<unknown>,
  filterProps: GetSearchRequestsExportVariables
): Promise<void> => {
  // Translation key for the table headlines
  const headlines = [
    'affiliationType',
    'gender',
    'lastName',
    'firstName',
    'email',
    'department',
    'phone',
    'prefLanguage',
    'country',
    'role',
    'incomingDate',
    'moveInDate',
    'moveOutDate',
    'verified',
    'totalRentMin',
    'totalRentMax',
    'depositMax',
    'listingType',
    'roomCount',
    'spaceMin',
    'spaceMax',
    'numberOfTenants',
    'city',
    'district',
    'configuration',
    'remarks',
    'links'
  ];
  // Return promise when query is finished
  return new Promise<void>((resolve, reject) => {
    // Reset cache and query the data for the landlord export
    apolloClient.cache.reset().then(() => {
      apolloClient
        .query<GetSearchRequestsExport, GetSearchRequestsExportVariables>({
          query: GET_SEARCH_REQUESTS_EXPORT,
          variables: filterProps
        })
        .then((result) => {
          // Create headlines for csv
          let formattedData = '';
          headlines.forEach((headline, index) => {
            formattedData += index > 0 ? process.env.REACT_APP_CSV_SEPARATOR : '';
            formattedData += t(`views.searchRequests.export.headlines.${headline}`);
          });
          formattedData += '\n';
          if (result.data) {
            // Iterate over all listing data if available
            result.data.searchRequests.forEach((searchRequest) => {
              const role = searchRequest.role ? t(`enums.role.${searchRequest.role}`) : '–';
              if (searchRequest) {
                // Convert to types to translation keys if necessary
                const row = [
                  t(`enums.affiliationType.${searchRequest.affiliationType}`),
                  t(`enums.gender.${searchRequest.gender}`),
                  searchRequest.lastName,
                  searchRequest.firstName,
                  searchRequest.email,
                  t(`enums.department.${searchRequest.department}`),
                  searchRequest.phone ? `="${searchRequest.phone}"` : '', // put number inside ="" so that Excel treats it like a string and not number
                  t(`enums.language.${searchRequest.prefersEnglish ? 'english' : 'german'}`) as string,
                  t(`enums.country.${searchRequest.country}`),
                  role,
                  formatDate(searchRequest.createdAt),
                  formatDate(searchRequest.availableFrom),
                  formatDate(searchRequest.availableUntil),
                  t(`enums.verification.${searchRequest.isActive ? 'VERIFIED' : 'NOT_VERIFIED'}`),
                  searchRequest.totalRentFrom && searchRequest.totalRentFrom > 0 ? searchRequest.totalRentFrom : '-',
                  searchRequest.totalRentTo,
                  searchRequest.depositMax && searchRequest.depositMax > 0 ? searchRequest.depositMax : '-',
                  t(`enums.listingType.${searchRequest.type}`),
                  searchRequest.numberOfRooms.toLocaleString('de-DE'), // prevents Excel from interpreting it as a date (currently only german format supported)
                  searchRequest.squareMeterFrom,
                  searchRequest.squareMeterTo && searchRequest.squareMeterTo > 0 ? searchRequest.squareMeterTo : '-',
                  searchRequest.numberOfTenants,
                  t(`enums.city.${searchRequest.city}`),
                  searchRequest.districts && searchRequest.districts.length > 0
                    ? searchRequest.districts.map((district) => t(`enums.district.${district}`)).join(', ')
                    : '-',
                  searchRequest.tags && searchRequest.tags.length > 0
                    ? searchRequest.tags?.map((tag) => t(`enums.tags.${tag}`)).join(', ')
                    : '-',
                  searchRequest.remarks,
                  `${process.env.REACT_APP_PUBLIC_URL}/search-requests/${searchRequest.uuid}/view` // Create link
                ];
                // Create row
                formattedData += row.join(process.env.REACT_APP_CSV_SEPARATOR) + '\n';
              }
            });
          }
          // Create csv file from the data
          exportTable(formattedData, `${t('views.searchRequests.searchRequests')}_${new Date().toISOString()}.csv`);
        })
        .catch(() => reject())
        .finally(() => resolve());
    });
  });
};
