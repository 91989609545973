/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ContactType {
  COMPANY = "COMPANY",
  PRIVATE = "PRIVATE",
  PUBLIC_INSTITUTION = "PUBLIC_INSTITUTION",
}

export enum Gender {
  DIVERSE = "DIVERSE",
  FEMALE = "FEMALE",
  MALE = "MALE",
}

export enum HousingType {
  APARTMENT = "APARTMENT",
  ATTIC = "ATTIC",
  BASEMENT = "BASEMENT",
  GROUND_FLOOR = "GROUND_FLOOR",
  MEZZANINE = "MEZZANINE",
}

export enum ImageRotation {
  CLOCKWISE = "CLOCKWISE",
  COUNTERCLOCKWISE = "COUNTERCLOCKWISE",
}

export enum LandlordSortOrder {
  ALPHABETICALLY_ASC = "ALPHABETICALLY_ASC",
  ALPHABETICALLY_DESC = "ALPHABETICALLY_DESC",
  MOST_RECENT = "MOST_RECENT",
}

export enum LandlordStatus {
  BLOCKED = "BLOCKED",
  NOT_ACTIVATED = "NOT_ACTIVATED",
  VERIFIED = "VERIFIED",
}

export enum ListingSortOrder {
  LAST_MODIFIED = "LAST_MODIFIED",
  MOST_RECENT = "MOST_RECENT",
  RENT_ASCENDING = "RENT_ASCENDING",
  RENT_DESCENDING = "RENT_DESCENDING",
}

export enum ListingType {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  SHARED_APARTMENT = "SHARED_APARTMENT",
}

export enum Permission {
  ADMINISTRATOR = "ADMINISTRATOR",
  LANDLORD = "LANDLORD",
  MANAGEMENT = "MANAGEMENT",
  SEEKER = "SEEKER",
}

export enum PoiCategory {
  BAKERY = "BAKERY",
  PHARMACY = "PHARMACY",
  PLATFORM = "PLATFORM",
  SCHOOL = "SCHOOL",
  SUPERMARKET = "SUPERMARKET",
  TUM = "TUM",
}

export enum SearchRequestSortOrder {
  LAST_MODIFIED = "LAST_MODIFIED",
  MOST_RECENT = "MOST_RECENT",
  UNREAD_EMAIL_COUNT = "UNREAD_EMAIL_COUNT",
}

export enum TUMLocation {
  FREISING = "FREISING",
  GARCHING = "GARCHING",
  HEILBRONN = "HEILBRONN",
  MUNICH = "MUNICH",
  STRAUBING = "STRAUBING",
  GARMISCH_PARTENKIRCHEN = "GARMISCH_PARTENKIRCHEN",
}

export enum TagCategory {
  FURNISHING = "FURNISHING",
  OTHER = "OTHER",
  SANITARY = "SANITARY",
}

export enum TransportationType {
  DRIVING_CAR = "DRIVING_CAR",
  FOOT_WALKING = "FOOT_WALKING",
  PUBLIC_TRANSPORT = "PUBLIC_TRANSPORT",
}

export interface InputMail {
  from: string;
  to: string;
  subject: string;
  text?: string | null;
  cc?: string | null;
  html?: string | null;
  attachments?: string[] | null;
}

export interface LandlordFilter {
  status?: (LandlordStatus | null)[] | null;
  contactTypes?: (ContactType | null)[] | null;
  searchPhrase?: string | null;
}

export interface LandlordInput {
  id: string;
  firstName?: string | null;
  lastName: string;
  contactType?: ContactType | null;
  role?: string | null;
  isNew?: boolean | null;
  email?: string | null;
  notes?: string | null;
  companyName?: string | null;
  phone?: string | null;
  street?: string | null;
  houseNumber?: string | null;
  postalCode?: string | null;
  city?: string | null;
  gender?: Gender | null;
  showFirstName?: boolean | null;
  showLastName?: boolean | null;
  showEmail?: boolean | null;
  showPhone?: boolean | null;
  showCompany?: boolean | null;
}

export interface ListingFilter {
  tumLocation?: TUMLocation | null;
  type?: ListingType | null;
  availableFrom?: any | null;
  availableFromLatest?: any | null;
  numberOfRooms?: number | null;
  squareMeterFrom?: number | null;
  squareMeterTo?: number | null;
  totalRentFrom?: number | null;
  totalRentTo?: number | null;
  districts?: string[] | null;
  openEndedContract?: boolean | null;
  seekingStudents?: boolean | null;
  seekingProfessors?: boolean | null;
  seekingIncomings?: boolean | null;
  seekingDoctoralStudents?: boolean | null;
  seekingPostDoctoralStudents?: boolean | null;
  seekingGuestResearchers?: boolean | null;
  seekingTumEmployees?: boolean | null;
  depositMax?: number | null;
  landlordIsManagement?: boolean | null;
  verificationStatus?: (boolean | null)[] | null;
  activationStatus?: (boolean | null)[] | null;
  tags?: string[] | null;
  isListingPublic?: boolean | null;
}

export interface ListingImageInput {
  id: string;
  listing?: string | null;
  description: string;
  descriptionEn: string;
  isPreview?: boolean | null;
}

export interface ListingInput {
  tags?: string[] | null;
  images?: ListingImageInput[] | null;
  incidentalCostsTypes?: string[] | null;
  landlord?: string | null;
  id: string;
  type: ListingType;
  street: string;
  houseNumber: string;
  postalCode: string;
  tumLocation: TUMLocation;
  city: string;
  rent: number;
  availableFrom: any;
  isActive: boolean;
  isListingPublic: boolean;
  seekingStudents: boolean;
  seekingProfessors: boolean;
  seekingIncomings: boolean;
  seekingDoctoralStudents: boolean;
  seekingPostDoctoralStudents: boolean;
  seekingGuestResearchers: boolean;
  seekingTumEmployees: boolean;
  squareMeter: number;
  parkingSpace: boolean;
  district?: string | null;
  verifiedAt?: any | null;
  numberOfRooms?: number | null;
  incidentalCosts?: number | null;
  incidentalCostsCustomLabel?: string | null;
  oneTimeCosts?: number | null;
  oneTimeCostsLabel?: string | null;
  deposit?: number | null;
  availableUntil?: any | null;
  expirationDate?: any | null;
  furtherEquipment?: string | null;
  furtherEquipmentEn?: string | null;
  housingType?: HousingType | null;
  floor?: number | null;
  parkingSpaceCosts?: number | null;
  coordinates?: PointInput | null;
}

export interface ListingTargetInput {
  seekingStudents: boolean;
  seekingIncomings: boolean;
  seekingDoctoralStudents: boolean;
  seekingGuestResearchers: boolean;
  seekingPostDoctoralStudents: boolean;
  seekingProfessors: boolean;
  seekingTumEmployees: boolean;
}

export interface ListingLocationInput {
  seekingFreising: boolean;
  seekingGarching: boolean;
  seekingHeilbronn: boolean;
  seekingMunich: boolean;
  seekingStraubing: boolean;
  seekingGarmischPartenkirchen: boolean;
}

export interface PointInput {
  x: number;
  y: number;
}

export interface SearchRequestCommentInput {
  id: string;
  content: string;
}

export interface SearchRequestFilter {
  searchPhrase?: string | null;
  affiliationTypes?: string[] | null;
  roles?: string[] | null;
  isActive?: boolean | null;
}

export interface SearchRequestInput {
  id: string;
  isNew: boolean;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  affiliationType: string;
  department?: string | null;
  prefersEnglish: boolean;
  country?: string | null;
  phone?: string | null;
  type: ListingType;
  numberOfRooms: number;
  squareMeterFrom: number;
  squareMeterTo?: number | null;
  numberOfTenants: number;
  availableFrom: any;
  availableUntil?: any | null;
  city: TUMLocation;
  searchRadius: number;
  totalRentFrom: number;
  totalRentTo: number;
  depositMax?: number | null;
  remarks?: string | null;
  role: string;
  assignee?: string | null;
  tags?: string[] | null;
  districts?: string[] | null;
  isActive?: boolean | null;
  observers?: string[] | null;
}

export interface SeekerSearchRequestInput {
  email: string;
  type?: ListingType | null;
  numberOfRooms?: number | null;
  squareMeterFrom?: number | null;
  squareMeterTo?: number | null;
  availableFrom?: any | null;
  availableFromLatest?: any | null;
  tumLocation?: TUMLocation | null;
  totalRentFrom?: number | null;
  totalRentTo?: number | null;
  depositMax?: number | null;
  isOpenEnded?: boolean | null;
  tags?: string[] | null;
  districts?: string[] | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
