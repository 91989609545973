import { FC, useCallback, useState } from 'react';
import { ViewMode, ViewModeSwitcher } from '../../../../building-blocks/view-mode-switcher/view-mode-switcher';
import {
  ActionBar,
  ActionBarCenter,
  ActionBarLeft,
  ActionBarRight
} from '../../../../building-blocks/action-bar/action-bar';
import { useListingMutations } from '../listing-edit/listing-edit.hooks';
import { Button, OverflowMenuItem, Toggle } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setIsActive } from '../listing.state';
import { useUserPermission } from '../../../../../utils/hooks/user-data';
import { ContentCopySVGIcon, DeleteSVGIcon, LinkSVGIcon, MailSVGIcon, PersonSVGIcon } from '@react-md/material-icons';
import { ReactComponent as PersonOffIcon } from '../../../../../assets/svg/person-off.svg';
import { copyURLtoClipboard, createShareEntityLink, MailContent, shareByMail } from '../../../../../utils/actions';
import { useNavigate } from 'react-router-dom';
import { ConfirmationDialog } from '../../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { OverflowButton } from '../../../../controls/overflow-button/overflow-button';
import { useListingShareToken } from '../listing.hooks';
import { Param } from '../../../../../utils/constants';
import { ListingType } from '../../../../../graphql/types/globalTypes';

export interface ListingActionBarProps {
  isActive: boolean;
  isCreationMode: boolean;
  viewMode: string;
  getShareByMailContent?: () => MailContent;
  city?: string;
  type?: ListingType;
}

export const ListingActionBar: FC<ListingActionBarProps> = ({
  isActive,
  isCreationMode,
  viewMode,
  getShareByMailContent,
  city,
  type
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteModal] = useState(false);
  const { hasAdministrativeFunction, isLandlord } = useUserPermission();
  const { activateListing, deactivateListing, deleteListing, duplicateListing } = useListingMutations(isLandlord);
  const { withContactInfo, withoutContactInfo } = useListingShareToken();

  const getShareByMailWithTokenContent = useCallback(
    (token: string | null | undefined) => {
      return {
        mailSubject: t('sharingMail.listing.sharingMailSubject', {
          city,
          type: t(`enums.listingType.${type}`)
        }),
        mailBody: t('sharingMail.listing.sharingMailBody', {
          link: `${createShareEntityLink()}?${Param.SHARE}=${token}`
        })
      };
    },
    [city, type]
  );

  return !isCreationMode ? (
    <>
      <ActionBar>
        <ActionBarLeft>
          {hasAdministrativeFunction && (
            <Toggle
              id="toggleActivation"
              labelA={t('views.listings.inactive')}
              labelB={t('views.listings.active')}
              toggled={isActive}
              disabled={isCreationMode}
              onToggle={(isChecked) =>
                (isChecked ? activateListing() : deactivateListing()).then(() => dispatch(setIsActive(isChecked)))
              }
            />
          )}
          {isLandlord && (
            <div>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={LinkSVGIcon}
                className="bx--btn--hasIcon"
                disabled={isCreationMode}
                onClick={() => copyURLtoClipboard(createShareEntityLink(), t)}
              >
                {t('actions.copyLink')}
              </Button>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={MailSVGIcon}
                className="bx--btn--hasIcon"
                disabled={isCreationMode}
                onClick={() => getShareByMailContent && shareByMail(getShareByMailContent())}
              >
                {t('actions.forward')}
              </Button>
            </div>
          )}
        </ActionBarLeft>

        <ActionBarCenter>
          {hasAdministrativeFunction && (
            <>
              <OverflowButton icon={LinkSVGIcon} text={t('actions.copyLink')}>
                <OverflowMenuItem
                  data-testid="OverflowMenuItem"
                  hasDivider
                  itemText={
                    <span>
                      <PersonSVGIcon />
                      {t('actions.withContactData')}
                    </span>
                  }
                  onClick={() => {
                    const link = `${createShareEntityLink()}?${Param.SHARE}=${withContactInfo}`;
                    copyURLtoClipboard(link, t);
                  }}
                />
                <OverflowMenuItem
                  data-testid="OverflowMenuItem"
                  hasDivider
                  itemText={
                    <span>
                      <PersonOffIcon />
                      {t('actions.withoutContactData')}
                    </span>
                  }
                  onClick={() => {
                    const link = `${createShareEntityLink()}?${Param.SHARE}=${withoutContactInfo}`;
                    copyURLtoClipboard(link, t);
                  }}
                />
              </OverflowButton>
              <OverflowButton icon={MailSVGIcon} text={t('actions.forward')} disabled={isCreationMode}>
                <OverflowMenuItem
                  data-testid="OverflowMenuItem"
                  hasDivider
                  itemText={
                    <span>
                      <PersonSVGIcon />
                      {t('actions.withContactData')}
                    </span>
                  }
                  onClick={() => {
                    if (city && type) {
                      shareByMail(getShareByMailWithTokenContent(withContactInfo));
                    }
                  }}
                />
                <OverflowMenuItem
                  data-testid="OverflowMenuItem"
                  hasDivider
                  itemText={
                    <span>
                      <PersonOffIcon />
                      {t('actions.withoutContactData')}
                    </span>
                  }
                  onClick={() => {
                    if (city && type) {
                      shareByMail(getShareByMailWithTokenContent(withoutContactInfo));
                    }
                  }}
                />
              </OverflowButton>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={DeleteSVGIcon}
                className="bx--btn--hasIcon"
                disabled={isCreationMode}
                onClick={() => setDeleteModal(true)}
              >
                {t('actions.delete')}
              </Button>
            </>
          )}
        </ActionBarCenter>
        <ActionBarRight>
          {hasAdministrativeFunction && (
            <ViewModeSwitcher viewMode={viewMode === ViewMode.EDIT ? ViewMode.EDIT : ViewMode.VIEW} />
          )}
          {isLandlord && (
            <div>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={ContentCopySVGIcon}
                className="bx--btn--hasIcon"
                disabled={isCreationMode}
                onClick={() => {
                  duplicateListing().then((result) => {
                    if (result.data && !result.errors) {
                      const duplicateListingUUID = result.data.duplicateListing;
                      navigate(`../../${duplicateListingUUID}/edit`, { state: { duplicate: 1 } });
                    }
                  });
                }}
              >
                {t('actions.duplicate')}
              </Button>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={DeleteSVGIcon}
                className="bx--btn--hasIcon"
                disabled={isCreationMode}
                onClick={() => setDeleteModal(true)}
              >
                {t('actions.delete')}
              </Button>
            </div>
          )}
        </ActionBarRight>
      </ActionBar>
      {openDeleteModal && (
        <ConfirmationDialog
          open={true}
          title={`${t('views.account.modalDelete.title')}`}
          headline={`${t('views.account.modalDelete.headline')}`}
          text={`${t('views.account.modalDelete.text')}`}
          primaryActionLabel={`${t('actions.deleteListing')}`}
          onPrimaryActionClick={() => {
            deleteListing().then(() => {
              navigate('./../../');
            });
          }}
          onCloseClick={() => {
            setDeleteModal(false);
          }}
        />
      )}
    </>
  ) : null;
};

export default ListingActionBar;
