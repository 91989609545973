import { Breakpoints, CustomBreakPointsDefinition } from '../../../../utils/hooks/responsive.hooks';

// Count of multiselect filters, needed for force refresh
const MULTISELECT_FILTER_COUNT = 6;
const MULTISELECT_FILTER_COUNT_TABLET = 5;
const MULTISELECT_FILTER_COUNT_TABLET_SM = 3;
const MULTISELECT_FILTER_COUNT_PHONE = 1;

export const getFilterCount = (breakpoints: Breakpoints, isTabletSmall?: boolean): number => {
  if (breakpoints.isDesktop) {
    return MULTISELECT_FILTER_COUNT;
  } else if (breakpoints.isTablet) {
    if (isTabletSmall) {
      return MULTISELECT_FILTER_COUNT_TABLET_SM;
    }

    return MULTISELECT_FILTER_COUNT_TABLET;
  }
  return MULTISELECT_FILTER_COUNT_PHONE;
};

export const customListingsListBreakpointsDefinition: CustomBreakPointsDefinition = {
  isTabletSmall: (currentWidth) => currentWidth <= 630
};
