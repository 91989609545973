import { GetLandlordsExport, GetLandlordsExportVariables } from '../../../../graphql/types/GetLandlordsExport';
import { GET_LANDLORDS_EXPORT } from '../../../../graphql/queries/landlord';
import { exportTable } from '../../../../utils/actions';
import { ApolloClient } from '@apollo/client';
import { TFunction } from 'i18next';

/**
 * Exports the landlord list to csv file
 * @param t The translation function to translate headlines and types
 * @param apolloClient The apollo client to query the export data
 * @param filterProps The current set filter properties
 */
export const exportCsvData = (
  t: TFunction,
  apolloClient: ApolloClient<unknown>,
  filterProps: GetLandlordsExportVariables
): Promise<void> => {
  // Translation key for the table headlines
  const headlines = [
    'contactType',
    'status',
    'lastName',
    'firstName',
    'email',
    'phone',
    'activeListings',
    'notes',
    'links'
  ];
  // Return promise when query is finished
  return new Promise<void>((resolve, reject) => {
    // Reset cache and query the data for the landlord export
    apolloClient.cache.reset().then(() => {
      apolloClient
        .query<GetLandlordsExport, GetLandlordsExportVariables>({
          query: GET_LANDLORDS_EXPORT,
          variables: filterProps
        })
        .then((result) => {
          // Create headlines for csv
          let formattedData = '';
          headlines.forEach((headline, index) => {
            formattedData += index > 0 ? process.env.REACT_APP_CSV_SEPARATOR : '';
            formattedData += t(`views.landlord.export.headlines.${headline}`);
          });
          formattedData += '\n';
          if (result.data) {
            // Iterate over all landlord data if available
            result.data.landlords.forEach((landlord) => {
              if (landlord) {
                // Convert to types to translation keys if necessary
                const row = [
                  `${t(`enums.contactType.${landlord.contactType}`)}`,
                  `${t(`enums.approvalType.${landlord.status}`)}`,
                  landlord.lastName,
                  landlord.firstName,
                  landlord.email,
                  landlord.phone ? `="${landlord.phone}"` : '', // put number inside ="" so that Excel treats it like a string and not number
                  landlord.numberOfActiveListings,
                  landlord.notes ? `"${landlord.notes}"` : '', // Necessary to encode the separator
                  `${process.env.REACT_APP_PUBLIC_URL}/landlord/${landlord.uuid}/view` // Create link
                ];
                // Create row
                formattedData += row.join(process.env.REACT_APP_CSV_SEPARATOR) + '\n';
              }
            });
          }
          // Create csv file from the data
          exportTable(formattedData, `${t('views.landlord.landlords')}_${new Date().toISOString()}.csv`);
        })
        .catch(() => reject())
        .finally(() => resolve());
    });
  });
};
