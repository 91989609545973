import { gql } from '@apollo/client';

export const GET_SEARCH_REQUESTS = gql`
  # Get all search requests
  query GetSearchRequests(
    $resultLimit: Int
    $pageOffset: Int
    $orderBy: SearchRequestSortOrder
    $filter: SearchRequestFilter
  ) {
    searchRequests(resultLimit: $resultLimit, pageOffset: $pageOffset, orderBy: $orderBy, filter: $filter) {
      id
      uuid
      role
      isNew
      firstName
      lastName
      affiliationType
      createdAt
      isActive
      numberOfNewEmails
    }
  }
`;

export const GET_SEARCH_REQUESTS_EXPORT = gql`
  # Get all search requests
  query GetSearchRequestsExport($orderBy: SearchRequestSortOrder, $filter: SearchRequestFilter) {
    searchRequests(orderBy: $orderBy, filter: $filter) {
      id
      uuid
      role
      gender
      firstName
      lastName
      email
      country
      affiliationType
      availableFrom
      availableUntil
      createdAt
      isActive
      remarks
      department
      phone
      prefersEnglish
      totalRentFrom
      totalRentTo
      depositMax
      type
      numberOfRooms
      squareMeterFrom
      squareMeterTo
      numberOfTenants
      city
      districts
      tags
    }
  }
`;

export const GET_NUMBER_OF_SEARCH_REQUESTS = gql`
  # Get the total number of search requests
  query GetNumberOfSearchRequests($filter: SearchRequestFilter) {
    numberOfSearchRequests(filter: $filter)
  }
`;

export const GET_NUMBER_OF_NEW_SEARCH_REQUESTS = gql`
  # Get the total number of new search requests
  query GetNumberOfNewSearchRequests {
    numberOfNewSearchRequests
  }
`;

export const GET_SEARCH_REQUEST_BY_UUID = gql`
  # Returns search request by the given uuid
  query GetSearchRequestByUUID($uuid: String!) {
    searchRequestByUUID(uuid: $uuid) {
      id
      uuid
      role
      firstName
      lastName
      gender
      email
      phone
      isNew
      affiliationType
      department
      prefersEnglish
      country
      type
      numberOfRooms
      squareMeterFrom
      squareMeterTo
      numberOfTenants
      availableFrom
      availableUntil
      city
      searchRadius
      totalRentFrom
      totalRentTo
      depositMax
      remarks
      role
      tags
      districts
      observers {
        email
        role
      }
      assignee
    }
  }
`;

export const POST_NEW_SEARCH_REQUEST = gql`
  mutation PostNewSearchRequest(
    $gender: Gender!
    $lastName: String!
    $firstName: String!
    $email: String!
    $phone: String
    $affiliationType: String!
    $department: String
    $prefersEnglish: Boolean!
    $country: String
    $type: ListingType!
    $numberOfRooms: Float!
    $squareMeterFrom: Int!
    $squareMeterTo: Int
    $numberOfTenants: Int!
    $availableFrom: DateTime!
    $availableUntil: DateTime
    $city: TUMLocation!
    $districts: [String!]
    $searchRadius: Float!
    $totalRentFrom: Float!
    $totalRentTo: Float!
    $depositMax: Float
    $remarks: String
    $role: String!
    $assignee: String
  ) {
    addSearchRequest(
      gender: $gender
      lastName: $lastName
      firstName: $firstName
      email: $email
      phone: $phone
      affiliationType: $affiliationType
      department: $department
      prefersEnglish: $prefersEnglish
      country: $country
      type: $type
      numberOfRooms: $numberOfRooms
      squareMeterFrom: $squareMeterFrom
      squareMeterTo: $squareMeterTo
      numberOfTenants: $numberOfTenants
      availableFrom: $availableFrom
      availableUntil: $availableUntil
      city: $city
      districts: $districts
      searchRadius: $searchRadius
      totalRentFrom: $totalRentFrom
      totalRentTo: $totalRentTo
      depositMax: $depositMax
      remarks: $remarks
      role: $role
      assignee: $assignee
    )
  }
`;

export const PUT_UPDATE_SEARCH_REQUEST = gql`
  mutation UpdateSearchRequest($input: SearchRequestInput!) {
    updateSearchRequest(searchRequest: $input)
  }
`;

export const DELETE_SEARCH_REQUEST = gql`
  mutation DeleteSearchRequest($id: ID!) {
    deleteSearchRequest(id: $id)
  }
`;

export const PUT_OPEN_SEARCH_REQUEST = gql`
  mutation OpenSearchRequest($id: ID!) {
    openSearchRequest(id: $id)
  }
`;

export const PUT_CLOSE_SEARCH_REQUEST = gql`
  mutation CloseSearchRequest($id: ID!) {
    closeSearchRequest(id: $id)
  }
`;
