export const defaultCurrencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR'
});

export const integerCurrencyFormat = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0
});

export const formatCurrency = (value: number): string => {
  return defaultCurrencyFormat.format(value);
};

export const formatIntegerCurrency = (value: number): string => {
  return integerCurrencyFormat.format(value);
};
