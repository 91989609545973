import { FC, useEffect, useState } from 'react';
import styles from './map.module.scss';
import { MapContainer, Marker, Popup, ScaleControl, TileLayer, useMap, ZoomControl } from 'react-leaflet';
import { DivIcon, LatLngTuple, Point } from 'leaflet';
import { useTranslation } from 'react-i18next';
import { ListingPopup } from '../listing-popup/listing-popup';
import classNames from 'classnames';
import { PoiListing } from '../../views/listings/listing/listing-view/listing-view';
import { PoiCategory } from '../../views/listings/listing/listing.constants';

export interface ListingMarker {
  uuid: string;
  coordinates: Point;
}

interface MapProps {
  height?: string;
  width?: string;
  zoom?: number;
  maxZoom?: number;
  minZoom?: number;
  markers?: Point[] | null;
  centerToMarker?: boolean;
  centerToPoint?: Point;
  listings?: ListingMarker[];
  className?: string;
  poi?: PoiListing[];
  favorable?: boolean;
  favorites?: string[];
  refetch?: () => void;
}

// Munich as center
const initialCenter: LatLngTuple = [48.14, 11.55];

/**
 * Local component to center map again when prop changes
 */
export const CenterMap: FC<{ center: LatLngTuple; zoom?: number }> = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    if (zoom) {
      map.setZoom(zoom);
    }
    map.panTo(center);
  }, [map, center]);

  return null;
};

/**
 * Local component to center and zoom to the listings when prop changes
 */
export const FitListingBounds: FC<{ points?: Array<{ coordinates: Point }> }> = ({ points }) => {
  const map = useMap();
  const bounds: LatLngTuple[] = [];
  if (points && points.length > 0) {
    points.forEach((point) => {
      bounds.push([point.coordinates.x, point.coordinates.y]);
    });
  } else {
    // set initial center if no listings are given
    bounds.push(initialCenter);
  }

  useEffect(() => {
    if (bounds.length > 0) {
      map.fitBounds(bounds, { padding: [50, 50] });
      // Zoom out and set initial view if there are no listings
      if (points?.length === 0) {
        map.setZoom(10);
      }
    }
  }, [map, points]);

  return null;
};

/**
 * Converts a Point to a LatLngTuple
 * @param point The point to convert
 */
export const pointToLatLang = (point: Point): LatLngTuple => {
  return [point.x, point.y] as LatLngTuple;
};

export const LeafletMap: FC<MapProps> = ({
  height = '600px',
  width,
  maxZoom = 18,
  minZoom = 9,
  zoom = 18,
  markers,
  listings,
  centerToMarker,
  centerToPoint,
  className,
  poi,
  favorable,
  favorites,
  refetch
}) => {
  const { t } = useTranslation();

  // Returns whether the listing is a favorite of the current user
  const isFavorite = (uuid: string): boolean => {
    return favorites ? favorites.indexOf(uuid) !== -1 : false;
  };

  // Icons must be passed as string to create a div icon
  /* eslint-disable */
  const svgHouseIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,9.3V4h-3v2.6L12,3L2,12h3v8h5v-6h4v6h5v-8h3L19,9.3z M10,10c0-1.1,0.9-2,2-2s2,0.9,2,2H10z"/></g></svg>';
  const svgBakeryIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="2 2 20 20" width="28px"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19.28,16.34C18.07,15.45,17.46,15,17.46,15s0.32-0.59,0.96-1.78 c0.38-0.59,1.22-0.59,1.6,0l0.81,1.26c0.19,0.3,0.21,0.68,0.06,1l-0.22,0.47C20.42,16.49,19.76,16.67,19.28,16.34z M4.72,16.34 c-0.48,0.33-1.13,0.15-1.39-0.38L3.1,15.49c-0.15-0.32-0.13-0.7,0.06-1l0.81-1.26c0.38-0.59,1.22-0.59,1.6,0 C6.22,14.41,6.54,15,6.54,15S5.93,15.45,4.72,16.34z M15.36,9.37c0.09-0.68,0.73-1.06,1.27-0.75l1.59,0.9 c0.46,0.26,0.63,0.91,0.36,1.41L16.5,15h-1.8L15.36,9.37z M8.63,9.37L9.3,15H7.5l-2.09-4.08c-0.27-0.5-0.1-1.15,0.36-1.41l1.59-0.9 C7.89,8.31,8.54,8.69,8.63,9.37z M13.8,15h-3.6L9.46,8.12C9.39,7.53,9.81,7,10.34,7h3.3c0.53,0,0.94,0.53,0.88,1.12L13.8,15z" fill-rule="evenodd"/></g></svg>';
  const svgSchoolIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"/></svg>';
  const svgSupermarketIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>';
  const svgTransportIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M4 16c0 .88.39 1.67 1 2.22V20c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22V6c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6V6h12v5z"/></svg>';
  const svgPharmacyIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><g><rect fill="none" height="24" width="24"/></g><g><g><path d="M20,6h-4V4c0-1.1-0.9-2-2-2h-4C8.9,2,8,2.9,8,4v2H4C2.9,6,2,6.9,2,8v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V8 C22,6.9,21.1,6,20,6z M10,4h4v2h-4V4z M16,15h-3v3h-2v-3H8v-2h3v-3h2v3h3V15z"/></g></g></svg>';
  const svgTumIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="12.493px" viewBox="0 0 24 12.493" xml:space="preserve"><path d="M9.206,0v10.192h2.63V0H24v12.493h-2.302V2.301h-2.63v10.191h-2.301V2.301h-2.631v10.191H6.904V2.301H4.603 v10.191H2.302V2.301H0V0H9.206z"/></svg>';
  const svgFavoriteIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>';
  /* eslint-enable */
  const poiCategoryMap = new Map();
  poiCategoryMap.set(PoiCategory.TUM, svgTumIcon);
  poiCategoryMap.set(PoiCategory.BAKERY, svgBakeryIcon);
  poiCategoryMap.set(PoiCategory.SCHOOL, svgSchoolIcon);
  poiCategoryMap.set(PoiCategory.SUPERMARKET, svgSupermarketIcon);
  poiCategoryMap.set(PoiCategory.TRANSPORT, svgTransportIcon);
  poiCategoryMap.set(PoiCategory.PHARMACY, svgPharmacyIcon);

  // Icon for address
  const addressMarker = new DivIcon({
    iconSize: new Point(36, 36),
    className: 'HouseIcon',
    html: svgHouseIcon
  });

  // Icon for favorite
  const favoriteMarker = new DivIcon({
    iconSize: new Point(36, 36),
    className: 'FavoriteIcon',
    html: svgFavoriteIcon
  });

  // Icon for listing
  const listingMarker = new DivIcon({
    iconSize: new Point(36, 36),
    className: 'ListingIcon',
    html: svgHouseIcon
  });

  const iconMarker = (icon: PoiCategory): DivIcon => {
    return new DivIcon({
      iconSize: icon === PoiCategory.TUM ? new Point(36, 36) : new Point(24, 24),
      className: icon === PoiCategory.TUM ? 'TumIcon' : 'PoiIcon',
      html: poiCategoryMap.get(icon)
    });
  };

  // Icon for address
  const closeMarker = new DivIcon({
    iconSize: new Point(36, 36),
    className: 'CloseIcon',
    html: `<svg 
        focusable="false" 
        preserveAspectRatio="xMidYMid meet" 
        xmlns="http://www.w3.org/2000/svg" 
        fill="#ffffff" 
        aria-label="${t('actions.close')}"
        width="20" 
        height="20" 
        viewBox="0 0 32 32" 
        role="img">
        <path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path>
      </svg>`
  });

  // Set actual center depending on the given properties
  const [actualCenter, setActualCenter] = useState(
    centerToPoint
      ? pointToLatLang(centerToPoint)
      : markers && centerToMarker
      ? pointToLatLang(markers[0])
      : initialCenter
  );

  useEffect(() => {
    if (centerToPoint && actualCenter !== pointToLatLang(centerToPoint)) {
      setActualCenter(pointToLatLang(centerToPoint));
    } else if (centerToMarker && markers) {
      setActualCenter(pointToLatLang(markers[0]));
    }
  }, [centerToPoint, markers, centerToMarker]);

  const [activeListing, setActiveListing] = useState<ListingMarker | null>(null);

  // Poi without TUM
  const envPoi = poi?.filter((point) => point.category !== PoiCategory.TUM);

  return (
    <MapContainer
      className={classNames(styles.Map, className)}
      center={actualCenter}
      zoom={zoom}
      minZoom={minZoom}
      maxZoom={maxZoom}
      scrollWheelZoom={true}
      style={{ height, width }}
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors | &copy; <a href="https://openrouteservice.org/">openrouteservice.org</a> by HeiGIT'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="topright" />
      {poi &&
        poi.map((marker) => {
          return (
            <Marker
              position={[marker.coordinates.x, marker.coordinates.y]}
              icon={iconMarker(marker.category)}
              key={marker.coordinates.x.toString() + marker.coordinates.y.toString()}
              title={
                t(`enums.poiCategory.${marker.category}`) +
                (marker.category === PoiCategory.TUM ? ` (${marker.name})` : '')
              }
              keyboard={false}
              zIndexOffset={100}
            />
          );
        })}
      {markers &&
        markers.map((marker) => {
          return (
            <Marker
              position={[marker.x, marker.y]}
              icon={addressMarker}
              key={marker.x.toString() + marker.y.toString()}
              title={t('listing.listing')}
              keyboard={false}
              zIndexOffset={200}
            />
          );
        })}
      {listings &&
        listings.map((listing) => {
          return (
            <Marker
              position={pointToLatLang(listing.coordinates)}
              icon={
                activeListing?.uuid === listing.uuid
                  ? closeMarker
                  : isFavorite(listing.uuid)
                  ? favoriteMarker
                  : listingMarker
              }
              key={listing.uuid}
              zIndexOffset={300}
            >
              <Popup
                onOpen={() => setActiveListing(listing)}
                onClose={() => setActiveListing(null)}
                closeButton={false}
                offset={new Point(-98, 304)}
              >
                <ListingPopup
                  uuid={listing.uuid}
                  favorable={favorable}
                  isFavorite={isFavorite(listing.uuid)}
                  refetch={refetch}
                />
              </Popup>
            </Marker>
          );
        })}
      {(!listings || listings.length === 0) && (!envPoi || (envPoi && envPoi.length === 0)) && (
        <CenterMap center={actualCenter} zoom={centerToPoint ? 11 : undefined} />
      )}
      {((listings && listings.length > 0) || (envPoi && envPoi.length > 0)) && (
        <FitListingBounds points={listings || envPoi} />
      )}
      <ScaleControl metric={true} imperial={false} />
    </MapContainer>
  );
};

export default LeafletMap;
