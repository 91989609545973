import { FC, useState } from 'react';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import styles from './mail-section.module.scss';
import classNames from 'classnames';
import { Card } from '../../controls/card/card';
import { ExpandLessSVGIcon, ExpandMoreSVGIcon } from '@react-md/material-icons';
import { LazyQueryResult } from '@apollo/client';
import { GetEmails, GetEmailsVariables } from '../../../graphql/types/GetEmails';
import { MailView } from '../../controls/mail-view/mail-view';

export interface MailSectionProps {
  email: string;
  getEmailsQueryData: LazyQueryResult<GetEmails, GetEmailsVariables>;
  onMaximizeAll?: () => void;
  onMailSeen?: (id: number) => void;
}

export const MailSection: FC<MailSectionProps> = (props: MailSectionProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [openAll, setOpenAll] = useState(false);
  const { loading, data, error } = props.getEmailsQueryData;

  if (!loading && !error && data && data.receiveEmails && data.receiveEmails.length > 0) {
    const childCount = data.receiveEmails.length;
    const reversedOrderedMails = data.receiveEmails.slice().reverse();
    const mails = expanded ? reversedOrderedMails : reversedOrderedMails.slice(0, 3);

    return (
      <Card className={styles.MailSection}>
        <div>
          <Button
            id="minimize-button"
            kind="ghost"
            size="field"
            className={classNames(styles.ExpandButton)}
            data-testid="minimizeButton"
            onClick={() => setOpenAll(false)}
            renderIcon={ExpandLessSVGIcon}
          >
            {t('actions.minimizeAll')}
          </Button>
          <Button
            id="maximize-button"
            kind="ghost"
            size="field"
            className={classNames(styles.ExpandButton)}
            data-testid="maximizeButton"
            onClick={() => {
              setOpenAll(true);
            }}
            renderIcon={ExpandMoreSVGIcon}
          >
            {t('actions.maximizeAll')}
          </Button>
        </div>

        {childCount > 0 &&
          mails.map((mail) => {
            return (
              <MailView
                data-testid={'mail'}
                key={mail.id}
                id={mail.id}
                from={mail.from}
                to={mail.to}
                cc={mail.cc}
                date={new Date(mail.date)}
                subject={mail.subject}
                text={mail.text || ''}
                unseen={mail.unseen}
                attachment={mail.attachments}
                reply={props.email === mail.to}
                open={openAll}
                onSeen={props.onMailSeen}
              />
            );
          })}
        {childCount > 3 && (
          <Button
            id="mailExpansion"
            kind="ghost"
            size="field"
            className={classNames(styles.ExpandButton, { [styles.ExpandButton__expanded]: expanded })}
            data-testid="expandMailButton"
            onClick={() => setExpanded(!expanded)}
            renderIcon={ExpandMoreSVGIcon}
          >
            {`${!expanded ? t('actions.showAll') : t('actions.showLess')} ${!expanded ? `(${childCount})` : ''}`}{' '}
          </Button>
        )}
      </Card>
    );
  }

  return loading ? <div className={styles.MailSectionSkeleton} /> : null;
};

export default MailSection;
