import { FC, useCallback, useState } from 'react';
import {
  Checkbox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  FormGroup,
  MultiSelect,
  NumberInput,
  RadioButton,
  RadioButtonGroup
} from 'carbon-components-react';
import styles from './search-request-edit-info.module.scss';
import { ListingType, TagCategory, TUMLocation } from '../../../../../../graphql/types/globalTypes';
import { useTranslation } from 'react-i18next';
import {
  setAvailableFrom,
  setAvailableUntil,
  setCity,
  setDeposit,
  setDistricts,
  setNumberOfRooms,
  setNumberOfTenants,
  setRemarks,
  setSquareMeterFrom,
  setSquareMeterTo,
  setTags,
  setTotalRentFrom,
  setTotalRentTo,
  setType
} from '../../search-request.state';
import { useDispatch } from 'react-redux';
import { CustomMultiSelectItem, StyleableProps } from '../../../../../../utils/types';
import classNames from 'classnames';
import {
  AREA_INPUT_DEFAULT_PROPS,
  AVAILABLE_BY_MAX_DATE_STRING,
  AVAILABLE_BY_MIN_DATE_STRING,
  COST_INPUT_DEFAULT_PROPS,
  getNumberChangeHandler,
  preventFractionInputHandler
} from '../../../../../../utils/validation/input-validation';
import { RequiredFormItem } from '../../../../../building-blocks/content-view/content-view';
import { getMultiSelectLabel } from '../../../../../../utils/general';
import { TextAreaCount } from '../../../../../controls/text-area-count/text-area-count';
import { SearchRequestFormProps } from '../search-request-edit-contact/search-request-edit-contact';
import { useDropdownItemsFromEnumsOrConstants } from '../../../../listings/listing/listing.hooks';
import { useDistrictFilterItems } from '../../../../listings/listings-list/listings-list.hooks';
import { UNIT_AREA_SQUARE_METER, UNIT_CURRENCY_EURO } from '../../../../../../utils/constants';

const ORDERED_TAG_CATEGORIES = [TagCategory.FURNISHING, TagCategory.SANITARY, TagCategory.OTHER];

const ItemGroup: FC<StyleableProps> = ({ children, className }) => (
  <div className={classNames(styles.ItemGroup, className)}>{children}</div>
);

export const SearchRequestEditInfo: FC<SearchRequestFormProps> = ({
  formState,
  getValidationPropsForField,
  clearFormError
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isImmediatelyAvailable, setIsImmediatelyAvailable] = useState(false);
  const [forceMultiSelectRerender, setForceMultiSelectRerender] = useState(1);
  const { numberOfRoomsItems, cityItems, tagsForCategories } = useDropdownItemsFromEnumsOrConstants(formState.city);

  const districtItems = useDistrictFilterItems(formState.city);

  // Custom handler which checks the imaginaryTarget field provided by carbon react for the given value.
  // Also handles empty values and values exceeding max length
  const handleNumberChange = useCallback((setter) => getNumberChangeHandler(setter, 5), []);

  return (
    <>
      <FormGroup
        className={styles.ItemSection}
        legendText={t('views.searchRequests.sections.subsections.object') as string}
      >
        <RequiredFormItem isEditMode className={styles.InformationSectionListingType}>
          <FormGroup legendText={t('views.listings.listingType') as string} className={styles.RadioButtonGroup}>
            <RadioButtonGroup
              name="listingType"
              defaultSelected={formState?.type}
              onChange={(value) => dispatch(setType(value as ListingType))}
              orientation="horizontal"
            >
              {Object.values(ListingType).map((type) => (
                <RadioButton id={type} key={type} value={type} labelText={t(`enums.listingType.${type}`)} />
              ))}
            </RadioButtonGroup>
          </FormGroup>
        </RequiredFormItem>
        <ItemGroup>
          <RequiredFormItem isEditMode>
            <Dropdown
              id="numberOfRooms"
              titleText={t('formFields.numberOfRooms') as string}
              label={t('formFields.pleaseSelect') as string}
              items={numberOfRoomsItems}
              itemToString={(item) => (item ? item.text : '')}
              selectedItem={
                formState?.numberOfRooms
                  ? numberOfRoomsItems.find((item) => Math.abs(item.id - (formState.numberOfRooms || 0)) < 0.00001)
                  : null
              }
              {...getValidationPropsForField(
                'numberOfRooms',
                ({ selectedItem }) => selectedItem && dispatch(setNumberOfRooms(selectedItem.id))
              )}
            />
          </RequiredFormItem>

          <RequiredFormItem isEditMode className={styles.ItemRange}>
            <NumberInput
              id="squareMeterFrom"
              value={formState?.squareMeterFrom || ''}
              label={`${t('formFields.livingSpace')} (${UNIT_AREA_SQUARE_METER})`}
              {...getValidationPropsForField(
                'squareMeterFrom',
                handleNumberChange((value: number) => dispatch(setSquareMeterFrom(value)))
              )}
              {...AREA_INPUT_DEFAULT_PROPS}
            />
            <div className={styles.ItemRangeDash}>&ndash;</div>
            <NumberInput
              id="squareMeterTo"
              label={t('formFields.livingSpaceMax')}
              value={formState?.squareMeterTo || ''}
              {...getValidationPropsForField(
                'squareMeterTo',
                handleNumberChange((value: number) => dispatch(setSquareMeterTo(value)))
              )}
              {...AREA_INPUT_DEFAULT_PROPS}
            />
          </RequiredFormItem>

          <NumberInput
            id="numberOfTenants"
            value={formState.numberOfTenants}
            label={t('formFields.numberOfTenants')}
            min={0}
            max={20}
            step={1}
            allowEmpty
            onKeyDown={preventFractionInputHandler}
            {...getValidationPropsForField(
              'numberOfTenants',
              handleNumberChange((value: number) => dispatch(setNumberOfTenants(value)))
            )}
          />
        </ItemGroup>
        <ItemGroup className={styles.ItemGroupSmall}>
          <RequiredFormItem isEditMode>
            <DatePicker
              id="availableFrom"
              datePickerType="single"
              value={formState.availableFrom}
              allowInput={false}
              dateFormat={'d.m.Y'}
              onChange={(dates) => {
                dispatch(setAvailableFrom(dates[0] ? dates[0].toISOString() : ''));
                clearFormError('availableFrom');
              }}
              className={styles.InformationSectionMovingDatesInput}
              minDate={AVAILABLE_BY_MIN_DATE_STRING}
              maxDate={AVAILABLE_BY_MAX_DATE_STRING}
            >
              <DatePickerInput
                id="availableFrom"
                placeholder="dd.mm.yyyy"
                labelText={t('formFields.moveInDate') as string}
                disabled={isImmediatelyAvailable}
                {...getValidationPropsForField('availableFrom')}
              />
            </DatePicker>
            <Checkbox
              id="availableImmediately"
              labelText={t('formFields.immediately') as string}
              checked={isImmediatelyAvailable}
              onChange={(isChecked) => {
                setIsImmediatelyAvailable(isChecked);
                isChecked && dispatch(setAvailableFrom(new Date().toISOString()));
                clearFormError('availableFrom');
              }}
              className={styles.Checkbox}
            />
          </RequiredFormItem>
          <div>
            <DatePicker
              id="availableUntil"
              datePickerType="single"
              value={
                formState?.availableUntil === null || formState?.availableUntil.length === 0
                  ? undefined
                  : formState?.availableUntil
              }
              allowInput={false}
              dateFormat={'d.m.Y'}
              onChange={(dates) => {
                dispatch(setAvailableUntil(dates[0].toISOString()));
                clearFormError('availableUntil');
              }}
              className={styles.InformationSectionMovingDatesInput}
              minDate={AVAILABLE_BY_MIN_DATE_STRING}
              maxDate={AVAILABLE_BY_MAX_DATE_STRING}
            >
              <DatePickerInput
                id="availableUntil"
                placeholder="dd.mm.yyyy"
                labelText={t('formFields.moveOutDate') as string}
                disabled={formState?.availableUntil === null}
                {...getValidationPropsForField('availableUntil')}
              />
            </DatePicker>
            <Checkbox
              id="isOpenEnded"
              checked={formState?.availableUntil === null}
              labelText={t('formFields.openEnded') as string}
              onChange={(isChecked) => {
                isChecked ? dispatch(setAvailableUntil(null)) : dispatch(setAvailableUntil(''));
                clearFormError('availableUntil');
              }}
              className={styles.Checkbox}
            />
          </div>
        </ItemGroup>
      </FormGroup>
      <FormGroup
        legendText={t('views.searchRequests.sections.subsections.location') as string}
        className={styles.ItemSection}
      >
        <ItemGroup>
          <RequiredFormItem isEditMode>
            <Dropdown
              id="city"
              titleText={t('formFields.city') as string}
              label={t('formFields.pleaseSelect') as string}
              items={cityItems}
              itemToString={(item) => (item ? item.text : '')}
              selectedItem={cityItems.find((cityItem) => cityItem.id === formState?.city) || null}
              {...getValidationPropsForField('city', ({ selectedItem }) => {
                if (selectedItem) {
                  dispatch(setCity(selectedItem.id as TUMLocation));
                  dispatch(setDistricts([]));
                  setForceMultiSelectRerender(-1);
                  setTimeout(() => {
                    setForceMultiSelectRerender(1);
                  });
                }
              })}
            />
          </RequiredFormItem>
          {forceMultiSelectRerender > 0 && formState.districts && districtItems.length > 0 ? (
            <MultiSelect
              id="districts"
              titleText={t('formFields.district') as string}
              disabled={formState.city === null}
              label={getMultiSelectLabel(
                t,
                formState.districts?.map((district) => ({
                  text: district !== 'OTHER' ? `enums.district.${district}` : 'enums.district.OTHER_FILTER',
                  id: district
                })),
                districtItems,
                true
              )}
              items={districtItems}
              itemToString={(item: CustomMultiSelectItem<string>) => (item ? item.text : '')}
              onChange={({ selectedItems }: { selectedItems: CustomMultiSelectItem<string>[] }) =>
                selectedItems && dispatch(setDistricts(selectedItems.map((item) => item.id)))
              }
              initialSelectedItems={districtItems.filter(
                (item) => formState.districts && formState.districts?.indexOf(item.id) > -1
              )}
            />
          ) : (
            // we need to render a different component to reset the selected items as carbon multiselect
            // does not allow any other mechanism.
            <div>
              <MultiSelect
                id="districtsSkeleton"
                label={t('main.all') as string}
                titleText={t('formFields.district') as string}
                items={[]}
                onChange={() => {
                  /* void */
                }}
              />
            </div>
          )}
        </ItemGroup>
      </FormGroup>
      <FormGroup
        legendText={t('views.searchRequests.sections.subsections.cost') as string}
        className={styles.ItemSection}
      >
        <ItemGroup>
          <div>
            <RequiredFormItem isEditMode className={styles.ItemRange}>
              <NumberInput
                id="totalRentFrom"
                value={formState?.totalRentFrom || ''}
                label={t('formFields.totalRent')}
                {...getValidationPropsForField(
                  'totalRentFrom',
                  handleNumberChange((value: number) => dispatch(setTotalRentFrom(value)))
                )}
                {...COST_INPUT_DEFAULT_PROPS}
              />
              <div className={styles.ItemRangeDash}>&ndash;</div>
              <NumberInput
                id="totalRentTo"
                label={t('formFields.totalRentMax')}
                value={formState?.totalRentTo || ''}
                {...getValidationPropsForField(
                  'totalRentTo',
                  handleNumberChange((value: number) => dispatch(setTotalRentTo(value)))
                )}
                {...COST_INPUT_DEFAULT_PROPS}
              />
            </RequiredFormItem>
            <p className={styles.InfoText}>{t('formFields.totalRentInfoText')}</p>
          </div>
          <NumberInput
            id="deposit"
            value={formState?.depositMax || ''}
            label={`${t('formFields.depositMax')} (${UNIT_CURRENCY_EURO}})`}
            onChange={handleNumberChange((value: number) => dispatch(setDeposit(value)))}
            {...COST_INPUT_DEFAULT_PROPS}
          />
          <div />
        </ItemGroup>
      </FormGroup>
      <FormGroup
        legendText={t('views.searchRequests.sections.subsections.configuration') as string}
        className={styles.ItemSection}
      >
        <div className={styles.TagsSection}>
          {ORDERED_TAG_CATEGORIES.map((category) => (
            <FormGroup
              key={category}
              name={category}
              legendText={t(`enums.tagCategory.${category}`) as string}
              className={category === TagCategory.OTHER ? styles.MiscTags : undefined}
            >
              {tagsForCategories.get(category)?.map((tag) => (
                <Checkbox
                  id={tag.id}
                  key={tag.id}
                  name={tag.id}
                  value={tag.id}
                  labelText={tag.text}
                  checked={(formState?.tags && formState?.tags.indexOf(tag.id) > -1) || false}
                  onChange={(checked) =>
                    checked
                      ? dispatch(setTags([...(formState?.tags ?? {}), tag.id]))
                      : dispatch(setTags(formState?.tags.filter((tagListTag) => tagListTag !== tag.id)))
                  }
                />
              ))}
            </FormGroup>
          ))}
        </div>
      </FormGroup>
      <FormGroup
        legendText={t('views.searchRequests.sections.subsections.remarks') as string}
        className={styles.ItemSection}
      >
        <ItemGroup className={styles.ItemGroupLarge}>
          <TextAreaCount
            labelText={t('formFields.remarks')}
            id="remarks"
            value={formState?.remarks || ''}
            maxLength={500}
            placeholder={t('formFields.text')}
            wrapperClassName={styles.TextArea}
            {...getValidationPropsForField('remarks', (event) => {
              dispatch(setRemarks(event.target.value));
            })}
          />
        </ItemGroup>
      </FormGroup>
    </>
  );
};

export default SearchRequestEditInfo;
