import { FC, useCallback, useEffect, useState } from 'react';
import { FilterContainer } from '../../../controls/filter-container/filter-container';
import { SearchField } from '../../../controls/search-field/search-field';
import { Button, DataTableSkeleton, Dropdown, InlineLoading, MultiSelect } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { ActionBar, ActionBarLeft, ActionBarRight } from '../../../building-blocks/action-bar/action-bar';
import { AddBoxSVGIcon, LaunchSVGIcon } from '@react-md/material-icons';
import styles from './landlord-list.module.scss';
import { useNavigate } from 'react-router-dom';
import { ContactType, LandlordSortOrder, LandlordStatus } from '../../../../graphql/types/globalTypes';
import { GET_LANDLORDS, GET_NUMBER_OF_LANDLORDS } from '../../../../graphql/queries/landlord';
import { GetLandlords, GetLandlordsVariables } from '../../../../graphql/types/GetLandlords';
import { useApolloClient, useQuery } from '@apollo/client';
import LandlordListTable from './landlord-list.table';
import { NumberOfLandlords, NumberOfLandlordsVariables } from '../../../../graphql/types/NumberOfLandlords';
import { resetForm } from '../landlord-view/landlord-view.state';
import { useDispatch } from 'react-redux';
import { CustomMultiSelectItem } from '../../../../utils/types';
import { exportCsvData } from './landlord-list.export';
import { useUrlSearchParams } from 'use-url-search-params';
import { FILTER_SEPARATOR, Param } from '../../../../utils/constants';
import { getItemsByParam, getMultiSelectLabel } from '../../../../utils/general';
import classNames from 'classnames';
import { usePaginationParam, useResetPagination } from '../../../../utils/hooks/pagination';
import Pagination from '../../../controls/pagination/pagination';
import { useUserPermission } from '../../../../utils/hooks/user-data';

// Count of multiselect filters, needed for force refresh
const MULTISELECT_FILTER_COUNT = 2;

// Type definitions
type ApprovalItem = CustomMultiSelectItem<string>;
type ContactTypeItem = CustomMultiSelectItem<ContactType>;
type SortingItem = CustomMultiSelectItem<LandlordSortOrder>;

export const LandlordList: FC = () => {
  const navigate = useNavigate();
  // Check if the user is allowed to load this view and return to start view otherwise
  const permissions = useUserPermission();

  if (!permissions.hasAdministrativeFunction) {
    navigate('/listings');
  }
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const [params, setParams] = useUrlSearchParams({}, {});

  const sortingItems = [
    { id: LandlordSortOrder.MOST_RECENT, text: t('sorting.mostRecent') },
    { id: LandlordSortOrder.ALPHABETICALLY_ASC, text: t('sorting.alphabeticalAscending') },
    { id: LandlordSortOrder.ALPHABETICALLY_DESC, text: t('sorting.alphabeticalDescending') }
  ];

  const approvalItems: ApprovalItem[] = [
    { id: LandlordStatus.VERIFIED, text: `${t('enums.approvalType.VERIFIED')}` },
    { id: LandlordStatus.BLOCKED, text: `${t('enums.approvalType.BLOCKED')}` },
    { id: LandlordStatus.NOT_ACTIVATED, text: `${t('enums.approvalType.NOT_ACTIVATED')}` }
  ];

  const contactTypeItems = Object.keys(ContactType).map((key) => ({
    text: t(`enums.contactType.${key}`),
    id: key as ContactType
  }));

  const [contactTypeFilter, setContactTypeFilter] = useState<ContactTypeItem[]>([]);
  const [approvalFilter, setApprovalFilter] = useState<ApprovalItem[]>([]);
  const [sorting, setSorting] = useState<SortingItem>(sortingItems[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [forceFilterReRender, setForceFilterReRender] = useState(-1);
  const [disableExport, setDisableExport] = useState(false);

  const dispatch = useDispatch();
  const { pageOffset, handlePageOffsetChange, resultLimit, handleResultLimitChange } = usePaginationParam(
    Number(process.env.REACT_APP_PAGINATION_MAX)
  );

  const variables: GetLandlordsVariables = {
    pageOffset,
    orderBy: sorting ? sorting.id : LandlordSortOrder.MOST_RECENT,
    resultLimit,
    filter: {
      contactTypes: contactTypeFilter ? contactTypeFilter.map((item) => item.id) : [],
      searchPhrase,
      status: approvalFilter ? approvalFilter.map((item) => item.id as LandlordStatus) : []
    }
  };
  // Get all landlords by filter and sorting
  const { loading, data } = useQuery<GetLandlords, GetLandlordsVariables>(GET_LANDLORDS, {
    variables,
    fetchPolicy: 'no-cache'
  });
  // Get the total number of landlords with filter
  const { data: dataNumberOfLandlords } = useQuery<NumberOfLandlords, NumberOfLandlordsVariables>(
    GET_NUMBER_OF_LANDLORDS,
    { variables, fetchPolicy: 'no-cache' }
  );

  useResetPagination(
    // reset pagination when the number of results is smaller than result limit times page offset
    (dataNumberOfLandlords &&
      variables.resultLimit &&
      dataNumberOfLandlords.numberOfLandlords > variables.resultLimit) ||
      dataNumberOfLandlords === undefined
  );

  const resetFilter = useCallback((): void => {
    setContactTypeFilter([]);
    setApprovalFilter([]);
    // // Since there is no type for `undefined`, this must be ignored
    // // because the parameter should be removed from the url.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setParams({ [Param.APPROVAL]: undefined, [Param.SEARCH]: undefined, [Param.TYPE]: undefined });
    setSorting(sortingItems[0]);
    setSearchPhrase('');
    setSearchValue('');
    setForceFilterReRender(-1);
    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, [setContactTypeFilter, setApprovalFilter, setSorting, setSearchPhrase, setSearchValue, setForceFilterReRender]);

  // Export landlord data to csv file
  const exportData = (): void => {
    setDisableExport(true);
    exportCsvData(t, apolloClient, variables).then(() => setDisableExport(false));
  };

  // Load filter from url initially
  useEffect(() => {
    if (params[Param.APPROVAL]) {
      getItemsByParam(String(params[Param.APPROVAL]), approvalItems, setApprovalFilter);
    }
    if (params[Param.TYPE]) {
      getItemsByParam(String(params[Param.TYPE]), contactTypeItems, setContactTypeFilter);
    }

    // Extract filter value for search
    if (params[Param.SEARCH]) {
      setSearchValue(String(params[Param.SEARCH]));
      setSearchPhrase(String(params[Param.SEARCH]));
    }

    // Render the MultiSelects
    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, []);

  return (
    <div>
      <FilterContainer
        counter={dataNumberOfLandlords?.numberOfLandlords || 0}
        headline={t('views.landlord.landlords')}
        className={styles.LandlordListFilterContainer}
        onResetClick={() => resetFilter()}
      >
        <SearchField
          labelText={`${t('views.landlord.landlords')}`}
          size={'lg'}
          onSearchClick={() => {
            setSearchPhrase(searchValue);
            setParams({ [Param.SEARCH]: searchValue });
          }}
          onChange={(event) => {
            setSearchValue(event.target.value);
            // Remove url parameter and set search empty when clear button is pressed
            if (event.target.value.trim().length === 0) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.SEARCH]: undefined });
            }
            setSearchPhrase('');
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setSearchPhrase(searchValue);
              setParams({ [Param.SEARCH]: searchValue });
            }
          }}
          value={searchValue}
          placeholder={`${t('views.landlord.searchPlaceholder')}`}
        />

        {/* 
          Multiselect does not offer a way to control the selected items. Therefore we 
          need to forcefully rerender the filters after a reset button click
        */}
        {forceFilterReRender > -1 ? (
          <>
            <MultiSelect
              titleText={`${t('views.landlord.approval')}`}
              label={getMultiSelectLabel(t, approvalFilter, approvalItems)}
              id={'approval'}
              onChange={({ selectedItems }) => {
                setApprovalFilter(selectedItems);
                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.APPROVAL]:
                    selectedItems.length > 0 ? selectedItems.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
                });
              }}
              initialSelectedItems={approvalFilter}
              items={approvalItems}
              itemToString={(item) => item?.text || ''}
            />
            <MultiSelect
              titleText={`${t('views.landlord.landlordProfile')}`}
              label={getMultiSelectLabel(t, contactTypeFilter, contactTypeItems)}
              id={'contactType'}
              onChange={({ selectedItems }) => {
                setContactTypeFilter(selectedItems);
                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.TYPE]:
                    selectedItems.length > 0 ? selectedItems.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
                });
              }}
              initialSelectedItems={contactTypeFilter}
              items={contactTypeItems}
              itemToString={(item) => item?.text || ''}
            />
          </>
        ) : (
          <>
            {new Array(MULTISELECT_FILTER_COUNT).map(() => (
              <div className={styles.FilterPlaceholder} />
            ))}
          </>
        )}
      </FilterContainer>
      <ActionBar>
        <ActionBarLeft>
          <Button
            kind="ghost"
            size="sm"
            renderIcon={AddBoxSVGIcon}
            className="bx--btn--hasIcon"
            onClick={() => {
              dispatch(resetForm());
              navigate('new');
            }}
          >
            {t('actions.createAccount')}
          </Button>
        </ActionBarLeft>
        <ActionBarRight>
          {disableExport && <InlineLoading description={t('actions.export')} />}
          {!disableExport && (
            <Button
              kind="ghost"
              size="sm"
              renderIcon={LaunchSVGIcon}
              className="bx--btn--hasIcon"
              disabled={data ? data.landlords.length === 0 : false}
              onClick={exportData}
            >
              {t('actions.export')}
            </Button>
          )}
        </ActionBarRight>
      </ActionBar>

      <div className={classNames(styles.Content, 'global-content-wrapper-block')}>
        <Dropdown
          id="sort"
          type="inline"
          titleText={t('sorting.sortBy') as string}
          label={sorting ? sorting.text : sortingItems[0].text}
          items={sortingItems}
          defaultValue={sortingItems[0].id}
          itemToString={(item) => (item ? item.text : '')}
          onChange={({ selectedItem }) => selectedItem && setSorting(selectedItem)}
        />

        {loading ? (
          <DataTableSkeleton showHeader={false} showToolbar={false} />
        ) : (
          <LandlordListTable landlords={data ? data.landlords : []} />
        )}

        {!loading && data?.landlords && dataNumberOfLandlords && variables.resultLimit && (
          <div className={styles.Pagination}>
            <Pagination
              totalItems={Math.ceil(dataNumberOfLandlords.numberOfLandlords / variables.resultLimit)}
              page={pageOffset}
              hideStepper={dataNumberOfLandlords.numberOfLandlords <= variables.resultLimit}
              resultLimit={resultLimit}
              onChange={handlePageOffsetChange}
              onResultLimitChange={(newLimit) => handleResultLimitChange(newLimit)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LandlordList;
