import { FC } from 'react';
import { CancelSVGIcon, SaveSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GlobalState } from '../../../../../redux/store';
import { FormStateValidationFunction } from '../../../../../utils/validation/validation-utils';
import { SaveBar } from '../../../../building-blocks/save-bar/save-bar';
import { ReactComponent as PreviewIcon } from '../../../../../assets/svg/wysiwyg.svg';
import { ListingFormDataState } from '../listing.state';
import styles from './listing-save-bar.module.scss';
import { ReactComponent as ToggleOff } from '../../../../../assets/svg/toggle-off.svg';
import { useSaveFunction } from './listing-save-bar.hooks';
import { useListingMutations } from '../listing-edit/listing-edit.hooks';
import { useUserPermission } from '../../../../../utils/hooks/user-data';

export interface ListingSaveBarEditProps {
  validateState: FormStateValidationFunction;
  isCreationMode: boolean;
  refetchListing?: () => Promise<unknown>;
  showActionModal?: () => void;
}

export const ListingSaveBarEdit: FC<ListingSaveBarEditProps> = ({
  validateState,
  isCreationMode,
  refetchListing,
  showActionModal
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const isFormDirty = useSelector<GlobalState, boolean>((state) => state.formsDirtyState.isAnyFormDirty);
  const { isActive } = formState;
  const saveFunction = useSaveFunction(validateState, refetchListing);
  const { isLandlord } = useUserPermission();
  const { deactivateListing } = useListingMutations(isLandlord);

  return (
    <SaveBar trigger={null}>
      <div>
        <Button
          kind={'ghost'}
          size="default"
          renderIcon={CancelSVGIcon}
          onClick={() => {
            navigate(isCreationMode ? '..' : '../..');
          }}
        >
          {t('actions.cancel')}
        </Button>
        {!isActive && (
          <Button kind={'ghost'} size="default" renderIcon={SaveSVGIcon} onClick={() => saveFunction(false)()}>
            {t('actions.save')}
          </Button>
        )}
      </div>
      <div>
        {!isCreationMode && isActive && refetchListing && (
          <Button
            kind="secondary"
            onClick={() => {
              deactivateListing().then(() => {
                refetchListing().then(() => {
                  showActionModal && showActionModal();
                });
              });
            }}
            renderIcon={ToggleOff}
            className={styles.IconButton}
          >
            {t('actions.deactivate')}
          </Button>
        )}
        <Button
          kind="secondary"
          onClick={() => {
            navigate('../preview');
          }}
          renderIcon={PreviewIcon}
          className={styles.IconButton}
        >
          {t('actions.preview')}
        </Button>
        <Button
          onClick={() => saveFunction(true)().then(() => showActionModal && showActionModal())}
          type="submit"
          form="listing-data"
          disabled={isActive && !isFormDirty}
        >
          {isActive ? t('actions.publishChanges') : t('actions.publish')}
        </Button>
      </div>
    </SaveBar>
  );
};

export default ListingSaveBarEdit;
