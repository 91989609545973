import { FC, useCallback, useState } from 'react';
import styles from './profile-dialog.module.scss';
import { Button, Form, TextInput } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useLandlordUserData } from '../account/account-view.hooks';
import { ProfileDialogViewMode, SetViewModeProps } from './profile-dialog';
import SaveBar from '../../building-blocks/save-bar/save-bar';
import * as Yup from 'yup';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { RequiredFormItem } from '../../building-blocks/content-view/content-view';
import { useMutation } from '@apollo/client';
import { UpdatePassword, UpdatePasswordVariables } from '../../../graphql/types/UpdatePassword';
import { PUT_UPDATE_PASSWORD } from '../../../graphql/queries/user-auth';
import { useQueryResultToast } from '../../../utils/hooks/query-result-toast';
import { useQueryResultNotificationError } from '../../../utils/hooks/query-result-notification';

const changePasswordValidationSchema = Yup.object().shape(
  {
    password: Yup.string().typeError('errors.required').required('errors.required').min(8, 'errors.passwordMin'),
    newPassword: Yup.string()
      .typeError('errors.required')
      .notOneOf([Yup.ref('password')], 'errors.passwordMustBeDifferent')
      .required('errors.required')
      .min(8, 'errors.passwordMin'),
    newPasswordConfirm: Yup.string().oneOf([Yup.ref('newPassword'), null], 'errors.passwordNotEqual')
  },
  []
);

export const PasswordChangeView: FC<SetViewModeProps> = ({ setViewMode }) => {
  const { t } = useTranslation();
  const {
    getLandLordQuery: { data: userData }
  } = useLandlordUserData();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const resetForm = useCallback(() => {
    setPassword('');
    setNewPassword('');
    setNewPasswordConfirm('');
  }, [setPassword, setNewPassword, setNewPasswordConfirm]);

  const { getValidationPropsForField, validateState } = useFormValidation(
    changePasswordValidationSchema,
    {
      password,
      newPassword,
      newPasswordConfirm
    },
    {
      showToasts: false,
      scrollToInvalidField: false
    }
  );

  const [updatePassword, { data, error }] = useMutation<UpdatePassword, UpdatePasswordVariables>(PUT_UPDATE_PASSWORD, {
    variables: {
      oldPassword: password,
      newPassword
    }
  });

  const errorNotification = useQueryResultNotificationError(error);

  // we only need the success toast
  useQueryResultToast(data, undefined, {
    success: {
      titleKey: 'notifications.passwordUpdateSuccess'
    },
    error: {
      titleKey: 'passwordUpdateError'
    }
  });

  return (
    <div>
      <h3 className={styles.SectionHeading}>{t('views.account.credentials')}</h3>
      {userData?.landlord && (
        <>
          <Form id="password-change" className={styles.ChangeCredentials} onSubmit={(e) => e.preventDefault()}>
            <div className={styles.ReadOnlyInputWrapper}>
              <TextInput
                id="mailAddress"
                className={styles.CredentialsInputReadOnly}
                labelText={t('formFields.emailAddress') as string}
                value={userData.landlord.email || ''}
                readOnly
              />
            </div>
            <RequiredFormItem isEditMode>
              <TextInput
                id="oldPassword"
                type="password"
                labelText={t('formFields.oldPassword') as string}
                placeholder={t('formFields.oldPassword') as string}
                value={password}
                {...getValidationPropsForField('password', (event) => {
                  setPassword(event.target.value);
                })}
              />
            </RequiredFormItem>
            <RequiredFormItem isEditMode>
              <TextInput
                id="newPassword"
                type="password"
                labelText={t('formFields.newPassword') as string}
                placeholder={t('formFields.placeholder.newPassword') as string}
                value={newPassword}
                {...getValidationPropsForField('newPassword', (event) => {
                  setNewPassword(event.target.value);
                })}
              />
            </RequiredFormItem>
            <RequiredFormItem isEditMode>
              <TextInput
                id="newPasswordConfirm"
                type="password"
                labelText={t('formFields.newPasswordConfirm') as string}
                placeholder={t('formFields.placeholder.newPassword') as string}
                value={newPasswordConfirm}
                {...getValidationPropsForField('newPasswordConfirm', (event) => {
                  setNewPasswordConfirm(event.target.value);
                })}
              />
            </RequiredFormItem>
            {error && <div>{errorNotification}</div>}
          </Form>
          <SaveBar trigger={{}} className={styles.SaveBar}>
            <Button
              kind={'secondary'}
              onClick={() => {
                resetForm();
                setViewMode(ProfileDialogViewMode.OVERVIEW);
              }}
            >
              {t('actions.cancel')}
            </Button>
            <Button
              onClick={() => {
                validateState(() => {
                  updatePassword()
                    .then(() => {
                      setViewMode(ProfileDialogViewMode.OVERVIEW);
                    })
                    .catch(() => ({}));
                });
              }}
              type="submit"
              form="password-change"
            >
              {t('actions.changePassword')}
            </Button>
          </SaveBar>
        </>
      )}
    </div>
  );
};

export default PasswordChangeView;
