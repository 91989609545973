import { FC, useEffect, useState } from 'react';
import { ContactType, Gender } from '../../../../../graphql/types/globalTypes';
import { BusinessSVGIcon, LockSVGIcon, MailSVGIcon, PersonSVGIcon, PhoneSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import { NeedsTranslationProps } from '../../../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { isApproved } from '../../../../../utils/permissions';
import { ApprovalType } from '../../../../controls/approval-state/approval-state';
import styles from './listing-view.module.scss';
import { useTranslation } from 'react-i18next';
import { ListingFormDataState } from '../listing.state';
import { UserPermissions } from '../../../../../utils/hooks/user-data';
import { createShareEntityLink, shareByMail } from '../../../../../utils/actions';
import classNames from 'classnames';
import { useResponsiveBreakpoints } from '../../../../../utils/hooks/responsive.hooks';
import { useLazyQuery } from '@apollo/client';
import { GET_IS_LISTING_TOKEN_VALID } from '../../../../../graphql/queries/listings';
import {
  GetIsListingTokenValid,
  GetIsListingTokenValidVariables
} from '../../../../../graphql/types/GetIsListingTokenValid';
import { Param } from '../../../../../utils/constants';

const ContactDataHeading: FC<NeedsTranslationProps & { contactType?: ContactType; verifiedStatus?: ApprovalType }> = ({
  t,
  contactType,
  verifiedStatus
}) => (
  <div className={styles.ContactDataHeading}>
    {contactType === ContactType.PRIVATE ? <PersonSVGIcon /> : <BusinessSVGIcon />}{' '}
    <div>
      {contactType ? t(`enums.contactType.${contactType}`) : t('enums.contactType.MANAGED')}
      {verifiedStatus !== undefined && (
        <p className={styles.VerifiedStatusNotice}>
          {verifiedStatus === ApprovalType.Approved
            ? t('views.listings.verifiedByTUMLiving')
            : verifiedStatus === ApprovalType.Blocked
            ? t('views.listings.blocked')
            : t('views.listings.notVerified')}
        </p>
      )}
    </div>
  </div>
);

interface ContactDataInfoProps {
  gender?: Gender;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  company?: string;
  listingTitle: string;
}

const ContactDataInfo: FC<ContactDataInfoProps> = ({
  gender,
  firstName,
  lastName,
  phone,
  email,
  company,
  listingTitle
}) => {
  const { t } = useTranslation();
  const breakpoints = useResponsiveBreakpoints();
  return (
    <div>
      {company && <div className={styles.ContactDataRow}>{company}</div>}
      {gender && (firstName || lastName) && (
        <div className={styles.ContactDataRow}>
          {t(`enums.salutation.${gender}`) + (gender !== Gender.DIVERSE ? ' ' : '')}
          {`${firstName ? firstName + ' ' : ''} ${lastName ?? ''}`}
        </div>
      )}
      {phone && (
        <div className={styles.ContactDataRow}>
          <PhoneSVGIcon /> {phone}
        </div>
      )}
      {email && (
        <>
          <div className={styles.ContactDataRow}>
            <MailSVGIcon /> {email}
          </div>
          <Button
            size="field"
            onClick={() => {
              shareByMail(
                {
                  mailSubject: t('contactMail.listing.sharingMailSubject', { listing: listingTitle }),
                  mailBody: ''
                },
                email
              );
            }}
            className={classNames(styles.ContactButton, !breakpoints.isDesktop ? 'bx--btn--sm' : undefined)}
          >
            {t('actions.writeMail')}
          </Button>
        </>
      )}
    </div>
  );
};

export interface ListingViewContactCardProps {
  formState: ListingFormDataState;
  listingTitle: string;
  userPermissions: UserPermissions;
  shareToken?: string;
}

export const ListingViewContactCard: FC<ListingViewContactCardProps> = ({
  formState,
  listingTitle,
  userPermissions,
  shareToken
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const breakpoints = useResponsiveBreakpoints();
  const { hasAdministrativeFunction, isAnonymous, isLandlord } = userPermissions;
  const [hasValidShareToken, setHasValidShareToken] = useState(false);

  const [fetchToken, { data, loading }] = useLazyQuery<GetIsListingTokenValid, GetIsListingTokenValidVariables>(
    GET_IS_LISTING_TOKEN_VALID,
    { fetchPolicy: 'no-cache' }
  );

  // Check if user is anonymous with a share listing token
  useEffect(() => {
    if (isAnonymous && shareToken && formState.uuid && !data && !loading) {
      // Check if token is valid
      fetchToken({
        variables: {
          listingUuid: formState.uuid,
          sharedListingToken: shareToken
        }
      });
    }

    if (data && !loading) {
      setHasValidShareToken(data.isListingTokenValid);
    }
  }, [data, loading, isAnonymous, shareToken, formState.uuid]);

  return (
    <div
      className={classNames(styles.TwoColumnSection, {
        [styles.TwoColumnSection__WrapMobile]: !breakpoints.isDesktop
      })}
    >
      {isAnonymous && !hasValidShareToken ? (
        <div className={styles.ContactDataHiddenNotice}>
          <LockSVGIcon />
          <div>
            <p>{t('views.listings.contactHiddenNotice')}</p>
            <Button
              size="field"
              onClick={() => {
                navigate({
                  pathname: '.',
                  search: `?${Param.LOGIN}=1&${Param.TAB}=0`
                });
              }}
              className={!breakpoints.isDesktop ? 'bx--btn--sm' : undefined}
            >
              {t('actions.login')}
            </Button>
          </div>
        </div>
      ) : (
        <div>
          {formState.landlord ? (
            <ContactDataInfo
              gender={formState.landlord.gender || undefined}
              firstName={
                formState.landlord.showFirstName && formState.landlord.firstName
                  ? formState.landlord.firstName
                  : undefined
              }
              lastName={
                formState.landlord.showLastName && formState.landlord.lastName ? formState.landlord.lastName : undefined
              }
              phone={formState.landlord.showPhone && formState.landlord.phone ? formState.landlord.phone : undefined}
              email={formState.landlord.showEmail && formState.landlord.email ? formState.landlord.email : undefined}
              company={
                formState.landlord.showCompany &&
                formState.landlord.companyName &&
                formState.landlord.contactType !== ContactType.PRIVATE
                  ? formState.landlord.companyName
                  : undefined
              }
              listingTitle={listingTitle}
            />
          ) : (
            <ContactDataInfo
              gender={Gender.DIVERSE}
              firstName={process.env.REACT_APP_MANAGED_LISTING_CONTACT_FIRST_NAME}
              lastName={process.env.REACT_APP_MANAGED_LISTING_CONTACT_LAST_NAME}
              phone={process.env.REACT_APP_MANAGED_LISTING_CONTACT_PHONE}
              email={process.env.REACT_APP_MANAGED_LISTING_CONTACT_MAIL}
              listingTitle={listingTitle}
            />
          )}
        </div>
      )}
      <div className={styles.ContactTypeSection}>
        {!isAnonymous && (
          <ContactDataHeading
            contactType={formState.landlord?.contactType}
            t={t}
            verifiedStatus={
              formState.landlord && hasAdministrativeFunction ? isApproved(formState.landlord) : undefined
            }
          />
        )}
        {!hasAdministrativeFunction && !isLandlord && (
          <div className={classNames(styles.ReportContact, { [styles.ReportContact__Spacing]: !isAnonymous })}>
            <p className={styles.ReportContactNotice}>{t('views.listings.contactReportNotice')}</p>
            <Button
              kind="ghost"
              size="sm"
              className={styles.ReportButton}
              onClick={() =>
                shareByMail(
                  {
                    mailSubject: t('emailSubjects.suspiciousListing'),
                    mailBody: `${t('emailContent.suspiciousListing.title', {
                      link: createShareEntityLink()
                    })}\n\n${t('emailContent.suspiciousListing.message')}`
                  },
                  process.env.REACT_APP_CONTACT_MAIL_SUSPICIOUS_LISTING
                )
              }
            >
              {t('actions.reportListing')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingViewContactCard;
