import * as Yup from 'yup';
import { TUMLocation, ListingType } from '../../../../graphql/types/globalTypes';
import { ManagementType } from './listing.constants';
import { LocalImageDescriptor } from './listing.state';
import { isNotDefinedOrEmpty } from '../../../../utils/validation/state-validation-checks';

export const listingValidationSchema = Yup.object().shape(
  {
    uuid: Yup.string().optional().nullable(),
    landlord: Yup.mixed().when('$managementType', (managementType: ManagementType, schema: Yup.AnySchema) =>
      managementType === ManagementType.LANDLORD ? schema.required('errors.required') : schema.nullable()
    ),

    type: Yup.mixed().oneOf(Object.values(ListingType)).required('errors.required'),
    street: Yup.string().required('errors.required').min(3, 'errors.tooShort3'),
    numberOfRooms: Yup.number().typeError('errors.required').required('errors.required'),
    postalCode: Yup.string().required('errors.required').min(3, 'errors.tooShort3'),
    city: Yup.string().required('errors.required').min(2, 'errors.tooShort2'),
    tumLocation: Yup.mixed().oneOf(Object.values(TUMLocation)).required('errors.required'),
    rent: Yup.number().required('errors.required').notOneOf([0], 'errors.required'),
    availableFrom: Yup.date().typeError('errors.required'),
    squareMeter: Yup.number().required('errors.required').not([0], 'errors.required'),

    incidentalCosts: Yup.number().min(0).integer().optional().nullable(),
    heatingCosts: Yup.number().min(0).integer().optional().nullable(),
    additionalCosts: Yup.number().min(0).integer().optional().nullable(),
    deposit: Yup.number().min(0).integer().optional().nullable(),

    availableUntil: Yup.date().nullable().typeError('errors.required'),
    expirationDate: Yup.date().nullable().typeError('errors.required'),
    publicationDate: Yup.date().nullable().typeError('errors.required'),

    images: Yup.array()
      .transform((images: LocalImageDescriptor[]) => images.filter((image) => !image.markedForDeletion))
      .of(
        Yup.object().shape(
          {
            description: Yup.string().when('descriptionEn', {
              is: isNotDefinedOrEmpty,
              then: Yup.string()
                .typeError('errors.eitherRequired')
                .required('errors.eitherRequired')
                .min(3, 'errors.tooShort3'),
              otherwise: Yup.string().optional().nullable()
            }),
            descriptionEn: Yup.string().when('description', {
              is: isNotDefinedOrEmpty,
              then: Yup.string()
                .typeError('errors.eitherRequired')
                .required('errors.eitherRequired')
                .min(3, 'errors.tooShort3'),
              otherwise: Yup.string().optional().nullable()
            })
          },
          [['description', 'descriptionEn']]
        )
      )
  },
  [
    // avoid circular dependencies by validating fields in all possible orders
    ['furtherEquipment', 'furtherEquipmentEn'],
    ['furtherEquipmentEn', 'furtherEquipment']
  ]
);
