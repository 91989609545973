import { FC, SyntheticEvent } from 'react';
import styles from './search-request-card.module.scss';
import { ListingType } from '../../../graphql/types/globalTypes';
import { useTranslation } from 'react-i18next';
import { Button, Link } from 'carbon-components-react';
import { ChevronRightSVGIcon, DeleteSVGIcon, PauseSVGIcon, PlayArrowSVGIcon } from '@react-md/material-icons';
import { renderTagIcon } from '../../views/listings/listing/listing-view/listing-view.tag-icons';
import { Card } from '../card/card';
import { formatDate } from '../../../utils/intl/dates-and-times';
import { formatRange } from '../../views/search-requests/search-request/search-request.utils';
import { useResponsiveBreakpoints } from '../../../utils/hooks/responsive.hooks';

export interface SearchRequestCardProps {
  id: string;

  tumLocation: string | null;
  districts: string[] | null;
  type: ListingType | null;
  availableFrom: string | null;
  availableFromLatest: string | null;
  numberOfRooms: number | null;
  squareMeterFrom: number | null;
  squareMeterTo: number | null;
  totalRentFrom: number | null;
  totalRentTo: number | null;
  depositMax: number | null;
  tags: string[] | null;
  isActive: boolean;

  numberOfResults: number;
  resultsLink: string; // Todo check if url should be given as property or generated here

  onPauseClick?: (event: SyntheticEvent) => void;
  onDeleteClick?: (event: SyntheticEvent) => void;
}

export const SearchRequestCard: FC<SearchRequestCardProps> = (props: SearchRequestCardProps) => {
  const { t } = useTranslation();
  const breakpoint = useResponsiveBreakpoints(); // Render results as aside only on desktop

  // Create headline depending on the given properties
  const headline = (): string => {
    // Listing type or generic
    const type = props.type ? t(`enums.listingType.${props.type}`) : t('views.seeker.livingSpace');
    // TUM location (city) or empty string
    const city = props.tumLocation ? ' ' + t(`enums.city.${props.tumLocation}`) : '';
    // Districts
    let districts = '';
    if (props.districts) {
      // Only show districts if it is not "other"
      if (props.districts.length === 1) {
        districts = props.districts[0] !== 'OTHER' ? ' ' + t(`enums.district.${props.districts[0]}`) : '';
      } else if (props.districts.length > 1) {
        // Take the first district if it is not other and add the further districts only as counter
        districts =
          props.districts[0] !== 'OTHER'
            ? ' ' + t(`enums.district.${props.districts[0]}`)
            : ' ' + t(`enums.district.${props.districts[1]}`);
        districts += ' (+' + (props.districts.length - 1);
        districts +=
          ' ' + (props.districts.length === 2 ? t('views.seeker.district') : t('views.seeker.districts')) + ')';
      }
    }
    // Return full headline
    return (
      type +
      ' ' +
      t('views.seeker.toRent') +
      (city.length > 0 || districts.length > 0 ? ' ' + t('views.seeker.in') + ' ' : '') +
      city +
      districts
    );
  };

  const createId = (id: string): string => {
    return props.id + '_' + id;
  };

  return (
    <Card className={styles.SearchRequestCard}>
      <div className={styles.content} role="region">
        <h3>{headline()}</h3>
        {!breakpoint.isMobile && !breakpoint.isTablet && (
          <aside className={styles.results}>
            <h4>
              {props.numberOfResults +
                ' ' +
                (props.numberOfResults === 1 ? t('views.seeker.result') : t('views.seeker.results'))}
            </h4>
            <Link className={styles.resultLink} href={props.resultsLink}>
              <ChevronRightSVGIcon aria-hidden={true} />
              {t('views.seeker.showResults')}
            </Link>
          </aside>
        )}
        <div className={styles.searchCriteria}>
          <table>
            <tbody>
              <tr>
                <th id={createId('tumLocation')}>{t('formFields.tumLocation')}</th>
                <td aria-labelledby={createId('tumLocation')}>
                  {props.tumLocation === null ? t('main.any') : t(`enums.city.${props.tumLocation}`)}
                </td>
              </tr>
              <tr>
                <th id={createId('listingType')}>{t('views.listings.filter.type')}</th>
                <td aria-labelledby={createId('listingType')}>
                  {props.type ? t(`enums.listingType.${props.type}`) : t('main.any')}
                </td>
              </tr>
              <tr>
                <th id={createId('availableFrom')}>{t('views.listings.filter.availableBy')}</th>
                <td aria-labelledby={createId('availableFrom')}>
                  {props.availableFrom ? formatDate(props.availableFrom) : t('main.any')}
                </td>
              </tr>
              <tr>
                <th id={createId('availableFromLatest')}>{t('views.listings.filter.availableFromLatest')}</th>
                <td aria-labelledby={createId('availableFromLatest')}>
                  {props.availableFromLatest ? formatDate(props.availableFromLatest) : (t('main.any') as string)}
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <th id={createId('roomCount')}>{t('views.listings.filter.roomCount')}</th>
                <td aria-labelledby={createId('roomCount')}>
                  {props.numberOfRooms ? t('formFields.from') + ' ' + props.numberOfRooms : t('main.any')}
                </td>
              </tr>
              <tr>
                <th id={createId('space')}>{t('views.listings.filter.space')}</th>
                <td aria-labelledby={createId('space')}>
                  {!props.squareMeterFrom && !props.squareMeterTo
                    ? t('main.any')
                    : formatRange(t, props.squareMeterFrom, props.squareMeterTo, 'm²')}
                </td>
              </tr>
              <tr>
                <th id={createId('totalRentFrom')}>{t('views.listings.filter.rent')}</th>
                <td aria-labelledby={createId('totalRentFrom')}>
                  {!props.totalRentFrom && !props.totalRentTo
                    ? t('main.any')
                    : formatRange(t, props.totalRentFrom, props.totalRentTo, '€')}
                </td>
              </tr>
              <tr>
                <th id={createId('deposit')}>{t('views.listings.filter.deposit')}</th>
                <td aria-labelledby={createId('deposit')}>
                  {props.depositMax ? `${props.depositMax} €` : t('main.any')}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {props.tags && (
          <ul className={styles.TagsList}>
            {props.tags.map((tag, index) => {
              return (
                <li key={index} className={styles.ListingTag}>
                  {renderTagIcon(tag, true, t)}
                  <span aria-hidden="true">{t(`enums.tags.${tag}`)}</span>
                </li>
              );
            })}
          </ul>
        )}
        {!breakpoint.isDesktop && (
          <div className={styles.results}>
            <span>{props.numberOfResults}&nbsp;</span>
            <Link className={styles.resultLink} href={props.resultsLink}>
              {props.numberOfResults === 1 ? t('views.seeker.result') : t('views.seeker.results')}
            </Link>
            <ChevronRightSVGIcon aria-hidden={true} />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <Button
          size="sm"
          kind="ghost"
          type="button"
          renderIcon={props.isActive ? PauseSVGIcon : PlayArrowSVGIcon}
          iconDescription={t(props.isActive ? 'actions.pause' : 'actions.resume')}
          onClick={props.onPauseClick}
        >
          {t(props.isActive ? 'actions.pause' : 'actions.resume')}
        </Button>
        <Button
          size="sm"
          kind="ghost"
          type="button"
          renderIcon={DeleteSVGIcon}
          iconDescription={t('actions.delete')}
          onClick={props.onDeleteClick}
        >
          {t('actions.delete')}
        </Button>
      </div>
    </Card>
  );
};
