import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useDocumentTitle = (titleInfo?: string): void => {
  const { t } = useTranslation();

  useEffect(() => {
    if (titleInfo) {
      document.title = `${t('main.document_title')} – ${titleInfo}`;
    }
  }, [titleInfo]);
};
