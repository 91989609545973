import { CloseSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { FC, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styles from './sidebar.module.scss';

export interface SideBarProps {
  title: string;
  onClose: () => void;
  open?: boolean;
  actionLabel?: string;
}

export const SideBar: FC<SideBarProps> = ({ children, open, onClose, title, actionLabel }) => {
  const { t } = useTranslation();

  const sideBarCloseListener = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', sideBarCloseListener);
    return () => {
      window.removeEventListener('keydown', sideBarCloseListener);
    };
  }, [sideBarCloseListener]);

  const sideBar = (
    <>
      {/* We do not need any accessibility here since the closing of the sidebar can be achieved via button press
          or Escape key. This is just a comfort function for desktop users.
        */}
      {/* eslint-disable-next-line spellcheck/spell-checker */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(styles.SideBarBackground, {
          [styles.SideBarBackground__open]: open
        })}
        onClick={onClose}
      />
      <FocusTrap active={open} focusTrapOptions={{ initialFocus: 'aside', allowOutsideClick: true }}>
        <aside
          className={classNames(styles.SideBar, {
            [styles.SideBar__open]: open
          })}
        >
          <div className={styles.SideBarHeading}>
            <h2>{title}</h2>
            <Button
              renderIcon={CloseSVGIcon}
              iconDescription={t('actions.close')}
              tooltipPosition={'bottom'}
              hasIconOnly
              onClick={onClose}
              kind="ghost"
              size="field"
            />
          </div>
          <div className={styles.SideBarContent}>{children}</div>
          {actionLabel && (
            <div className={styles.SideBarActions}>
              <Button onClick={onClose} kind="primary">
                {actionLabel}
              </Button>
            </div>
          )}
        </aside>
      </FocusTrap>
    </>
  );

  return createPortal(sideBar, document.body);
};

export default SideBar;
