import { ToastNotification } from 'carbon-components-react';
import { toast } from 'react-toastify';

export const copyURLtoClipboard = (url: string, t: (key: string) => string): void => {
  navigator.clipboard.writeText(url).then(
    () => {
      /* clipboard successfully set */
      toast(() => <ToastNotification kind="success" title={t('notifications.copySuccess') as string} caption="" />, {
        closeButton: false
      });
    },
    () => {
      toast(() => <ToastNotification kind="error" title={t('notifications.copyError') as string} caption="" />, {
        closeButton: false
      });
    }
  );
};

/**
 * Copies the given text (all links to public listings) to the clipboard and shows a success/error message on completion.
 */
export const copyAllURLsToClipboard = async (
  textPromise: Promise<string>,
  t: (key: string) => string
): Promise<void> => {
  let copyPromise;

  // Copying to clipboard in Safari is only allowed in a synchronous way after user interaction. Because we
  // need to fetch async information, we need to pass a promise to the ClipboardItem constructor for safari.
  // Firefox currently does not support this, it also does not prevent async clipboard text.

  // eslint-disable-next-line
  // @ts-ignore
  if (typeof ClipboardItem && navigator.clipboard.write) {
    // Case for Safari
    // eslint-disable-next-line
    // @ts-ignore
    const text = new ClipboardItem({
      'text/plain': textPromise.then((text) => new Blob([text], { type: 'text/plain' }))
    });
    // eslint-disable-next-line
    // @ts-ignore
    copyPromise = navigator.clipboard.write([text]);
  } else {
    // Case for Firefox
    const text = await textPromise;
    copyPromise = navigator.clipboard.writeText(text);
  }

  copyPromise.then(
    () => {
      /* clipboard successfully set */
      toast(
        () => (
          <ToastNotification
            kind="success"
            title={t('notifications.copyAllSuccess') as string}
            caption={t('notifications.copyAllSuccessSubtitle') as string}
          />
        ),
        {
          closeButton: false
        }
      );
    },
    () => {
      toast(() => <ToastNotification kind="error" title={t('notifications.copyAllError') as string} caption="" />, {
        closeButton: false
      });
    }
  );
};

export type MailContent = { mailBody: string; mailSubject: string };

export const shareByMail = (content: MailContent, to?: string): void => {
  window.location.assign(
    `mailto:${to || ''}?subject=${encodeURI(content.mailSubject)}&body=${encodeURI(content.mailBody)}`
  );
};

export const exportTable = (csvContent: string, name: string): void => {
  // Create blob with UTF-8 BOM for better excel support
  const blob = new Blob(
    [
      // eslint-disable-next-line spellcheck/spell-checker
      new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
      csvContent
    ],
    { type: 'text/csv;charset=utf-8' }
  );
  const encodedUri = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  document.body.appendChild(link); // Required for FF

  link.click();

  document.body.removeChild(link);
};

export const exportAttachment = (content: string, name: string): void => {
  // eslint-disable-next-line spellcheck/spell-checker
  const encodedUri = encodeURI(content);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', name);
  document.body.appendChild(link); // Required for FF

  link.click();

  document.body.removeChild(link);
};

export const createShareEntityLink = (): string => {
  const currentLocation = window.location.href;

  // we usually want the last 3 levels of the url which are assumed to have the schema (:entityType/:uuid/:viewMode)
  return [process.env.REACT_APP_PUBLIC_URL, ...currentLocation.split('/').slice(-3)]
    .join('/')
    .replace(/\/edit/, '/view')
    .replace(/\/account/, '/listings')
    .replace(/\?share=.*/, '');
};
