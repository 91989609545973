import * as Yup from 'yup';
import { registerValidationShape } from '../../register-dialog/register-dialog.validation';

export const landlordValidationSchema = Yup.object().shape({
  ...registerValidationShape,
  notes: Yup.string()
    .optional()
    .transform((value) => (value === '' ? null : value))
    .nullable()
    .min(3, 'errors.tooShort3'),

  email: Yup.string().typeError('errors.required').required('errors.required').email('errors.invalidMail'),
  agreement: Yup.mixed().optional()
});

export const landlordBlockSchema = Yup.object().shape({
  blockedReason: Yup.string().typeError('errors.required').required('errors.required').min(3, 'errors.tooShort3')
});
