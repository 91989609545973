import {
  BusinessSVGIcon,
  EditSVGIcon,
  PersonSVGIcon,
  VisibilityOffSVGIcon,
  VisibilitySVGIcon
} from '@react-md/material-icons';
import {
  Button,
  DataTableSkeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'carbon-components-react';
import classNames from 'classnames';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType } from '../../../graphql/types/globalTypes';
import { Card } from '../../controls/card/card';
import styles from './landlord-contact-data.module.scss';
import EditContactDetailsDialog from '../edit-contact-details-dialog/edit-contact-details-dialog';
import { useDispatch } from 'react-redux';
import { LandlordProfileDataState, setLandlordProfileData } from '../account/account-view.state';
import { GetLandLordById, GetLandLordByIdVariables } from '../../../graphql/types/GetLandLordById';
import { ApolloQueryResult } from '@apollo/client';

export const VISIBILITY_ATTRIBUTE_KEYS = ['companyName', 'firstName', 'lastName', 'phone', 'email'];

interface NeedsTranslationProps {
  t: (key: string) => string;
}

export const VisibilityIcon: FC<{ visible?: boolean } & NeedsTranslationProps> = ({ visible, t }) => {
  return !visible ? (
    <VisibilityOffSVGIcon className={styles.DisabledIcon} title={t('icons.visible')} />
  ) : (
    <VisibilitySVGIcon title={t('icons.notVisible')} />
  );
};

export const ContactDataInfoText: FC<NeedsTranslationProps> = ({ t }) => {
  return (
    <span id="contactDataInfo" className={classNames(styles.InfoText, styles.InfoTextSmall)}>
      {t('views.account.contactDataVisibilityInfoText')}
    </span>
  );
};

export const ContactPartnerInfoText: FC<NeedsTranslationProps> = ({ t }) => {
  return (
    <span id="contactPartnerInfo" className={classNames(styles.InfoText, styles.InfoTextSmall)}>
      {t('views.account.contactPartnerVisibilityInfoText')}
    </span>
  );
};

export const ContactDataHeading: FC<NeedsTranslationProps & { contactType?: ContactType }> = ({ t, contactType }) => (
  <div className={styles.ContactDataHeading}>
    {contactType === ContactType.PRIVATE ? <PersonSVGIcon /> : <BusinessSVGIcon />}{' '}
    {contactType && t(`enums.contactType.${contactType}`)}
  </div>
);

// invisible table header for improved accessibility
export const ContactDataTableHeader: FC<NeedsTranslationProps> = ({ t }) => (
  <TableHead className={styles.ContactDataTableHeader}>
    <TableRow>
      <TableHeader>{t('main.visibility')}</TableHeader>
      <TableHeader>{t('main.attribute')}</TableHeader>
      <TableHeader>{t('main.value')}</TableHeader>
    </TableRow>
  </TableHead>
);

export interface LandlordContactDataProps {
  landlordId?: string;
  landlordProfileData: LandlordProfileDataState;
  loading: boolean;
  userData?: GetLandLordById;
  refetch: (variables?: Partial<GetLandLordByIdVariables> | undefined) => Promise<ApolloQueryResult<GetLandLordById>>;
}

export const LandlordContactData: FC<LandlordContactDataProps> = ({
  landlordId,
  landlordProfileData,
  loading,
  userData,
  refetch
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openEditContactDetails, setOpenEditContactDetails] = useState(false);

  const visibilityRows = useMemo(() => {
    /* eslint-disable @typescript-eslint/ban-ts-comment  */
    return VISIBILITY_ATTRIBUTE_KEYS.map((key) => {
      const isInstitutionRow =
        key === 'companyName' && userData?.landlord?.contactType === ContactType.PUBLIC_INSTITUTION;
      return {
        key,
        label: isInstitutionRow ? t('views.common.tableHeaders.institution') : t(`views.common.tableHeaders.${key}`),
        // @ts-ignore
        value: userData?.landlord[key],
        // @ts-ignore
        visibility: landlordProfileData[`show${key.charAt(0).toUpperCase() + key.slice(1)}`]
      };
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment  */
  }, [userData?.landlord, landlordProfileData, t]);

  const resetProfileData = useCallback(() => {
    userData?.landlord &&
      dispatch(
        setLandlordProfileData({
          showFirstName: userData?.landlord.showFirstName,
          showLastName: userData?.landlord.showLastName,
          showEmail: userData?.landlord.showEmail,
          showPhone: userData?.landlord.showPhone,
          showCompanyName: userData?.landlord.showCompany
        })
      );
  }, [userData?.landlord]);

  useEffect(() => {
    if (userData?.landlord) {
      resetProfileData();
    }
  }, [resetProfileData]);

  return (
    <>
      <Card className={styles.ContactDataCard}>
        <div className={styles.ContactDataCardInfoRow}>
          <ContactDataInfoText t={t} />
          <Button
            kind="ghost"
            size="field"
            renderIcon={EditSVGIcon}
            className={styles.EditContactDataVisibilityButton}
            onClick={() => setOpenEditContactDetails(true)}
          >
            {t('actions.editAlt')}
          </Button>
        </div>
        <ContactDataHeading t={t} contactType={userData?.landlord?.contactType} />

        {userData?.landlord ? (
          <Table aria-describedby="contactPartnerInfo" className={styles.ContactDataTable}>
            <ContactDataTableHeader t={t} />
            <TableBody>
              {visibilityRows.map((rowData) => {
                if (!(rowData.key === 'companyName' && userData?.landlord?.contactType === ContactType.PRIVATE)) {
                  return (
                    <Fragment key={rowData.key}>
                      <TableRow>
                        <TableCell>
                          <VisibilityIcon visible={rowData.visibility} t={t} />
                        </TableCell>
                        <TableCell>{rowData.label}</TableCell>
                        <TableCell>{rowData.value}</TableCell>
                      </TableRow>
                      {rowData.key === 'companyName' ? (
                        <TableRow>
                          <TableCell className={styles.ContactSubheading}>
                            <ContactPartnerInfoText t={t} />
                          </TableCell>
                        </TableRow>
                      ) : undefined}
                    </Fragment>
                  );
                }
                // landlords that do not have a company or institution, don't need the showCompany visibility
                return undefined;
              })}
            </TableBody>
          </Table>
        ) : loading ? (
          <DataTableSkeleton
            showHeader={false}
            showToolbar={false}
            columnCount={3}
            rowCount={VISIBILITY_ATTRIBUTE_KEYS.length}
            className={styles.ContactDataTable}
            compact={true}
          />
        ) : null}
      </Card>
      {openEditContactDetails && (
        <EditContactDetailsDialog
          landlordId={landlordId}
          onCloseModal={() => {
            setOpenEditContactDetails(false);
            refetch();
          }}
        />
      )}
    </>
  );
};

export default LandlordContactData;
