export const defaultNumber = new Intl.NumberFormat('de-DE');
export const defaultFileSizeFormat = new Intl.NumberFormat('de-DE');

export const formatNumber = (value: number): string => {
  return defaultNumber.format(value);
};

export const formatFileSize = (value: number): string => {
  return defaultFileSizeFormat.format(value);
};
