import { TagCategory } from '../../../../graphql/types/globalTypes';

export enum ManagementType {
  LANDLORD = 'LANDLORD',
  MANAGEMENT = 'MANAGEMENT'
}

export enum ListingVisibility {
  PUBLIC = 'PUBLIC',
  NOT_PUBLIC = 'NOT_PUBLIC'
}

export enum ParkingSpaceAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export const ORDERED_TAG_CATEGORIES = [TagCategory.FURNISHING, TagCategory.SANITARY, TagCategory.OTHER];

export enum TransportationType {
  CAR = 'DRIVING_CAR',
  FOOT = 'FOOT_WALKING',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT'
}

export enum PoiCategory {
  TUM = 'TUM',
  TRANSPORT = 'PLATFORM',
  PHARMACY = 'PHARMACY',
  SUPERMARKET = 'SUPERMARKET',
  BAKERY = 'BAKERY',
  SCHOOL = 'SCHOOL'
}
