import { FC } from 'react';
import styles from './search-field.module.scss';
import classNames from 'classnames';
import { Button, Search, SearchProps } from 'carbon-components-react';
import { ArrowForwardSVGIcon } from '@react-md/material-icons';
import { useTranslation } from 'react-i18next';

export interface SearchFieldProps extends SearchProps {
  onSearchClick: () => void;
}

/**
 * Adapted Search from Carbon React with additional search button
 * @param props
 * @constructor
 */
export const SearchField: FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const { t } = useTranslation();

  const { onSearchClick, ...searchProps } = props;

  return (
    <div
      className={classNames(styles.SearchField, {
        [styles.SearchField__lg]: props.size === 'lg'
      })}
    >
      <Search {...searchProps} />
      <Button
        hasIconOnly
        renderIcon={ArrowForwardSVGIcon}
        iconDescription={t('tooltips.search')}
        onClick={onSearchClick}
        disabled={props.disabled}
        className={styles.SearchButton}
      />
    </div>
  );
};
