import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from './redux/store';
import { setIsCsrfMWRunning, UserState } from './redux/user.state';
import { useApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useEffect } from 'react';

/**
 * This hook is triggered when the csrf token is set in the state and makes sure that appollo
 * transmits the token as a HTTP-Header. This ensures the implementation of the double submit
 * cookie pattern.
 *
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross-Site_Request_Forgery_Prevention_Cheat_Sheet.html#double-submit-cookie
 */
export const useCSRF = (): void => {
  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const apolloClient = useApolloClient();
  const dispatch = useDispatch();

  // whenever a csrf token is set in the state, we include it as a header in all graphql calls
  useEffect(() => {
    if (userState.csrf !== null) {
      const csrfLink = setContext((_, { headers }) => {
        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            [`${process.env.REACT_APP_SESSION_CSRF_HEADER_NAME}`]: userState.csrf
          }
        };
      });
      const originalLink = apolloClient.link;

      apolloClient.setLink(csrfLink.concat(apolloClient.link));

      dispatch(setIsCsrfMWRunning(true));
      // restore original link on cleanup
      return () => {
        apolloClient.setLink(originalLink);
        dispatch(setIsCsrfMWRunning(false));
      };
    }
  }, [userState.csrf]);
};
