import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuItem } from './components/controls/menu/menu';
import { AdministrationState } from './redux/administration.state';
import { GlobalState } from './redux/store';
import { UserState } from './redux/user.state';
import { IS_DEV_MODE } from './utils/constants';
import { useUserPermission } from './utils/hooks/user-data';

export const useNavigationMenu = (): MenuItem[] => {
  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const { numberOfNewLandlords, numberOfNewSearchRequests } = useSelector<GlobalState, AdministrationState>(
    (state) => state.administrationState
  );

  const permissions = useUserPermission();
  const { hasAdministrativeFunction, isAdmin, isLandlord, isSeeker, isAnonymous } = permissions;

  const navigationMenuItems = useMemo(() => {
    const tabs: MenuItem[] = [
      {
        titleKey: 'navigation.listings',
        linkTo: '/listings'
      }
    ];

    if (hasAdministrativeFunction) {
      tabs.push({
        titleKey: 'navigation.landlord',
        linkTo: '/landlord'
      });
      tabs.push({
        titleKey: 'navigation.inquiries',
        linkTo: '/search-requests'
      });
    }

    if (isAdmin) {
      tabs.push({
        titleKey: 'navigation.admin',
        linkTo: '/admin'
      });
    }

    if (isLandlord) {
      tabs.push({
        titleKey: 'navigation.mySpace',
        linkTo: '/account',
        subMenuItems: [
          {
            titleKey: 'views.account.myListings',
            linkTo: '/account#listings'
          },
          {
            titleKey: 'views.account.contactData',
            linkTo: '/account#contact'
          }
        ]
      });
    }

    if (isSeeker) {
      tabs.push({
        titleKey: 'navigation.mySpace',
        linkTo: '/seeker'
      });
    }

    if (isSeeker || isAnonymous) {
      tabs.push({
        titleKey: 'navigation.tips',
        linkTo: '/tips'
      });
    }

    if (IS_DEV_MODE) {
      tabs.push({
        titleKey: 'navigation.components',
        linkTo: '/components'
      });
    }

    return tabs;
  }, [numberOfNewLandlords, numberOfNewSearchRequests, userState, permissions]);

  return navigationMenuItems;
};
