import { FC, PropsWithChildren, useState } from 'react';
import styles from './favorite-button.module.scss';
import { Button } from 'carbon-components-react';
import { FavoriteBorderSVGIcon, FavoriteSVGIcon } from '@react-md/material-icons';
import { useTranslation } from 'react-i18next';
import { useAddAndDeleteFavorites } from '../../views/seeker/seeker-view.hooks';
import classNames from 'classnames';

export interface FavoriteButtonProps {
  refetch?: () => void;
  isFavorite: boolean;
  listingId: string;
}

export const FavoriteButton: FC<PropsWithChildren<FavoriteButtonProps>> = (
  props: PropsWithChildren<FavoriteButtonProps>
) => {
  const { t } = useTranslation();
  const [favoriteHovered, setFavoriteHovered] = useState(false);
  const { addFavorite, deleteFavorite } = useAddAndDeleteFavorites(props.listingId);

  return (
    <div
      className={classNames(styles.FavoriteButton, {
        [styles.isFavorite]: props.isFavorite
      })}
    >
      <Button
        hasIconOnly
        kind={'secondary'}
        onMouseEnter={() => setFavoriteHovered(true)}
        onMouseLeave={() => setFavoriteHovered(false)}
        renderIcon={favoriteHovered || props.isFavorite ? FavoriteSVGIcon : FavoriteBorderSVGIcon}
        tooltipPosition="right"
        iconDescription={props.isFavorite ? t('actions.deleteFavorite') : t('actions.addFavorite')}
        onClick={(event) => {
          event.stopPropagation();
          props.isFavorite
            ? deleteFavorite().then(() => props.refetch && props.refetch())
            : addFavorite().then(() => props.refetch && props.refetch());
        }}
      />
    </div>
  );
};
