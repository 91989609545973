import { gql } from '@apollo/client';

export const GET_PERMISSION_MAPPINGS = gql`
  # Retrieves all email addresses and their roles that have the given permissions
  # Called on the admin page to retrieve list of all users that have management or admin rights
  query GetPermissionMappings($permissions: [Permission!] = [MANAGEMENT, ADMINISTRATOR]) {
    permissionMappings(permissions: $permissions) {
      email
      permission
      role
    }
  }
`;

export const GET_MANAGEMENT_ROLES = gql`
  # Retrieves all roles for the given permission
  # Called to get all possible values for role dropdown depending on the selection in the permission dropdown
  query GetManagementRoles($permission: Permission = MANAGEMENT) {
    rolesForPermission(permission: $permission)
  }
`;

export const GET_TAGS_FOR_CATEGORY = gql`
  query GetTagsForCategory($forCategory: TagCategory) {
    tags(forCategory: $forCategory)
  }
`;

export const POST_NEW_PERMISSION_MAPPING = gql`
  # Adds a new permission or overrides existing ones
  # Called when the user adds a new permission on the admin page
  mutation PostNewPermissionMapping($email: String!, $permission: Permission!, $role: String!) {
    setPermission(email: $email, permission: $permission, role: $role)
  }
`;

export const DELETE_PERMISSION_MAPPING = gql`
  mutation DeletePermissionMapping($email: String!) {
    # Removes permissions from the user with the given email
    # Called when the user clicks on the delete button in the admin page list
    deletePermission(email: $email)
  }
`;
