import { ApolloQueryResult, MutationFunctionOptions, useMutation, useQuery } from '@apollo/client';
import {
  DELETE_PERMISSION_MAPPING,
  GET_MANAGEMENT_ROLES,
  GET_PERMISSION_MAPPINGS,
  POST_NEW_PERMISSION_MAPPING
} from '../../../graphql/queries/roles-and-permissions';
import {
  DeletePermissionMapping,
  DeletePermissionMappingVariables
} from '../../../graphql/types/DeletePermissionMapping';
import { GetManagementRoles, GetManagementRolesVariables } from '../../../graphql/types/GetManagementRoles';
import { GetPermissionMappings } from '../../../graphql/types/GetPermissionMappings';
import {
  PostNewPermissionMapping,
  PostNewPermissionMappingVariables
} from '../../../graphql/types/PostNewPermissionMapping';
import { useGenericQueryErrorToast, useQueryResultToast } from '../../../utils/hooks/query-result-toast';

type AddMappingMutation = (
  options?: MutationFunctionOptions<PostNewPermissionMapping, PostNewPermissionMappingVariables>
) => Promise<unknown>;
type DeleteMappingMutation = (
  options?: MutationFunctionOptions<DeletePermissionMapping, DeletePermissionMappingVariables>
) => Promise<unknown>;
type RefetchRoleOptionsQuery = (
  variable?: GetManagementRolesVariables
) => Promise<ApolloQueryResult<GetManagementRoles>>;

export const useAddAndDeleteMappings = (): {
  addMapping: AddMappingMutation;
  deleteMapping: DeleteMappingMutation;
} => {
  const [addMapping, { data: addMappingData, error: addMappingError }] = useMutation<
    PostNewPermissionMapping,
    PostNewPermissionMappingVariables
  >(POST_NEW_PERMISSION_MAPPING);
  const [deleteMapping, { data: deleteMappingData, error: deleteMappingError }] = useMutation<
    DeletePermissionMapping,
    DeletePermissionMappingVariables
  >(DELETE_PERMISSION_MAPPING);

  useQueryResultToast(addMappingData, addMappingError, {
    success: {
      titleKey: 'notifications.addMappingSuccess'
    },
    error: {
      titleKey: 'notifications.addMappingError'
    }
  });

  useQueryResultToast(deleteMappingData, deleteMappingError, {
    success: {
      titleKey: 'notifications.deleteMappingSuccess'
    },
    error: {
      titleKey: 'notifications.deleteMappingError'
    }
  });

  return {
    addMapping,
    deleteMapping
  };
};

export const useFetchMappings = (): {
  refetch: () => Promise<unknown>;
  data?: GetPermissionMappings;
} => {
  const { refetch, data, error } = useQuery<GetPermissionMappings>(GET_PERMISSION_MAPPINGS);
  useGenericQueryErrorToast(error);

  return {
    refetch,
    data
  };
};

export const useFetchRoleOptions = (): {
  refetch: RefetchRoleOptionsQuery;
  data?: GetManagementRoles;
} => {
  const { refetch, data, error } = useQuery<GetManagementRoles, GetManagementRolesVariables>(GET_MANAGEMENT_ROLES);
  useGenericQueryErrorToast(error);

  return {
    refetch,
    data
  };
};
