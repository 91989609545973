import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './verification.module.scss';
import { CheckSVGIcon, CloseSVGIcon } from '@react-md/material-icons';

export const Verification: FC<{
  verified: boolean;
  isNew?: boolean;
  trueTranslationKey?: string;
  falseTranslationKey?: string;
}> = ({ verified, isNew, trueTranslationKey, falseTranslationKey }) => {
  const { t } = useTranslation();

  const verifiedLabel = trueTranslationKey || 'enums.verification.VERIFIED';
  const notVerifiedLabel = falseTranslationKey || 'enums.verification.NOT_VERIFIED';

  return (
    <span
      className={classNames(styles.Verification, {
        [styles.Verification__verified]: verified
      })}
    >
      {verified ? (
        <>
          <CheckSVGIcon />
          {isNew ? <strong>{t(verifiedLabel)}</strong> : t(verifiedLabel)}
        </>
      ) : (
        <>
          <CloseSVGIcon />
          {isNew ? <strong>{t(notVerifiedLabel)}</strong> : t(notVerifiedLabel)}
        </>
      )}
    </span>
  );
};

export default Verification;
