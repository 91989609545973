import { Tag } from 'carbon-components-react';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import styles from './tag-input.module.scss';

export interface TagInputProps {
  id: string;
  initialTags: string[];
  placeHolder: string;
  tags?: string[];
  validateTag?: (tag: string) => boolean;
  onChange?: (tags: string[]) => void;
}

export const TagInput: FC<TagInputProps> = ({ initialTags, placeHolder, tags, validateTag, id, onChange }) => {
  const [inputValue, setInputValue] = useState('');
  const [tagState, setTagState] = useState(tags || initialTags);

  useEffect(() => {
    onChange && onChange(tagState);
  }, [tagState]);

  useEffect(() => {
    if (tags && tagState !== tags) {
      setTagState(tags);
    }
  }, [tags]);

  return (
    <div className={styles.TagInputWrapper}>
      {tagState.length > 0 && (
        <div className={styles.TagContainer}>
          {tagState.map((tag, index) => (
            <Tag
              className={classNames(styles.Tag, { [styles.Tag__invalid]: validateTag && !validateTag(tag) })}
              data-testid="Tag"
              key={index}
              onClose={() => {
                const newTags = tagState.slice();
                newTags.splice(index, 1);
                setTagState(newTags);
              }}
              filter
            >
              {tag}
            </Tag>
          ))}
        </div>
      )}
      <input
        id={id}
        type="text"
        placeholder={placeHolder}
        title={placeHolder}
        className={styles.TagInput}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyDown={(event) => {
          if (event.key === ' ' || event.key === 'Enter' || event.key === ',') {
            event.preventDefault();

            if (inputValue.length > 0) {
              setTagState([...tagState, inputValue]);
              setInputValue('');
            }
          }
        }}
        onBlur={() => {
          if (inputValue.length > 0) {
            setTagState([...tagState, inputValue]);
            setInputValue('');
          }
        }}
      ></input>
    </div>
  );
};

export default TagInput;
