import { Children, cloneElement, FC, isValidElement, ReactElement, useState } from 'react';
import { SendSVGIcon } from '@react-md/material-icons';
import { Button, Form, TextInput } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import styles from './comment-section.module.scss';
import CommentBox, { CommentBoxProps } from '../../controls/comment-box/comment-box';
import { ReactComponent as ChevronDownIcon } from '../../../assets/svg/chevron-down.svg';
import classNames from 'classnames';

export interface CommentSectionProps {
  onSubmitNewComment: (comment: string) => void;
}

export const CommentSection: FC<CommentSectionProps> = ({ onSubmitNewComment, children }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [isCurrentlyEdited, setIsCurrentlyEdited] = useState(false);
  const childCount = Children.count(children);

  Children.forEach(children, (child) => {
    if (!(isValidElement(child) && child.type === CommentBox)) {
      throw new Error('Only CommentBox allowed as direct children of CommentSection');
    }
  });

  const renderedChildren = Children.toArray(children).map((child) =>
    cloneElement(child as ReactElement<CommentBoxProps>, {
      onEditModeSwitch: (isEdited: boolean) => setIsCurrentlyEdited(isEdited),
      blockEditAndDelete: isCurrentlyEdited
    })
  );

  return (
    <div className={styles.CommentSection}>
      <Form
        id="search-request-comment"
        className={styles.NewComment}
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <TextInput
          id="newComment"
          labelText={t('formFields.addComment') as string}
          placeholder={t('formFields.writeComment')}
          value={newComment}
          onChange={(event) => setNewComment(event.target.value)}
          data-testid="NewCommentInput"
          disabled={isCurrentlyEdited}
        />
        <Button
          kind="primary"
          size="field"
          type="submit"
          form="search-request-comment"
          renderIcon={SendSVGIcon}
          hasIconOnly
          iconDescription={t('formFields.addComment')}
          disabled={newComment.length === 0 || isCurrentlyEdited}
          data-testid="NewCommentSubmit"
          onClick={() => {
            onSubmitNewComment && onSubmitNewComment(newComment);
            setNewComment('');
          }}
        />
      </Form>

      {childCount > 0 && <div className={styles.Comments}>{expanded ? renderedChildren : renderedChildren[0]}</div>}
      {childCount > 1 && (
        <Button
          id="commentExpansion"
          kind="ghost"
          size="field"
          className={classNames(styles.ExpandButton, { [styles.ExpandButton__expanded]: expanded })}
          data-testid="ExpandCommentsButton"
          onClick={() => setExpanded(!expanded)}
        >
          {`${!expanded ? t('actions.showAll') : t('actions.showLess')} ${!expanded ? `(${childCount})` : ''}`}{' '}
          <ChevronDownIcon />
        </Button>
      )}
    </div>
  );
};

export default CommentSection;
