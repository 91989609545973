import { FetchResult, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { TUMLocation, Gender, Permission } from '../../../../graphql/types/globalTypes';
import { GlobalState } from '../../../../redux/store';
import { useQueryResultToast } from '../../../../utils/hooks/query-result-toast';
import { PostNewSearchRequest, PostNewSearchRequestVariables } from '../../../../graphql/types/PostNewSearchRequest';
import {
  DELETE_SEARCH_REQUEST,
  POST_NEW_SEARCH_REQUEST,
  PUT_CLOSE_SEARCH_REQUEST,
  PUT_OPEN_SEARCH_REQUEST,
  PUT_UPDATE_SEARCH_REQUEST
} from '../../../../graphql/queries/search-requests';
import { SearchRequestsFormDataState } from './search-request.state';
import { UpdateSearchRequest, UpdateSearchRequestVariables } from '../../../../graphql/types/UpdateSearchRequest';
import { DeleteSearchRequest, DeleteSearchRequestVariables } from '../../../../graphql/types/DeleteSearchRequest';
import { OpenSearchRequest, OpenSearchRequestVariables } from '../../../../graphql/types/OpenSearchRequest';
import { CloseSearchRequest, CloseSearchRequestVariables } from '../../../../graphql/types/CloseSearchRequest';
import { GET_PERMISSION_MAPPINGS } from '../../../../graphql/queries/roles-and-permissions';
import { GetPermissionMappings, GetPermissionMappingsVariables } from '../../../../graphql/types/GetPermissionMappings';
import { useEffect } from 'react';
import { useFetchNumberOfNewSearchRequests } from '../../../../utils/hooks/administration.hooks';
import { useCurrentUser } from '../../../../utils/hooks/user-data';

export const usePostNewSearchRequestsMutation = (): (() => Promise<FetchResult<PostNewSearchRequest>>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { gender, city, numberOfRooms, totalRentTo, showResultToast, ...variables } = useSelector<
    GlobalState,
    SearchRequestsFormDataState
  >((state) => state.searchRequestsFormData);

  const transformedVariables = Object.assign({}, variables, {
    gender: gender as Gender,
    city: city as TUMLocation,
    numberOfRooms: numberOfRooms || 0,
    totalRentTo: totalRentTo || 0
  });

  const [postNewSearchRequest, { data: saveData, error: saveError }] = useMutation<
    PostNewSearchRequest,
    PostNewSearchRequestVariables
  >(POST_NEW_SEARCH_REQUEST, {
    variables: transformedVariables
  });

  useQueryResultToast(saveData, saveError, {
    success: {
      titleKey: 'notifications.searchRequestCreationSuccess'
    },
    error: {
      titleKey: 'notifications.searchRequestCreationError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return postNewSearchRequest;
};

export const useUpdateSearchRequest = (): (() => Promise<FetchResult<UpdateSearchRequest>>) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    gender,
    city,
    numberOfRooms,
    totalRentTo,
    uuid,
    showResultToast,
    role,
    observers,
    ...variables
  } = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const transformedVariables = Object.assign({}, variables, {
    gender: gender as Gender,
    city: city as TUMLocation,
    numberOfRooms: numberOfRooms || 0,
    totalRentTo: totalRentTo || 0,
    isNew: false,
    id: variables.id || '',
    role: role || '',
    observers: observers.map((observer) => observer.email)
  });

  const [updateSearchRequest, { data, error }] = useMutation<UpdateSearchRequest, UpdateSearchRequestVariables>(
    PUT_UPDATE_SEARCH_REQUEST,
    {
      variables: { input: transformedVariables }
    }
  );

  useQueryResultToast(data, error, {
    success: {
      titleKey: 'notifications.searchRequestUpdateSuccess'
    },
    error: {
      titleKey: 'notifications.searchRequestUpdateError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return updateSearchRequest;
};

export const useDeleteSearchRequest = (): (() => Promise<FetchResult<unknown>>) => {
  const { id } = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);

  const [deleteSearchRequest, { data, error }] = useMutation<DeleteSearchRequest, DeleteSearchRequestVariables>(
    DELETE_SEARCH_REQUEST,
    { variables: { id: id || '' } }
  );

  useQueryResultToast(data, error, {
    success: {
      titleKey: 'notifications.searchRequestDeleteSuccess'
    },
    error: {
      titleKey: 'notifications.searchRequestDeleteError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return deleteSearchRequest;
};

export const useOpenCloseSearchRequests = (): {
  openSearchRequest: () => Promise<FetchResult<unknown>>;
  closeSearchRequest: () => Promise<FetchResult<unknown>>;
} => {
  const { id } = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);

  const [openSearchRequest, { data: openSearchRequestData, error: openSearchRequestError }] = useMutation<
    OpenSearchRequest,
    OpenSearchRequestVariables
  >(PUT_OPEN_SEARCH_REQUEST, { variables: { id: id || '' } });

  const [closeSearchRequest, { data: closeSearchRequestData, error: closeSearchRequestError }] = useMutation<
    CloseSearchRequest,
    CloseSearchRequestVariables
  >(PUT_CLOSE_SEARCH_REQUEST, { variables: { id: id || '' } });

  useQueryResultToast(openSearchRequestData, openSearchRequestError, {
    success: {
      titleKey: 'notifications.searchRequestOpenSuccess'
    },
    error: {
      titleKey: 'notifications.searchRequestOpenError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(closeSearchRequestData, closeSearchRequestError, {
    success: {
      titleKey: 'notifications.searchRequestCloseSuccess',
      subTitleKey: 'notifications.searchRequestCloseSuccessMail'
    },
    error: {
      titleKey: 'notifications.searchRequestCloseError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return {
    openSearchRequest,
    closeSearchRequest
  };
};

export const usePermissionMappings = (): GetPermissionMappings | undefined => {
  const { data } = useQuery<GetPermissionMappings, GetPermissionMappingsVariables>(GET_PERMISSION_MAPPINGS, {
    variables: { permissions: [Permission.MANAGEMENT, Permission.ADMINISTRATOR] }
  });

  return data;
};

export const useMarkAsSeenAndAutoAssign = (isLoaded: boolean): void => {
  const formState = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);
  const currentUser = useCurrentUser();

  const [updateSearchRequest] = useMutation<UpdateSearchRequest, UpdateSearchRequestVariables>(
    PUT_UPDATE_SEARCH_REQUEST,
    {
      onError: () => ({})
    }
  );

  const fetchNumberOfNewSearchRequests = useFetchNumberOfNewSearchRequests();

  useEffect(() => {
    if (formState.id !== null && formState.isNew && isLoaded) {
      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        gender,
        city,
        numberOfRooms,
        totalRentTo,
        uuid,
        showResultToast,
        role,
        observers,
        ...variables
      } = formState;
      /* eslint-enable @typescript-eslint/no-unused-vars */

      const transformedVariables = Object.assign({}, variables, {
        gender: gender as Gender,
        city: city as TUMLocation,
        numberOfRooms: numberOfRooms || 0,
        totalRentTo: totalRentTo || 0,
        isNew: false,
        id: variables.id || '',
        role: role || '',
        observers: observers.map((observer) => observer.email)
      });

      // automatically assign current user to search request
      if (formState.assignee === null && currentUser?.email && currentUser.role) {
        transformedVariables.assignee = currentUser.email;
        transformedVariables.role = currentUser.role;
      }

      updateSearchRequest({
        variables: {
          input: transformedVariables
        }
      }).then(fetchNumberOfNewSearchRequests);
    }
  }, [formState, isLoaded, currentUser]);
};
