import { useEffect } from 'react';

export const useBodyScrollBlocker = (isBlocked: boolean): void => {
  useEffect(() => {
    if (isBlocked) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isBlocked]);
};
