import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Listing } from '../listings/listing/listing';
import { ListingsSingleLevelSubRouter } from '../listings/listings-router';
import { AccountView } from './account-view';

export const AccountRouter: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<AccountView />} />
      <Route path="/:viewMode/*" element={<ListingsSingleLevelSubRouter defaultView={<Listing />} />} />
      <Route path="/:uuid/:viewMode/*" element={<Listing />} />
    </Routes>
  );
};

export default AccountRouter;
