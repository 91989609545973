import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { SearchRequestsList } from './search-requests-list/search-requests-list';
import SearchRequest from './search-request/search-request';

export const SearchRequestsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<SearchRequestsList />} />
      <Route path="/new/*" element={<SearchRequest />} />
      <Route path="/:uuid/*" element={<SearchRequest />} />
    </Routes>
  );
};

export default SearchRequestsRouter;
