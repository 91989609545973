import { FC } from 'react';
import { Dropdown, FormGroup, RadioButton, RadioButtonGroup, TextInput } from 'carbon-components-react';
import styles from './search-request-edit-contact.module.scss';
import { Gender } from '../../../../../../graphql/types/globalTypes';
import { useTranslation } from 'react-i18next';
import {
  SearchRequestsFormDataState,
  setAffiliationType,
  setCountry,
  setDepartment,
  setEmail,
  setFirstName,
  setGender,
  setLastName,
  setPhone,
  setPrefersEnglish
} from '../../search-request.state';
import { useDispatch } from 'react-redux';
import { DefaultMultiSelectItem, StyleableProps } from '../../../../../../utils/types';
import classNames from 'classnames';
import { ValidationProps } from '../../../../../../utils/validation/validation-utils';
import { AffiliationType, affiliationTypeItems, countries, departments } from '../../../search-requests-types';
import { RequiredFormItem } from '../../../../../building-blocks/content-view/content-view';

const ItemGroup: FC<StyleableProps> = ({ children, className }) => (
  <div className={classNames(styles.ItemGroup, className)}>{children}</div>
);

export interface SearchRequestFormProps {
  formState: SearchRequestsFormDataState;
  getValidationPropsForField: (
    fieldName: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    wrappedOnChange?: ((event: any) => void) | undefined
  ) => ValidationProps;
  clearFormError: (fieldName: string) => void;
}

export const SearchRequestEditContact: FC<SearchRequestFormProps> = ({ formState, getValidationPropsForField }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <>
      <RequiredFormItem isEditMode>
        <FormGroup className={styles.RadioButtonGroup} legendText={t('views.searchRequests.gender') as string}>
          <RadioButtonGroup
            name="gender"
            valueSelected={formState.gender || Gender.DIVERSE}
            onChange={(value: Gender) => dispatch(setGender(value))}
          >
            <RadioButton id={Gender.MALE} key={Gender.MALE} value={Gender.MALE} labelText={t('enums.gender.MALE')} />
            <RadioButton
              id={Gender.FEMALE}
              key={Gender.FEMALE}
              value={Gender.FEMALE}
              labelText={t('enums.gender.FEMALE')}
            />
            <RadioButton
              id={Gender.DIVERSE}
              key={Gender.DIVERSE}
              value={Gender.DIVERSE}
              labelText={t('enums.gender.DIVERSE')}
            />
          </RadioButtonGroup>
        </FormGroup>
      </RequiredFormItem>
      <ItemGroup>
        <RequiredFormItem isEditMode>
          <TextInput
            id="lastName"
            name="lastName"
            labelText={`${t('formFields.lastName')}`}
            placeholder={`${t('formFields.lastName')}`}
            value={formState.lastName}
            {...getValidationPropsForField('lastName', (event) => {
              dispatch(setLastName(event.target.value));
            })}
          />
        </RequiredFormItem>
        <RequiredFormItem isEditMode>
          <TextInput
            id="firstName"
            name="firstName"
            labelText={`${t('formFields.firstName')}`}
            placeholder={`${t('formFields.firstName')}`}
            value={formState.firstName}
            {...getValidationPropsForField('firstName', (event) => {
              dispatch(setFirstName(event.target.value));
            })}
          />
        </RequiredFormItem>
      </ItemGroup>
      <ItemGroup>
        <RequiredFormItem isEditMode>
          <TextInput
            id="email"
            name="email"
            labelText={`${t('formFields.emailAddress')}`}
            placeholder={`${t('formFields.placeholder.email')}`}
            value={formState.email}
            {...getValidationPropsForField('email', (event) => {
              dispatch(setEmail(event.target.value));
            })}
          />
        </RequiredFormItem>
        <TextInput
          id="phone"
          labelText={`${t('formFields.telephoneNumber')}`}
          placeholder={`${t('formFields.placeholder.phone')}`}
          value={formState.phone}
          {...getValidationPropsForField('phone', (event) => {
            dispatch(setPhone(event.target.value));
          })}
        />
      </ItemGroup>
      <ItemGroup>
        <RequiredFormItem isEditMode>
          <Dropdown
            id="affiliationType"
            titleText={t('formFields.affiliationType') as string}
            label={t('formFields.pleaseSelect') as string}
            items={affiliationTypeItems}
            itemToString={(item) => (item ? t(item.text) : '')}
            selectedItem={
              formState.affiliationType
                ? affiliationTypeItems.find((item) => item.id === formState.affiliationType)
                : null
            }
            {...getValidationPropsForField(
              'affiliationType',
              ({ selectedItem }: { selectedItem: DefaultMultiSelectItem }) => {
                selectedItem && dispatch(setAffiliationType(selectedItem.id as AffiliationType));
              }
            )}
          />
        </RequiredFormItem>
        <Dropdown
          id="department"
          titleText={t('formFields.department') as string}
          label={t('formFields.pleaseSelect') as string}
          items={departments}
          itemToString={(item) => (item ? t(`enums.department.${item}`) : '')}
          selectedItem={formState.department}
          {...getValidationPropsForField('department', ({ selectedItem }: { selectedItem: string }) => {
            selectedItem && dispatch(setDepartment(selectedItem));
          })}
        />
      </ItemGroup>
      <ItemGroup>
        <RequiredFormItem isEditMode>
          <Dropdown
            id="country"
            titleText={t('formFields.country') as string}
            label={t('formFields.pleaseSelect') as string}
            items={countries}
            itemToString={(item) => (item ? t(`enums.country.${item}`) : '')}
            selectedItem={formState.country}
            {...getValidationPropsForField('country', ({ selectedItem }: { selectedItem: string }) => {
              selectedItem && dispatch(setCountry(selectedItem));
            })}
          />
        </RequiredFormItem>
        <FormGroup legendText={t('formFields.prefLanguage') as string} className={styles.RadioButtonGroup}>
          <RadioButtonGroup
            name="preferredLanguage"
            onChange={(value: string) => dispatch(setPrefersEnglish(value === 'english'))}
            valueSelected={formState.prefersEnglish ? 'english' : 'german'}
          >
            <RadioButton id={'german'} key={'german'} value={'german'} labelText={t('formFields.language.german')} />
            <RadioButton
              id={'english'}
              key={'english'}
              value={'english'}
              labelText={t('formFields.language.english')}
            />
          </RadioButtonGroup>
        </FormGroup>
      </ItemGroup>
    </>
  );
};

export default SearchRequestEditContact;
