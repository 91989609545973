import { FC } from 'react';
import { ContentSwitcher, Switch } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export enum ViewMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export interface ViewModeSwitcherProps {
  viewMode?: ViewMode;
  onChange?: (mode: ViewMode) => void;
}

export const ViewModeSwitcher: FC<ViewModeSwitcherProps> = ({ onChange, viewMode }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <ContentSwitcher
      onChange={({ name }) => {
        /* istanbul ignore else */
        if (name !== undefined && typeof name === 'string') {
          onChange && onChange(name as ViewMode);
          navigate(`../${name.toLowerCase()}`, { replace: true });
        }
      }}
      selectedIndex={viewMode === ViewMode.EDIT ? 0 : 1}
    >
      <Switch name={ViewMode.EDIT} value={ViewMode.EDIT} text={t('modes.edit')} />
      <Switch name={ViewMode.VIEW} value={ViewMode.VIEW} text={t('modes.view')} />
    </ContentSwitcher>
  );
};

export default ViewModeSwitcher;
