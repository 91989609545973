import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal } from 'carbon-components-react';
import styles from './profile-dialog.module.scss';
import { useTranslation } from 'react-i18next';
import { ProfileDialogOverview } from './profile-dialog.overview';
import { PasswordChangeView } from './profile-dialog.password-change';
import { ContactDataChangeView } from './profile-dialog.contact-data';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import { FormDataDiscardDialog } from '../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { resetForm } from '../landlord/landlord-view/landlord-view.state';

export enum ProfileDialogViewMode {
  OVERVIEW,
  CHANGE_PASSWORD,
  CHANGE_PROFILE_DATA
}

export interface ProfileDialogProps {
  onCloseModal: () => void;
}

export interface SetViewModeProps {
  setViewMode: Dispatch<SetStateAction<ProfileDialogViewMode>>;
}

export const ProfileDialog: FC<ProfileDialogProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [viewMode, setViewMode] = useState(ProfileDialogViewMode.OVERVIEW);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const hasDirtyForm = useSelector<GlobalState, boolean>((state) => state.formsDirtyState.isAnyFormDirty);

  const currentView = useMemo(() => {
    switch (viewMode) {
      case ProfileDialogViewMode.OVERVIEW:
        return <ProfileDialogOverview setViewMode={setViewMode} />;
      case ProfileDialogViewMode.CHANGE_PASSWORD:
        return <PasswordChangeView setViewMode={setViewMode} />;
      case ProfileDialogViewMode.CHANGE_PROFILE_DATA:
        return <ContactDataChangeView setViewMode={setViewMode} />;
      default:
        break;
    }
  }, [viewMode]);

  return createPortal(
    <>
      <Modal
        className={styles.ProfileDialog}
        passiveModal={true}
        open={true}
        onRequestClose={() => {
          !hasDirtyForm ? props.onCloseModal() : setIsConfirmationDialogOpen(true);
        }}
        modalLabel={t('views.account.myProfile')}
        selectorPrimaryFocus={'.bx--modal-container'}
        iconDescription={t('actions.close')}
        preventCloseOnClickOutside={true}
      >
        <div className="bx--modal-custom-content">{currentView}</div>
      </Modal>
      <FormDataDiscardDialog
        open={isConfirmationDialogOpen}
        onPrimaryActionClick={() => {
          // discard
          dispatch(resetForm());
          setIsConfirmationDialogOpen(false);
          props.onCloseModal();
        }}
        onSecondaryActionClick={() => {
          setIsConfirmationDialogOpen(false);
        }}
      />
    </>,
    document.body
  );
};

export default ProfileDialog;
