import { FC } from 'react';
import styles from './legal-details.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import sanitizeHtml from 'sanitize-html';
import { sanitizeOptions } from '../../../utils/sanitize';
import { useDocumentTitle } from '../../../utils/hooks/document-title';

export const LegalDetails: FC = () => {
  const { t } = useTranslation();
  useDocumentTitle(t('views.legalDetails.headline'));

  return (
    <section className={classNames(styles.LegalDetails, 'global-content-wrapper-block')}>
      <h2>{t('views.legalDetails.headline')}</h2>
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            t('views.legalDetails.content', { interpolation: { escapeValues: false } }),
            sanitizeOptions
          )
        }}
      />
    </section>
  );
};

export default LegalDetails;
