import { gql } from '@apollo/client';

export const GET_SEEKER_SEARCH_REQUESTS = gql`
  query GetSeekerSearchRequests($email: String!) {
    seekerSearchRequests(email: $email) {
      id
      email
      type
      numberOfRooms
      squareMeterFrom
      squareMeterTo
      availableFrom
      availableFromLatest
      tumLocation
      totalRentFrom
      totalRentTo
      depositMax
      tags
      districts
      isActive
      resultCount
      isOpenEnded
    }
  }
`;

export const POST_NEW_SEEKER_SEARCH_REQUEST = gql`
  mutation PostNewSeekerSearchRequest($input: SeekerSearchRequestInput!) {
    addSeekerSearchRequest(input: $input)
  }
`;

export const PUT_UPDATE_SEEKER_SEARCH_REQUEST = gql`
  mutation UpdateSeekerSearchRequest($id: ID!, $email: String!, $isActive: Boolean!) {
    updateSeekerSearchRequestActiveState(id: $id, email: $email, isActive: $isActive)
  }
`;

export const DELETE_SEEKER_SEARCH_REQUEST = gql`
  mutation DeleteSeekerSearchRequest($id: ID!, $email: String!) {
    deleteSeekerSearchRequest(id: $id, email: $email)
  }
`;
