import { FC } from 'react';
import styles from './maintenance.module.scss';
import { useTranslation } from 'react-i18next';

export const Maintenance: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Maintenance}>
      <h1>{t('views.maintenance.headline')}</h1>
      <p>{t('views.maintenance.content')}</p>
    </div>
  );
};
