import { FC, PropsWithChildren, SyntheticEvent } from 'react';
import styles from './card.module.scss';
import classNames from 'classnames';

export interface CardProps {
  role?: string;
  className?: string;
  onClick?: (event: SyntheticEvent) => void;
}

export const Card: FC<PropsWithChildren<CardProps>> = (props: PropsWithChildren<CardProps>) => {
  return (
    <div
      role={props.role}
      className={classNames(styles.Card, props.className)}
      onClick={props.onClick}
      onKeyPress={props.onClick}
    >
      {props.children}
    </div>
  );
};
