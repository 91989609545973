import {
  Button,
  DataTableSkeleton,
  Dropdown,
  Link,
  TextInputSkeleton,
  ToastNotification
} from 'carbon-components-react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FilterContainer, FilterTagContainer } from '../../../controls/filter-container/filter-container';
import styles from './listings-list.module.scss';
import { ListSVGIcon, MapSVGIcon, ViewStreamSVGIcon } from '@react-md/material-icons';

import { useLocation, useNavigate } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { TUMLocation, ListingSortOrder } from '../../../../graphql/types/globalTypes';
import SideBar from '../../../controls/sidebar/sidebar';
import { RangeFilter } from '../../../controls/range-filter/range-filter';
import { ListingsListTable } from './listings-list-table/listings-list-table';
import { usePostNewSeekerSearchRequest, useTagsFromState } from './listings-list.hooks';
import { GetListings, GetListingsVariables } from '../../../../graphql/types/GetListings';
import {
  anyItem,
  AnyItem,
  Availability,
  AvailabilityItem,
  availabilityItems,
  CityItem,
  getResponsivePlaceholderItem,
  ListingTypeItem,
  listingTypeItems,
  RoomItem,
  SortingItem,
  sortingItems
} from './listings-list.filter-types';
import _ from 'lodash';
import ListingsListFilter from './listings-list-filter/listings-list-filter';
import { GlobalState } from '../../../../redux/store';
import {
  ListingsListFilterState,
  resetListingsFilter,
  setAvailableFrom,
  setAvailableFromLatest,
  setDistricts,
  setImmediatelyAvailable,
  setIsNewFilterConfiguration,
  setRentFromFilter,
  setRentToFilter,
  setSquareMeterFromFilter,
  setSquareMeterToFilter
} from './listings-list-filter/listings-list-filter.state';
import { dateForAvailability, getAvailabilityFromDate } from './listings-list.utils';
import {
  SearchRequestsFormDataState,
  setShowResultToast
} from '../../search-requests/search-request/search-request.state';
import { toast } from 'react-toastify';
import { GET_LISTINGS, GET_NUMBER_OF_LISTINGS } from '../../../../graphql/queries/listings';
import { GetNumberOfListings, GetNumberOfListingsVariables } from '../../../../graphql/types/GetNumberOfListings';
import { ListingsListActionBar } from './listing-list-action-bar/listings-list-action-bar';
import { useDropdownItemsFromEnumsOrConstants, useTumLocations } from '../listing/listing.hooks';
import { useUserPermission } from '../../../../utils/hooks/user-data';
import LeafletMap from '../../../controls/map/map';
import {
  ListingCardContainer,
  ListingCardContainerSkeleton
} from '../../../building-blocks/listing-card-container/listing-card-container';
import { Param } from '../../../../utils/constants';
import { Point } from 'leaflet';
import classNames from 'classnames';
import {
  useCurrentBreakpoint,
  useCustomBreakpoints,
  useResponsiveBreakpoints
} from '../../../../utils/hooks/responsive.hooks';
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filter.svg';
import { customListingsListBreakpointsDefinition, getFilterCount } from './listings-list.responsiveness';
import { GetFavorites, GetFavoritesVariables } from '../../../../graphql/types/GetFavorites';
import { GET_FAVORITES } from '../../../../graphql/queries/favorites';
import { UserState } from '../../../../redux/user.state';
import { ReactComponent as BookmarkAdd } from '../../../../assets/svg/bookmark_add.svg';
import { ConfirmationDialog } from '../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { usePaginationParam, useResetPagination } from '../../../../utils/hooks/pagination';
import Pagination from '../../../controls/pagination/pagination';

export enum ListingsViewMode {
  LIST = 'LIST',
  TABLE = 'TABLE',
  MAP = 'MAP'
}

const ListingsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apolloClient = useApolloClient();
  const [params, setParams] = useUrlSearchParams({}, {});
  const { state } = useLocation();
  const [localViewMode, setLocalViewMode] = useState<ListingsViewMode>();
  const [openSearchRequestSaveConfirmation, setOpenSearchRequestSaveConfirmation] = useState<boolean>(false);

  const filterState = useSelector<GlobalState, ListingsListFilterState>((state) => state.listingsListFilter);
  const searchRequestState = useSelector<GlobalState, SearchRequestsFormDataState>(
    (state) => state.searchRequestsFormData
  );
  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const permissions = useUserPermission();
  const { hasAdministrativeFunction, isSeeker } = permissions;

  const breakpoints = useResponsiveBreakpoints();
  const customBreakPoints = useCustomBreakpoints(customListingsListBreakpointsDefinition);
  const currentBreakpoint = useCurrentBreakpoint();
  const [currentSize, setCurrentSize] = useState<string | null>(null);

  const [sorting, setSorting] = useState<SortingItem>(sortingItems[0]);
  const resultLimitList = Number(process.env.REACT_APP_PAGINATION_LISTING_LIST_MAX);
  const resultLimitTable = Number(process.env.REACT_APP_PAGINATION_MAX);
  const { pageOffset, handlePageOffsetChange, resultLimit, handleResultLimitChange } = usePaginationParam(
    localViewMode === ListingsViewMode.LIST ? resultLimitList : resultLimitTable
  );

  // Filter labels depending on screen size
  const initialCityLabel = getResponsivePlaceholderItem('views.listings.filter.place', breakpoints.isDesktop);
  const initialListingTypeLabel = getResponsivePlaceholderItem(
    'views.listings.filter.type',
    breakpoints.isDesktop || breakpoints.isMobile
  );
  const initialRoomLabel = getResponsivePlaceholderItem(
    'views.listings.filter.rooms',
    breakpoints.isDesktop || breakpoints.isMobile
  );

  // Local filter state
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [areFilterLoadedFromParams, setAreFilterLoadedFromParams] = useState(false);
  const [tumLocationFilter, setTumLocationFilter] = useState<CityItem | AnyItem>(initialCityLabel);
  const [forceFilterReRender, setForceFilterReRender] = useState(-1);
  const listingTypeFilterState = useState<ListingTypeItem | AnyItem>(initialListingTypeLabel);
  const [listingTypeFilter, setListingTypeFilter] = listingTypeFilterState;
  const availabilityFilterState = useState<AvailabilityItem | AnyItem>(anyItem);
  const [availabilityFilter, setAvailabilityFilter] = availabilityFilterState;
  const roomFilterState = useState<RoomItem | AnyItem>(initialRoomLabel);
  const [roomFilter, setRoomFilter] = roomFilterState;

  const { numberOfRoomsItems, districtItems, tagsForCategories, cityItems } = useDropdownItemsFromEnumsOrConstants(
    (tumLocationFilter?.id as TUMLocation) || null
  );
  const tags = useTagsFromState(districtItems, tagsForCategories);

  // Filter query variables
  const variables: GetListingsVariables = {
    pageOffset,
    filter: {
      tumLocation: tumLocationFilter ? (tumLocationFilter?.id as TUMLocation) : null,
      type: listingTypeFilter ? listingTypeFilter?.id : null,
      numberOfRooms: roomFilter ? roomFilter.id : null,
      totalRentFrom: filterState.rentFromFilter,
      totalRentTo: filterState.rentToFilter,
      squareMeterFrom: filterState.squareMeterFromFilter,
      squareMeterTo: filterState.squareMeterToFilter,
      availableFrom: filterState.availableFrom || null,
      districts: filterState.districts.length > 0 ? filterState.districts.map((item) => item.id) : null,
      openEndedContract: filterState.openEnded,
      availableFromLatest: filterState.availableFromLatest || null,
      seekingDoctoralStudents: filterState.seekingDoctoralStudents ? true : null,
      seekingGuestResearchers: filterState.seekingGuestResearchers ? true : null,
      seekingIncomings: filterState.seekingIncomings ? true : null,
      seekingPostDoctoralStudents: filterState.seekingPostDoctoralStudents ? true : null,
      seekingProfessors: filterState.seekingProfessors ? true : null,
      seekingStudents: filterState.seekingStudents ? true : null,
      seekingTumEmployees: filterState.seekingTumEmployees ? true : null,
      activationStatus: filterState.activationStatus,
      verificationStatus: filterState.verificationStatus,
      depositMax: filterState.deposit,
      isListingPublic: filterState.isListingPublic,
      landlordIsManagement: filterState.landlordIsManagement || null,
      tags: filterState.tags
    },
    orderBy: sorting ? sorting.id : ListingSortOrder.MOST_RECENT,
    resultLimit
  };

  // Fetch listings
  const [fetchListings, { data, loading }] = useLazyQuery<GetListings, GetListingsVariables>(GET_LISTINGS, {
    variables,
    fetchPolicy: 'no-cache'
  });
  // Fetch number of listings
  const [fetchNumberOfListings, { data: dataNumberOfListings, loading: loadingNumberOfListings }] = useLazyQuery<
    GetNumberOfListings,
    GetNumberOfListingsVariables
  >(GET_NUMBER_OF_LISTINGS, {
    variables,
    fetchPolicy: 'no-cache'
  });

  // Fetch favorites
  const [fetchFavorites, { loading: favoriteLoading, data: favoriteData, refetch: favoriteRefetch }] = useLazyQuery<
    GetFavorites,
    GetFavoritesVariables
  >(GET_FAVORITES, {
    fetchPolicy: 'no-cache'
  });

  useResetPagination(
    // reset pagination when in map mode or when the number of results is smaller than result limit times page offset
    (localViewMode !== ListingsViewMode.MAP &&
      dataNumberOfListings?.numberOfListings &&
      variables.resultLimit &&
      dataNumberOfListings.numberOfListings > variables.resultLimit * pageOffset) ||
      dataNumberOfListings === undefined
  );

  // Get favorites only for seekers
  useEffect(() => {
    if (!favoriteLoading && userState.user?.email && !favoriteData && permissions.isSeeker) {
      fetchFavorites({ variables: { emailAddress: userState.user.email } });
    }
  }, [favoriteData, favoriteLoading]);

  const tumPoi = useTumLocations();
  const [markers, setMarkers] = useState<Array<{ uuid: string; coordinates: Point }>>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const viewModeFromRouterState = state && state.listingsViewMode;

    const finalViewMode: ListingsViewMode = params[Param.VIEW_MODE]
      ? params[Param.VIEW_MODE] === 'table'
        ? ListingsViewMode.TABLE
        : params[Param.VIEW_MODE] === 'map'
        ? ListingsViewMode.MAP
        : ListingsViewMode.LIST
      : viewModeFromRouterState || (hasAdministrativeFunction ? ListingsViewMode.TABLE : ListingsViewMode.LIST);

    setLocalViewMode(finalViewMode);
    if (!params[Param.VIEW_MODE]) {
      setParams({
        [Param.VIEW_MODE]: finalViewMode.toLowerCase()
      });
    }
  }, [params, state]);

  useEffect(() => {
    // Re-render labels if device size changed
    if (!currentSize || currentBreakpoint !== currentSize) {
      setTumLocationFilter(initialCityLabel);
      setListingTypeFilter(initialListingTypeLabel);
      setRoomFilter(initialRoomLabel);
      setForceFilterReRender(-1);
      setCurrentSize(currentBreakpoint);
      setTimeout(() => {
        setForceFilterReRender(1);
      });
    }
  }, [currentBreakpoint]);

  const resetFilter = useCallback((): void => {
    dispatch(resetListingsFilter());
    dispatch(setSquareMeterToFilter(null));
    dispatch(setSquareMeterFromFilter(null));
    dispatch(setRentToFilter(null));
    dispatch(setRentFromFilter(null));
    setAvailabilityFilter(anyItem);
    setRoomFilter(getResponsivePlaceholderItem('views.listings.filter.rooms', breakpoints.isDesktop));
    setTumLocationFilter(getResponsivePlaceholderItem('views.listings.filter.place', breakpoints.isDesktop));
    setListingTypeFilter(getResponsivePlaceholderItem('views.listings.filter.type', breakpoints.isDesktop));
    navigate('/listings');
    setForceFilterReRender(-1);
    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, [setForceFilterReRender, breakpoints.isDesktop]);

  // Handles the filter input for square meters
  const handleSquareMeterInput = useCallback(
    _.debounce((range) => {
      dispatch(setSquareMeterFromFilter(range.start !== undefined ? range.start : null));
      dispatch(setSquareMeterToFilter(range.end !== undefined ? range.end : null));
      setParams({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [Param.SQUARE_FROM]: range.start !== undefined && range.start !== 0 ? range.start : undefined,
        [Param.SQUARE_TO]: range.end !== undefined ? range.end : undefined
      });
    }, 1000),
    [fetch]
  );

  // Handles the filter input for the total rent
  const handleRentInput = useCallback(
    _.debounce((range) => {
      dispatch(setRentFromFilter(range.start !== undefined ? range.start : null));
      dispatch(setRentToFilter(range.end !== undefined ? range.end : null));
      setParams({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [Param.RENT_FROM]: range.start !== undefined && range.start !== 0 ? range.start : undefined,
        [Param.RENT_TO]: range.end !== undefined ? range.end : undefined
      });
    }, 1000),
    [fetch]
  );

  // Load filter from url initially
  useEffect(() => {
    // Show only active listings initially
    if (!params[Param.ACTIVATED] && hasAdministrativeFunction) {
      setParams({ [Param.ACTIVATED]: 1 });
    }
    if (params[Param.TUM_LOCATION]) {
      setTumLocationFilter(cityItems.find((item) => item.id === params[Param.TUM_LOCATION]) || anyItem);
    }
    if (params[Param.TYPE]) {
      setListingTypeFilter(listingTypeItems.find((item) => item.id === params[Param.TYPE]) || anyItem);
    }
    if (params[Param.ROOMS]) {
      setRoomFilter(numberOfRoomsItems.find((item) => item.id === Number(params[Param.ROOMS])) || anyItem);
    }
    if (params[Param.AVAILABLE_FROM]) {
      dispatch(setAvailableFrom(String(params[Param.AVAILABLE_FROM])));
      const availability = getAvailabilityFromDate(String(params[Param.AVAILABLE_FROM]));
      setAvailabilityFilter(availabilityItems.find((item) => item.id === availability) || anyItem);
    }
    if (params[Param.AVAILABLE_LATEST]) {
      dispatch(setAvailableFromLatest(String(params[Param.AVAILABLE_LATEST])));
      const availability = getAvailabilityFromDate(String(params[Param.AVAILABLE_LATEST]));
      setAvailabilityFilter(availabilityItems.find((item) => item.id === availability) || anyItem);
    }
    if (params[Param.SQUARE_FROM]) {
      dispatch(setSquareMeterFromFilter(Number(params[Param.SQUARE_FROM])));
    }
    if (params[Param.SQUARE_TO]) {
      dispatch(setSquareMeterToFilter(Number(params[Param.SQUARE_TO])));
    }
    if (params[Param.RENT_FROM]) {
      dispatch(setRentFromFilter(Number(params[Param.RENT_FROM])));
    }
    if (params[Param.RENT_TO]) {
      dispatch(setRentToFilter(Number(params[Param.RENT_TO])));
    }

    // Reset cache and load listings
    apolloClient.cache.reset().then(() => {
      fetchListings();
      fetchNumberOfListings();
    });
    setForceFilterReRender(-1);
    setAreFilterLoadedFromParams(true);
    // Render components with new initial values
    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, [t]);

  // Show notification if someone searched listings from a search request
  useEffect(() => {
    if (!loadingNumberOfListings && dataNumberOfListings && searchRequestState.showResultToast) {
      const user = searchRequestState.firstName + ' ' + searchRequestState.lastName;
      toast(
        () => (
          <ToastNotification
            kind="info"
            title={t('notifications.listingsFound', { count: dataNumberOfListings.numberOfListings }) as string}
            subtitle={
              <span className="toast-link">
                {`${t('notifications.listingsFoundForSearchRequest')} | `}
                <Link href={`search-requests/${searchRequestState.uuid}/view`}>{user}</Link>
              </span>
            }
            caption=""
            style={{ minWidth: '30rem' }}
          />
        ),
        { autoClose: false, closeButton: false }
      );
      dispatch(setShowResultToast(false));
    }
  }, [loadingNumberOfListings, dataNumberOfListings]);

  // Render filter again if state changed (otherwise the default value won't change)
  // NOTE: This effect needs to run after the filter have been read out from the url parameters,
  //       as it is in conflict on first render.
  useEffect(() => {
    if (areFilterLoadedFromParams) {
      setAvailabilityFilter(
        filterState.immediatelyAvailable
          ? availabilityItems[0]
          : availabilityFilter === availabilityItems[0]
          ? anyItem
          : availabilityFilter
      );
      // Only re-render if sidebar value changed
      if (sideBarOpen) {
        setForceFilterReRender(-1);
        setTimeout(() => {
          setForceFilterReRender(1);
        });
      }
    }
  }, [filterState.immediatelyAvailable, areFilterLoadedFromParams, availabilityFilter]);

  // Render filter again if state changed (otherwise the default value won't change)
  // NOTE: This effect needs to run after the filter have been read out from the url parameters,
  //       as it is in conflict on first render.
  useEffect(() => {
    if (areFilterLoadedFromParams) {
      if (filterState.availableFrom === null) {
        setAvailabilityFilter(anyItem);
        setForceFilterReRender(-1);
        setTimeout(() => {
          setForceFilterReRender(1);
        });
      }
    }
  }, [filterState.availableFrom, areFilterLoadedFromParams]);

  const getItemLabel = useCallback((item) => {
    return item.id === null ? `${t(item.text)}` : item.text;
  }, []);

  const postNewSeekerSearchRequest = usePostNewSeekerSearchRequest(
    filterState,
    tumLocationFilter.id as TUMLocation,
    roomFilter.id,
    listingTypeFilter.id
  );

  useEffect(() => {
    if (!loading && data?.listings) {
      const marker: Array<{ uuid: string; coordinates: Point }> = [];
      const listingsWithCoordinates = data.listings.filter((listing) => listing?.coordinates !== null);
      listingsWithCoordinates.forEach((listing) => {
        if (listing?.coordinates) {
          marker.push({ uuid: listing.uuid, coordinates: new Point(listing?.coordinates.x, listing?.coordinates.y) });
        }
      });
      setMarkers(marker);
    }
  }, [data?.listings]);

  // Returns the tum location to center the map to the selected district if none marker is found
  const centerToTumLocation = (): Point | undefined => {
    if (tumPoi.length > 0 && markers.length === 0) {
      const tum = tumPoi.filter((poi) => poi.address === tumLocationFilter.id);
      return tum && tum[0] ? tum[0].coordinates : undefined;
    }
  };

  const renderView = (): ReactNode | undefined => {
    if (localViewMode === ListingsViewMode.TABLE) {
      return loading ? <DataTableSkeleton showHeader={false} showToolbar={false} /> : <ListingsListTable data={data} />;
    } else if (localViewMode === ListingsViewMode.LIST) {
      return data && !loading ? (
        permissions.isSeeker && favoriteData?.favorites ? (
          <ListingCardContainer
            favorable={true}
            favorites={favoriteData.favorites.map((favorite) => {
              return favorite.uuid;
            })}
            refetch={favoriteRefetch}
            listings={data.listings}
            editable={false}
            headingLevel={'h2'}
          />
        ) : (
          <ListingCardContainer listings={data.listings} editable={false} headingLevel={'h2'} />
        )
      ) : (
        <ListingCardContainerSkeleton count={4} />
      );
    } else if (localViewMode === ListingsViewMode.MAP) {
      return permissions.isSeeker && favoriteData?.favorites ? (
        <LeafletMap
          zoom={10}
          listings={markers}
          poi={tumPoi}
          className={styles.Map}
          centerToPoint={centerToTumLocation()}
          favorable={true}
          favorites={favoriteData.favorites.map((favorite) => {
            return favorite.uuid;
          })}
          refetch={favoriteRefetch}
        />
      ) : (
        <LeafletMap
          zoom={10}
          listings={markers}
          poi={tumPoi}
          className={styles.Map}
          centerToPoint={centerToTumLocation()}
        />
      );
    }
  };

  return (
    <div>
      <FilterContainer
        counter={dataNumberOfListings?.numberOfListings || 0}
        headline={t('views.listings.listings') + (tumLocationFilter.id !== null ? ` in ${tumLocationFilter.text}` : '')}
        className={styles.ListingsListFilterContainer}
        onResetClick={() => resetFilter()}
      >
        {!breakpoints.isMobile && isSeeker && (
          <Button
            className={styles.SaveButton}
            kind={'secondary'}
            size="field"
            onClick={() => {
              postNewSeekerSearchRequest().then(() => {
                setOpenSearchRequestSaveConfirmation(true);
              });
              dispatch(setIsNewFilterConfiguration(false));
            }}
          >
            {t('actions.saveSearch')}
            <BookmarkAdd aria-hidden={true} />
          </Button>
        )}
        {/*
          Multiselect does not offer a way to control the selected items. Therefore we
          need to forcefully rerender the filters after a reset button click
        */}
        {forceFilterReRender > -1 ? (
          <>
            <Dropdown
              titleText={`${t('views.listings.filter.place')}`}
              label={tumLocationFilter.id === null ? `${t(tumLocationFilter.text)}` : tumLocationFilter.text}
              id={'place'}
              onChange={({ selectedItem }: { selectedItem: CityItem }) => {
                setTumLocationFilter(selectedItem);
                dispatch(setDistricts([])); // reset district filter state
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                setParams({
                  // @ts-ignore
                  [Param.TUM_LOCATION]: selectedItem.id !== null ? selectedItem.id : undefined,
                  // @ts-ignore
                  [Param.DISTRICTS]: undefined
                });
                dispatch(setIsNewFilterConfiguration(true));
                /* eslint-enable @typescript-eslint/ban-ts-comment */
              }}
              initialSelectedItem={tumLocationFilter}
              items={[anyItem, ...cityItems]}
              itemToString={getItemLabel}
            />
            {!breakpoints.isMobile && (
              <Dropdown
                titleText={`${t('views.listings.filter.type')}`}
                label={`${t(listingTypeFilter.text)}`}
                id={'type'}
                onChange={({ selectedItem }: { selectedItem: ListingTypeItem }) => {
                  setListingTypeFilter(selectedItem);
                  dispatch(setIsNewFilterConfiguration(true));
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.TYPE]: selectedItem.id !== null ? selectedItem.id : undefined });
                }}
                items={[anyItem, ...listingTypeItems]}
                itemToString={(item) => `${t(item.text)}`}
                initialSelectedItem={listingTypeFilter}
              />
            )}
            {breakpoints.isDesktop && (
              <Dropdown
                titleText={`${t('views.listings.filter.availableBy')}`}
                label={`${t('views.listings.filter.availableBy')}`}
                id={'availableBy'}
                onChange={({ selectedItem }: { selectedItem: AvailabilityItem | AnyItem }) => {
                  setAvailabilityFilter(selectedItem);
                  if (selectedItem.id === null) {
                    dispatch(setAvailableFrom(null));
                    dispatch(setAvailableFromLatest(null));
                    if (filterState.immediatelyAvailable) {
                      dispatch(setImmediatelyAvailable(false));
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setParams({ [Param.AVAILABLE_FROM]: undefined, [Param.AVAILABLE_LATEST]: undefined });
                  } else {
                    const date = dateForAvailability(selectedItem.id).toISOString();
                    if (selectedItem.id === Availability.NOW) {
                      dispatch(setAvailableFrom(null));
                      dispatch(setAvailableFromLatest(date));
                      dispatch(setImmediatelyAvailable(true));
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setParams({ [Param.AVAILABLE_FROM]: undefined, [Param.AVAILABLE_LATEST]: date });
                    } else {
                      dispatch(setAvailableFromLatest(null));
                      dispatch(setAvailableFrom(date));
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setParams({ [Param.AVAILABLE_FROM]: date, [Param.AVAILABLE_LATEST]: undefined });
                    }
                  }
                }}
                items={[anyItem, ...availabilityItems]}
                itemToString={(item) => `${t(item.text)}`}
                initialSelectedItem={availabilityFilter}
              />
            )}
            {!breakpoints.isMobile && (
              <Dropdown
                titleText={`${t('views.listings.filter.roomCount')}`}
                label={roomFilter.id === null ? `${t(roomFilter.text)}` : roomFilter.text}
                id={'roomCount'}
                onChange={({ selectedItem }: { selectedItem: RoomItem }) => {
                  setRoomFilter(selectedItem);
                  dispatch(setIsNewFilterConfiguration(true));
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.ROOMS]: selectedItem.id !== null ? selectedItem.id : undefined });
                }}
                items={[anyItem, ...numberOfRoomsItems]}
                itemToString={getItemLabel}
                initialSelectedItem={roomFilter}
              />
            )}
            {!customBreakPoints.isTabletSmall && (
              <>
                <RangeFilter
                  id={'space'}
                  label={`${t('views.listings.filter.space')}`}
                  kind="area"
                  onChange={handleSquareMeterInput}
                  min={filterState.squareMeterFromFilter || undefined}
                  max={filterState.squareMeterToFilter || undefined}
                  showLabelAsPlaceholder={!breakpoints.isDesktop}
                  alignContent={breakpoints.isDesktop ? 'left' : 'right'}
                />
                <RangeFilter
                  id={'rent'}
                  label={`${t('views.listings.filter.rent')}`}
                  kind="currency"
                  onChange={handleRentInput}
                  min={filterState.rentFromFilter || undefined}
                  max={filterState.rentToFilter || undefined}
                  showLabelAsPlaceholder={!breakpoints.isDesktop}
                  alignContent={breakpoints.isDesktop ? 'left' : 'right'}
                />
              </>
            )}
          </>
        ) : (
          <>
            {Array.from(new Array(getFilterCount(breakpoints, customBreakPoints.isTabletSmall))).map((value, index) => (
              <TextInputSkeleton key={index} className={styles.FilterPlaceholder} hideLabel={!breakpoints.isDesktop} />
            ))}
          </>
        )}
        {breakpoints.isDesktop ? (
          <Button size="field" onClick={() => setSideBarOpen(true)}>
            {t('views.listings.filter.moreFilters')}
          </Button>
        ) : (
          <Button
            kind="secondary"
            size="field"
            onClick={() => setSideBarOpen(true)}
            renderIcon={FilterIcon}
            iconDescription={t('views.listings.filter.moreFilters')}
            hasIconOnly
            tooltipAlignment="end"
          />
        )}
        <FilterTagContainer>{tags}</FilterTagContainer>
      </FilterContainer>

      <SideBar
        open={sideBarOpen}
        title={t('views.listings.filter.additionalFilters')}
        onClose={() => setSideBarOpen(false)}
      >
        <ListingsListFilter
          tumLocation={(tumLocationFilter?.id as TUMLocation) || null}
          isOpen={sideBarOpen}
          availabilityFilterState={availabilityFilterState}
          roomFilterState={roomFilterState}
          listingTypeFilterState={listingTypeFilterState}
          numberOfRoomsItems={numberOfRoomsItems}
          listingTypeItems={listingTypeItems}
          handleRentInput={handleRentInput}
          handleSquareMeterInput={handleSquareMeterInput}
          setOpenSearchRequestSaveConfirmation={setOpenSearchRequestSaveConfirmation}
          setSideBarOpen={setSideBarOpen}
        />
      </SideBar>

      <ListingsListActionBar variables={variables} />

      <div className={classNames(styles.Content, 'global-content-wrapper-block')}>
        <div
          className={classNames(styles.TableActions, {
            [styles.TableActions__Map]: localViewMode === ListingsViewMode.MAP
          })}
        >
          {localViewMode !== ListingsViewMode.MAP && (
            <Dropdown
              id="sort"
              type="inline"
              titleText={t('sorting.sortBy') as string}
              label={`${t(sorting ? sorting.text : sortingItems[0].text)}`}
              items={
                hasAdministrativeFunction
                  ? sortingItems
                  : sortingItems.filter((item) => item.id !== ListingSortOrder.LAST_MODIFIED)
              }
              defaultValue={sortingItems[0].id}
              itemToString={(item) => (item ? t(item.text) : '')}
              onChange={({ selectedItem }) => selectedItem && setSorting(selectedItem)}
            />
          )}

          <div className={styles.ViewModeSwitch} role="tablist">
            {hasAdministrativeFunction && (
              <Button
                role="tab"
                size="field"
                kind={localViewMode === ListingsViewMode.TABLE ? 'primary' : 'secondary'}
                onClick={() => {
                  setParams({ [Param.VIEW_MODE]: ListingsViewMode.TABLE.toLowerCase() });
                  handleResultLimitChange(resultLimitTable);
                }}
                iconDescription={!breakpoints.isDesktop && t('actions.showAsTable')}
                hasIconOnly={!breakpoints.isDesktop}
              >
                <ListSVGIcon title={t('actions.showAsTable')} /> <span>{t('actions.table')}</span>
              </Button>
            )}
            <Button
              size="field"
              role="tab"
              kind={localViewMode === ListingsViewMode.LIST ? 'primary' : 'secondary'}
              onClick={() => {
                setParams({
                  [Param.VIEW_MODE]: ListingsViewMode.LIST.toLowerCase()
                });
                handleResultLimitChange(resultLimitList);
              }}
              iconDescription={!breakpoints.isDesktop && t('actions.showAsCardList')}
              hasIconOnly={!breakpoints.isDesktop}
            >
              <ViewStreamSVGIcon title={t('actions.showAsCardList')} /> <span>{t('actions.list')}</span>
            </Button>
            <Button
              size="field"
              role="tab"
              kind={localViewMode === ListingsViewMode.MAP ? 'primary' : 'secondary'}
              onClick={() =>
                setParams({ [Param.VIEW_MODE]: ListingsViewMode.MAP.toLowerCase(), [Param.RESULT_LIMIT]: '' })
              }
              iconDescription={!breakpoints.isDesktop && t('actions.showMap')}
              hasIconOnly={!breakpoints.isDesktop}
            >
              <MapSVGIcon title={t('actions.showMap')} /> <span>{t('actions.map')}</span>
            </Button>
          </div>
        </div>

        {renderView()}

        {!loading &&
          localViewMode !== ListingsViewMode.MAP &&
          data?.listings &&
          dataNumberOfListings &&
          variables.resultLimit && (
            <div className={styles.Pagination}>
              <Pagination
                totalItems={Math.ceil(dataNumberOfListings.numberOfListings / variables.resultLimit)}
                page={pageOffset}
                hideStepper={dataNumberOfListings.numberOfListings <= variables.resultLimit}
                resultLimit={resultLimit}
                onChange={handlePageOffsetChange}
                onResultLimitChange={(newLimit) => handleResultLimitChange(newLimit)}
              />
            </div>
          )}

        {permissions.isSeeker && openSearchRequestSaveConfirmation && (
          <ConfirmationDialog
            open={openSearchRequestSaveConfirmation}
            title={`${t('views.listings.modalConfirmSeekerSearchRequestSave.title')}`}
            headline={`${t('views.listings.modalConfirmSeekerSearchRequestSave.headline')}`}
            text={`${t('views.listings.modalConfirmSeekerSearchRequestSave.text')}`}
            primaryActionLabel={t('views.listings.modalConfirmSeekerSearchRequestSave.primaryActionLabel')}
            secondaryActionLabel={t('views.listings.modalConfirmSeekerSearchRequestSave.secondaryActionLabel')}
            onPrimaryActionClick={() => {
              setOpenSearchRequestSaveConfirmation(false);
              navigate('/seeker', { state: { linkTo: '#searchrequests' } });
            }}
            onSecondaryActionClick={() => {
              setOpenSearchRequestSaveConfirmation(false);
            }}
            onCloseClick={() => {
              setOpenSearchRequestSaveConfirmation(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListingsList;
