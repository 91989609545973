import { FC } from 'react';
import { Button, Dropdown } from 'carbon-components-react';
import { ReactComponent as ArrowLeftSvg } from '../../../assets/svg/arrow_left.svg';
import { ReactComponent as ArrowRightSvg } from '../../../assets/svg/arrow_right.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './pagination.module.scss';

export interface PaginationProps {
  /**
   * Total number of pages.
   */
  totalItems: number;

  /**
   * Current page index (starting from 0, will be displayed as starting from 1)
   */
  page?: number;

  /**
   * Currently selected resultLimit (displayed as selected value in the dropdown)
   */
  resultLimit?: number;

  /**
   * If the stepper (next/previous page) buttons should be hidden.
   */
  hideStepper?: boolean;

  /**
   * User pressed next or previous page buttons.
   * @param page The new page number.
   */
  onChange?(page: number): void;

  /**
   * User selected a different result limit from the dropdown.
   * @param resultLimit The selected number of results.
   */
  onResultLimitChange?(resultLimit: number): void;
}

export const Pagination: FC<PaginationProps> = ({
  totalItems,
  page = 0,
  hideStepper = false,
  resultLimit,
  onChange,
  onResultLimitChange
}) => {
  const { t } = useTranslation();

  const paginationOptions = [
    { id: 10, text: '10' },
    { id: 20, text: '20' },
    { id: 50, text: '50' },
    { id: 100, text: '100' }
  ];

  return (
    <div className={classNames(styles.Pagination)}>
      <div className={classNames(styles.PaginationSettings)}>
        <Dropdown
          className={'SmallFlyout'}
          id="pagination-result-limit"
          type="inline"
          titleText={t('pagination.resultLimitDropdownLabel') as string}
          label={`${resultLimit}`}
          items={paginationOptions}
          defaultValue={resultLimit}
          selectedItem={paginationOptions.find((item) => item.id === resultLimit)}
          itemToString={(item) => item.text}
          onChange={({ selectedItem }) => {
            if (selectedItem) {
              onResultLimitChange?.(selectedItem.id);
            }
          }}
        />
      </div>

      {!hideStepper && (
        <nav className={classNames(styles.PaginationStepper)} data-testid="PaginationStepper">
          <Button
            kind="ghost"
            data-testid="PaginationPreviousPage"
            renderIcon={ArrowLeftSvg}
            hasIconOnly
            disabled={page - 1 < 0}
            size="sm"
            iconDescription={t('actions.previousPage')}
            onClick={() => {
              onChange?.(Math.max(0, page - 1));
            }}
          ></Button>
          <span>
            <span className={'sr-only'}>{t('pagination.item')}</span>
            <span className={classNames(styles.PaginationCurrentPage)} data-testid="PaginationCurrentPage">
              {page + 1}
            </span>{' '}
            / <span className={'sr-only'}>{t('pagination.of')}</span>
            <span className={classNames(styles.PaginationMaxPage)}>{totalItems}</span>
          </span>
          <Button
            kind="ghost"
            data-testid="PaginationNextPage"
            renderIcon={ArrowRightSvg}
            disabled={page + 1 >= totalItems}
            hasIconOnly
            size="sm"
            iconDescription={t('actions.nextPage')}
            onClick={() => {
              onChange?.(Math.min(totalItems - 1, page + 1));
            }}
          ></Button>
        </nav>
      )}
    </div>
  );
};

export default Pagination;
