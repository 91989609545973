import { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { Button, Form, Modal, TextInput } from 'carbon-components-react';
import styles from './reset-password-dialog.module.scss';
import classNames from 'classnames';
import { useUrlSearchParams } from 'use-url-search-params';
import { useMutation } from '@apollo/client';
import { POST_NEW_PASSWORD_AFTER_RESET } from '../../../graphql/queries/user-auth';
import { useQueryResultNotificationError } from '../../../utils/hooks/query-result-notification';
import {
  PostNewPasswordAfterReset,
  PostNewPasswordAfterResetVariables
} from '../../../graphql/types/PostNewPasswordAfterReset';
import { RequiredFormItem } from '../../building-blocks/content-view/content-view';
import * as Yup from 'yup';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { Param } from '../../../utils/constants';

const changePasswordValidationSchema = Yup.object().shape(
  {
    password: Yup.string().typeError('errors.required').required('errors.required').min(8, 'errors.passwordMin'),
    passwordConfirm: Yup.string().oneOf([Yup.ref('password'), null], 'errors.passwordNotEqual')
  },
  []
);

export const ResetPasswordDialog: FC = () => {
  enum View {
    Reset,
    Success
  }
  const { t } = useTranslation();
  const [view, setView] = useState<View>(View.Reset);
  const [params, setParams] = useUrlSearchParams({}, {});
  const [token, setToken] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [open, setOpen] = useState<boolean>(false);

  const resetForm = useCallback(() => {
    setPassword('');
    setPasswordConfirm('');
  }, [setPassword, setPasswordConfirm]);

  const { getValidationPropsForField, validateState } = useFormValidation(
    changePasswordValidationSchema,
    {
      password,
      passwordConfirm
    },
    {
      showToasts: false,
      scrollToInvalidField: false
    }
  );

  const [newPassword, { error: newPasswordError }] = useMutation<
    PostNewPasswordAfterReset,
    PostNewPasswordAfterResetVariables
  >(POST_NEW_PASSWORD_AFTER_RESET, { variables: { password, token } });

  const error = useQueryResultNotificationError(newPasswordError);

  useEffect(() => {
    if (params[Param.RESET_PASSWORD] && params[Param.RESET_PASSWORD].toString().length > 0) {
      resetForm();
      setOpen(true);
      setToken(params[Param.RESET_PASSWORD].toString());
    }
  }, [params[Param.RESET_PASSWORD]]);

  // Form submit to reset password
  const onReset = (event: FormEvent): void => {
    event.preventDefault();
    validateState(() => {
      newPassword()
        .then(() => {
          setView(View.Success);
        })
        .catch(() => ({}));
    });
  };

  // Close dialog and remove confirm param from url
  const onClose = (): void => {
    setOpen(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setParams({ [Param.RESET_PASSWORD]: undefined });
    resetForm();
  };

  return !open ? (
    <></>
  ) : (
    createPortal(
      <Modal
        className={styles.ResetPasswordDialog}
        passiveModal={true}
        size={'sm'}
        open={true}
        onRequestClose={onClose}
        modalLabel={t('header.modalResetPassword.modalHeadline')}
        selectorPrimaryFocus={'.bx--modal-content'}
        iconDescription={t('actions.close')}
        preventCloseOnClickOutside={true}
      >
        {/* Dialog content to reset password */}
        {view === View.Reset && (
          <>
            <Form id="password-change" className={styles.content} onSubmit={onReset}>
              <RequiredFormItem isEditMode>
                <TextInput
                  id="password"
                  type="password"
                  labelText={t('formFields.newPassword') as string}
                  placeholder={t('formFields.placeholder.newPassword') as string}
                  value={password}
                  {...getValidationPropsForField('password', (event) => {
                    setPassword(event.target.value);
                  })}
                />
              </RequiredFormItem>
              <RequiredFormItem isEditMode>
                <TextInput
                  id="passwordConfirm"
                  type="password"
                  labelText={t('formFields.newPasswordConfirm') as string}
                  placeholder={t('formFields.placeholder.newPassword') as string}
                  value={passwordConfirm}
                  {...getValidationPropsForField('passwordConfirm', (event) => {
                    setPasswordConfirm(event.target.value);
                  })}
                />
              </RequiredFormItem>
              {error && <div>{error}</div>}
            </Form>
            <div className={classNames('bx--modal-custom-footer')}>
              <Button type="submit" form="password-change">
                {t('actions.changePassword')}
              </Button>
            </div>
          </>
        )}
        {/* Dialog content when password was reset successfully */}
        {view === View.Success && (
          <>
            <div className={classNames(styles.content, styles.SuccessView)}>
              <p>{t('header.modalResetPassword.successContent')}</p>
            </div>
            <div className={classNames('bx--modal-custom-footer', styles.alignLeft)}>
              <Button kind={'secondary'} onClick={onClose}>
                {t('actions.close')}
              </Button>
            </div>
          </>
        )}
      </Modal>,
      document.body
    )
  );
};

export default ResetPasswordDialog;
