import { useQuery } from '@apollo/client';
import { ComboBox, ComboBoxProps } from 'carbon-components-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_LANDLORDS_FOR_SEARCH } from '../../../../../../graphql/queries/landlord';
import {
  GetLandlordsForSearch,
  GetLandlordsForSearchVariables,
  GetLandlordsForSearch_landlords
} from '../../../../../../graphql/types/GetLandlordsForSearch';
import _ from 'lodash';
import { LandlordSortOrder } from '../../../../../../graphql/types/globalTypes';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../../../../redux/store';
import { ListingFormDataState } from '../../listing.state';
import { GetListingByUUID_listingByUUID_landlord } from '../../../../../../graphql/types/GetListingByUUID';

export interface LandLordSearchProps {
  onSelect?: (landlordId: GetListingByUUID_listingByUUID_landlord | null) => void;
  disabled?: boolean;
}

type ValidationProps = Pick<ComboBoxProps, 'invalid' | 'invalidText' | 'onBlur'>;

export const LandLordSearch: FC<LandLordSearchProps & ValidationProps> = ({
  disabled,
  onSelect,
  invalid,
  invalidText
}) => {
  const { t } = useTranslation();

  const { landlord } = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);

  // initially fetch first 25 landlords until user inputs a search string
  const { refetch, data } = useQuery<GetLandlordsForSearch, GetLandlordsForSearchVariables>(GET_LANDLORDS_FOR_SEARCH, {
    variables: { resultLimit: 25, orderBy: LandlordSortOrder.MOST_RECENT }
  });

  const handleInput = useCallback(
    _.debounce(
      (textInput: string) =>
        refetch({
          resultLimit: undefined,
          filter: { searchPhrase: textInput }
        }),
      300
    ),
    [fetch]
  );

  return (
    <ComboBox
      id="assignedLandLord"
      items={data?.landlords ? data.landlords : []}
      placeholder={t('views.listings.managementTypes.landlord')}
      disabled={disabled}
      onInputChange={handleInput}
      itemToString={(item: GetLandlordsForSearch_landlords | null | undefined) => {
        return item && item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : '';
      }}
      onChange={({ selectedItem }) => onSelect && onSelect(selectedItem || null)}
      selectedItem={landlord}
      tabIndex={0}
      invalid={invalid}
      invalidText={invalidText}
    />
  );
};

export default LandLordSearch;
