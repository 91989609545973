import { useCallback, useEffect, useState } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import { Param } from '../constants';

export const usePaginationParam = (
  resultLimitDefault = 10
): {
  pageOffset: number;
  resultLimit?: number;
  handlePageOffsetChange: (pageOffset: number) => void;
  handleResultLimitChange: (resultLimit: number) => void;
} => {
  const [params, setParams] = useUrlSearchParams({}, {});
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [resultLimit, setResultLimit] = useState<number>(5);

  const handlePagination = useCallback(
    (pageOffset: number) => {
      setParams({
        [Param.PAGE]: pageOffset
      });
    },
    [setParams]
  );

  const handleResultLimit = useCallback(
    (resultLimit: number) => {
      setParams({
        [Param.RESULT_LIMIT]: resultLimit
      });
    },
    [setParams]
  );

  // Load pagination from url param
  useEffect(() => {
    if (params[Param.PAGE] && !isNaN(Number(params[Param.PAGE]))) {
      const paginationFromParams = Number(params[Param.PAGE]);
      paginationFromParams !== pageOffset && setPageOffset(Number(params[Param.PAGE]));
    } else if (pageOffset !== 0) {
      setPageOffset(0);
    }

    if (params[Param.RESULT_LIMIT] && !isNaN(Number(params[Param.RESULT_LIMIT]))) {
      const resultLimitFromParams = Number(params[Param.RESULT_LIMIT]);
      resultLimitFromParams !== resultLimit && setResultLimit(Number(params[Param.RESULT_LIMIT]));
    } else if (resultLimit !== 0) {
      setResultLimit(resultLimitDefault);
    }
  }, [pageOffset, resultLimit, params]);
  return {
    pageOffset,
    resultLimit,
    handlePageOffsetChange: handlePagination,
    handleResultLimitChange: handleResultLimit
  };
};

export const useResetPagination = (keepPagination: boolean): void => {
  const [, setParams] = useUrlSearchParams({}, {});

  // Reset page offset if pagination is not kept
  useEffect(() => {
    if (!keepPagination) {
      setParams({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        [Param.PAGE]: undefined
      });
    }
  }, [keepPagination]);
};
