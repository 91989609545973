import { MutationFunctionOptions, useMutation, useQuery } from '@apollo/client';
import {
  PostLandlordRegisteredSubscription,
  PostLandlordRegisteredSubscriptionVariables
} from '../../../graphql/types/PostLandlordRegisteredSubscription';
import {
  GET_LANDLORD_REGISTERED_SUBSCRIPTION,
  GET_LISTING_LOCATION_SUBSCRIPTIONS,
  GET_LISTING_PUBLISHED_SUBSCRIPTION,
  GET_LISTING_TARGET_SUBSCRIPTIONS,
  GET_REQUEST_UPDATED_SUBSCRIPTION,
  GET_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION,
  GET_SEARCH_REQUEST_SUBSCRIPTION,
  POST_LANDLORD_REGISTERED_SUBSCRIPTION,
  POST_LISTING_PUBLISHED_SUBSCRIPTION,
  POST_REQUEST_UPDATED_SUBSCRIPTION,
  POST_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION,
  POST_SEARCH_REQUEST_SUBSCRIPTION,
  UPDATE_LISTING_LOCATION_SUBSCRIPTIONS,
  UPDATE_LISTING_TARGET_SUBSCRIPTIONS
} from '../../../graphql/queries/user-settings';
import { PostListingPublishedSubscription } from '../../../graphql/types/PostListingPublishedSubscription';
import {
  PostSearchRequestSubscription,
  PostSearchRequestSubscriptionVariables
} from '../../../graphql/types/PostSearchRequestSubscription';
import {
  PostRequestUpdatedSubscription,
  PostRequestUpdatedSubscriptionVariables
} from '../../../graphql/types/PostRequestUpdatedSubscription';
import {
  PostSearchRequestCommentsSubscription,
  PostSearchRequestCommentsSubscriptionVariables
} from '../../../graphql/types/PostSearchRequestCommentsSubscription';
import { GetLandlordRegisteredSubscription } from '../../../graphql/types/GetLandlordRegisteredSubscription';
import { GetListingPublishedSubscription } from '../../../graphql/types/GetListingPublishedSubscription';
import { GetSearchRequestSubscription } from '../../../graphql/types/GetSearchRequestSubscription';
import { GetRequestUpdatedSubscription } from '../../../graphql/types/GetRequestUpdatedSubscription';
import { GetSearchRequestCommentsSubscription } from '../../../graphql/types/GetSearchRequestCommentsSubscription';
import { useGenericQueryErrorToast } from '../../../utils/hooks/query-result-toast';
import { GetListingTargetSubscriptions } from '../../../graphql/types/GetListingTargetSubscriptions';
import {
  UpdateListingTargetSubscriptions,
  UpdateListingTargetSubscriptionsVariables
} from '../../../graphql/types/UpdateListingTargetSubscriptions';
import {
  UpdateListingLocationSubscriptions,
  UpdateListingLocationSubscriptionsVariables
} from '../../../graphql/types/UpdateListingLocationSubscriptions';
import { GetListingLocationSubscriptions } from '../../../graphql/types/GetListingLocationSubscriptions';

// Interface with all settings and refetch function
export interface SubscriptionSettings {
  landlordRegistered: boolean;
  listingPublished: boolean;
  searchRequest: boolean;
  requestUpdated: boolean;
  newComments: boolean;
  refetch: () => Promise<unknown>;
  landlordRefetch: () => Promise<unknown>;
  listingRefetch: () => Promise<unknown>;
  searchRefetch: () => Promise<unknown>;
  updatedRefetch: () => Promise<unknown>;
  commentsRefetch: () => Promise<unknown>;
}

// Mutation types
type UpdateLandlordRegisteredMapping = (
  options?: MutationFunctionOptions<PostLandlordRegisteredSubscription, PostLandlordRegisteredSubscriptionVariables>
) => Promise<unknown>;
type UpdateListingPublishedMapping = (
  options?: MutationFunctionOptions<PostListingPublishedSubscription, PostLandlordRegisteredSubscriptionVariables>
) => Promise<unknown>;
type UpdateSearchRequestMapping = (
  options?: MutationFunctionOptions<PostSearchRequestSubscription, PostSearchRequestSubscriptionVariables>
) => Promise<unknown>;
type UpdateRequestUpdatedMapping = (
  options?: MutationFunctionOptions<PostRequestUpdatedSubscription, PostRequestUpdatedSubscriptionVariables>
) => Promise<unknown>;
type UpdateNewCommentsMapping = (
  options?: MutationFunctionOptions<
    PostSearchRequestCommentsSubscription,
    PostSearchRequestCommentsSubscriptionVariables
  >
) => Promise<unknown>;

/**
 * Returns all mutations to update the user's subscription settings
 */
export const useUpdateSubscriptionMappings = (): {
  updateLandlordRegistered: UpdateLandlordRegisteredMapping;
  updateListingPublished: UpdateListingPublishedMapping;
  updateSearchRequest: UpdateSearchRequestMapping;
  updateRequestUpdated: UpdateRequestUpdatedMapping;
  updateSearchRequestComments: UpdateNewCommentsMapping;
} => {
  const [updateLandlordRegistered] = useMutation<
    PostLandlordRegisteredSubscription,
    PostLandlordRegisteredSubscriptionVariables
  >(POST_LANDLORD_REGISTERED_SUBSCRIPTION);
  const [updateListingPublished] = useMutation<
    PostListingPublishedSubscription,
    PostLandlordRegisteredSubscriptionVariables
  >(POST_LISTING_PUBLISHED_SUBSCRIPTION);
  const [updateSearchRequest] = useMutation<PostSearchRequestSubscription, PostSearchRequestSubscriptionVariables>(
    POST_SEARCH_REQUEST_SUBSCRIPTION
  );
  const [updateRequestUpdated] = useMutation<PostRequestUpdatedSubscription, PostRequestUpdatedSubscriptionVariables>(
    POST_REQUEST_UPDATED_SUBSCRIPTION
  );
  const [updateSearchRequestComments] = useMutation<
    PostSearchRequestCommentsSubscription,
    PostSearchRequestCommentsSubscriptionVariables
  >(POST_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION);

  return {
    updateLandlordRegistered,
    updateListingPublished,
    updateSearchRequest,
    updateRequestUpdated,
    updateSearchRequestComments
  };
};

/**
 * Returns the state of the user's subscription settings and provides a function to refetch all settings
 */
export const useSubscriptionMappings = (): SubscriptionSettings => {
  const {
    data: landlordRegisteredData,
    error: landlordError,
    refetch: landlordRefetch
  } = useQuery<GetLandlordRegisteredSubscription>(GET_LANDLORD_REGISTERED_SUBSCRIPTION);
  const {
    data: listingPublishedData,
    error: listingError,
    refetch: listingRefetch
  } = useQuery<GetListingPublishedSubscription>(GET_LISTING_PUBLISHED_SUBSCRIPTION);
  const {
    data: searchRequestData,
    error: searchError,
    refetch: searchRefetch
  } = useQuery<GetSearchRequestSubscription>(GET_SEARCH_REQUEST_SUBSCRIPTION);
  const {
    data: requestUpdatedData,
    error: updatedError,
    refetch: updatedRefetch
  } = useQuery<GetRequestUpdatedSubscription>(GET_REQUEST_UPDATED_SUBSCRIPTION);
  const {
    data: newCommentsData,
    error: commentsError,
    refetch: commentsRefetch
  } = useQuery<GetSearchRequestCommentsSubscription>(GET_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION);

  useGenericQueryErrorToast(landlordError || listingError || searchError || updatedError || commentsError);

  return {
    landlordRegistered: !!landlordRegisteredData?.hasNewLandlordRegisteredSubscription,
    listingPublished: !!listingPublishedData?.hasNewListingPublishedSubscription,
    searchRequest: !!searchRequestData?.hasNewSearchRequestSubscription,
    requestUpdated: !!requestUpdatedData?.hasWatchedRequestUpdatedSubscription,
    newComments: !!newCommentsData?.hasSearchRequestNewCommentSubscription,
    refetch: () =>
      Promise.all([landlordRefetch(), listingRefetch(), searchRefetch(), updatedRefetch(), commentsRefetch()]),
    landlordRefetch,
    listingRefetch,
    searchRefetch,
    updatedRefetch,
    commentsRefetch
  };
};

export interface ListingTargets {
  seekingStudents: boolean;
  seekingIncomings: boolean;
  seekingDoctoralStudents: boolean;
  seekingGuestResearchers: boolean;
  seekingPostDoctoralStudents: boolean;
  seekingProfessors: boolean;
  seekingTumEmployees: boolean;
}

export const useListingTargets = (): {
  targets: ListingTargets;
  refetchListingTargets: () => Promise<unknown>;
  updateListingTargets: (
    options?: MutationFunctionOptions<UpdateListingTargetSubscriptions, UpdateListingTargetSubscriptionsVariables>
  ) => Promise<unknown>;
} => {
  const {
    data: listingTargetSubscriptions,
    error: listingTargetSubscriptionsError,
    refetch: listingTargetsRefetch
  } = useQuery<GetListingTargetSubscriptions>(GET_LISTING_TARGET_SUBSCRIPTIONS);

  const [updateListingTargetSubscriptions] = useMutation<
    UpdateListingTargetSubscriptions,
    UpdateListingTargetSubscriptionsVariables
  >(UPDATE_LISTING_TARGET_SUBSCRIPTIONS);

  useGenericQueryErrorToast(listingTargetSubscriptionsError);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...listingTargets } =
    listingTargetSubscriptions && listingTargetSubscriptions.getListingTargetSubscriptions
      ? listingTargetSubscriptions.getListingTargetSubscriptions
      : {
          __typename: undefined,
          seekingStudents: false,
          seekingIncomings: false,
          seekingDoctoralStudents: false,
          seekingGuestResearchers: false,
          seekingPostDoctoralStudents: false,
          seekingProfessors: false,
          seekingTumEmployees: false
        };

  return {
    targets: listingTargets,
    refetchListingTargets: listingTargetsRefetch,
    updateListingTargets: updateListingTargetSubscriptions
  };
};

export interface ListingLocations {
  seekingFreising: boolean;
  seekingGarching: boolean;
  seekingHeilbronn: boolean;
  seekingMunich: boolean;
  seekingStraubing: boolean;
  seekingGarmischPartenkirchen: boolean;
}

export const useListingLocations = (): {
  locations: ListingLocations;
  refetchListingLocations: () => Promise<unknown>;
  updateListingLocations: (
    options?: MutationFunctionOptions<UpdateListingLocationSubscriptions, UpdateListingLocationSubscriptionsVariables>
  ) => Promise<unknown>;
} => {
  const {
    data: listingLocationSubscriptions,
    error: listingLocationSubscriptionsError,
    refetch: listingLocationsRefetch
  } = useQuery<GetListingLocationSubscriptions>(GET_LISTING_LOCATION_SUBSCRIPTIONS);

  const [updateListingLocationSubscriptions] = useMutation<
    UpdateListingLocationSubscriptions,
    UpdateListingLocationSubscriptionsVariables
  >(UPDATE_LISTING_LOCATION_SUBSCRIPTIONS);

  useGenericQueryErrorToast(listingLocationSubscriptionsError);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...listingLocations } =
    listingLocationSubscriptions && listingLocationSubscriptions.getListingLocationSubscriptions
      ? listingLocationSubscriptions.getListingLocationSubscriptions
      : {
          __typename: undefined,
          seekingFreising: false,
          seekingGarching: false,
          seekingHeilbronn: false,
          seekingMunich: false,
          seekingStraubing: false,
          seekingGarmischPartenkirchen: false
        };

  return {
    locations: listingLocations,
    refetchListingLocations: listingLocationsRefetch,
    updateListingLocations: updateListingLocationSubscriptions
  };
};
