import { ApolloQueryResult, useLazyQuery, useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  GET_INCIDENTAL_COST_TYPES,
  GET_LISTING_BY_UUID,
  GET_LISTING_BY_UUID_WITHOUT_CONTACT_INFO,
  GET_SHARE_LISTING_TOKEN
} from '../../../../graphql/queries/listings';
import { GetIncidentalCostsTypes } from '../../../../graphql/types/GetIncidentalCostsTypes';
import {
  GetListingByUUID,
  GetListingByUUID_listingByUUID,
  GetListingByUUIDVariables
} from '../../../../graphql/types/GetListingByUUID';
import { TUMLocation, HousingType, TagCategory } from '../../../../graphql/types/globalTypes';
import { GlobalState } from '../../../../redux/store';
import { useGenericQueryErrorToast } from '../../../../utils/hooks/query-result-toast';
import { CustomMultiSelectItem } from '../../../../utils/types';
import { ListingFormDataState, LocalImageDescriptor, setListingFormData } from './listing.state';
import { getApiBaseUrl } from '../../../../utils/api';
import {
  GetListingImagesForListing,
  GetListingImagesForListingVariables
} from '../../../../graphql/types/GetListingImagesForListing';
import { useUserPermission } from '../../../../utils/hooks/user-data';
import { useUrlSearchParams } from 'use-url-search-params';
import { Param } from '../../../../utils/constants';
import { GetShareListingToken, GetShareListingTokenVariables } from '../../../../graphql/types/GetShareListingToken';
import { GetEnvironmentalInfo, GetEnvironmentalInfoVariables } from '../../../../graphql/types/GetEnvironmentalInfo';
import { GET_ENVIRONMENT_INFO, GET_TUM_LOCATIONS } from '../../../../graphql/queries/geolocation';
import { PoiListing } from './listing-view/listing-view';
import { PoiCategory, TransportationType } from './listing.constants';
import { Point } from 'leaflet';
import { formatTime } from '../../../../utils/intl/dates-and-times';
import { GetTumLocations } from '../../../../graphql/types/GetTumLocations';
import { GET_LISTING_IMAGES } from '../../../../graphql/queries/listing-images';

const NUMBER_OF_ROOMS_VALUES = [1, 1.5, 2, 3, 4];

export type TagsForCategories = Map<TagCategory, CustomMultiSelectItem<string>[]>;

export type ListingSelectableItems = {
  numberOfRoomsItems: CustomMultiSelectItem<number>[];
  cityItems: CustomMultiSelectItem<string>[];
  districtItems: CustomMultiSelectItem<string>[];
  housingTypeItems: CustomMultiSelectItem<string>[];
  incidentalCostTypes: CustomMultiSelectItem<string>[];
  tagsForCategories: TagsForCategories;
};

export const useDropdownItemsFromEnumsOrConstants = (selectedCity: TUMLocation | null): ListingSelectableItems => {
  const { t } = useTranslation();

  const numberOfRoomsItems = useMemo(() => {
    return NUMBER_OF_ROOMS_VALUES.map((value) => ({
      id: value,
      text: t(value < 4 ? 'formFields.roomCount' : 'formFields.roomCountOpenEnded', { count: value })
    }));
  }, [t]);

  const cityItems = useMemo(
    () =>
      Object.values(TUMLocation).map((value) => ({
        id: value,
        text: t(`enums.city.${value}`)
      })),
    [t]
  );

  const housingTypeItems = useMemo(
    () =>
      Object.values(HousingType).map((value) => ({
        id: value,
        text: t(`enums.housingType.${value}`)
      })),
    [t]
  );

  const [districtsForCity, setDistrictsForCity] = useState<string[]>([]);

  useEffect(() => {
    if (selectedCity) {
      // REST API is used in this case to avoid cors issues when the search request form is included externally
      fetch(`${getApiBaseUrl()}api/districts/${selectedCity}`, { method: 'get' }).then((response) => {
        response.json().then((json) => {
          new Map(Object.entries(json)).forEach((entries) => {
            setDistrictsForCity(entries as string[]);
          });
        });
      });
    }
  }, [setDistrictsForCity, selectedCity]);

  const districtItems = useMemo(() => {
    if (districtsForCity) {
      // we can safely cast here, since we filter out the null values
      return districtsForCity
        .filter((district) => district !== null)
        .map(
          (district) =>
            district && {
              id: district,
              text: t(`enums.district.${district}`)
            }
        ) as CustomMultiSelectItem<string>[];
    }
    return [];
  }, [districtsForCity]);

  const [tagsForCategories, setTagsForCategories] = useState(new Map());

  useEffect(() => {
    // REST API is used in this case to avoid cors issues when the search request form is included externally
    fetch(`${getApiBaseUrl()}api/tags`, { method: 'get' }).then((response) => {
      response.json().then((json) => {
        const map = new Map();
        new Map(Object.entries(json)).forEach((entries, index) => {
          const array: Array<{ id: string; text: string }> = [];
          (entries as string[]).forEach((entry: string) => {
            array.push({ id: entry, text: t(`enums.tags.${entry}`) });
          });
          map.set(index, array);
        });
        setTagsForCategories(map);
      });
    });
  }, [setTagsForCategories, t]);

  const { data: incidentalCostsTypesData } = useQuery<GetIncidentalCostsTypes>(GET_INCIDENTAL_COST_TYPES);

  const incidentalCostTypes = useMemo(() => {
    if (incidentalCostsTypesData?.incidentalCostsTypes) {
      return incidentalCostsTypesData?.incidentalCostsTypes.map((type) => ({
        id: type,
        text: t(`enums.incidentalCostsTypes.${type}`)
      }));
    }
    return [];
  }, [incidentalCostsTypesData, t]);

  return {
    numberOfRoomsItems,
    cityItems,
    districtItems,
    housingTypeItems,
    incidentalCostTypes,
    tagsForCategories
  };
};

const transformDataToListingForm = (listing: GetListingByUUID_listingByUUID): ListingFormDataState => {
  /* eslint-disable @typescript-eslint/no-unused-vars, spellcheck/spell-checker */
  const { createdAt, __typename, images, coordinates, ...listingWithOmittedFields } = listing;
  /* eslint-enable @typescript-eslint/no-unused-vars, spellcheck/spell-checker */
  const result: ListingFormDataState = Object.assign({}, listingWithOmittedFields, {
    landlord: listing.landlord,
    tags: listing.tags || [],
    incidentalCostsTypes: listing.incidentalCostsTypes || [],
    // eslint-disable-next-line spellcheck/spell-checker
    coordinates: coordinates ? Object.assign({}, coordinates, { __typename: undefined }) : null,
    images: images
      ? images.map(
          (image): LocalImageDescriptor => {
            return Object.assign({}, image, {
              status: 'complete' as const,
              origin: 'server',
              // eslint-disable-next-line spellcheck/spell-checker
              __typename: undefined
            });
          }
        )
      : []
  });

  return result;
};

export const useFetchListing = (): {
  refetch: (() => Promise<unknown>) | undefined;
  isLoading: boolean;
} => {
  // get uuid from url
  const { uuid } = useParams();
  const [params] = useUrlSearchParams({}, {});
  const dispatch = useDispatch();
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const [isLoading, setIsLoading] = useState(false);
  const { isAnonymous } = useUserPermission();

  const [fetchListing, { data, error, refetch }] = useLazyQuery<GetListingByUUID, GetListingByUUIDVariables>(
    isAnonymous && !params[Param.SHARE] ? GET_LISTING_BY_UUID_WITHOUT_CONTACT_INFO : GET_LISTING_BY_UUID,
    {
      fetchPolicy: 'no-cache'
    }
  );

  useGenericQueryErrorToast(error);

  useEffect(() => {
    if (uuid && uuid !== 'new' && !isLoading && ((!data && !error) || uuid !== formState.uuid)) {
      fetchListing({
        variables: { uuid, sharedListingToken: params[Param.SHARE] ? params[Param.SHARE].toString() : null }
      });
      setIsLoading(true);
    }
  }, [uuid, isLoading, data, error, formState.uuid]);

  useEffect(() => {
    if (data && data.listingByUUID) {
      dispatch(setListingFormData(transformDataToListingForm(data.listingByUUID)));
      setIsLoading(false);
    }
  }, [data]);

  return {
    refetch,
    isLoading
  };
};

export const useListingShareToken = (): {
  withContactInfo: string | null | undefined;
  withoutContactInfo: string | null | undefined;
} => {
  const { uuid } = useParams();
  const { hasAdministrativeFunction } = useUserPermission();

  if (uuid && hasAdministrativeFunction) {
    const { data: withContactInfo } = useQuery<GetShareListingToken, GetShareListingTokenVariables>(
      GET_SHARE_LISTING_TOKEN,
      {
        fetchPolicy: 'no-cache',
        variables: {
          listingUuid: uuid,
          includeContactInfo: true
        }
      }
    );

    const { data: withoutContactInfo } = useQuery<GetShareListingToken, GetShareListingTokenVariables>(
      GET_SHARE_LISTING_TOKEN,
      {
        fetchPolicy: 'no-cache',
        variables: {
          listingUuid: uuid,
          includeContactInfo: false
        }
      }
    );

    return {
      withContactInfo: withContactInfo?.shareListingToken,
      withoutContactInfo: withoutContactInfo?.shareListingToken
    };
  }
  return {
    withContactInfo: '',
    withoutContactInfo: ''
  };
};

export const useFetchListingImages = (): {
  imageData: GetListingImagesForListing | undefined;
  refetch:
    | ((
        variables?: Partial<GetListingImagesForListingVariables> | undefined
      ) => Promise<ApolloQueryResult<GetListingImagesForListing>>)
    | undefined;
} => {
  const { uuid } = useParams();
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const [isLoading, setIsLoading] = useState(false);
  const [params] = useUrlSearchParams({}, {});

  const [fetchListingImages, { data, error, refetch }] = useLazyQuery<
    GetListingImagesForListing,
    GetListingImagesForListingVariables
  >(GET_LISTING_IMAGES, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (uuid && uuid === formState.uuid && !isLoading && formState.id) {
      // IMPROVEMENT: declare max width in env variables
      fetchListingImages({
        variables: {
          listingId: formState.id,
          maxWidth: 1280,
          sharedListingToken: params[Param.SHARE] ? params[Param.SHARE].toString() : null
        }
      });
      setIsLoading(true);
    }
  }, [uuid, isLoading, data, error, formState.uuid]);

  return {
    imageData: data,
    refetch
  };
};

export const useTumLocations = (): PoiListing[] => {
  const { data, loading } = useQuery<GetTumLocations>(GET_TUM_LOCATIONS, { fetchPolicy: 'no-cache' });
  const { t } = useTranslation();

  return useMemo(() => {
    const tumPoi: PoiListing[] = [];

    if (data && data.tumLocations) {
      data.tumLocations.forEach((tum) => {
        if (tum) {
          // Convert to enums and push in the correct category
          tumPoi.push({
            address: tum.city,
            type: null,
            coordinates: new Point(tum.coordinates.x, tum.coordinates.y),
            duration: '',
            name: t(`enums.tumLocation.${tum.city}`),
            category: PoiCategory.TUM
          });
        }
      });
    }

    return tumPoi;
  }, [data, loading]);
};

export const useEnvironmentalInfo = (): { tumPoi: PoiListing[]; envPoi: PoiListing[] } => {
  const { uuid } = useParams();
  const { t, i18n } = useTranslation();

  const [fetchEnvironmentalInfo, { data, loading }] = useLazyQuery<GetEnvironmentalInfo, GetEnvironmentalInfoVariables>(
    GET_ENVIRONMENT_INFO,
    {
      fetchPolicy: 'no-cache'
    }
  );
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);

  useEffect(() => {
    if (uuid && uuid === formState.uuid && !loading) {
      fetchEnvironmentalInfo({ variables: { uuid } });
    }
  }, [uuid, formState.uuid]);

  return useMemo(() => {
    const tumPoi: PoiListing[] = [];
    const envPoi: PoiListing[] = [];

    if (data && data.environmentalInformationForListing.length > 0) {
      data.environmentalInformationForListing.forEach((env) => {
        // Convert to enums and push in the correct category
        const convertedInfo = {
          address: env.address,
          type:
            env.type === TransportationType.PUBLIC_TRANSPORT.toString()
              ? TransportationType.PUBLIC_TRANSPORT
              : TransportationType.FOOT,
          coordinates: new Point(env.coordinates.x, env.coordinates.y),
          duration: formatTime(env.duration, t),
          name: env.category.toUpperCase() === PoiCategory.TUM ? t(`enums.tumLocation.${env.name}`) : env.name,
          category: env.category.toUpperCase() as PoiCategory
        };
        if (convertedInfo.category === PoiCategory.TUM) {
          tumPoi.push(convertedInfo);
        } else {
          envPoi.push(convertedInfo);
        }
      });
    }

    return {
      tumPoi,
      envPoi
    };
  }, [data, loading, i18n.language]);
};
