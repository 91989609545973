import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../redux/store';
import { User } from '../../redux/user.state';
import { hasAdministrativeFunction, isAdmin, isLandlord, isSeeker } from '../permissions';

export const useCurrentUser = (): User | null => {
  const userState = useSelector<GlobalState, User | null>((state) => state.user?.user);
  return userState;
};

export type UserPermissions = {
  permission: string;
  hasAdministrativeFunction: boolean;
  isAdmin: boolean;
  isLandlord: boolean;
  isAnonymous: boolean;
  isSeeker: boolean;
};

export const useUserPermission = (): UserPermissions => {
  const user = useCurrentUser();

  const permissions = useMemo(() => {
    return {
      permission: user?.permission || 'ANONYMOUS',
      hasAdministrativeFunction: user !== null && hasAdministrativeFunction(user.permission),
      isAdmin: user !== null && isAdmin(user.permission),
      isLandlord: user !== null && isLandlord(user.permission),
      isAnonymous: user === null,
      isSeeker: user !== null && isSeeker(user.permission)
    };
  }, [user]);

  return permissions;
};
