import { gql } from '@apollo/client';

export const GET_LISTINGS_EXPORT = gql`
  # Get all landlords for the landlord view
  query GetListingsExport($orderBy: ListingSortOrder, $filter: ListingFilter) {
    listings(orderBy: $orderBy, filter: $filter) {
      uuid
      landlord {
        companyName
        firstName
        lastName
        email
        phone
      }
      type
      numberOfRooms
      availableFrom
      availableUntil
      city
      tumLocation
      district
      street
      houseNumber
      postalCode
      totalRent
      deposit
      tags
      images {
        id
      }
      seekingStudents
      seekingProfessors
      seekingIncomings
      seekingDoctoralStudents
      seekingPostDoctoralStudents
      seekingGuestResearchers
      seekingTumEmployees
      isListingPublic
      verifiedAt
    }
  }
`;

export const GET_LISTINGS_EXPORT_LINK_ONLY = gql`
  query GetListingsExportLinkOnly($orderBy: ListingSortOrder, $filter: ListingFilter) {
    listings(orderBy: $orderBy, filter: $filter) {
      uuid
    }
  }
`;

export const GET_LISTINGS = gql`
  # Get all landlords for the landlord view
  query GetListings($resultLimit: Int, $pageOffset: Int, $orderBy: ListingSortOrder, $filter: ListingFilter) {
    listings(resultLimit: $resultLimit, pageOffset: $pageOffset, orderBy: $orderBy, filter: $filter) {
      id
      uuid
      landlord {
        companyName
        firstName
        lastName
        email
        phone
      }
      type
      numberOfRooms
      availableFrom
      availableUntil
      city
      tumLocation
      district
      street
      houseNumber
      postalCode
      totalRent
      deposit
      squareMeter
      tags
      images {
        id
        modifiedAt
        description
        descriptionEn
      }
      seekingStudents
      seekingProfessors
      seekingIncomings
      seekingDoctoralStudents
      seekingPostDoctoralStudents
      seekingGuestResearchers
      seekingTumEmployees
      isListingPublic
      publicationDate
      verifiedAt
      createdAt
      isActive
      coordinates {
        x
        y
      }
      previewImage {
        id
        description
        descriptionEn
      }
    }
  }
`;

export const GET_NUMBER_OF_LISTINGS = gql`
  query GetNumberOfListings($filter: ListingFilter) {
    numberOfListings(filter: $filter)
  }
`;

export const GET_DISCTRICTS_FOR_CITY = gql`
  query GetDistrictsForCity($city: TUMLocation!) {
    districts(forLocation: $city)
  }
`;

export const GET_INCIDENTAL_COST_TYPES = gql`
  query GetIncidentalCostsTypes {
    incidentalCostsTypes
  }
`;

export const listingFragment = gql`
  fragment listingByUUID on Listing {
    id
    uuid
    type
    housingType
    floor
    numberOfRooms
    availableFrom
    availableUntil
    city
    tumLocation
    district
    street
    houseNumber
    postalCode
    rent
    incidentalCosts
    incidentalCostsCustomLabel
    incidentalCostsTypes
    oneTimeCosts
    oneTimeCostsLabel
    parkingSpace
    parkingSpaceCosts
    deposit
    squareMeter
    tags
    images {
      id
      listing
      fileName
      description
      descriptionEn
      isPreview
      modifiedAt
    }
    seekingStudents
    seekingProfessors
    seekingIncomings
    seekingDoctoralStudents
    seekingPostDoctoralStudents
    seekingGuestResearchers
    seekingTumEmployees
    furtherEquipment
    furtherEquipmentEn
    isActive
    isListingPublic
    publicationDate
    expirationDate
    verifiedAt
    createdAt
    modifiedAt
    coordinates {
      x
      y
    }
  }
`;

export const landlordContactInfoFragment = gql`
  fragment landlordContactInfo on Landlord {
    firstName
    lastName
    email
    phone
    companyName
    gender
    showFirstName
    showLastName
    showEmail
    showPhone
    showCompany
    verifiedAt
    blockedBy
  }
`;

export const GET_LISTING_BY_UUID = gql`
  ${listingFragment}
  ${landlordContactInfoFragment}
  query GetListingByUUID($uuid: String!, $sharedListingToken: String) {
    listingByUUID(uuid: $uuid, sharedListingToken: $sharedListingToken) {
      landlord {
        id
        uuid
        contactType
        ...landlordContactInfo
      }
      ...listingByUUID
    }
  }
`;

export const GET_LISTING_BY_UUID_WITHOUT_CONTACT_INFO = gql`
  ${listingFragment}
  query GetListingByUUIDWithoutContactInfo($uuid: String!) {
    listingByUUID(uuid: $uuid) {
      landlord {
        id
        uuid
        contactType
      }
      ...listingByUUID
    }
  }
`;

export const GET_LISTING_SHORT_BY_UUID = gql`
  query GetListingShortByUUID($uuid: String!) {
    listingByUUID(uuid: $uuid) {
      id
      uuid
      type
      numberOfRooms
      city
      tumLocation
      district
      street
      houseNumber
      postalCode
      rent
      totalRent
      squareMeter
      images {
        id
        modifiedAt
        description
        descriptionEn
      }
      previewImage {
        id
        modifiedAt
        description
        descriptionEn
      }
    }
  }
`;

export const POST_NEW_LISTING = gql`
  mutation PostNewListing(
    $landlord: ID
    $type: ListingType!
    $street: String!
    $postalCode: String!
    $city: String!
    $tumLocation: TUMLocation!
    $rent: Float!
    $houseNumber: String
    $district: String
    $availableFrom: DateTime!
    $isActive: Boolean!
    $isListingPublic: Boolean!
    $seekingStudents: Boolean!
    $seekingProfessors: Boolean!
    $seekingIncomings: Boolean!
    $seekingDoctoralStudents: Boolean!
    $seekingPostDoctoralStudents: Boolean!
    $seekingGuestResearchers: Boolean!
    $seekingTumEmployees: Boolean!
    $squareMeter: Float!
    $numberOfRooms: Float
    $incidentalCosts: Float
    $incidentalCostsTypes: [String!]
    $oneTimeCosts: Float
    $oneTimeCostsLabel: String
    $parkingSpace: Boolean!
    $parkingSpaceCosts: Float
    $deposit: Float
    $verifiedAt: DateTime
    $availableUntil: DateTime
    $expirationDate: DateTime
    $furtherEquipment: String
    $furtherEquipmentEn: String
    $tags: [String!]
    $images: [ListingImageInput!]
    $coordinates: PointInput
  ) {
    addListing(
      landlord: $landlord
      type: $type
      street: $street
      postalCode: $postalCode
      city: $city
      tumLocation: $tumLocation
      rent: $rent
      houseNumber: $houseNumber
      district: $district
      availableFrom: $availableFrom
      isActive: $isActive
      isListingPublic: $isListingPublic
      seekingStudents: $seekingStudents
      seekingProfessors: $seekingProfessors
      seekingIncomings: $seekingIncomings
      seekingDoctoralStudents: $seekingDoctoralStudents
      seekingPostDoctoralStudents: $seekingPostDoctoralStudents
      seekingGuestResearchers: $seekingGuestResearchers
      seekingTumEmployees: $seekingTumEmployees
      squareMeter: $squareMeter
      numberOfRooms: $numberOfRooms
      incidentalCosts: $incidentalCosts
      incidentalCostsTypes: $incidentalCostsTypes
      oneTimeCosts: $oneTimeCosts
      oneTimeCostsLabel: $oneTimeCostsLabel
      parkingSpace: $parkingSpace
      parkingSpaceCosts: $parkingSpaceCosts
      deposit: $deposit
      verifiedAt: $verifiedAt
      availableUntil: $availableUntil
      expirationDate: $expirationDate
      furtherEquipment: $furtherEquipment
      furtherEquipmentEn: $furtherEquipmentEn
      tags: $tags
      images: $images
      coordinates: $coordinates
    )
  }
`;

export const POST_DUPLICATE_LISTING = gql`
  mutation PostDuplicateListing($listingId: ID!) {
    duplicateListing(listingId: $listingId)
  }
`;

export const PUT_UPDATE_LISTING = gql`
  mutation UpdateListing($listing: ListingInput!) {
    updateListing(listing: $listing)
  }
`;

export const PUT_ACTIVATE_LISTING = gql`
  mutation ActivateListing($listingId: ID!) {
    activateListing(id: $listingId)
  }
`;
export const PUT_DEACTIVATE_LISTING = gql`
  mutation DeactivateListing($listingId: ID!) {
    deactivateListing(id: $listingId)
  }
`;

export const DELETE_LISTING = gql`
  mutation DeleteListing($listingId: ID!) {
    deleteListing(id: $listingId)
  }
`;

export const GET_SHARE_LISTING_TOKEN = gql`
  query GetShareListingToken($listingUuid: String!, $includeContactInfo: Boolean!) {
    shareListingToken(listingUuid: $listingUuid, includeContactInfo: $includeContactInfo)
  }
`;

export const GET_IS_LISTING_TOKEN_VALID = gql`
  query GetIsListingTokenValid($listingUuid: String!, $sharedListingToken: String!) {
    isListingTokenValid(listingUuid: $listingUuid, sharedListingToken: $sharedListingToken)
  }
`;
