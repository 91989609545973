import styles from './filter-container.module.scss';
import { Children, FC, isValidElement, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Button } from 'carbon-components-react';
import { ReplaySVGIcon } from '@react-md/material-icons';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from '../../../utils/hooks/document-title';

export interface FilterContainerProps {
  headline: string;
  counter?: number;
  className?: string;
  onResetClick?: () => void;
}

export const FilterTagContainer: FC = ({ children }) => {
  return <div className={styles.FilterTagContainer}>{children}</div>;
};

export const FilterContainer: FC<PropsWithChildren<FilterContainerProps>> = (
  props: PropsWithChildren<FilterContainerProps>
) => {
  const { t } = useTranslation();

  const tagContainer: ReactNode[] = [];
  const filter: ReactNode[] = [];

  Children.forEach(props.children, (child) => {
    if (isValidElement(child) && child.type === FilterTagContainer) {
      tagContainer.push(child);
    } else {
      filter.push(child);
    }
  });

  useDocumentTitle(props.headline);

  return (
    <section className={classNames(props.className, styles.FilterContainer)}>
      <div className="global-content-wrapper-block">
        <h1>
          {props.counter !== undefined && <strong>{props.counter} </strong>}
          {props.headline}
        </h1>
        <div className={styles.content}>{filter}</div>
        <div className={styles.subContent}>
          {tagContainer}
          {props.onResetClick !== undefined && (
            <div className={styles.reset}>
              <Button
                kind="ghost"
                size="sm"
                renderIcon={ReplaySVGIcon}
                className="bx--btn--hasIcon"
                onClick={props.onResetClick}
              >
                {t('filter.reset')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
