import { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Helper component that scrolls the page to the top every time the route
 * changes. E.g. when browsing a list (page scrolled down) and selecting an
 * item which leads to a detail page, this component will automatically scroll
 * the page up.
 */
const ScrollRestoration: FC = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollRestoration;
