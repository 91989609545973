import { ApolloError } from '@apollo/client';
import { InlineNotification } from 'carbon-components-react';
import { CSSProperties, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export const useQueryResultNotificationError = (
  queryError: ApolloError | undefined,
  style?: CSSProperties
): ReactElement | undefined => {
  const error = useQueryResultErrorMessage(queryError);
  if (error) {
    return <InlineNotification style={style} kind="error" title={error} />;
  }
};

export const useQueryResultErrorMessage = (queryError: ApolloError | undefined): string | undefined => {
  const { t, i18n } = useTranslation();

  if (queryError) {
    let error = t('errors.default');
    // Check if there is any more meaningful error code coming from the backend
    if (queryError.graphQLErrors.length > 0 && queryError.graphQLErrors[0].extensions?.code) {
      const errorCode = queryError.graphQLErrors[0].extensions?.code;
      const translationKey = `errors.${errorCode}`;
      error = i18n.exists(translationKey) ? t(translationKey) : t('errors.default');
    }
    return error;
  }
};
