import { FC } from 'react';
import { Tooltip } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { InfoSVGIcon } from '@react-md/material-icons';

export interface CostToolTipProps {
  infoText: string;
  triggerText?: string;
}

export const CostToolTip: FC<CostToolTipProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Tooltip
      tooltipBodyId="tooltip-body"
      align="center"
      direction="top"
      triggerText={props.triggerText}
      // works in other components just fine (e.g. Buttons) and is also used this way in
      // the official docs ¯\_(ツ)_/¯
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      renderIcon={InfoSVGIcon}
      iconDescription={t('icons.info')}
    >
      <p id="tooltip-body">{props.infoText}</p>
    </Tooltip>
  );
};

export default CostToolTip;
