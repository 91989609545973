import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Tag } from 'carbon-components-react';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../../redux/store';
import {
  ListingsListFilterState,
  setActivationStatus,
  setAvailableFrom,
  setAvailableFromLatest,
  setDeposit,
  setDistricts,
  setIsListingPublic,
  setLandlordIsManagement,
  setOpenEnded,
  setSeekingDoctoralStudents,
  setSeekingGuestResearchers,
  setSeekingIncomings,
  setSeekingPostDoctoralStudents,
  setSeekingProfessors,
  setSeekingStudents,
  setSeekingTumEmployees,
  setTags,
  setVerificationStatus
} from './listings-list-filter/listings-list-filter.state';
import { useTranslation } from 'react-i18next';
import { useUrlSearchParams } from 'use-url-search-params';
import { getDistrictItemsByParam, getTagsFromUrl } from './listings-list.utils';
import { CustomMultiSelectItem } from '../../../../utils/types';
import { FILTER_SEPARATOR, Param } from '../../../../utils/constants';
import { formatDate } from '../../../../utils/intl/dates-and-times';
import { ListingVisibility } from './listings-list.filter-types';
import { ListingType, TagCategory, TUMLocation } from '../../../../graphql/types/globalTypes';
import { TargetGroup } from '../../search-requests/search-requests-types';
import { useUserPermission } from '../../../../utils/hooks/user-data';
import { getApiBaseUrl } from '../../../../utils/api';
import { MutationFunctionOptions, useMutation } from '@apollo/client';
import {
  PostNewSeekerSearchRequest,
  PostNewSeekerSearchRequestVariables
} from '../../../../graphql/types/PostNewSeekerSearchRequest';
import { POST_NEW_SEEKER_SEARCH_REQUEST } from '../../../../graphql/queries/seeker-search-requests';

export const useTagsFromState = (
  districtItems: CustomMultiSelectItem<string>[],
  tagsForCategories: Map<TagCategory, CustomMultiSelectItem<string>[]>
): ReactNode => {
  const state = useSelector<GlobalState, ListingsListFilterState>((state) => state.listingsListFilter);
  const { t } = useTranslation();
  const [params, setParams] = useUrlSearchParams({}, {});
  const dispatch = useDispatch();
  const permissions = useUserPermission();
  const { hasAdministrativeFunction } = permissions;

  const setParamUndefined = (param: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setParams({ [param]: undefined });
  };

  const seekingNames = [
    `enums.targetGroup.${TargetGroup.GUEST_RESEARCHERS}`,
    `enums.targetGroup.${TargetGroup.PROFESSORS}`,
    `enums.targetGroup.${TargetGroup.INCOMINGS}`,
    `enums.targetGroup.${TargetGroup.STUDENTS}`,
    `enums.targetGroup.${TargetGroup.POST_DOCTORAL_STUDENTS}`,
    `enums.targetGroup.${TargetGroup.DOCTORAL_STUDENTS}`,
    `enums.targetGroup.${TargetGroup.TUM_EMPLOYEES}`
  ];

  // Renders boolean tag
  const renderTag = (
    paramName: string,
    tagName: string,
    setter: ((value: boolean) => void) | ((value: null) => void)
  ): ReactNode => {
    return (
      <Tag
        filter
        onClose={() => {
          dispatch(setter);
          setParamUndefined(paramName);
        }}
      >
        {t(tagName)}
      </Tag>
    );
  };

  return (
    <>
      {state.districts.map((district) => {
        return (
          <Tag
            filter
            key={district.id}
            onClose={() => {
              const districts = getDistrictItemsByParam(params, districtItems).filter(
                (item) => item.id !== district.id
              );
              dispatch(setDistricts(districts));
              setParams({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                [Param.DISTRICTS]:
                  districts.length > 0 ? districts.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
              });
            }}
          >
            {district.text}
          </Tag>
        );
      })}
      {!state.immediatelyAvailable && state.availableFrom && (
        <Tag
          filter
          onClose={() => {
            dispatch(setAvailableFrom(null));
            setParamUndefined('availableFrom');
          }}
        >
          {t('views.listings.filter.earliest') + ' ' + formatDate(state.availableFrom)}
        </Tag>
      )}
      {!state.immediatelyAvailable && state.availableFromLatest && (
        <Tag
          filter
          onClose={() => {
            dispatch(setAvailableFromLatest(null));
            setParamUndefined('availableLatest');
          }}
        >
          {t('views.listings.filter.latest') + ' ' + formatDate(state.availableFromLatest)}
        </Tag>
      )}
      {state.openEnded !== null &&
        renderTag('openEnded', `views.listings.filter.${state.openEnded ? 'openEnded' : 'timeLimit'}`, setOpenEnded)}
      {state.seekingGuestResearchers && renderTag('guestResearchers', seekingNames[0], setSeekingGuestResearchers)}
      {state.seekingProfessors && renderTag('professors', seekingNames[1], setSeekingProfessors)}
      {state.seekingIncomings && renderTag('incomings', seekingNames[2], setSeekingIncomings)}
      {state.seekingStudents && renderTag('students', seekingNames[3], setSeekingStudents)}
      {state.seekingPostDoctoralStudents &&
        renderTag('postDoctoralStudents', seekingNames[4], setSeekingPostDoctoralStudents)}
      {state.seekingDoctoralStudents && renderTag('doctoralStudents', seekingNames[5], setSeekingDoctoralStudents)}
      {state.seekingTumEmployees && renderTag('tumEmployees', seekingNames[6], setSeekingTumEmployees)}
      {state.deposit !== null && (
        <Tag
          filter
          onClose={() => {
            dispatch(setDeposit(null));
            setParamUndefined('deposit');
          }}
        >
          {t('views.listings.filter.deposit') + ' ' + state.deposit + ' €'}
        </Tag>
      )}
      {state.landlordIsManagement &&
        renderTag('landlordIsManagement', 'views.listings.filter.landlordIsManagementShort', setLandlordIsManagement)}
      {state.verificationStatus !== null && state.verificationStatus.indexOf(true) !== -1 && (
        <Tag
          filter
          onClose={() => {
            dispatch(setVerificationStatus(state.verificationStatus?.filter((item) => item !== true) || []));
            setParamUndefined('checked');
          }}
        >
          {t('views.listings.filter.checked')}
        </Tag>
      )}
      {state.verificationStatus !== null && state.verificationStatus?.indexOf(false) !== -1 && (
        <Tag
          filter
          onClose={() => {
            dispatch(setVerificationStatus(state.verificationStatus?.filter((item) => item !== false) || []));
            setParamUndefined('unchecked');
          }}
        >
          {t('views.listings.filter.unchecked')}
        </Tag>
      )}
      {hasAdministrativeFunction && state.activationStatus !== null && state.activationStatus?.indexOf(true) !== -1 && (
        <Tag
          filter
          onClose={() => {
            dispatch(setActivationStatus(state.activationStatus?.filter((item) => item !== true) || []));
            setParamUndefined('activated');
          }}
        >
          {t('views.listings.filter.activated')}
        </Tag>
      )}
      {state.activationStatus !== null && state.activationStatus?.indexOf(false) !== -1 && (
        <Tag
          filter
          onClose={() => {
            dispatch(setActivationStatus(state.activationStatus?.filter((item) => item !== false) || []));
            setParamUndefined('inactivated');
          }}
        >
          {t('views.listings.filter.inactivated')}
        </Tag>
      )}
      {state.tags.map((tag) => {
        return (
          <Tag
            filter
            key={tag}
            onClose={() => {
              const tagParams = getTagsFromUrl(params, tagsForCategories);
              dispatch(setTags(tagParams.filter((tagListTag) => tagListTag !== tag)));
              setParamUndefined(tag);
            }}
          >
            {t(`enums.tags.${tag}`)}
          </Tag>
        );
      })}
      {state.isListingPublic !== null &&
        renderTag(
          'public',
          `views.listings.filter.${state.isListingPublic ? ListingVisibility.PUBLIC : ListingVisibility.NOT_PUBLIC}`,
          setIsListingPublic
        )}
    </>
  );
};

export const useDistrictFilterItems = (selectedCity: TUMLocation | null): CustomMultiSelectItem<string>[] => {
  const { t } = useTranslation();

  const [districtsForCity, setDistrictsForCity] = useState<string[]>([]);

  useEffect(() => {
    if (selectedCity) {
      if (selectedCity !== TUMLocation.GARMISCH_PARTENKIRCHEN) {
        // REST API is used in this case to avoid cors issues when the search request form is included externally
        fetch(`${getApiBaseUrl()}api/districts/${selectedCity}`, { method: 'get' }).then((response) => {
          response.json().then((json) => {
            new Map(Object.entries(json)).forEach((entries) => {
              setDistrictsForCity(entries as string[]);
            });
          });
        });
      } else {
        // There are no districts for this TUM location
        setDistrictsForCity([]);
      }
    }
  }, [setDistrictsForCity, selectedCity]);

  const districtItems = useMemo(() => {
    if (districtsForCity) {
      // we can safely cast here, since we filter out the null values
      return districtsForCity
        .filter((district) => district !== null)
        .map(
          (district) =>
            district && {
              id: district,
              text: district !== 'OTHER' ? t(`enums.district.${district}`) : t('enums.district.OTHER_FILTER')
            }
        ) as CustomMultiSelectItem<string>[];
    }
    return [];
  }, [districtsForCity]);

  return districtItems;
};

type PostNewSeekerSearchRequestMutation = (
  options?: MutationFunctionOptions<PostNewSeekerSearchRequest, PostNewSeekerSearchRequestVariables>
) => Promise<unknown>;

export const usePostNewSeekerSearchRequest = (
  listingsListFiltersState: ListingsListFilterState,
  tumLocation: TUMLocation | null,
  numberOfRooms: number | null,
  listingType: ListingType | null
): PostNewSeekerSearchRequestMutation => {
  const email = useSelector<GlobalState, string | undefined>((state) => state.user.user?.email);

  const {
    availableFrom,
    availableFromLatest,
    deposit: depositMax,
    districts,
    tags,
    rentFromFilter: totalRentFrom,
    rentToFilter: totalRentTo,
    squareMeterFromFilter: squareMeterFrom,
    squareMeterToFilter: squareMeterTo,
    openEnded
  } = listingsListFiltersState;

  const [postNewSeekerSearchRequest] = useMutation<PostNewSeekerSearchRequest, PostNewSeekerSearchRequestVariables>(
    POST_NEW_SEEKER_SEARCH_REQUEST,
    {
      variables: {
        input: {
          email: email || '',
          tumLocation,
          depositMax,
          availableFrom,
          availableFromLatest,
          districts: districts.map((districtItem) => districtItem.id),
          tags,
          totalRentFrom,
          totalRentTo,
          squareMeterFrom,
          squareMeterTo,
          numberOfRooms,
          isOpenEnded: openEnded,
          type: listingType
        }
      }
    }
  );

  return postNewSeekerSearchRequest;
};
