import { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import { Button, OverflowMenu } from 'carbon-components-react';
import styles from './overflow-button.module.scss';
import classNames from 'classnames';

export interface OverflowButtonProps {
  text: string;
  icon: ReactNode;
  disabled?: boolean;
}

export const OverflowButton: FC<PropsWithChildren<OverflowButtonProps>> = (
  props: PropsWithChildren<OverflowButtonProps>
) => {
  const [loading, setLoading] = useState(true);
  const [, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef && buttonRef.current && loading) {
      const buttonText = document.createTextNode(props.text);
      const icon = buttonRef.current.childNodes[0];
      buttonRef.current.insertBefore(buttonText, icon);
      setLoading(false);
    }
  }, [buttonRef, loading]);

  return (
    <>
      {loading && (
        <Button
          data-testid={'OverflowLoadingButton'}
          kind="ghost"
          size="sm"
          renderIcon={props.icon}
          className="bx--btn--hasIcon"
          disabled={props.disabled}
        >
          {props.text}
        </Button>
      )}
      <OverflowMenu
        data-testid={'OverflowMenu'}
        className={classNames(styles.OverflowButton, { [styles.invisible]: loading })}
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation();
        }}
        flipped
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderIcon={props.icon}
        menuOptionsClass={styles.OverflowButtonMenu}
        disabled={props.disabled}
      >
        {props.children}
      </OverflowMenu>
    </>
  );
};
