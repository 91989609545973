import { FC, ReactElement } from 'react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import { useUserPermission } from '../../../utils/hooks/user-data';
import { Listing } from './listing/listing';
import ListingsList from './listings-list/listings-list';

export interface ListingsSingleLevelSubRouterProps {
  defaultView: ReactElement | null;
}

export const ListingsSingleLevelSubRouter: FC<ListingsSingleLevelSubRouterProps> = (props) => {
  const { viewMode } = useParams();

  const isUUIDOnly = /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12}?)/i.test(viewMode);

  // Navigate to view when only a uuid is given. '/new' and '/preview' are only available for administrative users
  return isUUIDOnly ? <Navigate to="./view" /> : props.defaultView;
};

const ListingsRouter: FC = () => {
  const { hasAdministrativeFunction } = useUserPermission();

  return (
    <Routes>
      <Route path="/" element={<ListingsList />} />
      <Route
        path="/:viewMode/*"
        element={<ListingsSingleLevelSubRouter defaultView={hasAdministrativeFunction ? <Listing /> : null} />}
      />
      <Route path="/:uuid/:viewMode/*" element={<Listing />} />
    </Routes>
  );
};

export default ListingsRouter;
