export default `<div class="navi">
  <ul class="level_1">
    <li class="first">
      <span class="pagetitle"><a href="https://www.tum.de/en/about-tum/">About TUM</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/about-tum/our-university/">Our University</a></li>
        <li><a href="https://www.tum.de/en/about-tum/news/">News</a></li>
        <li><a href="https://www.tum.de/en/about-tum/news/coronavirus/">Coronavirus</a></li>
        <li><a href="https://www.tum.de/en/about-tum/publications/">Publications</a></li>
        <li><a href="https://www.tum.de/en/about-tum/schools-departments/">Schools + Departments</a></li>
        <li><a href="https://www.tum.de/en/about-tum/awards-and-honors/">Awards and Honors</a></li>
        <li><a href="https://www.tum.de/en/about-tum/university-hospitals/">University Hospitals</a></li>
        <li><a href="http://www.lehren.tum.de/nc/en/">Teaching and QMS</a></li>
        <li><a href="https://www.tum.de/en/about-tum/working-at-tum/">Working at TUM</a></li>
        <li><a href="https://www.tum.de/en/about-tum/giving/">Giving</a></li>
        <li><a href="https://www.tum.de/en/about-tum/tum-fan/">TUM Fan?</a></li>
        <li><a href="https://www.tum.de/en/about-tum/contact-directions/">Contact&#160;&amp;&#160;Directions</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/en/research/">Research</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/research/schools-departments/">Schools + Departments</a></li>
        <li><a href="https://www.tum.de/en/research/research-centers/">Research Centers</a></li>
        <li><a href="https://www.exzellenz.tum.de/en/">Excellence Strategy</a></li>
        <li><a href="https://www.tum.de/en/research/research-projects/">Research projects</a></li>
        <li><a href="https://www.tum.de/en/research/research-partners/">Research Partners</a></li>
        <li><a href="https://www.forte.tum.de/en/">Research promotion</a></li>
        <li><a href="http://www.gs.tum.de/en/doctorate-at-tum/">Doctorate (Ph.D.)</a></li>
        <li><a href="https://www.tum.de/en/research/postdocs/">Postdocs</a></li>
        <li><a href="https://www.tum.de/en/about-tum/working-at-tum/open-positions/">Career openings</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/en/innovation/">Innovation</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/innovation/entrepreneurship/">Entrepre&#173;neurship</a></li>
        <li><a href="https://www.tum.de/en/innovation/technology-transfer/">Technology transfer</a></li>
        <li><a href="https://www.tum.de/en/innovation/industry-liaison-office/">Industry Liaison Office</a></li>
        <li><a href="https://www.tum.de/en/innovation/contacts/">Contacts</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/en/studies/">Studies</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/studies/welcome/">Welcome</a></li>
        <li><a href="https://www.tum.de/en/studies/degree-programs/">Degree programs</a></li>
        <li><a href="https://www.tum.de/en/studies/international-students/">International Students</a></li>
        <li><a href="https://www.tum.de/en/studies/support-and-advice/">Support&#160;&amp;&#160;Advice</a></li>
        <li><a href="https://www.tum.de/en/studies/application-and-acceptance/">Application</a></li>
        <li><a href="https://www.tum.de/en/studies/fees-and-financial-aid/">Fees and Financial Aid</a></li>
        <li><a href="https://www.tum.de/en/studies/during-your-studies/">During your Studies</a></li>
        <li><a href="https://www.tum.de/en/studies/graduation/">Completing your Studies</a></li>
        <li><a href="https://www.tum.de/en/studies/campus-life/">Campus life</a></li>
        <li><a href="https://www.tum.de/en/studies/university-life/">University Life</a></li>
        <li><a href="https://www.tum.de/en/studies/support-and-advice/support-during-studies/student-information/">Contacts</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/en/lifelong-learning/">Lifelong learning</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/lifelong-learning/executive-and-professional-education/">Executive and Professional Education</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/all-employees-from-tum/">All employees from TUM</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/scientific-staff-members/">Scientific staff members</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/research-and-science-managers/">Research and Science Managers</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/professors/">Professors</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/moocs/">MOOCs</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/publications-and-media/">Publications and Media</a></li>
        <li><a href="https://www.tum.de/en/lifelong-learning/contact/">Contact</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/en/global/">Global</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/en/global/international-locations/">International Locations</a></li>
        <li><a href="https://www.tum.de/en/global/tum-asia/">TUM Asia</a></li>
        <li><a href="https://www.tum.de/en/studies/international-students/">International Students</a></li>
        <li><a href="http://www.international.tum.de/en/go-international/">Exchange</a></li>
        <li><a href="http://www.international.tum.de/en/international-alliances/">International Alliances</a></li>
        <li><a href="http://www.sprachenzentrum.tum.de/en/homepage/">Language Center</a></li>
        <li><a href="https://www.tum.de/en/global/contacts/">Contacts</a></li>
      </ul>
    </li>
  </ul>
  <div style="clear: both"></div>
</div>
`;
