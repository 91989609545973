import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SaveBar } from '../../../../building-blocks/save-bar/save-bar';
import styles from './listing-save-bar.module.scss';
import { Button } from 'carbon-components-react';
import { EditSVGIcon, FavoriteBorderSVGIcon, FavoriteSVGIcon, ShareSVGIcon } from '@react-md/material-icons';
import { useCurrentUser, UserPermissions } from '../../../../../utils/hooks/user-data';
import { ListingInfoPanel, ListingInfoPanelSkeleton } from '../../../../controls/listing-info-panel/listing-info-panel';
import { useSaveFunction } from './listing-save-bar.hooks';
import { listingValidationSchema } from '../listing.validation';
import { useFormValidation } from '../../../../../utils/validation/validation-utils';
import { GlobalState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';
import { useListingMutations } from '../listing-edit/listing-edit.hooks';
import { ReactComponent as ToggleOff } from '../../../../../assets/svg/toggle-off.svg';
import { ListingFormDataState } from '../listing.state';
import { copyURLtoClipboard, createShareEntityLink } from '../../../../../utils/actions';
import { useCustomBreakpoints, useResponsiveBreakpoints } from '../../../../../utils/hooks/responsive.hooks';
import classNames from 'classnames';
import { useAddAndDeleteFavorites } from '../../../seeker/seeker-view.hooks';
import { useLazyQuery } from '@apollo/client';
import { GET_IS_FAVORITE } from '../../../../../graphql/queries/favorites';
import { GetIsFavorite, GetIsFavoriteVariables } from '../../../../../graphql/types/GetIsFavorite';

export interface ListingSaveBarViewProps {
  formState: ListingFormDataState;
  userPermissions: UserPermissions;
  isCreationMode: boolean;
  uuid: string;
  refetch?: () => Promise<unknown>;
  showActionModal?: () => void;
}

export const ListingSaveBarView: FC<ListingSaveBarViewProps> = ({
  formState,
  userPermissions,
  uuid,
  isCreationMode,
  refetch,
  showActionModal
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { viewMode } = useParams();
  const { hasAdministrativeFunction, isLandlord, isSeeker } = userPermissions;
  const currentUser = useCurrentUser();
  const isLoaded = uuid === undefined || uuid === formState.uuid;
  const isFormDirty = useSelector<GlobalState, boolean>((state) => state.formsDirtyState.isAnyFormDirty);

  const { validateState } = useFormValidation(listingValidationSchema, formState);
  const saveFunction = useSaveFunction(validateState, refetch);
  const { deactivateListing } = useListingMutations(isLandlord);
  const { addFavorite, deleteFavorite } = useAddAndDeleteFavorites(formState.id);

  // Fetch listings
  const [fetchFavorite, { loading, data, refetch: refetchFavorite }] = useLazyQuery<
    GetIsFavorite,
    GetIsFavoriteVariables
  >(GET_IS_FAVORITE, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (isSeeker && currentUser?.email && formState.uuid === uuid && formState.id && !loading && !data) {
      fetchFavorite({ variables: { listingId: formState.id, emailAddress: currentUser.email } });
    }
  }, [data, loading, currentUser, formState]);

  const isPreview = viewMode === 'preview' && uuid === undefined;
  const canBeEditedByCurrentUser =
    hasAdministrativeFunction ||
    (isLoaded && isLandlord && currentUser?.id?.toString() === formState.landlord?.id) ||
    isPreview;

  const breakpoints = useResponsiveBreakpoints();
  const customBreakPoints = useCustomBreakpoints({
    hideDetails: (currentWidth) => currentWidth <= 600
  });

  return (
    <SaveBar trigger={viewMode}>
      <div>
        {(!canBeEditedByCurrentUser || isPreview) &&
          !customBreakPoints.hideDetails &&
          (isLoaded ? (
            <ListingInfoPanel
              size={breakpoints.isDesktop ? 'lg' : 'default'}
              availableFrom={formState.availableFrom}
              numberOfRooms={formState.numberOfRooms}
              squareMeter={formState.squareMeter}
              totalRent={(formState.incidentalCosts || 0) + (formState.parkingSpaceCosts || 0) + formState.rent}
              hideIcons
            />
          ) : (
            <ListingInfoPanelSkeleton size={breakpoints.isDesktop ? 'lg' : 'default'} hideIcons />
          ))}
      </div>
      <div className={styles.SaveBarActions}>
        {canBeEditedByCurrentUser ? (
          <>
            {!isCreationMode && formState.isActive && refetch && (
              <Button
                size={!breakpoints.isDesktop ? 'field' : undefined}
                kind="secondary"
                onClick={() => {
                  deactivateListing().then(() => {
                    refetch().then(() => showActionModal && showActionModal());
                  });
                }}
                renderIcon={ToggleOff}
                iconDescription={t('actions.deactivate')}
                hasIconOnly={!breakpoints.isTablet}
                className={styles.IconButton}
                tooltipPosition={'right'}
              >
                {breakpoints.isMobile ? undefined : t('actions.deactivate')}
              </Button>
            )}
            <Button
              size={!breakpoints.isDesktop ? 'field' : undefined}
              kind="secondary"
              onClick={() => {
                viewMode === 'preview' && isPreview ? navigate('./../new') : navigate('./../edit');
              }}
              renderIcon={EditSVGIcon}
              className={styles.IconButton}
              hasIconOnly={!breakpoints.isDesktop}
              iconDescription={t('actions.editAlt')}
              tooltipPosition={'right'}
            >
              {breakpoints.isDesktop ? t('actions.editAlt') : undefined}
            </Button>
            <Button
              size={!breakpoints.isDesktop ? 'field' : undefined}
              onClick={() => saveFunction(true)().then(() => showActionModal && showActionModal())}
              disabled={formState.isActive && !isFormDirty}
            >
              {formState.isActive && !breakpoints.isMobile ? t('actions.publishChanges') : t('actions.publish')}
            </Button>
          </>
        ) : (
          <>
            <Button
              size={!breakpoints.isDesktop ? 'field' : undefined}
              kind="secondary"
              onClick={() => copyURLtoClipboard(createShareEntityLink(), t)}
              renderIcon={ShareSVGIcon}
              className={styles.IconButton}
              hasIconOnly={!breakpoints.isDesktop}
              iconDescription={t('actions.copyLink')}
              tooltipPosition={breakpoints.isMobile ? 'right' : 'top'}
            >
              {breakpoints.isDesktop ? t('actions.copyLink') : undefined}
            </Button>
            {data?.isFavorite !== undefined && isSeeker && formState.id && currentUser?.email && (
              <Button
                size={!breakpoints.isDesktop ? 'field' : undefined}
                kind="secondary"
                onClick={(event) => {
                  event.stopPropagation();
                  if (currentUser?.email && formState.id) {
                    data.isFavorite
                      ? deleteFavorite({
                          variables: { listingId: formState.id, emailAddress: currentUser.email }
                        }).then(() => refetchFavorite && refetchFavorite())
                      : addFavorite().then(() => refetchFavorite && refetchFavorite());
                  }
                }}
                renderIcon={data.isFavorite ? FavoriteSVGIcon : FavoriteBorderSVGIcon}
                className={styles.IconButton}
                hasIconOnly={!breakpoints.isDesktop}
                tooltipPosition={breakpoints.isMobile ? 'right' : 'top'}
                iconDescription={data.isFavorite ? t('actions.deleteFavorite') : t('actions.addFavorite')}
              >
                {breakpoints.isDesktop
                  ? data.isFavorite
                    ? t('actions.bookmarked')
                    : t('actions.bookmark')
                  : undefined}
              </Button>
            )}
            <a
              href="#contact"
              className={classNames('bx--btn bx--btn--primary', {
                'bx--btn--field bx--btn--sm': !breakpoints.isDesktop
              })}
            >
              {t('actions.contactLandlord')}
            </a>
          </>
        )}
      </div>
    </SaveBar>
  );
};

export default ListingSaveBarView;
