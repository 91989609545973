import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, TextInput, Tooltip } from 'carbon-components-react';
import {
  FormatAlignCenterSVGIcon,
  FormatAlignLeftSVGIcon,
  FormatAlignRightSVGIcon,
  FormatBoldSVGIcon,
  FormatItalicSVGIcon,
  FormatListBulletedSVGIcon,
  FormatListNumberedSVGIcon,
  FormatUnderlinedSVGIcon,
  InsertDriveFileSVGIcon,
  LinkSVGIcon
} from '@react-md/material-icons';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getCurrentLink, insertOrUpdateLink } from '../linkify-plugin';
import { DraftInlineStyle, EditorState, RichUtils } from 'draft-js';
import { useToolbarFocusRoving } from './mail-editor-toolbar.hooks';
import styles from '../mail-editor.module.scss';
import { BlockType } from '../mail-editor';

export const MAIL_EDITOR_FONT_SIZES = [8, 9, 10, 11, 12, 14, 16, 18, 24, 36, 48, 60, 72];
export const EDITOR_DEFAULT_FONT_SIZE = 14;

const fontsizeItems = MAIL_EDITOR_FONT_SIZES.map((size) => ({
  id: size,
  text: `${size}px`
}));

export interface MailEditorToolbarProps {
  editorState: EditorState;
  updateEditorState: (editorState: EditorState) => void;
  currentBlockType: string;
  inlineStyles?: DraftInlineStyle;
  onApplyTemplate?: () => void;
}

export const MailEditorToolbar: FC<MailEditorToolbarProps> = ({
  editorState,
  updateEditorState,
  inlineStyles,
  currentBlockType,
  onApplyTemplate
}) => {
  const { t } = useTranslation();

  const [isLinkFlyoutOpen, setIsLinkFlyoutOpen] = useState<boolean>(false);
  const [currentLinkText, setCurrentLinkText] = useState<string>('');
  const [currentLinkTarget, setCurrentLinkTarget] = useState<string>('');

  const tooltipRef = useRef<HTMLElement>(null);
  const { toolbarRef, toolbarButtons, selectedButtonState } = useToolbarFocusRoving();
  const [selectedToolBarButton, setSelectedToolBarButton] = selectedButtonState;

  const selectedFontSize = useMemo(() => {
    const inlineFontSize =
      inlineStyles &&
      inlineStyles.find((inlineStyle) => inlineStyle !== undefined && inlineStyle.startsWith('FONT_SIZE'));

    return fontsizeItems.find((item) => {
      if (inlineFontSize) {
        return inlineFontSize === `FONT_SIZE_${item.id}`;
      }
      return item.id === EDITOR_DEFAULT_FONT_SIZE;
    });
  }, [inlineStyles, fontsizeItems]);

  const getInlineButtonProps = useCallback(
    (style: string, toggleFunction: (state: EditorState, style: string) => EditorState) => {
      return {
        onMouseDown: (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        },
        onClick: () => {
          const newState = toggleFunction(editorState, style);
          updateEditorState(newState);
        },
        className: classNames(styles.ToolBarButton, {
          [styles.NotToggled]: (!inlineStyles || !inlineStyles.has(style)) && !(currentBlockType === style)
        })
      };
    },
    [editorState, updateEditorState]
  );

  return (
    <div
      id="toolbar"
      ref={toolbarRef}
      className={styles.ToolBar}
      role="toolbar"
      aria-label={t('actions.groups.textFormatting')}
      onKeyDown={(e) => {
        /* istanbul ignore else */
        if (toolbarButtons) {
          const toolBarButtonCount = toolbarButtons.length;

          if (e.key === 'ArrowLeft') {
            setSelectedToolBarButton((selectedToolBarButton - 1 + toolBarButtonCount) % toolBarButtonCount);
          } else if (e.key === 'ArrowRight') {
            setSelectedToolBarButton((selectedToolBarButton + 1) % toolBarButtonCount);
          }
        }
      }}
      onFocus={() => {
        if (selectedToolBarButton === -1) {
          setSelectedToolBarButton(0);
        }
      }}
    >
      <Button
        kind="ghost"
        renderIcon={FormatBoldSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.formatBold')}
        {...getInlineButtonProps('BOLD', RichUtils.toggleInlineStyle)}
      ></Button>
      <Button
        kind="ghost"
        renderIcon={FormatItalicSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.formatItalic')}
        {...getInlineButtonProps('ITALIC', RichUtils.toggleInlineStyle)}
      ></Button>
      <Button
        kind="ghost"
        renderIcon={FormatUnderlinedSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.formatUnderline')}
        {...getInlineButtonProps('UNDERLINE', RichUtils.toggleInlineStyle)}
      ></Button>
      <div className={styles.Spacer} />
      <Button
        kind="ghost"
        renderIcon={FormatAlignLeftSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.alignLeft')}
        {...getInlineButtonProps(BlockType.ALIGN_LEFT, RichUtils.toggleBlockType)}
      ></Button>
      <Button
        kind="ghost"
        renderIcon={FormatAlignCenterSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.alignCenter')}
        {...getInlineButtonProps(BlockType.ALIGN_CENTER, RichUtils.toggleBlockType)}
      ></Button>
      <Button
        kind="ghost"
        renderIcon={FormatAlignRightSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.alignRight')}
        {...getInlineButtonProps(BlockType.ALIGN_RIGHT, RichUtils.toggleBlockType)}
      ></Button>
      <div className={styles.Spacer} />
      <Button
        kind="ghost"
        renderIcon={FormatListBulletedSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.unorderedList')}
        {...getInlineButtonProps('unordered-list-item', RichUtils.toggleBlockType)}
      ></Button>
      <Button
        kind="ghost"
        renderIcon={FormatListNumberedSVGIcon}
        hasIconOnly
        size="sm"
        iconDescription={t('actions.orderedList')}
        {...getInlineButtonProps('ordered-list-item', RichUtils.toggleBlockType)}
      ></Button>
      <div className={styles.Spacer} />
      <Tooltip
        ref={tooltipRef}
        className={styles.LinkMenu}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderIcon={LinkSVGIcon}
        iconDescription={t('actions.addLink')}
        triggerClassName={styles.LinkMenuButton}
        open={isLinkFlyoutOpen}
        triggerId="addLinkTrigger"
        data-testid="AddLinkTrigger"
        onChange={(e, state) => {
          if (e.type !== 'focus' && state) {
            const { open } = state;
            setIsLinkFlyoutOpen(open);
            const currentLink = getCurrentLink(editorState);
            setCurrentLinkTarget(currentLink.url);
            setCurrentLinkText(currentLink.text);

            if (!open && tooltipRef.current) {
              const focusTarget = tooltipRef.current;
              setTimeout(() => {
                focusTarget.focus();
              });
            }
          }
        }}
      >
        <>
          <TextInput
            id="LinkTarget"
            data-testid="LinkTarget"
            size="sm"
            labelText="URL"
            value={currentLinkTarget}
            onChange={(e) => {
              setCurrentLinkTarget(e.target.value);
            }}
            placeholder="http://"
          />
          <TextInput
            id="LinkText"
            data-testid="LinkText"
            size="sm"
            labelText="Text"
            value={currentLinkText}
            onChange={(e) => {
              setCurrentLinkText(e.target.value);
            }}
            placeholder={t('formFields.text')}
          />
          <Button
            id="insertLink"
            data-testid="InsertLinkButton"
            size="sm"
            kind="primary"
            onClick={() => {
              setIsLinkFlyoutOpen(false);

              /* istanbul ignore else */
              if (tooltipRef.current) {
                const focusTarget = tooltipRef.current;
                setTimeout(() => {
                  focusTarget.focus();
                });
              }
              const stateWithLink = insertOrUpdateLink(editorState, currentLinkText, currentLinkTarget);
              updateEditorState(stateWithLink);
            }}
          >
            {t('actions.insertLink')}
          </Button>
        </>
      </Tooltip>
      <div className={styles.Spacer} />
      <Dropdown
        id="fontsize"
        hideLabel
        size="sm"
        label=""
        titleText={t('formFields.fontsize') as string}
        items={fontsizeItems}
        itemToString={(item) => item.text}
        selectedItem={selectedFontSize}
        onChange={({ selectedItem }) => {
          /* istanbul ignore else */
          if (selectedItem) {
            // IMPROVEMENT: Remove previous font size style
            const newState = RichUtils.toggleInlineStyle(editorState, `FONT_SIZE_${selectedItem.id}`);
            updateEditorState(newState);
          }
        }}
      ></Dropdown>

      <Button
        kind="ghost"
        renderIcon={InsertDriveFileSVGIcon}
        size="sm"
        className={styles.DraftButton}
        onMouseDown={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onClick={() => {
          onApplyTemplate && onApplyTemplate();
        }}
      >
        {t('actions.useDraft')}
      </Button>
    </div>
  );
};

export default MailEditorToolbar;
