import { ApolloQueryResult, FetchResult, MutationFunctionOptions, useMutation, useQuery } from '@apollo/client';
import {
  GET_LANDLORD_LISTING_DELETED_SUBSCRIPTION,
  GET_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION,
  GET_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION,
  POST_LANDLORD_LISTING_DELETED_SUBSCRIPTION,
  POST_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION,
  POST_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION
} from '../../../graphql/queries/user-settings';
import { useGenericQueryErrorToast, useQueryResultToast } from '../../../utils/hooks/query-result-toast';
import {
  PostLandlordListingPublishedSubscription,
  PostLandlordListingPublishedSubscriptionVariables
} from '../../../graphql/types/PostLandlordListingPublishedSubscription';
import {
  PostLandlordListingDeletedSubscription,
  PostLandlordListingDeletedSubscriptionVariables
} from '../../../graphql/types/PostLandlordListingDeletedSubscription';
import {
  PostLandlordListingInactiveSubscription,
  PostLandlordListingInactiveSubscriptionVariables
} from '../../../graphql/types/PostLandlordListingInactiveSubscription';
import { GetLandlordListingPublishedSubscription } from '../../../graphql/types/GetLandlordListingPublishedSubscription';
import { GetLandlordListingDeletedSubscription } from '../../../graphql/types/GetLandlordListingDeletedSubscription';
import { GetLandlordListingInactiveSubscription } from '../../../graphql/types/GetLandlordListingInactiveSubscription';
import { useLandlordUserData } from '../account/account-view.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import {
  initialLandlordFormDataState,
  LandlordFormDataState,
  setFormData
} from '../landlord/landlord-view/landlord-view.state';
import { ReactElement, useEffect } from 'react';
import { PUT_UPDATE_LANDLORD } from '../../../graphql/queries/landlord';
import { UpdateLandlord, UpdateLandlordVariables } from '../../../graphql/types/UpdateLandlord';
import { useQueryResultNotificationError } from '../../../utils/hooks/query-result-notification';

// Interface with all settings and refetch function
export interface SubscriptionSettings {
  listingPublished: boolean;
  listingDeleted: boolean;
  listingInactive: boolean;
  refetch: () => Promise<unknown>;
}

// Mutation types
type UpdateListingPublishedMapping = (
  options?: MutationFunctionOptions<
    PostLandlordListingPublishedSubscription,
    PostLandlordListingPublishedSubscriptionVariables
  >
) => Promise<unknown>;
type UpdateListingDeletedMapping = (
  options?: MutationFunctionOptions<
    PostLandlordListingDeletedSubscription,
    PostLandlordListingDeletedSubscriptionVariables
  >
) => Promise<unknown>;
type UpdateListingInactiveMapping = (
  options?: MutationFunctionOptions<
    PostLandlordListingInactiveSubscription,
    PostLandlordListingInactiveSubscriptionVariables
  >
) => Promise<unknown>;

/**
 * Returns all mutations to update the user's subscription settings
 */
export const useUpdateSubscriptionMappings = (): {
  updateListingPublished: UpdateListingPublishedMapping;
  updateListingDeleted: UpdateListingDeletedMapping;
  updateListingInactive: UpdateListingInactiveMapping;
} => {
  const [updateListingPublished] = useMutation<
    PostLandlordListingPublishedSubscription,
    PostLandlordListingPublishedSubscriptionVariables
  >(POST_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION);
  const [updateListingDeleted] = useMutation<
    PostLandlordListingDeletedSubscription,
    PostLandlordListingDeletedSubscriptionVariables
  >(POST_LANDLORD_LISTING_DELETED_SUBSCRIPTION);
  const [updateListingInactive] = useMutation<
    PostLandlordListingInactiveSubscription,
    PostLandlordListingInactiveSubscriptionVariables
  >(POST_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION);

  return {
    updateListingPublished,
    updateListingDeleted,
    updateListingInactive
  };
};

/**
 * Returns the state of the user's subscription settings and provides a function to refetch all settings
 */
export const useSubscriptionMappings = (): SubscriptionSettings => {
  const {
    data: listingPublishedData,
    error: listingPublishedError,
    refetch: listingPublishedRefetch
  } = useQuery<GetLandlordListingPublishedSubscription>(GET_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION);

  const {
    data: listingDeletedData,
    error: listingDeletedError,
    refetch: listingDeletedRefetch
  } = useQuery<GetLandlordListingDeletedSubscription>(GET_LANDLORD_LISTING_DELETED_SUBSCRIPTION);

  const {
    data: listingInactiveData,
    error: listingInactiveError,
    refetch: listingInactiveRefetch
  } = useQuery<GetLandlordListingInactiveSubscription>(GET_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION);

  useGenericQueryErrorToast(listingPublishedError || listingDeletedError || listingInactiveError);

  return {
    listingPublished: !!listingPublishedData?.hasListingPublishedSubscription,
    listingDeleted: !!listingDeletedData?.hasListingDeletedSubscription,
    listingInactive: !!listingInactiveData?.hasListingInactiveSubscription,
    refetch: () => Promise.all([listingPublishedRefetch(), listingDeletedRefetch(), listingInactiveRefetch()])
  };
};

type UpdateLandlordMutation = (
  options?: MutationFunctionOptions<UpdateLandlord, UpdateLandlordVariables> | undefined
) => Promise<FetchResult<UpdateLandlord>>;

/**
 * Fetches the profile data of the landlord and writes it into the landlord form state on change
 */
export const useLandlordProfileChangeData = (): {
  formState: LandlordFormDataState;
  updateLandlord: UpdateLandlordMutation;
  errorNotification: ReactElement | undefined;
  refetchProfileData: () => Promise<ApolloQueryResult<unknown>>;
} => {
  const {
    userState,
    getLandLordQuery: { refetch: refetchProfileData, data: userData }
  } = useLandlordUserData();
  const dispatch = useDispatch();
  const formState = useSelector<GlobalState, LandlordFormDataState>((state) => state.landlordFormData);

  const [updateLandlord, { data, error }] = useMutation<UpdateLandlord, UpdateLandlordVariables>(PUT_UPDATE_LANDLORD, {
    variables: {
      landlord: {
        id: userState?.user?.id || '',
        firstName: formState.firstName,
        lastName: formState.lastName,
        companyName: formState.companyName,
        gender: formState.gender,
        contactType: formState.contactType,
        phone: formState.phone,
        city: formState.city,
        houseNumber: formState.houseNumber,
        street: formState.street,
        postalCode: formState.postalCode
      }
    }
  });

  // we only show toast on success, errors are displayed inline
  useQueryResultToast(data, undefined, {
    success: {
      titleKey: 'notifications.profileUpdateSuccess'
    },
    error: {
      titleKey: ''
    }
  });

  const errorNotification = useQueryResultNotificationError(error);

  useEffect(() => {
    if (userData?.landlord) {
      dispatch(setFormData(Object.assign({}, initialLandlordFormDataState, userData.landlord)));
    }
  }, [userData]);

  return {
    formState,
    updateLandlord,
    errorNotification,
    refetchProfileData
  };
};
