import { FC, useState } from 'react';
import { AddBoxSVGIcon, LaunchSVGIcon, LinkSVGIcon } from '@react-md/material-icons';
import { Button, InlineLoading } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { ActionBar, ActionBarLeft, ActionBarRight } from '../../../../building-blocks/action-bar/action-bar';
import { resetForm } from '../../../landlord/landlord-view/landlord-view.state';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { exportCsvData, exportLinks } from '../listings-list.export';
import { GetListingsVariables } from '../../../../../graphql/types/GetListings';
import { useUserPermission } from '../../../../../utils/hooks/user-data';
import { copyAllURLsToClipboard } from '../../../../../utils/actions';

export interface ListingsListActionBarProps {
  variables: GetListingsVariables;
}

export const ListingsListActionBar: FC<ListingsListActionBarProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [disableExport, setDisableExport] = useState(false);
  const [disableCopyAllLinksButton, setDisableCopyAllLinksButton] = useState(false);
  const apolloClient = useApolloClient();

  const { hasAdministrativeFunction } = useUserPermission();

  // Export landlord data to csv file
  const exportData = (): void => {
    setDisableExport(true);
    exportCsvData(t, apolloClient, props.variables).then(() => setDisableExport(false));
  };

  // Export all listing links to clipboard
  const copyAllLinks = async (): Promise<void> => {
    setDisableCopyAllLinksButton(true);
    copyAllURLsToClipboard(exportLinks(apolloClient, props.variables), t).then(() => {
      setDisableCopyAllLinksButton(false);
    });
  };

  return hasAdministrativeFunction ? (
    <ActionBar>
      <ActionBarLeft>
        <Button
          kind="ghost"
          size="sm"
          renderIcon={AddBoxSVGIcon}
          className="bx--btn--hasIcon"
          onClick={() => {
            dispatch(resetForm());
            navigate('new');
          }}
        >
          {t('actions.createListing')}
        </Button>
      </ActionBarLeft>
      <ActionBarRight>
        {disableCopyAllLinksButton && <InlineLoading description={t('actions.copyLinkAll')} />}
        {!disableCopyAllLinksButton && (
          <Button kind="ghost" size="sm" renderIcon={LinkSVGIcon} className="bx--btn--hasIcon" onClick={copyAllLinks}>
            {t('actions.copyLinkAll')}
          </Button>
        )}
        {disableExport && <InlineLoading description={t('actions.export')} />}
        {!disableExport && (
          <Button kind="ghost" size="sm" renderIcon={LaunchSVGIcon} className="bx--btn--hasIcon" onClick={exportData}>
            {t('actions.export')}
          </Button>
        )}
      </ActionBarRight>
    </ActionBar>
  ) : null;
};

export default ListingsListActionBar;
