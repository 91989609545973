import {
  ContentCopySVGIcon,
  EditSVGIcon,
  MailSVGIcon,
  ShareSVGIcon,
  VisibilitySVGIcon
} from '@react-md/material-icons';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { copyURLtoClipboard, MailContent, shareByMail } from '../../../utils/actions';

export interface TableOverflowMenuProps {
  row: { uuid: string };
  onOpen: () => void;
  onClose: () => void;
  getShareByMailContent: () => MailContent;
  onDuplicate?: () => void;
}

export const TableOverflowMenu: FC<TableOverflowMenuProps> = ({
  row,
  onOpen,
  onClose,
  getShareByMailContent,
  onDuplicate
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <OverflowMenu
      onClick={(e) => {
        e.stopPropagation();
      }}
      flipped
      onOpen={onOpen}
      onClose={onClose}
      data-testid="OverflowMenu"
    >
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        itemText={
          <span>
            <EditSVGIcon />
            {t('actions.edit')}
          </span>
        }
        onClick={() => {
          navigate(`./${row.uuid}/edit`);
        }}
      />
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        itemText={
          <span>
            <VisibilitySVGIcon />
            {t('actions.open')}
          </span>
        }
        onClick={() => {
          navigate(`./${row.uuid}/view`);
        }}
      />
      {onDuplicate && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          itemText={
            <span>
              <ContentCopySVGIcon />
              {t('actions.duplicate')}
            </span>
          }
          onClick={onDuplicate}
        />
      )}
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        hasDivider
        itemText={
          <span>
            <ShareSVGIcon />
            {t('actions.shareByLink')}
          </span>
        }
        onClick={() => copyURLtoClipboard(process.env.REACT_APP_PUBLIC_URL + pathname + `/${row.uuid}/view`, t)}
      />
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        hasDivider
        itemText={
          <span>
            <MailSVGIcon />
            {t('actions.shareByMail')}
          </span>
        }
        onClick={() => shareByMail(getShareByMailContent())}
      />
    </OverflowMenu>
  );
};

export default TableOverflowMenu;
