import styles from './dialog-back-navigation.module.scss';
import { FC, PropsWithChildren } from 'react';
import { ArrowBackSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

export interface DialogBackNavigationProps {
  headline: string;
  info?: string;
  onBackClick: () => void;
}

export const DialogBackNavigation: FC<PropsWithChildren<DialogBackNavigationProps>> = (
  props: PropsWithChildren<DialogBackNavigationProps>
) => {
  const { t } = useTranslation();

  return (
    <div className={styles.DialogBackNavigation}>
      <Button
        kind="secondary"
        hasIconOnly
        renderIcon={ArrowBackSVGIcon}
        iconDescription={t('actions.back')}
        tooltipPosition="right"
        size={'field'}
        onClick={props.onBackClick}
      />
      <div className={styles.DialogBackNavigationInfo}>
        <h5>{props.headline}</h5>
        {props.info && <p>{props.info}</p>}
      </div>
    </div>
  );
};
