import { gql } from '@apollo/client';

export const GET_FAVORITES = gql`
  # Get favorites (listings) from email address
  query GetFavorites($emailAddress: String!) {
    favorites(emailAddress: $emailAddress) {
      id
      uuid
      type
      numberOfRooms
      availableFrom
      city
      district
      street
      houseNumber
      postalCode
      squareMeter
      tags
      images {
        id
        modifiedAt
        description
        descriptionEn
      }
      totalRent
    }
  }
`;

export const GET_IS_FAVORITE = gql`
  # Checks if the given listing is a favorite of the user
  query GetIsFavorite($emailAddress: String!, $listingId: ID!) {
    isFavorite(emailAddress: $emailAddress, listingId: $listingId)
  }
`;

export const POST_ADD_FAVORITE = gql`
  # Adds a new favorite
  mutation PostAddFavorite($emailAddress: String!, $listingId: ID!) {
    addFavorite(emailAddress: $emailAddress, listingId: $listingId)
  }
`;

export const DELETE_FAVORITE = gql`
  # Deletes favorite
  mutation DeleteFavorite($emailAddress: String!, $listingId: ID!) {
    deleteFavorite(emailAddress: $emailAddress, listingId: $listingId)
  }
`;
