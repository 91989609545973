import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AdministrationState {
  numberOfNewLandlords: number;
  numberOfNewSearchRequests: number;
}

export const initialAdministrationState: AdministrationState = {
  numberOfNewLandlords: 0,
  numberOfNewSearchRequests: 0
};

const slice = createSlice({
  name: 'AdministrationState',
  reducers: {
    setNumberOfNewLandlords: (state, action: PayloadAction<number>) => {
      state.numberOfNewLandlords = action.payload;
    },
    setNumberOfNewSearchRequests: (state, action: PayloadAction<number>) => {
      state.numberOfNewSearchRequests = action.payload;
    }
  },
  initialState: initialAdministrationState
});

export const setNumberOfNewLandlords: ActionCreatorWithPayload<number> = slice.actions.setNumberOfNewLandlords;
export const setNumberOfNewSearchRequests: ActionCreatorWithPayload<number> =
  slice.actions.setNumberOfNewSearchRequests;

export const administrationStateReducer = slice.reducer;
