import { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  ComposedModal,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Form,
  FormGroup,
  InlineNotification,
  ModalBody,
  ModalHeader,
  NumberInput,
  RadioButton,
  RadioButtonGroup,
  TextInput
} from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import {
  ContentView,
  ContentViewSection,
  RequiredFormItem
} from '../../../../building-blocks/content-view/content-view';
import styles from './listing-edit.module.scss';
import LandLordSearch from './listing-edit-landlord-search/listing-edit-landlord-search';
import {
  ContactType,
  Gender,
  HousingType,
  ListingType,
  TagCategory,
  TUMLocation
} from '../../../../../graphql/types/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListingFormDataState,
  setAvailableFrom,
  setAvailableUntil,
  setCity,
  setCoordinates,
  setDeposit,
  setDistrict,
  setFloor,
  setFurtherEquipment,
  setFurtherEquipmentEn,
  setHouseNumber,
  setHousingType,
  setIncidentalCosts,
  setIncidentalCostsCustomLabel,
  setIncidentalCostsTypes,
  setIsListingPublic,
  setLandlord,
  setNumberOfRooms,
  setOneTimeCosts,
  setOneTimeCostsLabel,
  setParkingSpace,
  setParkingSpaceCosts,
  setPostalCode,
  setRent,
  setSeekingDoctoralStudents,
  setSeekingFlags,
  setSeekingGuestResearchers,
  setSeekingIncomings,
  setSeekingPostDoctoralStudents,
  setSeekingProfessors,
  setSeekingStudents,
  setSeekingTumEmployees,
  setSquareMeter,
  setStreet,
  setTags,
  setTUMLocation,
  setType,
  setVerifiedAt
} from '../listing.state';
import { useManagementType } from './listing-edit.hooks';
import { MapSVGIcon } from '@react-md/material-icons';
import RentComposition from '../../../../controls/rent-composition/rent-composition';
import { TextAreaCount } from '../../../../controls/text-area-count/text-area-count';
import { listingValidationSchema } from '../listing.validation';
import { FormValidationUtils } from '../../../../../utils/validation/validation-utils';
import { FormikErrors, yupToFormErrors } from 'formik';
import ImageUpload from './listing-edit-image-upload/listing-edit-image-upload';
import {
  AREA_INPUT_DEFAULT_PROPS,
  AVAILABLE_BY_MIN_DATE_STRING,
  CITY_MAX_LENGTH,
  COST_INPUT_DEFAULT_PROPS,
  getNumberChangeHandler,
  POSTAL_CODE_MAX_LENGTH,
  postalCodeCharacterInputHandler
} from '../../../../../utils/validation/input-validation';
import { useCurrentUser, UserPermissions } from '../../../../../utils/hooks/user-data';
import {
  ListingVisibility,
  ManagementType,
  ORDERED_TAG_CATEGORIES,
  ParkingSpaceAvailability
} from '../listing.constants';
import { ListingActionBar } from '../listing-action-bar/listing-action-bar';
import { ViewMode } from '../../../../building-blocks/view-mode-switcher/view-mode-switcher';
import { CostToolTip } from './listing-edit-cost-tooltip/listing-edit-cost-tooltip';
import { setIsAnyFormDirty } from '../../../../../redux/forms.state';
import { FormBlockingDialog } from '../../../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import { ListingSelectableItems } from '../listing.hooks';
import { GlobalState } from '../../../../../redux/store';
import { LandlordFormDataState } from '../../../landlord/landlord-view/landlord-view.state';
import { LandlordProfileDataState } from '../../../account/account-view.state';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import LeafletMap from '../../../../controls/map/map';
import { Point } from 'leaflet';
import { listingAddressString } from '../../../../../utils/listings';
import { UNIT_AREA_SQUARE_METER, UNIT_CURRENCY_EURO } from '../../../../../utils/constants';

export interface ListingEditProps {
  formState: ListingFormDataState;
  uuid: string;
  isCreationMode: boolean;
  userPermissions: UserPermissions;
  selectableItems: ListingSelectableItems;
  onResetForm: () => void;
  formValidationUtils: FormValidationUtils<ListingFormDataState>;
}

export const ListingEdit: FC<ListingEditProps> = ({
  formState,
  isCreationMode,
  userPermissions,
  selectableItems,
  onResetForm,
  formValidationUtils
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasAdministrativeFunction, isLandlord } = userPermissions;
  const currentUser = useCurrentUser();
  const [isImmediatelyAvailable, setIsImmediatelyAvailable] = useState(false);
  const [coordinatesError, setCoordinatesError] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const provider = new OpenStreetMapProvider();
  const currentLandLordFormState = useSelector<GlobalState, LandlordFormDataState>((state) => state.landlordFormData);
  const currentLandLordPreferences = useSelector<GlobalState, LandlordProfileDataState>(
    (state) => state.landlordProfileData
  );

  const {
    formErrorState: [formErrors, setFormErrors],
    getValidationPropsForField,
    clearFormError
  } = formValidationUtils;

  const {
    type: listingType,
    availableFrom,
    availableUntil,
    district,
    city,
    tumLocation,
    street,
    numberOfRooms,
    squareMeter,
    floor,
    housingType,
    parkingSpace,
    houseNumber,
    postalCode,
    rent,
    incidentalCosts,
    incidentalCostsCustomLabel,
    incidentalCostsTypes,
    oneTimeCosts,
    oneTimeCostsLabel,
    parkingSpaceCosts,
    deposit,
    tags,
    furtherEquipment,
    furtherEquipmentEn,
    seekingStudents,
    seekingProfessors,
    seekingIncomings,
    seekingDoctoralStudents,
    seekingPostDoctoralStudents,
    seekingGuestResearchers,
    seekingTumEmployees,
    isActive,
    isListingPublic,
    expirationDate,
    verifiedAt,
    images
  } = formState;

  const [managementType, setManagementType] = useManagementType(formState);

  const {
    numberOfRoomsItems,
    cityItems,
    districtItems,
    incidentalCostTypes: incidentalCostTypesValues,
    tagsForCategories,
    housingTypeItems
  } = selectableItems;

  // Custom handler which checks the imaginaryTarget field provided by carbon react for the given value.
  // Also handles empty values and values exceeding max length
  const handleNumberChange = useCallback((setter) => getNumberChangeHandler(setter, 5), []);

  const seekingFlags = [
    seekingStudents,
    seekingProfessors,
    seekingIncomings,
    seekingDoctoralStudents,
    seekingPostDoctoralStudents,
    seekingGuestResearchers,
    seekingTumEmployees
  ];

  const seekingAll = seekingFlags.every((flag) => flag);
  const seekingNone = seekingFlags.every((flag) => !flag);

  useEffect(() => {
    if (seekingNone) {
      dispatch(setSeekingFlags(true));
    }
  }, [seekingNone, setSeekingFlags, dispatch]);

  const [displayedImageLength, setDisplayedImageLength] = useState(images.length);

  // re-validate if an image has been deleted and there were form errors before
  useEffect(() => {
    const newImageLength = images.filter((image) => !image.markedForDeletion).length;
    if (newImageLength < displayedImageLength && formErrors.images) {
      listingValidationSchema
        .validateAt('images', formState, { abortEarly: false })
        .then(() => {
          // everything alright, remove images from errors
          setFormErrors(Object.assign({}, formErrors, { images: undefined }));
        })
        .catch((error) => {
          const errors = yupToFormErrors<FormikErrors<ListingFormDataState>>(error);
          setFormErrors(Object.assign({}, formErrors, { images: errors.images }));
        });
    }
    setDisplayedImageLength(newImageLength);
  }, [images, displayedImageLength, formErrors]);

  /**
   * Effect which sets the landlord form field when a new listing is being created by a landlord.
   * Also sets other default values that differ from the creation by administrative users.
   */
  useEffect(() => {
    if (formState.landlord === null && isLandlord && isCreationMode && currentUser?.id) {
      const contactDataAndPreferences =
        currentLandLordFormState.id === currentUser?.id && currentLandLordPreferences
          ? {
              firstName: currentLandLordFormState.firstName,
              lastName: currentLandLordFormState.lastName,
              email: currentLandLordFormState.email,
              phone: currentLandLordFormState.phone,
              gender: currentLandLordFormState.gender,
              ...currentLandLordPreferences
            }
          : {};
      // Note: Only the id is relevant for the creation of the listing
      dispatch(
        setLandlord(
          Object.assign(
            {
              id: currentUser?.id,
              firstName: '',
              lastName: '',
              uuid: '',
              gender: Gender.DIVERSE,
              email: '',
              phone: '',
              companyName: '',
              showFirstName: true,
              showLastName: true,
              showEmail: true,
              showPhone: true,
              showCompany: true,
              contactType: ContactType.PRIVATE,
              verifiedAt: null,
              blockedBy: null,
              __typename: 'Landlord' as const
            },
            contactDataAndPreferences
          )
        )
      );
      dispatch(setIsListingPublic(true));

      // we have to reset the dirty state, which was triggered by the automatic actions above
      dispatch(setIsAnyFormDirty(false));
    }
  }, [formState.landlord, isLandlord, isCreationMode, currentUser]);

  useEffect(() => {
    if (formState.street.length > 3 && formState.postalCode.length > 3 && formState.city !== null) {
      // Get coordinates from address
      provider
        .search({ query: `${formState.street} ${formState.houseNumber}, ${formState.postalCode} ${formState.city}` })
        .then((result) => {
          dispatch(setCoordinates(result.length > 0 ? { x: result[0].y, y: result[0].x, __typename: 'Point' } : null));
          setCoordinatesError(result.length === 0);
        });
    } else {
      dispatch(setCoordinates(null));
    }
  }, [formState.street, formState.houseNumber, formState.postalCode, formState.city]);

  return (
    <>
      <ListingActionBar
        viewMode={ViewMode.EDIT}
        isCreationMode={isCreationMode}
        isActive={isActive}
        city={formState.city}
        type={formState.type}
      />
      <div className="global-content-wrapper-block">
        <Form
          id="listing-data"
          noValidate
          onSubmit={(event) => {
            // prevent submit since we have custom validation logic and requests
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <ContentView className={styles.Content}>
            {hasAdministrativeFunction && (
              <ContentViewSection title={t('views.listings.sections.landlord')} anchorId="landlord">
                <RadioButtonGroup
                  name="management"
                  valueSelected={managementType}
                  className={styles.ManagementTypeSelection}
                  onChange={(value) => {
                    setManagementType(value as ManagementType);

                    // reset selected landlord when management is chosen
                    if (value === ManagementType.MANAGEMENT) {
                      dispatch(setLandlord(null));
                    }
                  }}
                >
                  <RadioButton
                    id={ManagementType.MANAGEMENT}
                    key={ManagementType.MANAGEMENT}
                    value={ManagementType.MANAGEMENT}
                    labelText={t('views.listings.managementTypes.managementIsResponsible')}
                  />
                  <RadioButton
                    id={ManagementType.LANDLORD}
                    key={ManagementType.LANDLORD}
                    value={ManagementType.LANDLORD}
                    className={styles.ManagementTypeLandlord}
                    labelText={t('views.listings.managementTypes.assignLandlord')}
                  />
                </RadioButtonGroup>
                <LandLordSearch
                  disabled={managementType === ManagementType.MANAGEMENT}
                  onSelect={(landlordId) => {
                    dispatch(setLandlord(landlordId || null));
                    clearFormError('landlord');
                  }}
                  {...getValidationPropsForField('landlord')}
                />
              </ContentViewSection>
            )}
            <ContentViewSection title={t('views.listings.sections.object')} anchorId="object" showRequiredToolTip>
              <div className={styles.ObjectSection}>
                <RequiredFormItem className={styles.ObjectSectionListingType} isEditMode>
                  <FormGroup
                    legendText={t('views.listings.listingType') as string}
                    className={styles.ObjectSectionListingType}
                  >
                    <RadioButtonGroup
                      name="listingType"
                      valueSelected={listingType}
                      onChange={(value) => dispatch(setType(value as ListingType))}
                      orientation="horizontal"
                    >
                      {Object.values(ListingType).map((type) => (
                        <RadioButton id={type} key={type} value={type} labelText={t(`enums.listingType.${type}`)} />
                      ))}
                    </RadioButtonGroup>
                  </FormGroup>
                </RequiredFormItem>

                {listingType !== ListingType.HOUSE && (
                  <>
                    <Dropdown
                      id="housingType"
                      titleText={t('formFields.housingType') as string}
                      label={t('formFields.pleaseSelect') as string}
                      items={housingTypeItems}
                      itemToString={(item) => (item ? item.text : '')}
                      selectedItem={housingType ? housingTypeItems.find((item) => item.id === housingType) : null}
                      {...getValidationPropsForField('housingType', ({ selectedItem }) => {
                        if (selectedItem) {
                          dispatch(setHousingType(selectedItem.id));
                          [(HousingType.APARTMENT, HousingType.ATTIC)].indexOf(selectedItem.id) === -1 &&
                            dispatch(setFloor(0));
                        }
                      })}
                    />
                    {housingType && [HousingType.APARTMENT, HousingType.ATTIC].indexOf(housingType) > -1 ? (
                      <NumberInput
                        id="floor"
                        value={floor || ''}
                        label={t('formFields.floor')}
                        min={1}
                        max={1000}
                        step={1}
                        allowEmpty
                        placeholder={t('formFields.pleaseSpecify')}
                        {...getValidationPropsForField(
                          'floor',
                          handleNumberChange((value: number) => dispatch(setFloor(value)))
                        )}
                      />
                    ) : (
                      <div />
                    )}
                  </>
                )}

                <RequiredFormItem isEditMode>
                  <Dropdown
                    id="numberOfRooms"
                    titleText={t('formFields.numberOfRooms') as string}
                    label={t('formFields.pleaseSelect') as string}
                    items={numberOfRoomsItems}
                    itemToString={(item) => (item ? item.text : '')}
                    selectedItem={
                      numberOfRooms
                        ? numberOfRoomsItems.find((item) => Math.abs(item.id - numberOfRooms) < 0.00001)
                        : null
                    }
                    {...getValidationPropsForField(
                      'numberOfRooms',
                      ({ selectedItem }) => selectedItem && dispatch(setNumberOfRooms(selectedItem.id))
                    )}
                  />
                </RequiredFormItem>
                <RequiredFormItem isEditMode>
                  <NumberInput
                    id="squareMeter"
                    value={squareMeter || ''}
                    label={`${t('formFields.livingSpace')} (${UNIT_AREA_SQUARE_METER})`}
                    {...AREA_INPUT_DEFAULT_PROPS}
                    placeholder={t('formFields.pleaseSpecify')}
                    {...getValidationPropsForField(
                      'squareMeter',
                      handleNumberChange((value: number) => dispatch(setSquareMeter(value)))
                    )}
                  />
                </RequiredFormItem>

                <RequiredFormItem isEditMode>
                  <DatePicker
                    id="availableFrom"
                    datePickerType="single"
                    value={availableFrom}
                    allowInput={false}
                    dateFormat={'d.m.Y'}
                    onChange={(dates) => {
                      dispatch(setAvailableFrom(dates[0] ? dates[0].toISOString() : ''));
                      clearFormError('availableFrom');
                    }}
                    minDate={AVAILABLE_BY_MIN_DATE_STRING}
                  >
                    <DatePickerInput
                      id="availableFromInput"
                      placeholder="dd.mm.yyyy"
                      labelText={t('formFields.moveInDate') as string}
                      disabled={isImmediatelyAvailable}
                      {...getValidationPropsForField('availableFrom')}
                    />
                  </DatePicker>
                  <Checkbox
                    id="availableImmediately"
                    labelText={t('formFields.immediately') as string}
                    checked={isImmediatelyAvailable}
                    onChange={(isChecked) => {
                      setIsImmediatelyAvailable(isChecked);
                      isChecked && dispatch(setAvailableFrom(new Date().toISOString()));
                    }}
                    className={styles.Checkbox}
                  />
                </RequiredFormItem>
                <RequiredFormItem isEditMode>
                  <DatePicker
                    id="availableUntil"
                    datePickerType="single"
                    value={availableUntil === null || availableUntil.length === 0 ? undefined : availableUntil}
                    allowInput={false}
                    dateFormat={'d.m.Y'}
                    minDate={availableFrom || AVAILABLE_BY_MIN_DATE_STRING}
                    onChange={(dates) => {
                      dispatch(setAvailableUntil(dates[0].toISOString()));
                      clearFormError('availableUntil');
                    }}
                  >
                    <DatePickerInput
                      id="availableUntilInput"
                      placeholder="dd.mm.yyyy"
                      labelText={t('formFields.moveOutDate') as string}
                      disabled={availableUntil === null}
                      {...getValidationPropsForField('availableUntil')}
                    />
                  </DatePicker>
                  <Checkbox
                    id="isOpenEnded"
                    checked={availableUntil === null}
                    labelText={t('formFields.openEnded') as string}
                    onChange={(isChecked) => {
                      isChecked ? dispatch(setAvailableUntil(null)) : dispatch(setAvailableUntil(''));
                    }}
                    className={styles.Checkbox}
                  />
                </RequiredFormItem>
              </div>
            </ContentViewSection>
            <ContentViewSection title={t('views.listings.sections.address')} anchorId="address" showRequiredToolTip>
              <div className={styles.AddressSection}>
                <RequiredFormItem isEditMode>
                  <Dropdown
                    id="tumLocation"
                    titleText={
                      <CostToolTip
                        triggerText={t('formFields.tumLocation')}
                        infoText={t('tooltips.tumLocationTooltip')}
                      />
                    }
                    label={t('formFields.pleaseSelect') as string}
                    items={cityItems}
                    itemToString={(item) => (item ? item.text : '')}
                    selectedItem={cityItems.find((cityItem) => cityItem.id === tumLocation)}
                    onChange={({ selectedItem }) => {
                      selectedItem && dispatch(setTUMLocation(selectedItem.id as TUMLocation));
                      dispatch(setDistrict(null));
                    }}
                  />
                </RequiredFormItem>
                <div />
                <div />
                <RequiredFormItem isEditMode>
                  <TextInput
                    id="street"
                    name="street"
                    labelText={`${t('formFields.street')}`}
                    placeholder={`${t('formFields.streetName')}`}
                    value={street}
                    {...getValidationPropsForField('street', (event) => {
                      dispatch(setStreet(event.target.value));
                    })}
                  />
                </RequiredFormItem>
                <TextInput
                  // Note: streetNumber is recognized by chrome to improve auto filling
                  id="streetNumber"
                  name="streetNumber"
                  labelText={`${t('formFields.houseNumberAbbreviation')}`}
                  placeholder={'\u2013'}
                  onChange={(event) => {
                    dispatch(setHouseNumber(event.target.value));
                  }}
                  value={houseNumber}
                />
                <div />
                <RequiredFormItem isEditMode className={styles.CityLine}>
                  <TextInput
                    id="postalCode"
                    name="postalCode"
                    labelText={`${t('formFields.postalCode')}`}
                    placeholder={`${t('formFields.postalCodeAbbreviation')}`}
                    maxLength={POSTAL_CODE_MAX_LENGTH}
                    onKeyDown={postalCodeCharacterInputHandler}
                    value={postalCode}
                    {...getValidationPropsForField('postalCode', (event) => {
                      dispatch(setPostalCode(event.target.value));
                    })}
                  />
                  <TextInput
                    id="city"
                    name="city"
                    labelText={`${t('formFields.city')}`}
                    placeholder={`${t('formFields.city')}`}
                    maxLength={CITY_MAX_LENGTH}
                    value={city}
                    {...getValidationPropsForField('city', (event) => {
                      dispatch(setCity(event.target.value));
                    })}
                  />
                </RequiredFormItem>
                {tumLocation !== TUMLocation.GARMISCH_PARTENKIRCHEN && (
                  <Dropdown
                    id="district"
                    titleText={t('formFields.district') as string}
                    label={t('formFields.pleaseSelect') as string}
                    items={districtItems}
                    itemToString={(item) => (item ? item.text : '')}
                    onChange={({ selectedItem }) => selectedItem && dispatch(setDistrict(selectedItem.id))}
                    selectedItem={districtItems?.find((option) => option.id === district) || null}
                  />
                )}
              </div>

              {coordinatesError && (
                <InlineNotification
                  className={styles.AddressNotification}
                  kind="error"
                  title={t('views.listings.addressError') as string}
                />
              )}
              <Button
                size="field"
                kind="secondary"
                renderIcon={MapSVGIcon}
                className="bx--btn--hasIcon"
                disabled={formState.coordinates === null}
                onClick={() => setMapModal(true)}
              >
                {t('actions.showMap')}
              </Button>
            </ContentViewSection>
            <ContentViewSection title={t('views.listings.sections.cost')} anchorId="cost">
              <div className={styles.CostSection}>
                <div className={styles.CostSectionInputs}>
                  <RequiredFormItem isEditMode>
                    <NumberInput
                      id="rent"
                      value={rent > 0 ? rent : ''}
                      label={t('formFields.rent')}
                      {...COST_INPUT_DEFAULT_PROPS}
                      {...getValidationPropsForField(
                        'rent',
                        handleNumberChange((value: number) => dispatch(setRent(value)))
                      )}
                    />
                  </RequiredFormItem>
                  <div>
                    <NumberInput
                      id="incidentalCosts"
                      value={incidentalCosts || ''}
                      label={
                        <CostToolTip
                          triggerText={`${t('formFields.incidentalCosts')} (${UNIT_CURRENCY_EURO})`}
                          infoText={t('tooltips.incidentalCostsTooltip')}
                        />
                      }
                      onChange={handleNumberChange((value: number) => dispatch(setIncidentalCosts(value)))}
                      {...COST_INPUT_DEFAULT_PROPS}
                    />

                    <div className={styles.IncidentalCostsTypes}>
                      {incidentalCostTypesValues.map((type) => (
                        <Checkbox
                          id={type.id}
                          key={type.id}
                          name={type.id}
                          value={type.id}
                          labelText={type.text}
                          checked={(incidentalCostsTypes && incidentalCostsTypes.indexOf(type.id) > -1) || false}
                          onChange={(checked) =>
                            checked
                              ? dispatch(setIncidentalCostsTypes([...incidentalCostsTypes, type.id]))
                              : dispatch(
                                  setIncidentalCostsTypes(
                                    incidentalCostsTypes.filter((costType) => costType !== type.id)
                                  )
                                )
                          }
                        />
                      ))}

                      <div className={styles.CustomIncidentalCostType}>
                        <Checkbox
                          id="customIncidentalCostType"
                          key="customIncidentalCostType"
                          name="customIncidentalCostType"
                          labelText=""
                          aria-label={t('formFields.customIncidentalCostTypeCheckboxLabel')}
                          checked={incidentalCostsCustomLabel !== null}
                          onChange={(checked) => {
                            {
                              checked
                                ? dispatch(setIncidentalCostsCustomLabel(''))
                                : dispatch(setIncidentalCostsCustomLabel(null));
                            }
                          }}
                        />
                        <TextInput
                          id="customIncidentalCostTypeLabel"
                          name="customIncidentalCostTypeLabel"
                          labelText=""
                          placeholder={`${t('formFields.placeholder.other')}`}
                          value={incidentalCostsCustomLabel || ''}
                          onChange={(event) => {
                            dispatch(
                              setIncidentalCostsCustomLabel(event.target.value.length === 0 ? null : event.target.value)
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <FormGroup legendText={`${t('formFields.parkingSpace') as string} (${UNIT_CURRENCY_EURO})`}>
                    <RadioButtonGroup
                      name="parkingSpace"
                      valueSelected={
                        parkingSpace ? ParkingSpaceAvailability.AVAILABLE : ParkingSpaceAvailability.NOT_AVAILABLE
                      }
                      className={styles.RadioGroupVertical}
                      onChange={(value) => {
                        const hasParkingSpace = value === ParkingSpaceAvailability.AVAILABLE;
                        dispatch(setParkingSpace(hasParkingSpace));
                        !hasParkingSpace && dispatch(setParkingSpaceCosts(null));
                      }}
                    >
                      <RadioButton
                        id="doesNotHaveParkingSpace"
                        key={ParkingSpaceAvailability.NOT_AVAILABLE}
                        value={ParkingSpaceAvailability.NOT_AVAILABLE}
                        labelText={t('formFields.notAvailable')}
                      />
                      <RadioButton
                        id="hasParkingSpace"
                        key={ParkingSpaceAvailability.AVAILABLE}
                        value={ParkingSpaceAvailability.AVAILABLE}
                        labelText={t('formFields.available')}
                      />
                    </RadioButtonGroup>

                    {parkingSpace && (
                      <NumberInput
                        id="parkingSpaceCosts"
                        value={parkingSpaceCosts || ''}
                        ariaLabel={t('formFields.parkingSpaceCosts')}
                        onChange={handleNumberChange((value: number) => dispatch(setParkingSpaceCosts(value)))}
                        {...COST_INPUT_DEFAULT_PROPS}
                        className={styles.ParkingSpaceCosts}
                      />
                    )}
                  </FormGroup>

                  <FormGroup
                    key="oneTimeCosts"
                    name="oneTimeCosts"
                    legendText={
                      <CostToolTip
                        triggerText={`${t('formFields.oneTimeCosts')} (${UNIT_CURRENCY_EURO})`}
                        infoText={t('tooltips.oneTimeCostsTooltip')}
                      />
                    }
                  >
                    <TextInput
                      id="oneTimeCostsLabel"
                      name="oneTimeCostsLabel"
                      labelText=""
                      placeholder={`${t('formFields.placeholder.oneTimeCostsLabelPlaceholder')}`}
                      value={oneTimeCostsLabel || ''}
                      {...getValidationPropsForField('oneTimeCostsLabel', (event) => {
                        dispatch(setOneTimeCostsLabel(event.target.value));
                      })}
                    />
                    <NumberInput
                      id="oneTimeCosts"
                      value={oneTimeCosts || ''}
                      onChange={handleNumberChange((value: number) => dispatch(setOneTimeCosts(value)))}
                      {...COST_INPUT_DEFAULT_PROPS}
                      className={styles.OneTimeCosts}
                    />
                  </FormGroup>

                  <NumberInput
                    id="deposit"
                    value={deposit || ''}
                    label={
                      <CostToolTip
                        triggerText={`${t('formFields.deposit')} (${UNIT_CURRENCY_EURO})`}
                        infoText={t('tooltips.depositTooltip')}
                      />
                    }
                    onChange={handleNumberChange((value: number) => dispatch(setDeposit(value)))}
                    {...COST_INPUT_DEFAULT_PROPS}
                  />
                </div>

                <RentComposition
                  rent={rent}
                  incidentalCosts={incidentalCosts || 0}
                  incidentalCostsTypes={[
                    ...incidentalCostsTypes.map((type) => t(`enums.incidentalCostsTypes.${type}`)),
                    incidentalCostsCustomLabel
                  ]}
                  parkingSpaceCosts={parkingSpaceCosts || 0}
                  fullRent={rent + (incidentalCosts || 0) + (parkingSpaceCosts || 0)}
                  className={styles.RentOverview}
                />
              </div>
            </ContentViewSection>
            <ContentViewSection title={t('views.listings.sections.configuration')} anchorId="configuration">
              <div className={styles.TagsSection}>
                {ORDERED_TAG_CATEGORIES.map((category) => (
                  <FormGroup
                    key={category}
                    name={category}
                    legendText={t(`enums.tagCategory.${category}`) as string}
                    className={category === TagCategory.OTHER ? styles.MiscTags : undefined}
                  >
                    {tagsForCategories.get(category)?.map((tag) => (
                      <Checkbox
                        id={tag.id}
                        key={tag.id}
                        name={tag.id}
                        value={tag.id}
                        labelText={tag.text}
                        checked={(tags && tags.indexOf(tag.id) > -1) || false}
                        onChange={(checked) =>
                          checked
                            ? dispatch(setTags([...tags, tag.id]))
                            : dispatch(setTags(tags.filter((tagListTag) => tagListTag !== tag.id)))
                        }
                      />
                    ))}
                  </FormGroup>
                ))}
                <TextAreaCount
                  labelText={t('formFields.furtherEquipment')}
                  id="furtherEquipment"
                  value={furtherEquipment || ''}
                  maxLength={250}
                  placeholder={t('formFields.text')}
                  wrapperClassName={styles.TextArea}
                  {...getValidationPropsForField('furtherEquipment', (event) => {
                    dispatch(setFurtherEquipment(event.target.value));
                  })}
                />
                <TextAreaCount
                  labelText={t('formFields.furtherEquipmentEn')}
                  id="furtherEquipmentEn"
                  value={furtherEquipmentEn || ''}
                  maxLength={250}
                  placeholder={t('formFields.text')}
                  wrapperClassName={styles.TextArea}
                  {...getValidationPropsForField('furtherEquipmentEn', (event) => {
                    dispatch(setFurtherEquipmentEn(event.target.value));
                  })}
                />
              </div>
            </ContentViewSection>
            <ContentViewSection title={t('views.listings.sections.photos')} anchorId="photos">
              <ImageUpload formErrors={formErrors} />
            </ContentViewSection>
            <ContentViewSection title={t('views.listings.sections.visibility')} anchorId="visibility">
              <RequiredFormItem isEditMode>
                <FormGroup
                  key="targetGroups"
                  name="targetGroups"
                  legendText={t('formFields.targetGroupLabel') as string}
                >
                  <Checkbox
                    id="seekAll"
                    value="seekAll"
                    checked={seekingAll}
                    indeterminate={!seekingAll && !seekingNone}
                    labelText={t('formFields.all') as string}
                    onChange={(checked) => dispatch(setSeekingFlags(checked))}
                  />
                  <Checkbox
                    id="seekingStudents"
                    checked={seekingStudents}
                    labelText={t('enums.targetGroup.STUDENTS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingStudents(checked))}
                  />
                  <Checkbox
                    id="seekingProfessors"
                    checked={seekingProfessors}
                    labelText={t('enums.targetGroup.PROFESSORS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingProfessors(checked))}
                  />
                  <Checkbox
                    id="seekingIncomings"
                    checked={seekingIncomings}
                    labelText={t('enums.targetGroup.INCOMINGS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingIncomings(checked))}
                  />
                  <Checkbox
                    id="seekingDoctoralStudents"
                    checked={seekingDoctoralStudents}
                    labelText={t('enums.targetGroup.DOCTORAL_STUDENTS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingDoctoralStudents(checked))}
                  />
                  <Checkbox
                    id="seekingPostDoctoralStudents"
                    checked={seekingPostDoctoralStudents}
                    labelText={t('enums.targetGroup.POST_DOCTORAL_STUDENTS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingPostDoctoralStudents(checked))}
                  />
                  <Checkbox
                    id="seekingGuestResearchers"
                    checked={seekingGuestResearchers}
                    labelText={t('enums.targetGroup.GUEST_RESEARCHERS') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingGuestResearchers(checked))}
                  />
                  <Checkbox
                    id="seekingTumEmployees"
                    checked={seekingTumEmployees}
                    labelText={t('enums.targetGroup.TUM_EMPLOYEES') as string}
                    className={styles.SeekingDetail}
                    onChange={(checked) => dispatch(setSeekingTumEmployees(checked))}
                  />
                </FormGroup>
              </RequiredFormItem>
              <RequiredFormItem isEditMode>
                <FormGroup key="visibility" name="visibility" legendText={t('formFields.visibilityLabel') as string}>
                  <RadioButtonGroup
                    name="visibility"
                    valueSelected={isListingPublic ? ListingVisibility.PUBLIC : ListingVisibility.NOT_PUBLIC}
                    className={styles.ManagementTypeSelection}
                    onChange={(value) => dispatch(setIsListingPublic(value === ListingVisibility.PUBLIC))}
                    orientation="vertical"
                  >
                    <RadioButton
                      id={ListingVisibility.PUBLIC}
                      key={ListingVisibility.PUBLIC}
                      value={ListingVisibility.PUBLIC}
                      labelText={t(`enums.listingVisibility.${ListingVisibility.PUBLIC}`)}
                    />
                    <RadioButton
                      id={ListingVisibility.NOT_PUBLIC}
                      key={ListingVisibility.NOT_PUBLIC}
                      value={ListingVisibility.NOT_PUBLIC}
                      labelText={t(`enums.listingVisibility.${ListingVisibility.NOT_PUBLIC}`)}
                    />
                  </RadioButtonGroup>
                </FormGroup>
              </RequiredFormItem>
              <DatePicker
                id="expirationDate"
                datePickerType="single"
                value={expirationDate || undefined}
                allowInput={false}
                dateFormat={'d.m.Y'}
              >
                <DatePickerInput
                  id="expirationDateInput"
                  placeholder="dd.mm.yyyy"
                  labelText={t('formFields.expirationDate') as string}
                />
              </DatePicker>
            </ContentViewSection>
            {hasAdministrativeFunction && (
              <ContentViewSection title={t('views.listings.sections.check')} anchorId="check">
                <Checkbox
                  id="verificationCheck"
                  labelText={t('formFields.verifiedSuccess') as string}
                  checked={verifiedAt !== null}
                  onChange={(isChecked) => {
                    dispatch(setVerifiedAt(isChecked ? new Date().toISOString() : null));
                  }}
                />
              </ContentViewSection>
            )}
          </ContentView>
        </Form>

        <FormBlockingDialog onResetForm={onResetForm} currentlyEditedItemUUID={formState.uuid} />
        {mapModal && formState.coordinates && (
          <ComposedModal open={true} onClose={() => setMapModal(false)} className={styles.MapModal}>
            <ModalHeader>
              <h2>{t('views.listings.modal.mapHeadline')}</h2>
              <p>
                {listingAddressString(
                  t,
                  formState.street,
                  formState.houseNumber,
                  formState.postalCode,
                  formState.city,
                  formState.district
                )}
              </p>
            </ModalHeader>
            <ModalBody>
              <LeafletMap
                height={'23rem'}
                markers={[new Point(formState.coordinates.x, formState.coordinates.y)]}
                centerToMarker={true}
                zoom={18}
              />
            </ModalBody>
          </ComposedModal>
        )}
      </div>
    </>
  );
};

export default ListingEdit;
