import { FC, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AdminView } from './components/views/admin/admin';
import LandLordRouter from './components/views/landlord/landlord';
import { LanguageSwitcher } from './components/controls/language-switcher/language-switcher';
import { Footer } from './components/controls/footer/footer';
import { Header } from './components/controls/header/header';
import TabNavigation from './components/controls/tab-navigation/tab-navigation';
import { useNavigationTabs } from './app.navigation';
import { metaLinks, socialLinks } from './app.footer';
import { ToastContainer } from 'react-toastify';
import { useCSRF } from './app.hooks.csrf';
import { useAuth } from './app.hooks.auth';
import ListingsRouter from './components/views/listings/listings-router';
import SearchRequestsRouter from './components/views/search-requests/search-requests-router';
import AccountConfirmationDialog from './components/views/account-confirmation-dialog/account-confirmation-dialog';
import { START_VIEW_PATH } from './utils/constants';
import ResetPasswordDialog from './components/views/reset-password-dialog/reset-password-dialog';
import { useTranslation } from 'react-i18next';
import { hasAdministrativeFunction } from './utils/permissions';
import { AccountRouter } from './components/views/account/account-router';
import { useUserPermission } from './utils/hooks/user-data';
import SearchRequest from './components/views/external/search-request/search-request';
import { useResponsiveBreakpoints } from './utils/hooks/responsive.hooks';
import { useNavigationMenu } from './app.navigation-mobile';
import Menu from './components/controls/menu/menu';
import LegalDetails from './components/views/legal-details/legal-details';
import PrivacyPolicy from './components/views/privacy-policy/privacy-policy';
import SeekerView from './components/views/seeker/seeker-view';
import Tips from './components/views/tips/tips';
import { ComponentView } from './components/views/component-view/component-view';
import ScrollRestoration from './utils/scroll-restoration';
import { Maintenance } from './components/views/maintenance/maintenance';
import { useSelector } from 'react-redux';
import { GlobalState } from './redux/store';

const App: FC = () => {
  useCSRF();
  const userState = useAuth();
  const { t } = useTranslation();
  const { isLandlord } = useUserPermission();
  const { pathname } = useLocation();
  const requestTimeout = useSelector<GlobalState, boolean>((state) => state.user?.requestTimeout);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isExternal = pathname.indexOf('/ext') !== -1;

  const user =
    !isExternal && userState && userState.isCsrfMWRunning && userState.user?.name
      ? {
          displayName: userState.user.name,
          role: hasAdministrativeFunction(userState.user.permission)
            ? t(`enums.permission.${userState.user.permission}`)
            : t('main.account')
        }
      : undefined;

  const computeNavigationTabs = isExternal ? [] : useNavigationTabs();
  const breakpoints = useResponsiveBreakpoints();
  const menuItems = useNavigationMenu();
  const showMenu = menuItems.length > 1;

  return isExternal ? (
    <main role="main">
      {/* only render views when csrf is set. We do not get any data without it */}
      {userState?.isCsrfMWRunning && (
        <>
          <Routes>
            <Route path="/ext/search-request" element={<SearchRequest />} />
          </Routes>
        </>
      )}
    </main>
  ) : (
    <div>
      <LanguageSwitcher />
      <Header
        title="Living"
        user={user}
        hideLogin={requestTimeout}
        onOpenMenuClick={showMenu ? () => setIsMenuOpen(!isMenuOpen) : undefined}
      />
      {breakpoints.isDesktop ? (
        <TabNavigation tabs={computeNavigationTabs} />
      ) : (
        // only show Menu if there are at least 2 entries
        showMenu && <Menu id="navMenu" open={isMenuOpen} items={menuItems} />
      )}

      <ToastContainer />

      <main role="main">
        {/* only render views when csrf is set. We do not get any data without it */}
        {userState?.isCsrfMWRunning && !requestTimeout && (
          <>
            {!userState.user?.email && <ResetPasswordDialog />}
            {!userState.user?.email && <AccountConfirmationDialog />}
            <ScrollRestoration>
              <Routes>
                <Route path="/" element={<Navigate to={START_VIEW_PATH} />} />
                <Route path="/*" element={<Navigate to={START_VIEW_PATH} />} />
                <Route path="/seeker/*" element={<SeekerView />} />
                <Route path="/account/*" element={<AccountRouter />} />
                <Route path="/admin" element={<AdminView />} />
                <Route path="/landlord/*" element={<LandLordRouter />} />
                <Route path="/listings/*" element={<ListingsRouter />} />
                <Route path="/search-requests/*" element={<SearchRequestsRouter />} />
                <Route path="/logout" element={<Navigate to={START_VIEW_PATH} />} />
                <Route path="/legal-details" element={<LegalDetails />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/tips" element={<Tips />} />
                <Route path="/ext/search-request" element={<SearchRequest />} />
                <Route path="/components" element={<ComponentView />}></Route>
              </Routes>
            </ScrollRestoration>
          </>
        )}
        {requestTimeout && (
          <ScrollRestoration>
            <Routes>
              <Route path="/" element={<Maintenance />} />
              <Route path="/*" element={<Maintenance />} />
              <Route path="/legal-details" element={<LegalDetails />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/tips" element={<Tips />} />
              <Route path="/components" element={<ComponentView />}></Route>
            </Routes>
          </ScrollRestoration>
        )}
      </main>

      <Footer
        metaLinks={metaLinks}
        socialLinks={socialLinks}
        showContactInfo={isLandlord}
        contactInfo={{
          mail: process.env.REACT_APP_CONTACT_MAIL_LANDLORD,
          phone: process.env.REACT_APP_CONTACT_PHONE_LANDLORD
        }}
      />
    </div>
  );
};

export default App;
