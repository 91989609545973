import { FC } from 'react';
import { Button, Toggle, TextInput } from 'carbon-components-react';
import styles from './profile-dialog.module.scss';
import { useTranslation } from 'react-i18next';
import { NeedsTranslationProps } from '../../../utils/types';
import { ContactType } from '../../../graphql/types/globalTypes';
import { PersonSVGIcon, BusinessSVGIcon, PhoneSVGIcon, MailSVGIcon, EditSVGIcon } from '@react-md/material-icons';
import { useLandlordUserData } from '../account/account-view.hooks';
import { isApproved } from '../../../utils/permissions';
import { ApprovalState } from '../../controls/approval-state/approval-state';
import classNames from 'classnames';
import { ProfileDialogViewMode, SetViewModeProps } from './profile-dialog';
import { useSubscriptionMappings, useUpdateSubscriptionMappings } from './profile-dialog.hooks';

export const SectionDivider: FC = () => <div className={styles.Divider} />;

const ContactDataType: FC<NeedsTranslationProps & { contactType?: ContactType }> = ({ t, contactType }) => (
  <div className={styles.ContactDataType}>
    {contactType === ContactType.PRIVATE ? <PersonSVGIcon /> : <BusinessSVGIcon />}{' '}
    {contactType && t(`enums.contactType.${contactType}`)}
  </div>
);

export const ProfileDialogOverview: FC<SetViewModeProps> = ({ setViewMode }) => {
  const {
    getLandLordQuery: { data: userData }
  } = useLandlordUserData();
  const { t } = useTranslation();

  const { listingDeleted, listingInactive, listingPublished, refetch } = useSubscriptionMappings();
  const { updateListingDeleted, updateListingInactive, updateListingPublished } = useUpdateSubscriptionMappings();

  return (
    <>
      <h3 className={styles.SectionHeading} id="contact-data">
        {t('views.account.contactData')}
      </h3>
      {userData?.landlord && (
        <div className={styles.ContactDataOverview}>
          <Button
            kind="ghost"
            size="field"
            renderIcon={EditSVGIcon}
            className={styles.EditContactDataButton}
            onClick={() => setViewMode(ProfileDialogViewMode.CHANGE_PROFILE_DATA)}
          >
            {t('actions.editAlt')}
          </Button>
          <ContactDataType contactType={userData?.landlord?.contactType} t={t} />
          <ApprovalState highlight={false} type={isApproved(userData.landlord)} />
          <div>
            {userData.landlord.contactType !== ContactType.PRIVATE && (
              <div aria-labelledby="contact-data">{userData.landlord.companyName}</div>
            )}
            <div aria-labelledby="contact-data">
              {userData.landlord.gender ? t(`enums.salutation.${userData.landlord.gender}`) : ''}
            </div>
            <div aria-labelledby="contact-data">{`${userData.landlord.firstName} ${userData.landlord.lastName}`}</div>
            <div aria-labelledby="contact-data">{`${userData.landlord.street} ${userData.landlord.houseNumber}`}</div>
            <div aria-labelledby="contact-data">{`${userData.landlord.postalCode} ${userData.landlord.city}`}</div>
          </div>
          <div>
            {userData.landlord.phone && (
              <div aria-labelledby="contact-data" className={styles.ContactDataRowWithIcon}>
                <PhoneSVGIcon className={styles.Icon} /> {userData.landlord.phone}
              </div>
            )}
            {userData.landlord.email && (
              <div aria-labelledby="contact-data" className={styles.ContactDataRowWithIcon}>
                <MailSVGIcon className={styles.Icon} /> {userData.landlord.email}
              </div>
            )}
          </div>
        </div>
      )}
      <SectionDivider />
      <h3 className={styles.SectionHeading}>{t('views.account.credentials')}</h3>
      {userData?.landlord && (
        <div className={styles.CredentialsRow}>
          <TextInput
            id="mailAddress"
            className={styles.CredentialsInputReadOnly}
            labelText={t('formFields.emailAddress') as string}
            value={userData.landlord.email || ''}
            readOnly
          />
          <TextInput
            id="password"
            className={styles.CredentialsInputReadOnly}
            labelText={t('formFields.password') as string}
            value={'************'}
            readOnly
          />
          <Button
            kind="secondary"
            size="field"
            className={styles.ChangePasswordButton}
            onClick={() => setViewMode(ProfileDialogViewMode.CHANGE_PASSWORD)}
          >
            {t('actions.changePassword')}
          </Button>
        </div>
      )}
      <SectionDivider />
      <h3 className={classNames(styles.SectionHeading, styles.SectionHeadingWithSubtitle)}>
        {t('header.modalSettings.contentHeadline')}
      </h3>
      <p>{t('views.account.automaticNotificationsSubtitle')}</p>
      <div className={styles.NotificationsRow}>
        <Toggle
          id={'listing-deactivated'}
          labelText={t('views.account.notificationSettingsLabels.listingDeactivated')}
          labelA={t('actions.off')}
          labelB={t('actions.on')}
          toggled={listingInactive}
          onToggle={(checked) => {
            updateListingInactive({ variables: { subscribe: checked } }).then(() => refetch());
          }}
        />
        <Toggle
          id={'listing-published'}
          labelText={t('views.account.notificationSettingsLabels.listingPublished')}
          labelA={t('actions.off')}
          labelB={t('actions.on')}
          toggled={listingPublished}
          onToggle={(checked) => {
            updateListingPublished({ variables: { subscribe: checked } }).then(() => refetch());
          }}
        />
        <Toggle
          id={'listing-deleted'}
          labelText={t('views.account.notificationSettingsLabels.listingDeleted')}
          labelA={t('actions.off')}
          labelB={t('actions.on')}
          toggled={listingDeleted}
          onToggle={(checked) => {
            updateListingDeleted({ variables: { subscribe: checked } }).then(() => refetch());
          }}
        />
      </div>
    </>
  );
};

export default ProfileDialogOverview;
