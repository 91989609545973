import { NumberInput } from 'carbon-components-react';
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDropDown from '../filter-dropdown/filter-dropdown';
import styles from './range-filter.module.scss';
import { formatNumber } from '../../../utils/intl/numbers';
import {
  AREA_INPUT_DEFAULT_PROPS,
  COST_INPUT_DEFAULT_PROPS,
  preventLetterInputHandler
} from '../../../utils/validation/input-validation';
import classNames from 'classnames';
import { UNIT_AREA_SQUARE_METER, UNIT_CURRENCY_EURO } from '../../../utils/constants';

const RANGE_MAX_LENGTH = 5;

const COMMON_NUMBER_INPUT_PROPS = {
  placeholder: '0',
  allowEmpty: true,
  min: 0,
  onKeyDown: preventLetterInputHandler,
  maxLength: RANGE_MAX_LENGTH
};

export type RangeFilterChangeFunction = (range: Range) => void;
export type Range = {
  start?: number;
  end?: number;
};

export interface RangeFilterProps {
  id: string;
  label: string;
  text?: string;
  min?: number;
  max?: number;
  onChange?: RangeFilterChangeFunction;
  kind?: 'area' | 'currency';
  showLabelAsPlaceholder?: boolean;
  alignContent?: 'right' | 'left';
  inline?: boolean;
}

export const RangeFilter: FC<RangeFilterProps> = ({
  id,
  label,
  onChange,
  min,
  max,
  kind,
  text,
  showLabelAsPlaceholder,
  alignContent,
  inline
}) => {
  const { t } = useTranslation();

  const [currentMin, setCurrentMin] = useState<number | undefined>(min);
  const [currentMax, setCurrentMax] = useState<number | undefined>(max);

  const formatValue = useCallback(
    (value: number): string => {
      if (kind === 'currency') {
        return `${formatNumber(value)} €`;
      }
      return `${formatNumber(value)} m²`;
    },
    [kind]
  );

  const valueText = useMemo(() => {
    if (!currentMin && !currentMax) {
      return showLabelAsPlaceholder ? label : t('main.any');
    } else if (currentMin && !currentMax) {
      return `${t('formFields.from')} ${formatValue(currentMin)}`;
    } else if ((currentMin === undefined || currentMin === 0) && currentMax !== undefined && currentMax > 0) {
      return `${t('formFields.to')} ${formatValue(currentMax)}`;
    }
    return `${formatValue(currentMin || 0)} ${t('formFields.to')} ${currentMax && formatValue(currentMax)}`;
  }, [currentMin, currentMax, t]);

  const handleNumberChange = useCallback(
    (setter) => (
      event: ChangeEvent<HTMLInputElement> & {
        imaginaryTarget: HTMLInputElement;
      }
    ) => {
      if (event.imaginaryTarget && event.imaginaryTarget.value !== undefined) {
        const length = event.imaginaryTarget.value.length;

        if (length > 0 && length <= RANGE_MAX_LENGTH) {
          setter(Number.parseFloat(event.imaginaryTarget.value));
        } else if (length === 0) {
          setter(0);
        }
      }
    },
    []
  );

  useEffect(() => {
    onChange &&
      onChange({
        start: currentMin,
        end: currentMax
      });
  }, [onChange, currentMin, currentMax]);

  const unitValue = kind && (kind === 'currency' ? ` (${UNIT_CURRENCY_EURO})` : ` (${UNIT_AREA_SQUARE_METER})`);
  const stepSize = kind ? (kind === 'currency' ? COST_INPUT_DEFAULT_PROPS.step : AREA_INPUT_DEFAULT_PROPS.step) : 1;

  const rangeFilterContent = (
    <div
      id={!inline ? 'tooltip-body' : undefined}
      className={classNames(styles.RangeFilter, { [styles.RangeFilter__inline]: inline })}
    >
      <NumberInput
        id="rangeStart"
        label={`${t('formFields.from')}${unitValue ?? ''}`}
        step={stepSize}
        value={currentMin || ''}
        onChange={handleNumberChange(setCurrentMin)}
        {...COMMON_NUMBER_INPUT_PROPS}
      />

      <span>&ndash;</span>
      <NumberInput
        id="rangeEnd"
        label={`${t('formFields.to')}${unitValue ?? ''}`}
        step={stepSize}
        value={currentMax || ''}
        onChange={handleNumberChange(setCurrentMax)}
        {...COMMON_NUMBER_INPUT_PROPS}
      />
    </div>
  );

  return inline ? (
    rangeFilterContent
  ) : (
    <FilterDropDown id={id} label={label} value={text || valueText} alignContent={alignContent}>
      {rangeFilterContent}
    </FilterDropDown>
  );
};

export default RangeFilter;
