import { ReactNode } from 'react';
import { CustomMultiSelectItem, MultiSelectItem } from './types';
import { FILTER_SEPARATOR } from './constants';
import { TFunction } from 'i18next';

export const highlightIfNew = (highlight: boolean | null, text: string | null): ReactNode => {
  return highlight ? <strong>{text}</strong> : text;
};
export const getItemsByParam = <T,>(
  param: string,
  items: CustomMultiSelectItem<T>[],
  setter: (items: CustomMultiSelectItem<T>[]) => void
): void => {
  const ids = param.split(FILTER_SEPARATOR);
  const urlItems: Set<typeof items[0]> = new Set<typeof items[0]>();
  ids.forEach((id) => {
    const item = items.find((item) => item.id === id);
    if (item) {
      urlItems.add(item);
    }
  });
  setter(Array.from(urlItems));
};

export const getMultiSelectLabel = (
  t: TFunction,
  selectedItems: MultiSelectItem[] | undefined,
  allItems: MultiSelectItem[],
  translateAll?: boolean
): string => {
  return selectedItems && selectedItems.length > 0 && selectedItems.length < allItems.length
    ? selectedItems.map((item) => (translateAll ? t(item.text) : item.text)).join(', ')
    : `${t('main.all')}`;
};
