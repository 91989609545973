import {
  StarBorderSVGIcon,
  StarSVGIcon,
  CloseSVGIcon,
  ErrorSVGIcon,
  CheckCircleSVGIcon,
  PhotoCameraSVGIcon,
  ErrorOutlineSVGIcon,
  RotateLeftSVGIcon,
  RotateRightSVGIcon
} from '@react-md/material-icons';
import { Button, InlineLoading, TextInput, TextInputSkeleton } from 'carbon-components-react';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ImageRotation } from '../../../graphql/types/globalTypes';
import { RequiredFormItem } from '../../building-blocks/content-view/content-view';
import styles from './file-upload-item.module.scss';

export type FileStatus = 'complete' | 'uploading';

export type Descriptions = {
  description?: string;
  descriptionEn?: string;
};

type InputValidationProps = {
  invalid?: boolean;
  invalidText?: string;
};

export type UploadItemInputValidationProps = {
  description: InputValidationProps;
};

export type FileUploadItemProps = {
  status: FileStatus;
  fileName: string;
  id: string;
  image?: string;
  onFavorite?: () => void;
  onDelete?: () => void;
  onDescriptionChange?: (descriptions: Descriptions) => void;
  onImageRotate?: (rotation: ImageRotation) => void;
  isPreview?: boolean | null;
  error?: string;
  inputValidationProps?: UploadItemInputValidationProps;
} & Descriptions;

export const FileUploadItem: FC<FileUploadItemProps> = ({
  id,
  fileName,
  status,
  isPreview,
  image,
  error,
  description,
  descriptionEn,
  onFavorite,
  onDelete,
  onDescriptionChange,
  onImageRotate,
  inputValidationProps
}) => {
  const { t } = useTranslation();
  const isCompleted = !error && status !== 'uploading';

  const renderInputs = (): ReactElement => (
    <>
      {isCompleted && (
        <>
          <RequiredFormItem isEditMode className={styles.Description}>
            <TextInput
              id={`description_${id}`}
              labelText={t('formFields.germanOr') as string}
              data-testid="Description"
              value={description}
              onChange={(event) => onDescriptionChange && onDescriptionChange({ description: event.target.value })}
              invalid={inputValidationProps?.description.invalid}
              invalidText={inputValidationProps?.description.invalidText}
            />
          </RequiredFormItem>
          <div className={styles.Description}>
            <TextInput
              id={`descriptionEN_${id}`}
              labelText={t('formFields.englishDescription') as string}
              data-testid="DescriptionEN"
              value={descriptionEn}
              onChange={(event) => onDescriptionChange && onDescriptionChange({ descriptionEn: event.target.value })}
              invalid={inputValidationProps?.description.invalid}
              invalidText={inputValidationProps?.description.invalidText}
            />
          </div>
        </>
      )}
      {status === 'uploading' && (
        <>
          <TextInputSkeleton className={styles.Description} />
          <TextInputSkeleton className={styles.Description} />
        </>
      )}
    </>
  );

  const renderStatusIcon = (): ReactElement => (
    <>
      {status === 'uploading' && <InlineLoading />}
      {status === 'complete' && !error && (
        <>
          <span className="sr-only">{t('aria.imageUploadSuccess')}</span>
          <CheckCircleSVGIcon className={styles.StatusIndicator} />
        </>
      )}
      {status === 'complete' && error && (
        <ErrorSVGIcon className={classNames(styles.StatusIndicator, styles.StatusIndicator__error)} />
      )}
    </>
  );

  const renderDisclaimer = (): ReactElement => (
    <p className={styles.StatusDisclaimer}>
      {(status === 'uploading' || (!image && !error)) && (
        <>
          <PhotoCameraSVGIcon />
          <span>{t('views.listings.imageIsBeingUploaded')}</span>
        </>
      )}
      {error && (
        <>
          <ErrorOutlineSVGIcon className={styles.Error} />
          <span>{t(error)}</span>
        </>
      )}
    </p>
  );

  const renderImageContainer = (): ReactElement => (
    <>
      <div className={styles.ImageContainer}>
        <div className={styles.ImageWrapper}>
          {image ? <img src={image} alt={description}></img> : renderDisclaimer()}
          {image && onImageRotate && (
            <div className={styles.RotateButtons}>
              <Button
                kind={'ghost'}
                renderIcon={RotateRightSVGIcon}
                hasIconOnly
                size="sm"
                tooltipPosition="left"
                iconDescription={t('actions.rotateRight')}
                onClick={() => onImageRotate(ImageRotation.CLOCKWISE)}
                className={styles.RotationButton}
              />
              <Button
                kind={'ghost'}
                renderIcon={RotateLeftSVGIcon}
                hasIconOnly
                size="sm"
                tooltipPosition="left"
                iconDescription={t('actions.rotateLeft')}
                onClick={() => onImageRotate(ImageRotation.COUNTERCLOCKWISE)}
                className={styles.RotationButton}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <li className={classNames(styles.FileUploadItem, { [styles.FileUploadItem__error]: error !== undefined })}>
      <div className={classNames(styles.FileUploadItemHeader)}>
        <Button
          kind="ghost"
          size="sm"
          tooltipPosition="right"
          iconDescription={t('actions.markAsPreview')}
          hasIconOnly
          renderIcon={isPreview ? StarSVGIcon : StarBorderSVGIcon}
          className={styles.FavoriteButton}
          onClick={onFavorite}
          disabled={!isCompleted}
        />
        <span className={styles.FileUploadItemName}>{fileName}</span>

        {renderStatusIcon()}

        <Button
          kind="ghost"
          size="sm"
          tooltipPosition="left"
          iconDescription={t('actions.delete')}
          hasIconOnly
          renderIcon={CloseSVGIcon}
          onClick={onDelete}
          className={styles.DeleteButton}
        ></Button>
      </div>

      {renderImageContainer()}
      {renderInputs()}
    </li>
  );
};

export default FileUploadItem;
