import {
  ActionCreator,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { ContactType, Gender } from '../../../../graphql/types/globalTypes';
import { ApprovalType } from '../../../controls/approval-state/approval-state';

export interface LandlordFormDataState {
  id: string;
  uuid: string;
  contactType: ContactType;
  approvalState: ApprovalType;
  firstName: string | null;
  lastName: string;
  isNew: boolean;
  phone: string | null;
  email: string | null;
  notes: string | null;
  companyName: string | null;
  isBlocked: boolean | null;
  blockedBy: string | null;
  blockedReason: string | null;
  blockedAt: Date | null;
  verifiedAt: Date | null;
  verifiedBy: string | null;
  createdAt: Date | null;
  lastEditedBy: string | null;
  modifiedAt: Date | null;
  password: string | null;
  passwordConfirmed: string | null;
  gender: Gender;
  street: string | null;
  houseNumber: string | null;
  postalCode: string | null;
  city: string | null;
  privacyPolicyAccepted: boolean;
}

export const initialLandlordFormDataState: LandlordFormDataState = {
  id: 'unset',
  uuid: '',
  contactType: ContactType.PRIVATE,
  approvalState: ApprovalType.Approved,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  notes: null,
  companyName: '',
  isNew: false,
  isBlocked: false,
  blockedBy: null,
  blockedReason: null,
  blockedAt: null,
  verifiedAt: null,
  verifiedBy: null,
  createdAt: null,
  lastEditedBy: null,
  modifiedAt: null,
  password: null,
  passwordConfirmed: null,
  gender: Gender.DIVERSE,
  street: null,
  houseNumber: null,
  postalCode: null,
  city: null,
  privacyPolicyAccepted: false
};

export const slice = createSlice({
  name: 'LandlordFormData',
  reducers: {
    setContactType: (state: LandlordFormDataState, action: PayloadAction<ContactType>) => {
      state.contactType = action.payload;
    },
    setApprovalState: (state: LandlordFormDataState, action: PayloadAction<ApprovalType>) => {
      state.approvalState = action.payload;
    },
    setFirstName: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setTelephoneNumber: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setEmail: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setNotes: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.notes = action.payload;
    },
    setCompanyName: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.companyName = action.payload;
    },
    setBlockedReason: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.blockedReason = action.payload;
    },
    setIsBlocked: (state: LandlordFormDataState, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload;
    },
    setPassword: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setPasswordConfirmed: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.passwordConfirmed = action.payload;
    },
    setGender: (state: LandlordFormDataState, action: PayloadAction<Gender>) => {
      state.gender = action.payload;
    },
    setStreet: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.street = action.payload;
    },
    setHouseNumber: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.houseNumber = action.payload;
    },
    setPostalCode: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.postalCode = action.payload;
    },
    setCity: (state: LandlordFormDataState, action: PayloadAction<string>) => {
      state.city = action.payload;
    },
    setPrivacyPolicyAccepted: (state: LandlordFormDataState, action: PayloadAction<boolean>) => {
      state.privacyPolicyAccepted = action.payload;
    },
    resetForm: (state: LandlordFormDataState) => {
      Object.assign(state, initialLandlordFormDataState);
    },
    setFormData: (state: LandlordFormDataState, action: PayloadAction<LandlordFormDataState>) => {
      Object.assign(state, action.payload);
    }
  },
  initialState: initialLandlordFormDataState
});

export const setContactType: ActionCreatorWithPayload<ContactType> = slice.actions.setContactType;
export const setApprovalState: ActionCreatorWithPayload<ApprovalType> = slice.actions.setApprovalState;
export const setFirstName: ActionCreatorWithPayload<string> = slice.actions.setFirstName;
export const setLastName: ActionCreatorWithPayload<string> = slice.actions.setLastName;
export const setTelephoneNumber: ActionCreatorWithPayload<string> = slice.actions.setTelephoneNumber;
export const setEmail: ActionCreatorWithPayload<string> = slice.actions.setEmail;
export const setNotes: ActionCreatorWithPayload<string> = slice.actions.setNotes;
export const setCompanyName: ActionCreatorWithPayload<string> = slice.actions.setCompanyName;
export const setIsBlocked: ActionCreatorWithPayload<boolean> = slice.actions.setIsBlocked;
export const setBlockedReason: ActionCreatorWithPayload<string> = slice.actions.setBlockedReason;
export const setPassword: ActionCreatorWithPayload<string> = slice.actions.setPassword;
export const setPasswordConfirmed: ActionCreatorWithPayload<string> = slice.actions.setPasswordConfirmed;
export const setGender: ActionCreatorWithPayload<Gender> = slice.actions.setGender;
export const setStreet: ActionCreatorWithPayload<string> = slice.actions.setStreet;
export const setHouseNumber: ActionCreatorWithPayload<string> = slice.actions.setHouseNumber;
export const setPostalCode: ActionCreatorWithPayload<string> = slice.actions.setPostalCode;
export const setCity: ActionCreatorWithPayload<string> = slice.actions.setCity;
export const setPrivacyPolicyAccepted: ActionCreatorWithPayload<boolean> = slice.actions.setPrivacyPolicyAccepted;
export const resetForm: ActionCreatorWithoutPayload = slice.actions.resetForm;
export const setFormData: ActionCreatorWithPayload<LandlordFormDataState> = slice.actions.setFormData;

export const landlordFormDataReducer = slice.reducer;

const actionsTriggeringDirtyStateExclusions: ActionCreator<unknown>[] = [
  slice.actions.resetForm,
  slice.actions.setFormData,
  slice.actions.setIsBlocked,
  slice.actions.setBlockedReason,
  slice.actions.setApprovalState
];

export const landlordActionsTriggeringDirtyState = Object.values(slice.actions).filter(
  (value: ActionCreator<unknown>) => actionsTriggeringDirtyStateExclusions.indexOf(value) === -1
);

export const landlordActionsResettingDirtyState = [slice.actions.resetForm, slice.actions.setFormData];
