import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FileUploaderButton } from 'carbon-components-react';
import {
  addImage,
  ListingFormDataState,
  LocalImageDescriptor,
  markImageForDeletion,
  removeImage,
  setPreviewImage,
  updateImageDescription,
  updateImageDescriptionEN,
  updateImageStatus
} from '../../listing.state';
import { useDispatch, useSelector } from 'react-redux';
import { useDeleteListingImage, useRotateListingImage, useUploadListingImage } from '../listing-edit.hooks';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { GlobalState } from '../../../../../../redux/store';
import { formatFileSize } from '../../../../../../utils/intl/numbers';
import { logger } from '../../../../../../utils/logger';
import { uuidv4 } from '../../../../../../utils/uuid';
import {
  FILE_MAX_SIZE,
  BYTES_PER_MEGA_BYTE,
  FILE_MAX_COUNT,
  FILE_INPUT_IMAGE_ACCEPTS,
  FILE_INPUT_IMAGE_EXTENSIONS
} from '../../../../../../utils/validation/input-validation';
import {
  UploadItemInputValidationProps,
  FileUploadItem
} from '../../../../../controls/file-upload-item/file-upload-item';
import styles from '../listing-edit.module.scss';
import { getThumbnailImagePath } from '../../../../../../utils/listing-images';
import { ImageRotation } from '../../../../../../graphql/types/globalTypes';

export interface ImageUploadProps {
  formErrors: FormikErrors<ListingFormDataState>;
}

// any is actually expected in a type guard function
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFormikErrorOfImageDescriptor = (obj: any): obj is FormikErrors<LocalImageDescriptor> => {
  return typeof obj === 'object' && (obj.description !== undefined || obj.descriptionEn !== undefined);
};

export const ImageUpload: FC<ImageUploadProps> = ({ formErrors }) => {
  const formState = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const { images, id, uuid } = formState;
  const uploadListingImage = useUploadListingImage();
  const deleteListingImage = useDeleteListingImage();
  const rotateListingImage = useRotateListingImage();
  const [invalidateImageCacheTimestamp, setInvalidateImageCacheTimestamp] = useState(new Date().getTime());

  const dispatch = useDispatch();
  const { t } = useTranslation();

  // effect to automatically set a preview image, as long as there are images available
  useEffect(() => {
    const currentPreviewImage = images.find((image) => image.isPreview);
    if ((currentPreviewImage === undefined || currentPreviewImage.markedForDeletion) && images.length > 0) {
      const newPreviewImageId = images.find(
        (image) => !image.error && image.status === 'complete' && !image.markedForDeletion
      )?.id;
      newPreviewImageId && dispatch(setPreviewImage(newPreviewImageId));
    }
  }, [images]);

  // filter out images that are marked for deletion
  const displayedImages = useMemo(() => {
    return images.filter((image) => !image.markedForDeletion);
  }, [images]);

  const onFileUpload = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const maxIndex = FILE_MAX_COUNT - displayedImages.length;
      for (let index = 0; index < event.target.files.length; index++) {
        const file = event.target.files[index];
        const status: LocalImageDescriptor = {
          // we need an id for the image, since file name might not be unique
          id: `local_${uuidv4()}`,
          fileName: file.name,
          status: 'uploading',
          description: '',
          descriptionEn: '',
          isPreview: false,
          listing: id,
          origin: 'client',
          modifiedAt: ''
        };

        // IMPROVEMENT: Step 1 - Hash size, type and modified info to detect if file is already uploaded.
        //              Step 2 - For this to work when updating a listing, we would need to upload this hash as metadata.
        if (index >= maxIndex) {
          status.status = 'complete';
          status.error = 'errors.tooManyFiles';
        } else if (file.size > FILE_MAX_SIZE) {
          status.status = 'complete';
          status.error = 'errors.fileTooLarge';
        } else if (!FILE_INPUT_IMAGE_EXTENSIONS.find((extension) => file.name.toLowerCase().endsWith(extension))) {
          status.status = 'complete';
          status.error = 'errors.invalidFileFormat';
        }

        dispatch(addImage(status));

        const fileId = status.id;
        if (!status.error) {
          uploadListingImage({ variables: { file } })
            .then((result) => {
              const newId = result.data?.uploadListingImage;
              newId && dispatch(updateImageStatus({ id: fileId, status: 'complete', newId }));
            })
            .catch((error) => {
              logger.error(error);
            });
        }
      }
    }
  }, []);

  const isCreationMode = useMemo(() => uuid === null || uuid.length === 0, [uuid]);
  return (
    <>
      <p className={styles.InfoText}>
        {t('formFields.photoInfoMaxSize', { maxSize: `${formatFileSize(FILE_MAX_SIZE / BYTES_PER_MEGA_BYTE)} MB` })}
        <br />
        {t('formFields.photoInfoMaxCount', { maxCount: FILE_MAX_COUNT })}
      </p>

      <FileUploaderButton
        accept={FILE_INPUT_IMAGE_ACCEPTS}
        multiple={true}
        disabled={displayedImages.length >= FILE_MAX_COUNT}
        onChange={onFileUpload}
        disableLabelChanges={true}
        labelText={t('actions.uploadPhoto')}
      />

      <ol className={styles.Images}>
        {displayedImages.map((image, index) => {
          let validationProps: UploadItemInputValidationProps | undefined = undefined;

          if (formErrors.images && formErrors.images.length > index) {
            const errors = formErrors.images[index];
            if (isFormikErrorOfImageDescriptor(errors)) {
              validationProps = {
                description: {
                  invalid: errors.description !== undefined,
                  invalidText: errors.description && t(errors.description)
                }
              };
            }
          }

          return (
            <FileUploadItem
              key={index}
              {...image}
              image={
                image?.id && !image.id.startsWith('local')
                  ? getThumbnailImagePath(image.id, '') + `?ts=${invalidateImageCacheTimestamp}`
                  : undefined
              }
              onImageRotate={(rotation: ImageRotation) => {
                rotateListingImage({
                  variables: {
                    id: image.id,
                    rotation
                  }
                }).then(() => {
                  setInvalidateImageCacheTimestamp(new Date().getTime());
                });
              }}
              onDelete={() => {
                // check if image exists only locally
                if (image.id.startsWith('local')) {
                  // image was not uploaded so we can delete it locally
                  dispatch(removeImage(image.id));
                } else if (isCreationMode || image.origin === 'client') {
                  // image is new and not yet linked, so we can delete it
                  deleteListingImage({ variables: { id: image.id } }).then(
                    (result) => result.data?.deleteListingImage && dispatch(removeImage(image.id))
                  );
                } else {
                  // image is linked to the listing, so we mark it for deletion in case the user cancels the edit
                  dispatch(markImageForDeletion(image.id));
                }
              }}
              onFavorite={() => dispatch(setPreviewImage(image.id))}
              onDescriptionChange={(changeValue) => {
                const { description, descriptionEn } = changeValue;
                if (description !== undefined) {
                  dispatch(updateImageDescription({ id: image.id, description }));
                } else if (descriptionEn !== undefined) {
                  dispatch(updateImageDescriptionEN({ id: image.id, description: descriptionEn }));
                }
              }}
              inputValidationProps={validationProps}
            />
          );
        })}
      </ol>
    </>
  );
};

export default ImageUpload;
