import * as Yup from 'yup';
import {
  testOptionalLowerBoundary,
  testOptionalStringLength
} from '../../../../utils/validation/state-validation-checks';
import { Role } from '../search-requests-types';

export const searchRequestValidationSchema = Yup.object().shape(
  {
    gender: Yup.string().required('errors.required'),
    lastName: Yup.string().required('errors.required').min(2, 'errors.tooShort2'),
    firstName: Yup.string().required('errors.required').min(2, 'errors.tooShort2'),
    email: Yup.string().required('errors.required').email('errors.invalidMail'),
    phone: Yup.string().optional().nullable().test('hasMinLength', 'errors.tooShort6', testOptionalStringLength(5)),
    affiliationType: Yup.string().required('errors.required'),
    department: Yup.string().optional().nullable(),
    country: Yup.string().required('errors.required'),
    prefersEnglish: Yup.boolean().optional(),

    type: Yup.string().required('errors.required'),
    numberOfRooms: Yup.number().typeError('errors.required').required('errors.required'),
    squareMeterFrom: Yup.number()
      .typeError('errors.required')
      .positive('errors.required')
      .integer()
      .required('errors.required'),
    squareMeterTo: Yup.number()
      .integer()
      .optional()
      .when('$squareMeterFrom', (squareMeterFrom: number, schema: Yup.NumberSchema) =>
        squareMeterFrom > 0
          ? schema.test('boundaryMatch', 'errors.numberBoundaryMatch', testOptionalLowerBoundary(squareMeterFrom))
          : schema
      ),
    numberOfTenants: Yup.number().integer().optional().nullable(),
    availableFrom: Yup.date().typeError('errors.required'),
    availableUntil: Yup.date().nullable().typeError('errors.required'),
    city: Yup.string().typeError('errors.required').required('errors.required'),
    searchRadius: Yup.number().integer().optional().nullable(),
    totalRentFrom: Yup.number()
      .typeError('errors.required')
      .positive('errors.required')
      .min(0)
      .integer()
      .required('errors.required'),
    totalRentTo: Yup.number()
      .typeError('errors.required')
      .positive('errors.required')
      .integer()
      .required('errors.required')
      .when('$totalRentFrom', (totalRentFrom: number, schema: Yup.NumberSchema<number | null | undefined>) =>
        totalRentFrom > 0
          ? schema.test('boundaryMatch', 'errors.numberBoundaryMatch', testOptionalLowerBoundary(totalRentFrom))
          : schema
      ),
    deposit: Yup.number().positive().integer().optional().nullable(),
    remarks: Yup.string().optional().nullable().test('hasMinLength', 'errors.tooShort6', testOptionalStringLength(5)),
    role: Yup.string().oneOf(Object.keys(Role), 'errors.required').required('errors.required'),
    assignee: Yup.string().typeError('errors.required').required('errors.required')
  },
  []
);
