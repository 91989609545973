import * as Yup from 'yup';
import { ChangeEvent, KeyboardEvent } from 'react';

const isKeyboardCombination = (event: KeyboardEvent<HTMLInputElement>): boolean => {
  return event.key.length === 1 && (event.altKey || event.metaKey || event.ctrlKey);
};

export const preventFractionInputHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
  const restrictedChars = /[.,]/g;
  if (restrictedChars.test(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const preventLetterInputHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
  const restrictedChars = /[a-zA-Z]/g;

  if (!isKeyboardCombination(event) && event.key.length === 1 && restrictedChars.test(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const preventFranctionAndLetterInput = (event: KeyboardEvent<HTMLInputElement>): void => {
  preventFractionInputHandler(event);
  preventLetterInputHandler(event);
};

export const postalCodeCharacterInputHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
  const restrictedCharsWhitelist = /[0-9]/g;

  if (!isKeyboardCombination(event) && event.key.length === 1 && !restrictedCharsWhitelist.test(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const houseNumberCharacterInputHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
  const restrictedCharsWhitelist = /[0-9a-zA-Z\s-/]/g;

  if (!isKeyboardCombination(event) && event.key.length === 1 && !restrictedCharsWhitelist.test(event.key)) {
    event.preventDefault();
    event.stopPropagation();
  }
};

export const clearUpPhoneNumber = (phoneNumber: string): string => {
  const restrictedPhoneNumberChars = /[^0-9+\s]/g;
  const subsequentWhiteSpaces = /\s{2,}/g;

  return phoneNumber
    ? phoneNumber.replace(restrictedPhoneNumberChars, '').trim().replace(subsequentWhiteSpaces, ' ')
    : phoneNumber;
};

export const getNumberChangeHandler = (setter: (value: number) => void, maxLength: number) => (
  event: ChangeEvent<HTMLInputElement> & {
    imaginaryTarget: HTMLInputElement;
  }
): void => {
  if (event.imaginaryTarget && event.imaginaryTarget.value !== undefined) {
    const length = event.imaginaryTarget.value.length;

    if (length > 0 && length <= maxLength) {
      setter(Number.parseFloat(event.imaginaryTarget.value));
    } else if (length === 0) {
      setter(0);
    }
  }
};

export const verifyEmail = (email: string): boolean => {
  const mailSchema = Yup.string().email();
  // eslint-disable-next-line no-sync
  return mailSchema.isValidSync(email);
};

export const POSTAL_CODE_MAX_LENGTH = 5;
export const CITY_MAX_LENGTH = 255;
export const AVAILABLE_BY_MIN_DATE_STRING: string = ((): string => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  return today.toISOString();
})();
export const AVAILABLE_BY_MAX_DATE_STRING: string = ((): string => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  today.setFullYear(2100);

  return today.toISOString();
})();
export const COST_INPUT_DEFAULT_PROPS = {
  min: 0,
  placeholder: '0',
  max: 10000,
  step: 50,
  allowEmpty: true,
  onKeyDown: preventFranctionAndLetterInput
};

export const AREA_INPUT_DEFAULT_PROPS = {
  min: 0,
  placeholder: '0',
  max: 1000,
  step: 5,
  allowEmpty: true,
  onKeyDown: preventFranctionAndLetterInput
};

export const FILE_MAX_SIZE = 5000000;
export const FILE_MAX_COUNT = 10;
export const BYTES_PER_MEGA_BYTE = 1000000;
export const FILE_INPUT_IMAGE_EXTENSIONS = ['.jpg', '.jpeg', '.png'];
export const FILE_INPUT_IMAGE_ACCEPTS = ['image/png', 'image/jpg', 'image/jpeg', ...FILE_INPUT_IMAGE_EXTENSIONS];
