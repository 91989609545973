import {
  ContentCopySVGIcon,
  DeleteSVGIcon,
  EditSVGIcon,
  FavoriteBorderSVGIcon,
  MailSVGIcon,
  NotificationsSVGIcon,
  ShareSVGIcon,
  VisibilitySVGIcon
} from '@react-md/material-icons';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { copyURLtoClipboard, MailContent, shareByMail } from '../../../utils/actions';
import { useMutation } from '@apollo/client';
import { PostDuplicateListing, PostDuplicateListingVariables } from '../../../graphql/types/PostDuplicateListing';
import { POST_DUPLICATE_LISTING } from '../../../graphql/queries/listings';
import { useQueryResultToast } from '../../../utils/hooks/query-result-toast';
import { ReactComponent as ToggleOn } from '../../../assets/svg/toggle-on.svg';
import { ReactComponent as ToggleOff } from '../../../assets/svg/toggle-off.svg';
import { useUserPermission } from '../../../utils/hooks/user-data';

const preventPropagation = (callback: () => void) => (e: MouseEvent) => {
  e.stopPropagation();
  callback();
};

export interface ListingOverflowMenuProps {
  uuid: string;
  id: string;
  onOpen: () => void;
  onClose: () => void;
  getShareByMailContent: () => MailContent;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onDelete?: () => void;
  onReport?: () => void;
  isActive?: boolean;
}

export const ListingOverflowMenu: FC<ListingOverflowMenuProps> = ({
  id,
  uuid,
  onOpen,
  onClose,
  getShareByMailContent,
  isActive,
  onActivate,
  onDeactivate,
  onDelete,
  onReport
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLandlord, isSeeker } = useUserPermission();

  const [duplicate, { data: duplicateData, error }] = useMutation<PostDuplicateListing, PostDuplicateListingVariables>(
    POST_DUPLICATE_LISTING
  );

  useQueryResultToast(duplicateData, error, {
    error: {
      titleKey: 'notifications.genericActionError'
    }
  });

  return (
    <OverflowMenu
      onClick={(e) => {
        e.stopPropagation();
      }}
      flipped
      onOpen={onOpen}
      onClose={onClose}
      data-testid="OverflowMenu"
    >
      {!isSeeker && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              <EditSVGIcon />
              {t('actions.editAlt')}
            </span>
          }
          onClick={preventPropagation(() => {
            navigate(`./${uuid}/edit`);
          })}
        />
      )}
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        hasDivider
        itemText={
          <span>
            <VisibilitySVGIcon />
            {isSeeker ? t('actions.openExpose') : t('actions.open')}
          </span>
        }
        onClick={preventPropagation(() => {
          isSeeker ? navigate(`../listings/${uuid}/view`) : navigate(`./${uuid}/view`);
        })}
      />
      {!isSeeker && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              <ContentCopySVGIcon />
              {t('actions.duplicate')}
            </span>
          }
          onClick={preventPropagation(() =>
            duplicate({ variables: { listingId: id } }).then((result) => {
              if (result.data && !result.errors) {
                const duplicateListingUUID = result.data.duplicateListing;
                isLandlord
                  ? navigate(`./${duplicateListingUUID}/edit`, { state: { duplicate: 1 } })
                  : navigate(`../listings/${duplicateListingUUID}/edit`, { state: { duplicate: 1 } });
              }
            })
          )}
        />
      )}
      {!isActive && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              {isSeeker ? (
                <>
                  <FavoriteBorderSVGIcon />
                  {t('actions.addFavorite')}
                </>
              ) : (
                <>
                  <ToggleOn />
                  {t('actions.activate')}
                </>
              )}
            </span>
          }
          onClick={onActivate && preventPropagation(onActivate)}
        />
      )}
      {isActive && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              {isSeeker ? (
                <>
                  <FavoriteBorderSVGIcon />
                  {t('actions.deleteFavorite')}
                </>
              ) : (
                <>
                  <ToggleOff />
                  {t('actions.deactivate')}
                </>
              )}
            </span>
          }
          onClick={onDeactivate && preventPropagation(onDeactivate)}
        />
      )}
      {!isSeeker && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              <DeleteSVGIcon />
              {t('actions.delete')}
            </span>
          }
          onClick={onDelete && preventPropagation(onDelete)}
        />
      )}
      {!isSeeker && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              <ShareSVGIcon />
              {t('actions.shareByLink')}
            </span>
          }
          onClick={preventPropagation(() => {
            copyURLtoClipboard([process.env.REACT_APP_PUBLIC_URL || '', `listings/${uuid}/view`].join('/'), t);
          })}
        />
      )}
      {isSeeker && (
        <OverflowMenuItem
          data-testid="OverflowMenuItem"
          hasDivider
          itemText={
            <span>
              <NotificationsSVGIcon />
              {t('actions.reportListing')}
            </span>
          }
          onClick={onReport && preventPropagation(onReport)}
        />
      )}
      <OverflowMenuItem
        data-testid="OverflowMenuItem"
        hasDivider
        itemText={
          <span>
            <MailSVGIcon />
            {isSeeker ? t('actions.forward') : t('actions.shareByMail')}
          </span>
        }
        onClick={preventPropagation(() => shareByMail(getShareByMailContent()))}
      />
    </OverflowMenu>
  );
};

export default ListingOverflowMenu;
