import { FC, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow, Tag } from 'carbon-components-react';
import styles from './search-requests-list.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../../utils/intl/dates-and-times';
import TableOverflowMenu from '../../../building-blocks/table-overflow-menu/table-overflow-menu';
import { useShareTableEntry } from '../../../../utils/hooks/actions-hooks';
import Verification from '../../../controls/verification/verification';
import { GetSearchRequests } from '../../../../graphql/types/GetSearchRequests';
import { highlightIfNew } from '../../../../utils/general';
import { useUrlSearchParams } from 'use-url-search-params';

const TABLE_HEADERS = [
  '',
  'views.searchRequests.tableHeaders.mails',
  'views.searchRequests.tableHeaders.affiliationType',
  'views.searchRequests.tableHeaders.lastName',
  'views.searchRequests.tableHeaders.firstName',
  'views.searchRequests.tableHeaders.incomingDate',
  'views.searchRequests.tableHeaders.department',
  'views.searchRequests.tableHeaders.state',
  ''
];

export interface SearchRequestsListTableProps {
  data: GetSearchRequests | undefined;
}

export const SearchRequestsListTable: FC<SearchRequestsListTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const getShareLink = useShareTableEntry();
  const [params] = useUrlSearchParams({}, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map((header, index) => (
            <TableHeader key={index}>{header.length > 0 && t(header)}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data &&
          data.searchRequests.map((row) => {
            if (!row) return undefined;
            const affiliationType = row.affiliationType ? row.affiliationType : '–';
            const role = row.role ? t(`enums.role.${row.role}`) : '–';

            return (
              <TableRow
                key={row.uuid}
                onClick={() => {
                  !isMenuOpen &&
                    navigate(`./${row.uuid}/view`, {
                      state: {
                        params
                      }
                    });
                }}
              >
                <TableCell>{row.isNew ? <span className={styles.bullet}>●</span> : ''}</TableCell>
                <TableCell>{row.numberOfNewEmails > 0 ? <Tag>{row.numberOfNewEmails}</Tag> : ''}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, t(`enums.affiliationType.${affiliationType}`))}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, row.lastName)}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, row.firstName)}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, formatDate(row.createdAt))}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, role)}</TableCell>
                <TableCell>
                  <Verification
                    verified={row.isActive}
                    isNew={row.isNew}
                    trueTranslationKey={'enums.openState.OPEN'}
                    falseTranslationKey={'enums.openState.CLOSED'}
                  />
                </TableCell>
                <TableCell>
                  <TableOverflowMenu
                    row={row}
                    onClose={() => setIsMenuOpen(false)}
                    onOpen={() => setIsMenuOpen(true)}
                    getShareByMailContent={() => ({
                      mailSubject: t('sharingMail.searchRequest.sharingMailSubject', { id: row.uuid }),
                      mailBody: t('sharingMail.searchRequest.sharingMailBody', { link: getShareLink(row.uuid) })
                    })}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default SearchRequestsListTable;
