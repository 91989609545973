import { defaults } from 'sanitize-html';

/**
 * Options to transform the html of the mail's body
 */
export const sanitizeOptions = {
  transformTags: {
    strike: 'del',
    b: 'strong',
    i: 'em'
  },
  allowedTags: defaults.allowedTags.concat(['del']),
  allowedAttributes: {
    p: ['style', 'class'],
    span: ['style', 'class'],
    div: ['style', 'class'],
    a: ['href', 'name', 'target', 'class', 'title']
  },
  allowedStyles: {
    '*': {
      // Match HEX and RGB
      color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      'text-align': [/^left$/, /^right$/, /^center$/],
      // Match any number with px, em, or %
      'font-size': [/^\d+(?:px|em|%)$/]
    }
  }
};
