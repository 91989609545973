import { gql } from '@apollo/client';

export const GET_LISTING_IMAGES = gql`
  query GetListingImagesForListing($listingId: ID!, $maxWidth: Int, $sharedListingToken: String) {
    listingImagesForListing(listingId: $listingId, maxWidth: $maxWidth, sharedListingToken: $sharedListingToken) {
      id
      modifiedAt
      description
      descriptionEn
    }
  }
`;

export const POST_UPLOAD_LISTING_IMAGE = gql`
  mutation PostUploadListingImage($file: Upload!) {
    uploadListingImage(file: $file)
  }
`;

export const PUT_ROTATE_LISTING_IMAGE = gql`
  mutation PutRotateListingImage($id: ID!, $rotation: ImageRotation!) {
    rotateListingImage(id: $id, rotation: $rotation)
  }
`;

export const DELETE_LISTING_IMAGE = gql`
  mutation DeleteListingImage($id: ID!) {
    deleteListingImage(id: $id)
  }
`;

export const DELETE_MULTIPLE_LISTING_IMAGES = gql`
  mutation DeleteMultipleListingImages($ids: [ID!]!) {
    deleteListingImages(ids: $ids)
  }
`;
