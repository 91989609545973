import { FC, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { GetLandlords, GetLandlords_landlords } from '../../../../graphql/types/GetLandlords';
import { ApprovalState, ApprovalType } from '../../../controls/approval-state/approval-state';
import { highlightIfNew } from '../../../../utils/general';
import styles from './landlord-list.module.scss';
import { useNavigate } from 'react-router-dom';
import { useShareTableEntry } from '../../../../utils/hooks/actions-hooks';
import TableOverflowMenu from '../../../building-blocks/table-overflow-menu/table-overflow-menu';
import { useUrlSearchParams } from 'use-url-search-params';

export const LandlordListTable: FC<GetLandlords | null> = (props: GetLandlords) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const TABLE_HEADERS = [
    '',
    'views.landlord.tableHeaders.lastName',
    'views.landlord.tableHeaders.firstName',
    'views.landlord.tableHeaders.email',
    'views.landlord.tableHeaders.profile',
    'views.landlord.tableHeaders.activeListings',
    'views.landlord.tableHeaders.approvalState',
    ''
  ];

  const getApprovalType = (landlord: GetLandlords_landlords): ApprovalType => {
    if (landlord.blockedAt !== null) {
      return ApprovalType.Blocked;
    } else if (landlord.verifiedAt !== null) {
      return ApprovalType.Approved;
    }
    return ApprovalType.Inactivated;
  };

  const getProfileShareLink = useShareTableEntry();
  const [params] = useUrlSearchParams({}, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          {TABLE_HEADERS.map((header, index) => (
            <TableHeader key={index}>{header.length > 0 && t(header)}</TableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.landlords.map((row) => {
          return (
            row && (
              <TableRow
                key={row.uuid}
                onClick={() => {
                  !isMenuOpen &&
                    navigate(`./${row.uuid}/view`, {
                      state: {
                        params
                      }
                    });
                }}
              >
                <TableCell>{row.isNew ? <span className={styles.bullet}>●</span> : ''}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, row.lastName)}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, row.firstName)}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, row.email)}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, t(`enums.contactType.${row.contactType}`))}</TableCell>
                <TableCell>{highlightIfNew(row.isNew, String(row.numberOfActiveListings))}</TableCell>
                <TableCell>{<ApprovalState highlight={!!row.isNew} type={getApprovalType(row)} />}</TableCell>
                <TableCell>
                  <TableOverflowMenu
                    row={row}
                    onClose={() => setIsMenuOpen(false)}
                    onOpen={() => setIsMenuOpen(true)}
                    getShareByMailContent={() => ({
                      mailSubject: t('sharingMail.landlord.sharingMailSubject', {
                        name: `${row.firstName} ${row.lastName}`
                      }),
                      mailBody: t('sharingMail.landlord.sharingMailBody', {
                        link: getProfileShareLink(row.uuid)
                      })
                    })}
                  />
                </TableCell>
              </TableRow>
            )
          );
        })}
      </TableBody>
    </Table>
  );
};

export default LandlordListTable;
