import { FC } from 'react';
import styles from './approval-state.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ClearSVGIcon, RemoveCircleSVGIcon } from '@react-md/material-icons';
import { highlightIfNew } from '../../../utils/general';
import { ReactComponent as Verified } from '../../../assets/svg/verified.svg';

export enum ApprovalType {
  Approved,
  Blocked,
  Inactivated
}

export interface ApprovalStateProps {
  type: ApprovalType;
  highlight?: boolean;
}

export const ApprovalState: FC<ApprovalStateProps> = (props: ApprovalStateProps) => {
  const { t } = useTranslation();

  const highlight = props.highlight || false;

  return (
    <span
      className={classNames(styles.ApprovalState, {
        [styles.approved]: props.type === ApprovalType.Approved,
        [styles.blocked]: props.type === ApprovalType.Blocked,
        [styles.inactivated]: props.type === ApprovalType.Inactivated
      })}
    >
      {props.type === ApprovalType.Approved && (
        <>
          <Verified /> {highlightIfNew(highlight, t('enums.approvalType.VERIFIED'))}
        </>
      )}
      {props.type === ApprovalType.Blocked && (
        <>
          <RemoveCircleSVGIcon /> {highlightIfNew(highlight, t('enums.approvalType.BLOCKED'))}
        </>
      )}
      {props.type === ApprovalType.Inactivated && (
        <>
          <ClearSVGIcon /> {highlightIfNew(highlight, t('enums.approvalType.NOT_ACTIVATED'))}
        </>
      )}
    </span>
  );
};
