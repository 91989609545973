import { createRef, FC, useEffect, useState } from 'react';
import styles from './comment-box.module.scss';
import { useDateTimeFormat } from '../../../utils/hooks/dateTimeFormat';
import { useTranslation } from 'react-i18next';
import { Button, OverflowMenu, OverflowMenuItem, TextInput } from 'carbon-components-react';
import { DeleteSVGIcon, EditSVGIcon } from '@react-md/material-icons';
import classNames from 'classnames';

export interface CommentBoxProps {
  userName: string;
  date: Date;
  comment: string;
  isEdited?: boolean;
  canBeEditedOrDeletedByUser?: boolean;
  blockEditAndDelete?: boolean;
  onDelete?: () => void;
  onEdit?: (comment: string) => void;
  onEditModeSwitch?: (isEditMode: boolean) => void;
}

export const CommentBox: FC<CommentBoxProps> = ({
  comment,
  date,
  isEdited,
  canBeEditedOrDeletedByUser,
  blockEditAndDelete,
  userName,
  onDelete,
  onEdit,
  onEditModeSwitch
}) => {
  const { formatDateTimeWithSuffix } = useDateTimeFormat();
  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedCommentValue, setEditedCommentValue] = useState(comment);

  const commentInput = createRef<HTMLInputElement>();

  useEffect(() => {
    if (commentInput.current) {
      commentInput.current.focus();
    }
  }, [commentInput]);

  useEffect(() => {
    setEditedCommentValue(comment);
  }, [comment, setEditedCommentValue]);

  return (
    <article
      className={classNames(styles.CommentBox, { [styles.CommentBox__EditMode]: isEditMode })}
      data-testid="CommentBox"
      /* By the time of writing this, the role=comment is still a draft. This is for future support. */
      /* eslint-disable-next-line jsx-a11y/aria-role */
      role="comment"
      data-author={userName}
    >
      <div>
        <span className={styles.User}>{`${userName},`}</span>
        <span className={styles.Date}>
          <time dateTime={date ? new Date(date)?.toISOString() : ''}>{formatDateTimeWithSuffix(date)}</time>
          {`${isEdited ? `(${t('views.searchRequests.comments.edited')})` : ''}`}
        </span>
      </div>
      {!isEditMode ? (
        <p className={styles.Comment}>{comment}</p>
      ) : (
        <div className={styles.EditComment}>
          <TextInput
            id="comment"
            ref={commentInput}
            labelText=""
            hideLabel
            value={editedCommentValue}
            onChange={(event) => setEditedCommentValue(event.target.value)}
            data-testid="EditInput"
          ></TextInput>
          <div>
            <Button
              kind={'secondary'}
              size="sm"
              onClick={() => {
                setIsEditMode(false);
                onEditModeSwitch && onEditModeSwitch(false);
              }}
              data-testid="CancelButton"
            >
              {t('actions.cancel')}
            </Button>
            <Button
              kind={'primary'}
              size="sm"
              onClick={() => {
                setIsEditMode(false);
                onEdit && onEdit(editedCommentValue);
                onEditModeSwitch && onEditModeSwitch(false);
              }}
              data-testid="SaveButton"
            >
              {t('actions.save')}
            </Button>
          </div>
        </div>
      )}
      {canBeEditedOrDeletedByUser && !blockEditAndDelete && !isEditMode && (
        <OverflowMenu flipped className={styles.Menu} data-testid="OverFlowMenu">
          <OverflowMenuItem
            itemText={
              <span>
                <EditSVGIcon />
                {t('actions.edit')}
              </span>
            }
            onClick={() => {
              setIsEditMode(true);
              onEditModeSwitch && onEditModeSwitch(true);
            }}
            data-testid="EditButton"
          />
          <OverflowMenuItem
            itemText={
              <span>
                <DeleteSVGIcon />
                {t('actions.delete')}
              </span>
            }
            onClick={() => {
              onDelete && onDelete();
            }}
            data-testid="DeleteButton"
          />
        </OverflowMenu>
      )}
    </article>
  );
};

export default CommentBox;
