import { SocialLink } from './components/controls/footer/footer';

export const metaLinks = [
  {
    href: '/legal-details',
    translationKey: 'footer.about',
    isExternal: false
  },
  {
    href: '/privacy-policy',
    translationKey: 'footer.privacy',
    isExternal: false
  }
];

export const socialLinks: SocialLink[] = [
  {
    href: 'https://www.tum.de/die-tum/aktuelles/?type=100',
    title: 'RSS',
    type: 'rss'
  },
  {
    href: 'https://www.facebook.com/TU.Muenchen',
    title: 'Facebook',
    type: 'fb'
  },
  {
    href: 'https://www.instagram.com/tu.muenchen',
    title: 'Instagram',
    type: 'ig'
  },
  {
    href: 'https://www.youtube.com/TUMuenchen1',
    title: 'Youtube',
    type: 'yt'
  },
  {
    href: 'https://twitter.com/TU_Muenchen',
    title: 'Twitter',
    type: 'tw'
  },
  {
    href: 'https://www.linkedin.com/school/166283/',
    title: 'Linkedin',
    type: 'li'
  }
];
