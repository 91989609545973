import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Listing } from '../listings/listing/listing';
import { LandlordList } from './landlord-list/landlord-list';
import { LandlordView } from './landlord-view/landlord-view';

export const LandLordRouter: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<LandlordList />} />
      <Route path="/new" element={<LandlordView />} />
      <Route path="/:uuid" element={<LandlordView />} />
      <Route path="/:uuid/:viewMode" element={<LandlordView />} />
      <Route path="/:landlordUUID/:landlordViewMode/:uuid/:viewMode/*" element={<Listing />} />
    </Routes>
  );
};

export default LandLordRouter;
