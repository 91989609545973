import { AnyAction, combineReducers, configureStore, createAction } from '@reduxjs/toolkit';
import {
  landlordFormDataReducer,
  LandlordFormDataState
} from '../components/views/landlord/landlord-view/landlord-view.state';
import { listingFormDataReducer, ListingFormDataState } from '../components/views/listings/listing/listing.state';
import { UserState, userStateReducer } from './user.state';
import {
  listingsListFilterReducer,
  ListingsListFilterState
} from '../components/views/listings/listings-list/listings-list-filter/listings-list-filter.state';
import {
  searchRequestsFormDataReducer,
  SearchRequestsFormDataState
} from '../components/views/search-requests/search-request/search-request.state';
import { formDirtyStateMiddleWare, FormsDirtyState, formsDirtyStateReducer } from './forms.state';
import { landlordProfileDataReducer, LandlordProfileDataState } from '../components/views/account/account-view.state';
import { AdministrationState, administrationStateReducer } from './administration.state';

export type GlobalState = {
  user: UserState;
  formsDirtyState: FormsDirtyState;
  landlordFormData: LandlordFormDataState;
  listingFormData: ListingFormDataState;
  listingsListFilter: ListingsListFilterState;
  searchRequestsFormData: SearchRequestsFormDataState;
  landlordProfileData: LandlordProfileDataState;
  administrationState: AdministrationState;
};

export const logout = createAction<undefined>('GlobalState/logout');

const appReducer = combineReducers({
  user: userStateReducer,
  formsDirtyState: formsDirtyStateReducer,
  landlordFormData: landlordFormDataReducer,
  listingFormData: listingFormDataReducer,
  listingsListFilter: listingsListFilterReducer,
  searchRequestsFormData: searchRequestsFormDataReducer,
  landlordProfileData: landlordProfileDataReducer,
  administrationState: administrationStateReducer
});

const rootReducer = (state: GlobalState | undefined, action: AnyAction): GlobalState => {
  if (action.type === logout.type) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const defaultStore = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(formDirtyStateMiddleWare)
});
