import styles from './back-navigation.module.scss';
import { KeyboardArrowLeftSVGIcon } from '@react-md/material-icons';
import { FC, PropsWithChildren, ReactElement } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useDocumentTitle } from '../../../utils/hooks/document-title';

export interface BackNavigationProps {
  headline: string;
  navigateTo: string;
  subHeadline?: string;
  tag?: ReactElement;
  className?: string;
}

export const BackNavigation: FC<PropsWithChildren<BackNavigationProps>> = (
  props: PropsWithChildren<BackNavigationProps>
) => {
  const { state } = useLocation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const linkTo = state?.params
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      `${props.navigateTo}?${new URLSearchParams(state?.params).toString()}`
    : props.navigateTo;

  useDocumentTitle(`${props.headline}${props.subHeadline ? ` | ${props.subHeadline}` : ''}`);

  return (
    <nav className={classNames(props.className, styles.BackNavigation)}>
      <div className="global-content-wrapper">
        <Link to={linkTo} className={styles.BackNavigationLink}>
          <KeyboardArrowLeftSVGIcon />
          <div className={styles.TitleContainer}>
            <h1>{props.headline}</h1>
            {props.subHeadline && <div role="doc-subtitle" className={styles.SubTitle}>{`| ${props.subHeadline}`}</div>}
            {props.tag}
          </div>
        </Link>
      </div>
    </nav>
  );
};
