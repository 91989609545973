import { FC } from 'react';
import { DeleteSVGIcon, LinkSVGIcon, MailSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { copyURLtoClipboard, createShareEntityLink, MailContent, shareByMail } from '../../../utils/actions';

export interface EntityViewActionProps {
  onDelete?: () => void;
  getShareByMailContent: () => MailContent;
}

export const EntityViewActions: FC<EntityViewActionProps> = ({ onDelete, getShareByMailContent }) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        kind="ghost"
        size="sm"
        renderIcon={DeleteSVGIcon}
        className="bx--btn--hasIcon"
        onClick={onDelete}
        disabled={onDelete === undefined}
      >
        {t('actions.delete')}
      </Button>
      <Button
        kind="ghost"
        size="sm"
        renderIcon={LinkSVGIcon}
        className="bx--btn--hasIcon"
        onClick={() => copyURLtoClipboard(createShareEntityLink(), t)}
      >
        {t('actions.copyLink')}
      </Button>
      <Button
        kind="ghost"
        size="sm"
        renderIcon={MailSVGIcon}
        className="bx--btn--hasIcon"
        onClick={() => shareByMail(getShareByMailContent())}
      >
        {t('actions.forward')}
      </Button>
    </>
  );
};

export default EntityViewActions;
