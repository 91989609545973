import { useMutation } from '@apollo/client';
import { DELETE_LANDLORD } from '../../../../graphql/queries/landlord';
import { useQueryResultToast } from '../../../../utils/hooks/query-result-toast';
import { DeleteLandlord, DeleteLandlordVariables } from '../../../../graphql/types/DeleteLandlord';

// IMPROVEMENT: Use the default error handler as default props to all queries/mutations

// We already show errors by reacting to the error return object of queries/mutations.
// In addition, we use the error handle link, which automatically logs network/graphql errors.
// Therefore, we don't need a third error handling component, which is why this function is empty
// eslint-disable-next-line @typescript-eslint/no-empty-function, no-empty-function
const DEFAULT_ERROR_HANDLER = (): void => {};

export const useDeleteMutation = (id: string): { deleteLandlord: () => Promise<unknown> } => {
  const [deleteLandlord, { data: deleteResultData, error: deleteError }] = useMutation<
    DeleteLandlord,
    DeleteLandlordVariables
  >(DELETE_LANDLORD, {
    variables: { landlordId: id },
    onError: DEFAULT_ERROR_HANDLER
  });
  useQueryResultToast(deleteResultData, deleteError, {
    success: {
      titleKey: 'notifications.landlordDeleteSuccess'
    },
    error: {
      titleKey: 'notifications.landlordDeleteError',
      subTitleKey: 'notifications.landlordDeleteErrorSubtitle'
    }
  });

  return { deleteLandlord };
};
