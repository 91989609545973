import { createRef, FC, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Glide from '@glidejs/glide';
import { ChevronLeftSVGIcon, ChevronRightSVGIcon, CollectionsSVGIcon } from '@react-md/material-icons';
import { Button } from 'carbon-components-react';
import classNames from 'classnames';
import styles from './image-gallery.module.scss';
import { LAZY_LOADING_CLASSNAME } from '../../../image-lazy-loading';

type ImageDescriptor = {
  path: string;
  description?: string;
  descriptionEN?: string;
};

export interface ImageGalleryProps {
  images: ImageDescriptor[];
  paused?: boolean;
  showDescription?: boolean;
  showBullets?: boolean;
  showControlsOnHover?: boolean;
  isFullscreen?: boolean;
  onOpenGallery?: () => void;
  className?: string;
}

/**
 * Aria best practices
 * https://www.w3.org/TR/wai-aria-practices-1.1/#carousel
 */
export const ImageGallery: FC<ImageGalleryProps> = ({
  images,
  className,
  onOpenGallery,
  showDescription,
  showBullets,
  showControlsOnHover,
  isFullscreen,
  paused
}) => {
  const { t, i18n } = useTranslation();

  const containerRef = createRef<HTMLDivElement>();
  const [glideInstance, setGlideInstance] = useState<Glide.Properties | null>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  useEffect(() => {
    let glide: Glide.Static | undefined;
    if (containerRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      glide = new Glide(containerRef.current, {
        keyboard: false,
        animationDuration: 300
      });
      const gallery = glide.mount();
      glide.on('run', () => {
        setCurrentSlideIndex(gallery.index);
      });
      setGlideInstance(gallery);
    }

    return () => {
      if (glide) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        glide.destroy();
        setGlideInstance(null);
      }
    };
  }, [containerRef.current, images]);

  useEffect(() => {
    if (glideInstance) {
      if (paused) {
        glideInstance.disable();
      } else {
        glideInstance.enable();
      }
    }
  }, [glideInstance, paused]);

  const onKeyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (glideInstance) {
        if (event.key === 'ArrowLeft') {
          glideInstance.go('<');
        } else if (event.key === 'ArrowRight') {
          glideInstance.go('>');
        }
      }
    },
    [glideInstance]
  );

  return (
    <div
      className={classNames(
        'glide',
        styles.Gallery,
        { [styles.Gallery__ControlsHidden]: showControlsOnHover },
        { [styles.Gallery__Fullscreen]: isFullscreen },
        className
      )}
      ref={containerRef}
      onKeyDown={onKeyDownHandler}
      role="listbox"
      tabIndex={0}
      onClick={onOpenGallery}
    >
      <div className={classNames('glide__track', styles.Track)} data-glide-el="track">
        <ul className="glide__slides">
          {images.map((image, index) => {
            const currentDescription = i18n.language === 'de' ? image.description : image.descriptionEN;
            return (
              <li
                className={classNames('glide__slide')}
                key={index}
                aria-hidden={index !== currentSlideIndex}
                role="group"
                aria-roledescription="slide"
              >
                {showDescription && <p className={styles.GalleryImageDescription}>{currentDescription}</p>}
                <img
                  className={classNames(styles.GalleryImage, {
                    [LAZY_LOADING_CLASSNAME]: index === currentSlideIndex || index === currentSlideIndex + 1
                  })}
                  data-src={image.path}
                  alt={currentDescription}
                />
              </li>
            );
          })}
        </ul>
      </div>
      {images.length > 1 && (
        <>
          <div className={classNames(styles.GalleryControls)}>
            <Button
              kind="ghost"
              renderIcon={ChevronLeftSVGIcon}
              iconDescription={t('actions.back')}
              title={t('actions.back')}
              hasIconOnly
              data-testid="GalleryButtonBack"
              tooltipAlignment="start"
              onClick={(e) => {
                e.stopPropagation();
                glideInstance && glideInstance.go('<');
              }}
            ></Button>
            <Button
              kind="ghost"
              renderIcon={ChevronRightSVGIcon}
              iconDescription={t('actions.next')}
              title={t('actions.next')}
              hasIconOnly
              data-testid="GalleryButtonNext"
              tooltipAlignment="end"
              onClick={(e) => {
                e.stopPropagation();
                glideInstance && glideInstance.go('>');
              }}
            ></Button>
          </div>
          {onOpenGallery && (
            <Button
              size="field"
              className={styles.OpenGalleryButton}
              renderIcon={CollectionsSVGIcon}
              iconDescription={t('icons.openGallery')}
              title={t('icons.openGallery')}
              onClick={onOpenGallery}
              data-testid="GalleryButtonOpenFullscreen"
            >
              {t('actions.photos', { count: images.length })}
            </Button>
          )}
          {showBullets && (
            <div className="glide__bullets" data-glide-el="controls[nav]">
              {images.map((image, index) => (
                <button
                  type="button"
                  aria-label={t('actions.selectImage', { index })}
                  className="glide__bullet"
                  data-glide-dir={`=${index}`}
                  key={index}
                  data-testid={'GalleryButtonBullet'}
                  // IMPROVEMENT: To improve tab navigation in listing lists, bullets cannot be selected individually for now.
                  //              This should be replaced by focus roving. See the mail editor toolbar component,
                  //              or https://www.w3.org/TR/wai-aria-practices-1.1/#kbd_roving_tabindex for further information.
                  tabIndex={-1}
                />
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageGallery;
