import { FC, useMemo } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { ToastNotification } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { logout } from '../redux/store';
import { getApiBaseUrl } from '../utils/api';
import { IS_DEV_MODE, START_VIEW_PATH } from '../utils/constants';
import { TOAST_DEFAULT_PROPERTIES } from '../utils/hooks/query-result-toast';
import { logger } from '../utils/logger';
import { setRequestTimeOut } from '../redux/user.state';

const TUMApolloProvider: FC = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errorHandleLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        logger.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
        if (extensions?.code === 'UNAUTHENTICATED') {
          dispatch(logout());
          navigate(START_VIEW_PATH);
          toast(
            () => (
              <ToastNotification
                kind="error"
                title={t('notifications.sessionExpiredTitle') as string}
                subtitle={t('notifications.sessionExpiredSubTitle') as string}
                caption=""
              />
            ),
            TOAST_DEFAULT_PROPERTIES
          );
        }
      });

    if (networkError) {
      logger.error(`[Network error]: ${networkError}`);
    }
  });

  const apolloServerUri = `${getApiBaseUrl()}graphql` || '/graphql';

  // Timeout function wrapper for fetch
  const fetchWithTimeout = (uri: string, options: RequestInit, timeout = 5000): Promise<Response> => {
    return new Promise<Response>((resolve, reject) => {
      const timeoutId = setTimeout(() => reject(new Error('Request timed out')), timeout);

      fetch(uri, options)
        .then((response) => {
          if (response.status === 502) {
            dispatch(setRequestTimeOut(true));
          } else {
            clearTimeout(timeoutId);
            resolve(response);
          }
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          reject(error);
        });
    });
  };

  // Create apollo client
  const apolloClient = useMemo(() => {
    return new ApolloClient({
      cache: new InMemoryCache({
        resultCaching: false
      }),
      link: ApolloLink.from([
        errorHandleLink,
        createUploadLink({
          uri: apolloServerUri,
          fetch: (uri: string, options: RequestInit) => fetchWithTimeout(uri, options, 5000)
        })
      ]),
      connectToDevTools: IS_DEV_MODE
    });
  }, []);

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};

export default TUMApolloProvider;
