import { GetSearchRequestByUUID_searchRequestByUUID } from '../../../../graphql/types/GetSearchRequestByUUID';
import { SearchRequestsFormDataState } from './search-request.state';
import { TFunction } from 'i18next';
import { AffiliationType } from '../search-requests-types';
import { FILTER_SEPARATOR, Param } from '../../../../utils/constants';
import { GetSeekerSearchRequests_seekerSearchRequests } from '../../../../graphql/types/GetSeekerSearchRequests';

export const getFormData = (queryResult: GetSearchRequestByUUID_searchRequestByUUID): SearchRequestsFormDataState => {
  // eslint-disable-next-line spellcheck/spell-checker, @typescript-eslint/no-unused-vars
  const { __typename, ...searchRequest } = queryResult;
  return Object.assign(searchRequest);
};

export const formatRange = (t: TFunction, from: number | null, to: number | null, unit?: string): string => {
  let result = '';
  if (from && to) {
    result = from + '-' + to;
  } else if (from && !to) {
    result = t('formFields.from') + ' ' + from;
  } else if (!from && to) {
    result = t('formFields.to') + ' ' + to;
  }

  return result + (unit ? ` ${unit}` : '');
};

export const buildSearchRequestFilter = (state: SearchRequestsFormDataState): string => {
  const queryString = [
    state.availableUntil === null ? Param.OPEN_ENDED + '=true' : '',
    state.affiliationType.indexOf(AffiliationType.GUEST_RESEARCHER) !== -1 ? Param.GUEST_RESEARCHERS + '=1' : '',
    state.affiliationType.indexOf(AffiliationType.PROFESSOR) !== -1 ? Param.PROFESSORS + '=1' : '',
    state.affiliationType.indexOf(AffiliationType.INCOMING) !== -1 ? Param.INCOMINGS + '=1' : '',
    state.affiliationType.indexOf(AffiliationType.STUDENT) !== -1 ? Param.STUDENTS + '=1' : '',
    state.affiliationType.indexOf(AffiliationType.POST_DOCTORAL_STUDENT) !== -1 ? Param.POST_DOC_STUDENTS + '=1' : '',
    state.affiliationType.indexOf(AffiliationType.DOCTORAL_STUDENT) !== -1 ? Param.DOC_STUDENTS + '=1' : '',
    state.type !== null ? Param.TYPE + '=' + state.type : '',
    state.numberOfRooms !== null ? Param.ROOMS + '=' + state.numberOfRooms : '',
    state.squareMeterFrom > 0 ? Param.SQUARE_FROM + '=' + state.squareMeterFrom : '',
    state.squareMeterTo !== null ? Param.SQUARE_TO + '=' + state.squareMeterTo : '',
    state.totalRentFrom > 0 ? Param.RENT_FROM + '=' + state.totalRentFrom : '',
    state.totalRentTo !== null ? Param.RENT_TO + '=' + state.totalRentTo : '',
    state.availableFrom !== null ? Param.AVAILABLE_LATEST + '=' + state.availableFrom : '',
    state.city !== null ? Param.TUM_LOCATION + '=' + state.city : '',
    state.depositMax !== null ? Param.DEPOSIT + '=' + state.depositMax : '',
    state.districts ? Param.DISTRICTS + '=' + state.districts.join(FILTER_SEPARATOR) : ''
  ];
  if (state.tags) {
    state.tags.forEach((tag) => queryString.push(tag + '=1'));
  }

  return '?' + queryString.filter((item) => item !== '').join('&');
};

export const buildSeekerSearchRequestFilter = (
  seekerSearchRequest: GetSeekerSearchRequests_seekerSearchRequests
): string => {
  const queryString = [
    seekerSearchRequest.type !== null ? Param.TYPE + '=' + seekerSearchRequest.type : '',
    seekerSearchRequest.numberOfRooms !== null ? Param.ROOMS + '=' + seekerSearchRequest.numberOfRooms : '',
    seekerSearchRequest.squareMeterFrom && seekerSearchRequest.squareMeterFrom > 0
      ? Param.SQUARE_FROM + '=' + seekerSearchRequest.squareMeterFrom
      : '',
    seekerSearchRequest.squareMeterTo !== null ? Param.SQUARE_TO + '=' + seekerSearchRequest.squareMeterTo : '',
    seekerSearchRequest.totalRentFrom && seekerSearchRequest.totalRentFrom > 0
      ? Param.RENT_FROM + '=' + seekerSearchRequest.totalRentFrom
      : '',
    seekerSearchRequest.totalRentTo !== null ? Param.RENT_TO + '=' + seekerSearchRequest.totalRentTo : '',
    seekerSearchRequest.availableFrom !== null ? Param.AVAILABLE_FROM + '=' + seekerSearchRequest.availableFrom : '',
    seekerSearchRequest.availableFromLatest !== null
      ? Param.AVAILABLE_LATEST + '=' + seekerSearchRequest.availableFromLatest
      : '',
    seekerSearchRequest.tumLocation !== null ? Param.TUM_LOCATION + '=' + seekerSearchRequest.tumLocation : '',
    seekerSearchRequest.depositMax !== null ? Param.DEPOSIT + '=' + seekerSearchRequest.depositMax : ''
  ];

  if (seekerSearchRequest.districts && seekerSearchRequest.districts.length > 0) {
    queryString.push(Param.DISTRICTS + '=' + seekerSearchRequest.districts.join(FILTER_SEPARATOR));
  }

  if (seekerSearchRequest.tags) {
    seekerSearchRequest.tags.forEach((tag) => queryString.push(tag + '=1'));
  }

  return '?' + queryString.filter((item) => item !== '').join('&');
};
