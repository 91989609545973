import { ApolloError } from '@apollo/client';
import { ToastNotification } from 'carbon-components-react';
import { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const TOAST_DEFAULT_DSIPLAY_DURATION = 10000;
export const TOAST_DEFAULT_PROPERTIES = {
  progressClassName: 'Toastify__toast-progress',
  autoClose: TOAST_DEFAULT_DSIPLAY_DURATION,
  closeButton: false
};

export interface ToastMessage {
  titleKey: string;
  subTitleKey?: string;
}

export interface QueryResultMessages {
  success?: ToastMessage;
  error: ToastMessage;
}

export const useQueryResultToast = (
  queryResultData: unknown | null | undefined,
  queryError: ApolloError | undefined,
  messages: QueryResultMessages,
  style?: CSSProperties
): void => {
  const { t } = useTranslation();

  useEffect(() => {
    if (queryResultData && !queryError && messages.success) {
      toast(
        () => (
          <ToastNotification
            kind="success"
            title={messages.success ? (t(messages.success.titleKey) as string) : ''}
            subtitle={
              messages.success ? messages.success.subTitleKey && (t(messages.success.subTitleKey) as string) : ''
            }
            style={style}
            caption=""
          />
        ),
        TOAST_DEFAULT_PROPERTIES
      );
    } else if (queryError) {
      let subtitle = messages.error.subTitleKey && (t(messages.error.subTitleKey) as string);
      // Check if there is any more meaningful error code coming from the backend
      if (queryError.graphQLErrors.length > 0 && queryError.graphQLErrors[0].extensions?.code) {
        const errorCode = queryError.graphQLErrors[0].extensions?.code;
        const translationKey = `errors.${errorCode}`;
        const translation = t(translationKey);
        if (translation !== translationKey) {
          subtitle = translation;
        }
      }
      toast(
        () => (
          <ToastNotification
            kind="error"
            title={t(messages.error.titleKey) as string}
            subtitle={subtitle}
            style={style}
            caption=""
          />
        ),
        TOAST_DEFAULT_PROPERTIES
      );
    }
  }, [queryResultData, queryError]);
};

export const useGenericQueryErrorToast = (queryError: ApolloError | undefined): void => {
  const { t } = useTranslation();

  useEffect(() => {
    if (queryError) {
      toast(
        () => (
          <ToastNotification
            kind="error"
            title={t('notifications.genericDataQueryError') as string}
            subtitle={t('notifications.genericDataQueryErrorSubtitle') as string}
            caption=""
          />
        ),
        TOAST_DEFAULT_PROPERTIES
      );
    }
  }, [queryError]);
};
