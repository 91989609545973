import classNames from 'classnames';
import { FC, useCallback, useState, createRef, useEffect } from 'react';
import styles from './filter-dropdown.module.scss';
import { ReactComponent as ChevronDownIcon } from '../../../assets/svg/chevron-down.svg';

export interface FilterDropDownProps {
  id: string;
  label: string;
  value?: string;
  placeholder?: string;
  alignContent?: 'right' | 'left';
}

export const FilterDropDown: FC<FilterDropDownProps> = ({ children, id, label, value, placeholder, alignContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isMouseDownOnButton, setIsMouseDownOnButton] = useState(false);
  const dropDownRef = createRef<HTMLDivElement>();
  const filterRef = createRef<HTMLDivElement>();

  const labelId = id + '-label';
  const buttonId = id + '-button';

  const onBlurHandler = useCallback(
    (event) => {
      if (
        !isMouseDownOnButton &&
        (event.relatedTarget === null || !event.currentTarget.contains(event.relatedTarget as HTMLElement))
      ) {
        setIsOpen(false);
        setIsFocused(false);
      }
    },
    [setIsOpen, buttonId, dropDownRef, isMouseDownOnButton]
  );

  // focus dropdown automatically after opening
  useEffect(() => {
    if (dropDownRef.current && !isFocused && isOpen) {
      dropDownRef.current.focus();
      setIsFocused(true);
    }
  }, [dropDownRef.current, isOpen]);

  return (
    <div id={id} className={styles.FilterDropDown}>
      <label id={labelId} htmlFor={id} className={styles.FilterDropDownLabel}>
        {label}
      </label>

      <div
        ref={filterRef}
        className={classNames(styles.FilterDropDownMenu, {
          [styles.FilterDropDownMenu__open]: isOpen
        })}
      >
        <button
          id={buttonId}
          type="button"
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-labelledby={`${labelId} ${buttonId}`}
          onMouseDown={() => setIsMouseDownOnButton(true)}
          onMouseUp={() => setIsMouseDownOnButton(false)}
          onClick={() => {
            setIsFocused(false);
            setIsOpen(!isOpen);
          }}
          className={classNames(styles.FilterDropDownButton)}
        >
          <span>{value || placeholder}</span>
          <div className={styles.FilterDropDownMenuIcon}>
            <ChevronDownIcon />
          </div>
        </button>

        {isOpen && (
          <div
            ref={dropDownRef}
            className={classNames(styles.Dropdown, {
              [styles.Dropdown__right]: alignContent === 'right',
              [styles.Dropdown__left]: alignContent === 'left'
            })}
            role="textbox"
            onBlur={onBlurHandler}
            tabIndex={0}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterDropDown;
