import { ActionCreatorWithoutPayload, ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DistrictItem } from '../listings-list.filter-types';

export interface ListingsListPublicFilterState {
  districts: DistrictItem[];
  availableFrom: string | null;
  availableFromLatest: string | null;
  immediatelyAvailable: boolean;
  openEnded: boolean | null;
  deposit: number | null;
  tags: string[];
  rentFromFilter: number | null;
  rentToFilter: number | null;
  squareMeterFromFilter: number | null;
  squareMeterToFilter: number | null;
  isNewFilterConfiguration: boolean;
}

export interface ListingsListFilterState extends ListingsListPublicFilterState {
  seekingGuestResearchers: boolean | null;
  seekingProfessors: boolean | null;
  seekingIncomings: boolean | null;
  seekingStudents: boolean | null;
  seekingPostDoctoralStudents: boolean | null;
  seekingDoctoralStudents: boolean | null;
  seekingTumEmployees: boolean | null;
  landlordIsManagement: boolean;
  verificationStatus: (boolean | null)[] | null;
  activationStatus: (boolean | null)[] | null;
  isListingPublic: boolean | null;
}

export const initialListingsListFilterState: ListingsListFilterState = {
  districts: [],
  availableFrom: null,
  availableFromLatest: null,
  immediatelyAvailable: false,
  openEnded: null,
  seekingStudents: null,
  seekingProfessors: null,
  seekingIncomings: null,
  seekingDoctoralStudents: null,
  seekingPostDoctoralStudents: null,
  seekingGuestResearchers: null,
  seekingTumEmployees: null,
  deposit: null,
  landlordIsManagement: false,
  verificationStatus: null,
  activationStatus: [true],
  tags: [],
  isListingPublic: null,
  rentFromFilter: null,
  rentToFilter: null,
  squareMeterFromFilter: null,
  squareMeterToFilter: null,
  isNewFilterConfiguration: false
};

export const slice = createSlice({
  name: 'ListingFormData',
  reducers: {
    setDistricts: (state: ListingsListFilterState, action: PayloadAction<DistrictItem[]>) => {
      state.districts = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setAvailableFrom: (state: ListingsListFilterState, action: PayloadAction<string | null>) => {
      state.availableFrom = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setAvailableFromLatest: (state: ListingsListFilterState, action: PayloadAction<string | null>) => {
      state.availableFromLatest = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setImmediatelyAvailable: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.immediatelyAvailable = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setOpenEnded: (state: ListingsListFilterState, action: PayloadAction<boolean | null>) => {
      state.openEnded = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setSeekingStudents: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingStudents = action.payload;
    },
    setSeekingProfessors: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingProfessors = action.payload;
    },
    setSeekingIncomings: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingIncomings = action.payload;
    },
    setSeekingDoctoralStudents: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingDoctoralStudents = action.payload;
    },
    setSeekingPostDoctoralStudents: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingPostDoctoralStudents = action.payload;
    },
    setSeekingGuestResearchers: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingGuestResearchers = action.payload;
    },
    setSeekingTumEmployees: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingTumEmployees = action.payload;
    },
    setLandlordIsManagement: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.landlordIsManagement = action.payload;
    },
    setVerificationStatus: (state: ListingsListFilterState, action: PayloadAction<(boolean | null)[] | null>) => {
      state.verificationStatus = action.payload;
    },
    setActivationStatus: (state: ListingsListFilterState, action: PayloadAction<(boolean | null)[] | null>) => {
      state.activationStatus = action.payload;
    },
    setIsListingPublic: (state: ListingsListFilterState, action: PayloadAction<boolean | null>) => {
      state.isListingPublic = action.payload;
    },
    setDeposit: (state: ListingsListFilterState, action: PayloadAction<number | null>) => {
      state.deposit = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setTags: (state: ListingsListFilterState, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setSeekingFlags: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.seekingStudents = action.payload;
      state.seekingProfessors = action.payload;
      state.seekingIncomings = action.payload;
      state.seekingDoctoralStudents = action.payload;
      state.seekingPostDoctoralStudents = action.payload;
      state.seekingGuestResearchers = action.payload;
      state.seekingTumEmployees = action.payload;
    },
    setRentFromFilter: (state: ListingsListFilterState, action: PayloadAction<number | null>) => {
      state.rentFromFilter = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setRentToFilter: (state: ListingsListFilterState, action: PayloadAction<number | null>) => {
      state.rentToFilter = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setSquareMeterFromFilter: (state: ListingsListFilterState, action: PayloadAction<number | null>) => {
      state.squareMeterFromFilter = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setSquareMeterToFilter: (state: ListingsListFilterState, action: PayloadAction<number | null>) => {
      state.squareMeterToFilter = action.payload;
      // state.isNewFilterConfiguration = true;
    },
    setIsNewFilterConfiguration: (state: ListingsListFilterState, action: PayloadAction<boolean>) => {
      state.isNewFilterConfiguration = action.payload;
    },
    resetFilter: (state: ListingsListFilterState) => {
      Object.assign(state, initialListingsListFilterState, {
        isNewFilterConfiguration: true
      });
    },
    setListingsListFilterState: (state: ListingsListFilterState, action: PayloadAction<ListingsListFilterState>) => {
      Object.assign(state, action.payload);
      state.isNewFilterConfiguration = false;
    },
    setListingsListPublicFilterState: (
      state: ListingsListPublicFilterState,
      action: PayloadAction<ListingsListPublicFilterState>
    ) => {
      Object.assign(state, action.payload);
    }
  },
  initialState: initialListingsListFilterState
});

export const setDistricts: ActionCreatorWithPayload<DistrictItem[]> = slice.actions.setDistricts;
export const setAvailableFrom: ActionCreatorWithPayload<string | null> = slice.actions.setAvailableFrom;
export const setAvailableFromLatest: ActionCreatorWithPayload<string | null> = slice.actions.setAvailableFromLatest;
export const setImmediatelyAvailable: ActionCreatorWithPayload<boolean> = slice.actions.setImmediatelyAvailable;
export const setOpenEnded: ActionCreatorWithPayload<boolean | null> = slice.actions.setOpenEnded;
export const setSeekingStudents: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingStudents;
export const setSeekingProfessors: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingProfessors;
export const setSeekingIncomings: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingIncomings;
export const setSeekingDoctoralStudents: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingDoctoralStudents;
export const setSeekingPostDoctoralStudents: ActionCreatorWithPayload<boolean> =
  slice.actions.setSeekingPostDoctoralStudents;
export const setSeekingGuestResearchers: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingGuestResearchers;
export const setSeekingTumEmployees: ActionCreatorWithPayload<boolean> = slice.actions.setSeekingTumEmployees;
export const setDeposit: ActionCreatorWithPayload<number | null> = slice.actions.setDeposit;
export const setLandlordIsManagement: ActionCreatorWithPayload<boolean> = slice.actions.setLandlordIsManagement;
export const setVerificationStatus: ActionCreatorWithPayload<(boolean | null)[] | null> =
  slice.actions.setVerificationStatus;
export const setActivationStatus: ActionCreatorWithPayload<(boolean | null)[] | null> =
  slice.actions.setActivationStatus;

export const setIsListingPublic: ActionCreatorWithPayload<boolean | null> = slice.actions.setIsListingPublic;
export const setTags: ActionCreatorWithPayload<string[]> = slice.actions.setTags;
export const resetListingsFilter: ActionCreatorWithoutPayload = slice.actions.resetFilter;
export const setListingFilterState: ActionCreatorWithPayload<ListingsListFilterState> =
  slice.actions.setListingsListFilterState;
export const setListingPublicFilterState: ActionCreatorWithPayload<ListingsListPublicFilterState> =
  slice.actions.setListingsListPublicFilterState;
export const setRentFromFilter: ActionCreatorWithPayload<number | null> = slice.actions.setRentFromFilter;
export const setRentToFilter: ActionCreatorWithPayload<number | null> = slice.actions.setRentToFilter;
export const setSquareMeterFromFilter: ActionCreatorWithPayload<number | null> = slice.actions.setSquareMeterFromFilter;
export const setSquareMeterToFilter: ActionCreatorWithPayload<number | null> = slice.actions.setSquareMeterToFilter;
export const setIsNewFilterConfiguration: ActionCreatorWithPayload<boolean> = slice.actions.setIsNewFilterConfiguration;

export const listingsListFilterReducer = slice.reducer;
