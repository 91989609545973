import { FC, useCallback, useEffect, useState } from 'react';
import styles from './to-top-button.module.scss';
import { ReactComponent as ToTopIcon } from '../../../assets/svg/toTop.svg';
import classNames from 'classnames';

const SCROLL_Y_THRESHOLD = 500;

export const ToTopButton: FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onScrollListener = useCallback(() => {
    if (window.scrollY > SCROLL_Y_THRESHOLD) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScrollListener);
    return () => {
      window.removeEventListener('scroll', onScrollListener);
    };
  }, [onScrollListener]);

  return (
    <button
      type="button"
      className={classNames(styles.ToTopButton, {
        [styles.ToTopButton__visible]: isVisible
      })}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <ToTopIcon />
    </button>
  );
};

export default ToTopButton;
