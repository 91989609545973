import { gql } from '@apollo/client';

// Queries & Mutations for Management Settings

export const GET_LANDLORD_REGISTERED_SUBSCRIPTION = gql`
  # Returns whether the current user is subscribed to notifications when a new landlord was registered
  query GetLandlordRegisteredSubscription {
    hasNewLandlordRegisteredSubscription
  }
`;

export const GET_LISTING_PUBLISHED_SUBSCRIPTION = gql`
  # Returns whether the current user is subscribed to notifications when a new listing was published
  query GetListingPublishedSubscription {
    hasNewListingPublishedSubscription
  }
`;

export const GET_SEARCH_REQUEST_SUBSCRIPTION = gql`
  # Returns whether the current user is subscribed to notifications when there is a new search request
  query GetSearchRequestSubscription {
    hasNewSearchRequestSubscription
  }
`;

export const GET_REQUEST_UPDATED_SUBSCRIPTION = gql`
  # Returns whether the current user is subscribed to notifications when there is an update on a watched search request
  query GetRequestUpdatedSubscription {
    hasWatchedRequestUpdatedSubscription
  }
`;

export const GET_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION = gql`
  # Returns whether the current user is subscribed to notifications when there are new comments on a search request
  query GetSearchRequestCommentsSubscription {
    hasSearchRequestNewCommentSubscription
  }
`;

export const GET_LISTING_TARGET_SUBSCRIPTIONS = gql`
  # Returns the listing targets for the listing published email subscription
  query GetListingTargetSubscriptions {
    getListingTargetSubscriptions {
      seekingStudents
      seekingIncomings
      seekingDoctoralStudents
      seekingGuestResearchers
      seekingPostDoctoralStudents
      seekingProfessors
      seekingTumEmployees
    }
  }
`;

export const GET_LISTING_LOCATION_SUBSCRIPTIONS = gql`
  # Returns the listing locations for the listing published email subscription
  query GetListingLocationSubscriptions {
    getListingLocationSubscriptions {
      seekingFreising
      seekingGarching
      seekingHeilbronn
      seekingMunich
      seekingStraubing
      seekingGarmischPartenkirchen
    }
  }
`;

export const POST_LANDLORD_REGISTERED_SUBSCRIPTION = gql`
  # Updates the current user's subscription to notifications when a new landlord was registered
  mutation PostLandlordRegisteredSubscription($subscribe: Boolean!) {
    updateNewLandlordRegisteredSubscription(subscribe: $subscribe)
  }
`;

export const POST_LISTING_PUBLISHED_SUBSCRIPTION = gql`
  # Updates the current user's subscription to notifications when a new listing was published
  mutation PostListingPublishedSubscription($subscribe: Boolean!) {
    updateNewListingPublishedSubscription(subscribe: $subscribe)
  }
`;

export const POST_SEARCH_REQUEST_SUBSCRIPTION = gql`
  # Updates the current user's subscription to notifications when there is a new search request
  mutation PostSearchRequestSubscription($subscribe: Boolean!) {
    updateNewSearchRequestSubscription(subscribe: $subscribe)
  }
`;

export const POST_REQUEST_UPDATED_SUBSCRIPTION = gql`
  # Updates the current user's subscription to notifications when there is an update on a watched search request
  mutation PostRequestUpdatedSubscription($subscribe: Boolean!) {
    updateWatchedRequestUpdatedSubscription(subscribe: $subscribe)
  }
`;

export const POST_SEARCH_REQUEST_COMMENTS_SUBSCRIPTION = gql`
  # Updates the current user's subscription to notifications when there are new comments on a search request
  mutation PostSearchRequestCommentsSubscription($subscribe: Boolean!) {
    updateSearchRequestNewCommentSubscription(subscribe: $subscribe)
  }
`;

export const UPDATE_LISTING_TARGET_SUBSCRIPTIONS = gql`
  # Updates the listing targets for the listing published email subscription
  mutation UpdateListingTargetSubscriptions($targets: ListingTargetInput!) {
    updateListingTargetSubscriptions(targets: $targets)
  }
`;

export const UPDATE_LISTING_LOCATION_SUBSCRIPTIONS = gql`
  # Updates the listing locations for the listing published email subscription
  mutation UpdateListingLocationSubscriptions($locations: ListingLocationInput!) {
    updateListingLocationSubscriptions(locations: $locations)
  }
`;

// Queries & Mutations for Landlords Settings

export const GET_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION = gql`
  # Returns whether the landlord is subscribed to notifications when a listing was published
  query GetLandlordListingPublishedSubscription {
    hasListingPublishedSubscription
  }
`;

export const GET_LANDLORD_LISTING_DELETED_SUBSCRIPTION = gql`
  # Returns whether the landlord is subscribed to notifications when a listing was deleted
  query GetLandlordListingDeletedSubscription {
    hasListingDeletedSubscription
  }
`;

export const GET_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION = gql`
  # Returns whether the landlord is subscribed to notifications when a listing became inactive
  query GetLandlordListingInactiveSubscription {
    hasListingInactiveSubscription
  }
`;

export const POST_LANDLORD_LISTING_PUBLISHED_SUBSCRIPTION = gql`
  # Updates the landlord's subscription to notifications when a listing was published
  mutation PostLandlordListingPublishedSubscription($subscribe: Boolean!) {
    updateListingPublishedSubscription(subscribe: $subscribe)
  }
`;

export const POST_LANDLORD_LISTING_DELETED_SUBSCRIPTION = gql`
  # Updates the landlord's subscription to notifications when a listing was deleted
  mutation PostLandlordListingDeletedSubscription($subscribe: Boolean!) {
    updateListingDeletedSubscription(subscribe: $subscribe)
  }
`;

export const POST_LANDLORD_LISTING_INACTIVE_SUBSCRIPTION = gql`
  # Updates the landlord's subscription to notifications when a listing became inactive
  mutation PostLandlordListingInactiveSubscription($subscribe: Boolean!) {
    updateListingInactiveSubscription(subscribe: $subscribe)
  }
`;
