import { ApprovalType } from '../components/controls/approval-state/approval-state';
import { Permission } from '../graphql/types/globalTypes';

type ApprovableLandlord = {
  blockedBy: string | null;
  verifiedAt: unknown | null;
};

export const hasAdministrativeFunction = (permission?: string): boolean =>
  permission !== undefined &&
  [Permission.ADMINISTRATOR.toString(), Permission.MANAGEMENT.toString()].indexOf(permission) > -1;

export const isAdmin = (permission?: string): boolean => permission === Permission.ADMINISTRATOR.toString();

export const isLandlord = (permission?: string): boolean => permission === Permission.LANDLORD.toString();

export const isSeeker = (permission?: string): boolean => permission === Permission.SEEKER.toString();

export const isApproved = (landlord: ApprovableLandlord): ApprovalType => {
  const { blockedBy, verifiedAt } = landlord;

  if (blockedBy !== null) {
    return ApprovalType.Blocked;
  } else if (!verifiedAt) {
    return ApprovalType.Inactivated;
  }
  return ApprovalType.Approved;
};
