import { FC, ReactElement, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { SearchRequestView } from './search-request-view/search-request-view';
import { SearchRequestEdit } from './search-request-edit/search-request-edit';
import { useLazyQuery } from '@apollo/client';
import { GET_SEARCH_REQUEST_BY_UUID } from '../../../../graphql/queries/search-requests';
import {
  GetSearchRequestByUUID,
  GetSearchRequestByUUIDVariables
} from '../../../../graphql/types/GetSearchRequestByUUID';
import { SearchRequestsFormDataState, setSearchRequestsFormData } from './search-request.state';
import { getFormData } from './search-request.utils';
import { useDispatch, useSelector } from 'react-redux';
import { createShareEntityLink } from '../../../../utils/actions';
import { useTranslation } from 'react-i18next';
import { EntityViewActions } from '../../../building-blocks/entity-view-actions/entity-view-actions';
import { GlobalState } from '../../../../redux/store';
import { ConfirmationDialog } from '../../../building-blocks/confirmation-dialog/confirmation-dialog';
import { useDeleteSearchRequest, useMarkAsSeenAndAutoAssign } from './search-request.hooks';

export interface SearchRequestViewProps {
  formState: SearchRequestsFormDataState;
  entityViewActions?: ReactElement;
}

export const SearchRequest: FC = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDeleteModal, setDeleteModal] = useState(false);
  const navigate = useNavigate();
  const formState = useSelector<GlobalState, SearchRequestsFormDataState>((state) => state.searchRequestsFormData);
  const isLoaded = uuid ? formState.uuid === uuid : true;

  const [fetchSearchRequest, { loading, data: queryResultData, error: queryError }] = useLazyQuery<
    GetSearchRequestByUUID,
    GetSearchRequestByUUIDVariables
  >(GET_SEARCH_REQUEST_BY_UUID, { variables: { uuid }, fetchPolicy: 'no-cache' });

  const deleteSearchRequest = useDeleteSearchRequest();
  useMarkAsSeenAndAutoAssign(isLoaded);

  useEffect(() => {
    if (uuid && uuid !== 'new' && !loading && !queryResultData && !queryError) {
      fetchSearchRequest();
    }
  }, [uuid, loading, queryResultData]);

  useEffect(() => {
    if (queryResultData && queryResultData.searchRequestByUUID) {
      dispatch(setSearchRequestsFormData(getFormData(queryResultData.searchRequestByUUID)));
    }
  }, [queryResultData]);

  const entityViewActions = useMemo(
    () => (
      <EntityViewActions
        onDelete={() => setDeleteModal(true)}
        getShareByMailContent={() => ({
          mailBody: t('sharingMail.searchRequest.sharingMailBody', {
            link: createShareEntityLink()
          }),
          mailSubject: t('sharingMail.searchRequest.sharingMailSubject', {
            name: `${formState.firstName} ${formState.lastName}`
          })
        })}
      />
    ),
    [formState, setDeleteModal]
  );

  return (
    <>
      <Routes>
        <Route path="/" element={<SearchRequestEdit formState={formState} entityViewActions={entityViewActions} />} />
        <Route
          path="/edit"
          element={<SearchRequestEdit formState={formState} entityViewActions={entityViewActions} />}
        />
        <Route
          path="/view"
          element={<SearchRequestView formState={formState} entityViewActions={entityViewActions} />}
        />
      </Routes>
      <ConfirmationDialog
        open={openDeleteModal}
        title={`${t('views.searchRequests.modalDelete.title')}`}
        headline={`${t('views.searchRequests.modalDelete.headline')}`}
        text={t('views.searchRequests.modalDelete.text')}
        primaryActionLabel={`${t('actions.deleteSearchRequest')}`}
        onPrimaryActionClick={() => {
          deleteSearchRequest().then(() => {
            navigate('/search-requests');
          });
        }}
        onCloseClick={() => {
          setDeleteModal(false);
        }}
      />
    </>
  );
};

export default SearchRequest;
