import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  Checkbox,
  Form,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'carbon-components-react';
import styles from './edit-contact-details-dialog.module.scss';
import { useTranslation } from 'react-i18next';
import { FormDataDiscardDialog } from '../../building-blocks/form-blocking-dialog/form-blocking-dialog';
import SaveBar from '../../building-blocks/save-bar/save-bar';
import { NeedsTranslationProps } from '../../../utils/types';
import classNames from 'classnames';
import { ContactType } from '../../../graphql/types/globalTypes';
import { useDispatch } from 'react-redux';
import {
  setLandlordProfileData,
  setShowCompany,
  setShowEmail,
  setShowFirstName,
  setShowLastName,
  setShowPhone
} from '../account/account-view.state';
import { VISIBILITY_ATTRIBUTE_KEYS } from '../landlord-contact-data/landlord-contact-data';
import { useLandlordUserData } from '../landlord-contact-data/landlord-contact-data.hooks';

export interface EditContactDetailsDialogProps {
  landlordId: string | undefined;
  onCloseModal: () => void;
}

const ContactDataInfoText: FC<NeedsTranslationProps> = ({ t }) => {
  return (
    <span id="editContactDataInfo" className={classNames(styles.InfoText, styles.InfoTextSmall)}>
      {t('views.account.contactDataVisibilityInfoText')}
    </span>
  );
};

// invisible table header for improved accessibility
const ContactDataTableHeader: FC<NeedsTranslationProps> = ({ t }) => (
  <TableHead className={styles.ContactDataTableHeader}>
    <TableRow>
      <TableHeader>{t('main.visibility')}</TableHeader>
      <TableHeader>{t('main.attribute')}</TableHeader>
      <TableHeader>{t('main.value')}</TableHeader>
    </TableRow>
  </TableHead>
);

export const EditContactDetailsDialog: FC<EditContactDetailsDialogProps> = ({ onCloseModal, landlordId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const {
    updateLandlord,
    landlordProfileData,
    getLandLordQuery: { data: userData }
  } = useLandlordUserData(landlordId);

  const visibilityChangeFunctions = useMemo(() => {
    return {
      firstName: (value: boolean) => dispatch(setShowFirstName(value)),
      lastName: (value: boolean) => dispatch(setShowLastName(value)),
      email: (value: boolean) => dispatch(setShowEmail(value)),
      phone: (value: boolean) => dispatch(setShowPhone(value)),
      companyName: (value: boolean) => dispatch(setShowCompany(value))
    };
  }, []);

  const visibilityRows = useMemo(() => {
    /* eslint-disable @typescript-eslint/ban-ts-comment  */
    return VISIBILITY_ATTRIBUTE_KEYS.map((key) => {
      const isInstitutionRow =
        key === 'companyName' && userData?.landlord?.contactType === ContactType.PUBLIC_INSTITUTION;
      return {
        key,
        label: isInstitutionRow ? t('views.common.tableHeaders.institution') : t(`views.common.tableHeaders.${key}`),
        // @ts-ignore
        value: userData?.landlord[key],
        // @ts-ignore
        visibility: landlordProfileData[`show${key.charAt(0).toUpperCase() + key.slice(1)}`],
        // @ts-ignore
        onChange: visibilityChangeFunctions[key]
      };
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment  */
  }, [userData?.landlord, landlordProfileData, t]);

  const resetProfileData = useCallback(() => {
    userData?.landlord &&
      dispatch(
        setLandlordProfileData({
          showFirstName: userData?.landlord.showFirstName,
          showLastName: userData?.landlord.showLastName,
          showEmail: userData?.landlord.showEmail,
          showPhone: userData?.landlord.showPhone,
          showCompanyName: userData?.landlord.showCompany
        })
      );
  }, [userData?.landlord]);

  useEffect(() => {
    if (userData?.landlord) {
      resetProfileData();
    }
  }, [resetProfileData]);

  const isFormDirty = useCallback(() => {
    return (
      landlordProfileData.showFirstName !== userData?.landlord?.showFirstName ||
      landlordProfileData.showLastName !== userData?.landlord?.showLastName ||
      landlordProfileData.showEmail !== userData?.landlord?.showEmail ||
      landlordProfileData.showPhone !== userData?.landlord?.showPhone ||
      landlordProfileData.showCompanyName !== userData?.landlord?.showCompany
    );
  }, [landlordProfileData, userData?.landlord]);

  useEffect(() => {
    if (!landlordProfileData.showPhone && !landlordProfileData.showEmail) {
      dispatch(setShowEmail(true));
    }
  }, [landlordProfileData]);

  return createPortal(
    <>
      <Modal
        className={styles.EditContactDetailsDialog}
        passiveModal={true}
        open={true}
        onRequestClose={() => {
          !isFormDirty() ? onCloseModal() : setIsConfirmationDialogOpen(true);
        }}
        modalLabel={t('views.account.modalEdit.title')}
        selectorPrimaryFocus={'.bx--modal-container'}
        iconDescription={t('actions.close')}
        preventCloseOnClickOutside={true}
      >
        <div className="bx--modal-custom-content">
          <div className={styles.EditContactDetailsContent}>
            <ContactDataInfoText t={t} />

            <Form
              className={styles.EditContactDetailsForm}
              id="edit-contact-data-visibility-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <Table aria-describedby="editContactDataInfo" className={styles.ContactDataTable}>
                <ContactDataTableHeader t={t} />
                <TableBody>
                  {visibilityRows.map((rowData) => {
                    if (!(rowData.key === 'companyName' && userData?.landlord?.contactType === ContactType.PRIVATE)) {
                      return (
                        <TableRow key={rowData.key} className={rowData.visibility ? styles.ContactDataRowChecked : ''}>
                          <TableCell>
                            <Checkbox
                              id={rowData.key}
                              checked={rowData.visibility}
                              labelText=""
                              onChange={(checked) => rowData.onChange(checked)}
                            />
                          </TableCell>
                          <TableCell>{rowData.label}</TableCell>
                          <TableCell>{rowData.value}</TableCell>
                        </TableRow>
                      );
                    }
                    // landlords that do not have a company or institution, don't need the showCompany visibility
                    return undefined;
                  })}
                </TableBody>
              </Table>
            </Form>

            <SaveBar trigger={{}} className={styles.SaveBar}>
              <Button
                kind={'secondary'}
                onClick={() => {
                  !isFormDirty() ? onCloseModal() : setIsConfirmationDialogOpen(true);
                }}
              >
                {t('actions.cancel')}
              </Button>
              <Button
                onClick={() => {
                  if (userData?.landlord) {
                    const updatedLandlord = {
                      id: landlordId || '',
                      lastName: userData?.landlord?.lastName,
                      showFirstName: landlordProfileData.showFirstName,
                      showLastName: landlordProfileData.showLastName,
                      showEmail: landlordProfileData.showEmail,
                      showPhone: landlordProfileData.showPhone
                    };

                    const updatedLandlordInfo =
                      userData?.landlord?.contactType !== ContactType.PRIVATE
                        ? {
                            ...updatedLandlord,
                            showCompany: landlordProfileData.showCompanyName
                          }
                        : updatedLandlord;

                    // save changes
                    updateLandlord({
                      variables: {
                        landlord: updatedLandlordInfo
                      }
                    }).then(() => {
                      onCloseModal();
                    });
                  }
                }}
                type="submit"
                form="editContactDetails"
              >
                {t('actions.apply')}
              </Button>
            </SaveBar>
          </div>
        </div>
      </Modal>
      <FormDataDiscardDialog
        open={isConfirmationDialogOpen}
        onPrimaryActionClick={() => {
          // discard
          resetProfileData();
          setIsConfirmationDialogOpen(false);
          onCloseModal();
        }}
        onSecondaryActionClick={() => {
          setIsConfirmationDialogOpen(false);
        }}
      />
    </>,
    document.body
  );
};

export default EditContactDetailsDialog;
