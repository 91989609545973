import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { Button, Modal } from 'carbon-components-react';
import styles from './account-confirmation-dialog.module.scss';
import classNames from 'classnames';
import { useUrlSearchParams } from 'use-url-search-params';
import { useMutation } from '@apollo/client';
import { PostConfirmAccount, PostConfirmAccountVariables } from '../../../graphql/types/PostConfirmAccount';
import { POST_CONFIRM_ACCOUNT } from '../../../graphql/queries/user-auth';
import { useQueryResultErrorMessage } from '../../../utils/hooks/query-result-notification';
import { Param } from '../../../utils/constants';

export const AccountConfirmationDialog: FC = () => {
  const { t } = useTranslation();
  const [params, setParams] = useUrlSearchParams({}, {});
  const [confirmed, setConfirmed] = useState<boolean | null>(null);
  const [token, setToken] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const [confirmAccount, { error: confirmAccountError }] = useMutation<PostConfirmAccount, PostConfirmAccountVariables>(
    POST_CONFIRM_ACCOUNT,
    { variables: { token } }
  );

  const error = useQueryResultErrorMessage(confirmAccountError);

  useEffect(() => {
    if (params[Param.CONFIRM] && params[Param.CONFIRM].toString().length > 0) {
      setToken(params[Param.CONFIRM].toString());
    }
  }, [params[Param.CONFIRM]]);

  useEffect(() => {
    if (token.length > 0) {
      confirmAccount({ variables: { token } }).then((result) => {
        setOpen(true);
        if (result.data) {
          setConfirmed(result.data.confirmAccount);
        }
      });
    }
  }, [token]);

  // Close dialog and remove confirm param from url
  const onClose = (): void => {
    setOpen(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setParams({ [Param.CONFIRM]: undefined });
  };

  return !open ? (
    <></>
  ) : (
    createPortal(
      <Modal
        className={styles.AccountConfirmationDialog}
        passiveModal={true}
        size={'sm'}
        open={true}
        onRequestClose={onClose}
        modalLabel={t('header.modalAccountConfirmation.modalHeadline')}
        selectorPrimaryFocus={'.bx--modal-content'}
        iconDescription={t('actions.close')}
        preventCloseOnClickOutside={true}
      >
        <div className={classNames(styles.content, styles.SuccessView)}>
          {confirmed && <p>{t('header.modalAccountConfirmation.successContent')}</p>}
          {confirmed === false && !error && <p>{t('errors.default')}</p>}
          {error && <p>{error}</p>}
        </div>
        <div className={classNames('bx--modal-custom-footer', styles.alignLeft)}>
          <Button kind={'secondary'} onClick={onClose}>
            {t('actions.close')}
          </Button>
        </div>
      </Modal>,
      document.body
    )
  );
};

export default AccountConfirmationDialog;
