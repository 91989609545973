import { ChangeEvent, createElement, FC, useState } from 'react';
import styles from './text-area-count.module.scss';
import classNames from 'classnames';
import { TextArea, TextAreaProps } from 'carbon-components-react';

type TextAreaCountProps = TextAreaProps & { wrapperClassName?: string };

/**
 * Adapted TextArea from Carbon React with additional counter
 * @param props
 * @constructor
 */
export const TextAreaCount: FC<TextAreaCountProps> = (props: TextAreaCountProps) => {
  const [count, setCount] = useState<number>(props.value ? String(props.value).length : 0);

  const change = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    // Get and set the text length
    const target = event.currentTarget as HTMLTextAreaElement;
    setCount(target.value.length);
    // Forward property change event
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const { wrapperClassName, ...originalProps } = props;

  const element = createElement(TextArea, {
    ...originalProps,
    onChange: change
  });

  return (
    <div
      className={classNames(wrapperClassName, styles.TextAreaCounter, {
        [styles.TextAreaCounter__readonly]: props.readOnly
      })}
    >
      <span
        className={classNames(styles.counter, {
          [styles.disabled]: props.disabled
        })}
      >
        {count}
        {props.maxLength && ' / ' + props.maxLength}
      </span>
      {element}
    </div>
  );
};
