import { FC, useCallback, useEffect, useState } from 'react';
import { FilterContainer } from '../../../controls/filter-container/filter-container';
import { Button, DataTableSkeleton, Dropdown, InlineLoading, MultiSelect } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';
import { ActionBar, ActionBarLeft, ActionBarRight } from '../../../building-blocks/action-bar/action-bar';
import { AddBoxSVGIcon, LaunchSVGIcon } from '@react-md/material-icons';
import styles from './search-requests-list.module.scss';
import { useNavigate } from 'react-router-dom';
import { useApolloClient, useQuery } from '@apollo/client';
import { useUrlSearchParams } from 'use-url-search-params';
import { SearchRequestSortOrder } from '../../../../graphql/types/globalTypes';
import { DefaultMultiSelectItem } from '../../../../utils/types';
import { GetSearchRequests, GetSearchRequestsVariables } from '../../../../graphql/types/GetSearchRequests';
import { GET_NUMBER_OF_SEARCH_REQUESTS, GET_SEARCH_REQUESTS } from '../../../../graphql/queries/search-requests';
import {
  GetNumberOfSearchRequests,
  GetNumberOfSearchRequestsVariables
} from '../../../../graphql/types/GetNumberOfSearchRequests';
import { SearchField } from '../../../controls/search-field/search-field';
import SearchRequestsListTable from './search-requests-list.table';
import { exportCsvData } from './search-requests-list.export';
import {
  affiliationTypeItems,
  OpenStateItem,
  openStateItems,
  roleItems,
  SortingItem,
  sortingItems
} from '../search-requests-types';
import { FILTER_SEPARATOR, Param } from '../../../../utils/constants';
import { anyItem } from '../../listings/listings-list/listings-list.filter-types';
import { getItemsByParam, getMultiSelectLabel } from '../../../../utils/general';
import classNames from 'classnames';
import { usePaginationParam, useResetPagination } from '../../../../utils/hooks/pagination';
import Pagination from '../../../controls/pagination/pagination';
import { useUserPermission } from '../../../../utils/hooks/user-data';

const MULTISELECT_FILTER_COUNT = 4;

export const SearchRequestsList: FC = () => {
  const navigate = useNavigate();
  // Check if the user is allowed to load this view and return to start view otherwise
  const permissions = useUserPermission();

  if (!permissions.hasAdministrativeFunction) {
    navigate('/listings');
  }
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const [params, setParams] = useUrlSearchParams({}, {});

  const [sorting, setSorting] = useState<SortingItem>(sortingItems[0]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [affiliationTypeFilter, setAffiliationTypeFilter] = useState<DefaultMultiSelectItem[]>([]);
  const [roleFilter, setRoleFilter] = useState<DefaultMultiSelectItem[]>([]);
  const [openStateFilter, setOpenStateFilter] = useState<OpenStateItem>(anyItem);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [forceFilterReRender, setForceFilterReRender] = useState(-1);
  const [disableExport, setDisableExport] = useState(false);

  const { pageOffset, handlePageOffsetChange, resultLimit, handleResultLimitChange } = usePaginationParam(
    Number(process.env.REACT_APP_PAGINATION_SEARCH_REQUESTS_MAX)
  );

  const variables: GetSearchRequestsVariables = {
    pageOffset,
    orderBy: sorting ? sorting.id : SearchRequestSortOrder.MOST_RECENT,
    resultLimit,
    filter: {
      searchPhrase,
      affiliationTypes: affiliationTypeFilter.length > 0 ? affiliationTypeFilter.map((item) => item.id) : null,
      roles: roleFilter.length > 0 ? roleFilter.map((item) => item.id) : null,
      isActive: openStateFilter.id
    }
  };
  // Get all search requests by filter and sorting
  const { loading, data } = useQuery<GetSearchRequests, GetSearchRequestsVariables>(GET_SEARCH_REQUESTS, {
    variables,
    fetchPolicy: 'no-cache'
  });
  // Get the total number of landlords with filter
  const { data: dataNumberOfSearchRequests } = useQuery<GetNumberOfSearchRequests, GetNumberOfSearchRequestsVariables>(
    GET_NUMBER_OF_SEARCH_REQUESTS,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  useResetPagination(
    // reset pagination when the number of results is smaller than result limit times page offset
    (dataNumberOfSearchRequests?.numberOfSearchRequests &&
      variables.resultLimit &&
      dataNumberOfSearchRequests.numberOfSearchRequests > variables.resultLimit * pageOffset) ||
      dataNumberOfSearchRequests === undefined
  );

  const resetFilter = useCallback((): void => {
    setAffiliationTypeFilter([]);
    setRoleFilter([]);
    setOpenStateFilter(anyItem);
    setSorting(sortingItems[0]);
    setSearchPhrase('');
    setSearchValue('');
    setForceFilterReRender(-1);
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    setParams({
      // @ts-ignore
      [Param.SEARCH]: undefined, // @ts-ignore
      [Param.AFFILIATION]: undefined, // @ts-ignore
      [Param.ROLE]: undefined, // @ts-ignore
      [Param.OPEN]: undefined, // @ts-ignore
      [Param.PAGE]: undefined // @ts-ignore
    });
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, [
    setSorting,
    setSearchPhrase,
    setSearchValue,
    setForceFilterReRender,
    setAffiliationTypeFilter,
    setRoleFilter,
    setOpenStateFilter
  ]);

  // Export search request data to csv file
  const exportData = (): void => {
    setDisableExport(true);
    exportCsvData(t, apolloClient, variables).then(() => setDisableExport(false));
  };

  // Load filter from url initially
  useEffect(() => {
    if (!params[Param.OPEN]) {
      setParams({ [Param.OPEN]: 'true' });
      setOpenStateFilter(openStateItems.find((item) => item.id === true) || anyItem);
    }

    if (params[Param.SEARCH]) {
      setSearchPhrase(params[Param.SEARCH].toString());
      setSearchValue(params[Param.SEARCH].toString());
    }
    if (params[Param.AFFILIATION]) {
      getItemsByParam(String(params[Param.AFFILIATION]), affiliationTypeItems, setAffiliationTypeFilter);
    }
    if (params[Param.ROLE]) {
      getItemsByParam(String(params[Param.ROLE]), roleItems, setRoleFilter);
    }
    if (params[Param.OPEN]) {
      const value = String(params[Param.OPEN]) === 'true';
      setOpenStateFilter(openStateItems.find((item) => item.id === value) || anyItem);
    }

    // Render the MultiSelects
    setTimeout(() => {
      setForceFilterReRender(1);
    });
  }, []);

  return (
    <div>
      <FilterContainer
        counter={dataNumberOfSearchRequests?.numberOfSearchRequests || 0}
        headline={t('views.searchRequests.searchRequests')}
        className={styles.SearchRequestsListFilterContainer}
        onResetClick={() => resetFilter()}
      >
        <SearchField
          labelText={`${t('views.searchRequests.searchRequests')}`}
          size={'lg'}
          onSearchClick={() => {
            setSearchPhrase(searchValue);
            setParams({ [Param.SEARCH]: searchValue });
          }}
          onChange={(event) => {
            setSearchValue(event.target.value);
            // Remove url parameter and set search empty when clear button is pressed
            if (event.target.value.trim().length === 0) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.SEARCH]: undefined });
            }
            setSearchPhrase('');
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setSearchPhrase(searchValue);
              setParams({ [Param.SEARCH]: searchValue });
            }
          }}
          value={searchValue}
          placeholder={`${t('views.searchRequests.searchPlaceholder')}`}
        />
        {/* 
          Multiselect does not offer a way to control the selected items. Therefore we 
          need to forcefully rerender the filters after a reset button click
        */}
        {forceFilterReRender > -1 ? (
          <>
            <MultiSelect
              id="inline-affiliation-type"
              titleText={`${t('views.searchRequests.affiliationType')}`}
              label={getMultiSelectLabel(t, affiliationTypeFilter, affiliationTypeItems, true)}
              items={affiliationTypeItems}
              itemToString={(item) => (item ? t(item.text) : '')}
              onChange={({ selectedItems }: { selectedItems: DefaultMultiSelectItem[] }) => {
                setAffiliationTypeFilter(selectedItems);
                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.AFFILIATION]:
                    selectedItems.length > 0 ? selectedItems.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
                });
              }}
              initialSelectedItems={affiliationTypeFilter}
            />
            <MultiSelect
              id="inline-role"
              titleText={`${t('views.searchRequests.department')}`}
              label={getMultiSelectLabel(t, roleFilter, roleItems, true)}
              items={roleItems}
              itemToString={(item) => (item ? t(item.text) : '')}
              onChange={({ selectedItems }: { selectedItems: DefaultMultiSelectItem[] }) => {
                setRoleFilter(selectedItems);
                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.ROLE]:
                    selectedItems.length > 0 ? selectedItems.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
                });
              }}
              initialSelectedItems={roleFilter}
            />
            <Dropdown
              id="inline-openState"
              titleText={`${t('views.searchRequests.state')}`}
              label={openStateFilter.id === null ? `${t(openStateFilter.text)}` : openStateFilter.text}
              items={openStateItems}
              itemToString={(item) => `${t(item.text)}`}
              onChange={({ selectedItem }: { selectedItem: OpenStateItem }) => {
                setOpenStateFilter(selectedItem);
                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.OPEN]: selectedItem.id === true ? 'true' : selectedItem.id === false ? 'false' : undefined
                });
              }}
              initialSelectedItem={openStateFilter}
            />
          </>
        ) : (
          <>
            {new Array(MULTISELECT_FILTER_COUNT).map(() => (
              <div className={styles.FilterPlaceholder} />
            ))}
          </>
        )}
      </FilterContainer>
      <ActionBar>
        <ActionBarLeft>
          <Button
            kind="ghost"
            size="sm"
            renderIcon={AddBoxSVGIcon}
            className="bx--btn--hasIcon"
            onClick={() => {
              navigate('new');
            }}
          >
            {t('actions.createSearchRequest')}
          </Button>
        </ActionBarLeft>
        <ActionBarRight>
          {disableExport && <InlineLoading description={t('actions.export')} />}
          {!disableExport && (
            <Button
              kind="ghost"
              size="sm"
              renderIcon={LaunchSVGIcon}
              className="bx--btn--hasIcon"
              disabled={data ? data.searchRequests.length === 0 : false}
              onClick={exportData}
            >
              {t('actions.export')}
            </Button>
          )}
        </ActionBarRight>
      </ActionBar>

      <div className={classNames(styles.Content, 'global-content-wrapper-block')}>
        <Dropdown
          id="sort"
          type="inline"
          titleText={t('sorting.sortBy') as string}
          label={sorting ? `${t(sorting.text)}` : `${t(sortingItems[0].text)}`}
          items={sortingItems}
          defaultValue={sortingItems[0].id}
          itemToString={(item) => (item ? t(item.text) : '')}
          onChange={({ selectedItem }) => selectedItem && setSorting(selectedItem)}
        />

        {loading ? (
          <DataTableSkeleton showHeader={false} showToolbar={false} />
        ) : (
          <SearchRequestsListTable data={data} />
        )}

        {!loading && data?.searchRequests && dataNumberOfSearchRequests && variables.resultLimit && (
          <div className={styles.Pagination}>
            <Pagination
              totalItems={Math.ceil(dataNumberOfSearchRequests.numberOfSearchRequests / variables.resultLimit)}
              page={pageOffset}
              hideStepper={dataNumberOfSearchRequests.numberOfSearchRequests <= variables.resultLimit}
              resultLimit={resultLimit}
              onChange={handlePageOffsetChange}
              onResultLimitChange={(newLimit) => handleResultLimitChange(newLimit)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchRequestsList;
