import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import styles from './save-bar.module.scss';

export interface SaveBarProps {
  trigger: unknown;
  className?: string;
}

export const SaveBar: FC<SaveBarProps> = ({ children, trigger, className }) => {
  const saveBarPlaceHolderRef = useRef<HTMLDivElement>(null);
  const [isSaveBarVisible, setIsSaveBarVisible] = useState(false);

  useEffect(() => {
    /* istanbul ignore else */
    if (saveBarPlaceHolderRef && saveBarPlaceHolderRef.current !== null) {
      const saveBarPHObserver = new IntersectionObserver(
        (entries) => {
          if (entries.length > 0) {
            setIsSaveBarVisible(!entries[0].isIntersecting && entries[0].boundingClientRect.top > 0);
          }
        },
        {
          threshold: 0
        }
      );
      saveBarPHObserver.observe(saveBarPlaceHolderRef.current);
      return () => {
        saveBarPlaceHolderRef.current && saveBarPHObserver.unobserve(saveBarPlaceHolderRef.current);
        saveBarPHObserver.disconnect();
      };
    }
  }, [saveBarPlaceHolderRef, setIsSaveBarVisible, trigger]);

  return (
    <div ref={saveBarPlaceHolderRef} className={classNames(className, styles.SaveBarPlaceHolder)}>
      <div
        className={classNames(styles.SaveBar, {
          [styles.SaveBar__fixed]: isSaveBarVisible
        })}
        data-testid="SaveBar"
      >
        <div className="global-content-wrapper">{children}</div>
      </div>
    </div>
  );
};

export default SaveBar;
