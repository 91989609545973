import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BlockMapBuilder,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftHandleValue,
  DraftInlineStyle,
  Editor,
  EditorState,
  Modifier,
  RichUtils
} from 'draft-js';
import createLinkifyPlugin, { insertOrUpdateLink } from './linkify-plugin';
import htmlToDraft from 'html-to-draftjs';
import { MAIL_EDITOR_FONT_SIZES, MailEditorToolbar } from './mail-editor-toolbar/mail-editor-toolbar';
import { stateToHTML } from 'draft-js-export-html';
import { Tag } from 'carbon-components-react';
import styles from './mail-editor.module.scss';

const linkifyPlugin = createLinkifyPlugin();

export enum BlockType {
  ALIGN_LEFT = 'left',
  ALIGN_RIGHT = 'right',
  ALIGN_CENTER = 'center'
}

export interface MailEditorProps {
  onContentChange: (html: string, hasText: boolean) => void;
  htmlContent?: string;
  placeholder?: string;
  onApplyTemplate?: () => void;
  attachments?: string[];
  setMailAttachments?: Dispatch<SetStateAction<string[]>>;
}

export const MailEditor: FC<MailEditorProps> = ({
  onContentChange,
  htmlContent,
  placeholder,
  onApplyTemplate,
  attachments,
  setMailAttachments
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createEmpty(new CompositeDecorator(linkifyPlugin.decorators))
  );
  const editor = useRef(null);
  const [inlineStyles, setInlineStyles] = useState<DraftInlineStyle>();
  const [currentBlockType, setCurrentBlockType] = useState<string>('');
  const [internalHTMLContent, setInternalHTMLContent] = useState('');

  const fontsizeStyleMap = useMemo(() => {
    const fontStyleMap = {};
    MAIL_EDITOR_FONT_SIZES.forEach((size) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fontStyleMap[`FONT_SIZE_${size}`] = {
        fontSize: `${size}px`,
        style: {
          fontSize: `${size}px`
        }
      };
    });
    return fontStyleMap;
  }, []);

  const updateEditorState = useCallback(
    (newEditorState: EditorState) => {
      const currentInlineStyle: DraftInlineStyle = newEditorState.getCurrentInlineStyle();

      setCurrentBlockType(RichUtils.getCurrentBlockType(newEditorState));
      setInlineStyles(currentInlineStyle);
      setEditorState(newEditorState);

      const convertedHtmlContent = stateToHTML(newEditorState.getCurrentContent(), {
        inlineStyles: fontsizeStyleMap,
        blockStyleFn: (block) => {
          const blockType = block.getType();
          let alignment;
          switch (blockType) {
            case BlockType.ALIGN_LEFT:
              alignment = 'left';
              break;
            case BlockType.ALIGN_CENTER:
              alignment = 'center';
              break;
            case BlockType.ALIGN_RIGHT:
              alignment = 'right';
              break;
            default:
              break;
          }
          if (alignment) {
            return {
              style: {
                textAlign: alignment
              }
            };
          }
        }
      });
      setInternalHTMLContent(convertedHtmlContent);
      onContentChange(convertedHtmlContent, newEditorState.getCurrentContent().hasText());
    },
    [setInlineStyles, setEditorState]
  );

  const handlePastedText = useCallback(
    (text: string, html: string | undefined, editorState: EditorState): DraftHandleValue => {
      /* istanbul ignore else */
      if (html) {
        const contentBlock = htmlToDraft(html);
        let contentState = editorState.getCurrentContent();
        contentBlock.entityMap.forEach((value: { [key: string]: unknown }, key: string) => {
          contentState = contentState.mergeEntityData(key, value);
        });
        contentState = Modifier.replaceWithFragment(
          contentState,
          editorState.getSelection(),
          BlockMapBuilder.createFromArray(contentBlock.contentBlocks)
        );
        updateEditorState(EditorState.push(editorState, contentState, 'insert-characters'));
        return 'handled';
      } else if (text.startsWith('http')) {
        updateEditorState(insertOrUpdateLink(editorState, text, text));
        return 'handled';
      }
      return 'not-handled';
    },
    [updateEditorState]
  );

  useEffect(() => {
    if (htmlContent !== undefined) {
      if (htmlContent.length === 0) {
        updateEditorState(EditorState.createEmpty(new CompositeDecorator(linkifyPlugin.decorators)));
      } else if (internalHTMLContent !== htmlContent) {
        const clearedState = EditorState.push(editorState, ContentState.createFromText(''), 'delete-character');
        handlePastedText('', htmlContent, clearedState);
      }
    }
  }, [editorState, htmlContent, updateEditorState]);

  const getBlockStyle = (block: ContentBlock): string => {
    const blockType = block.getType();
    switch (blockType) {
      case BlockType.ALIGN_LEFT:
        return 'align-left';
      case BlockType.ALIGN_CENTER:
        return 'align-center';
      case BlockType.ALIGN_RIGHT:
        return 'align-right';
      default:
        break;
    }
    return '';
  };

  return (
    <div>
      <MailEditorToolbar
        editorState={editorState}
        updateEditorState={updateEditorState}
        inlineStyles={inlineStyles}
        currentBlockType={currentBlockType}
        onApplyTemplate={onApplyTemplate}
      />
      <Editor
        ref={editor}
        placeholder={
          !editorState.getCurrentContent().hasText() && currentBlockType === 'unstyled' ? placeholder : undefined
        }
        editorState={editorState}
        blockStyleFn={getBlockStyle}
        customStyleMap={fontsizeStyleMap}
        onChange={(newEditorState) => {
          updateEditorState(newEditorState);
        }}
        handleKeyCommand={(command): DraftHandleValue => {
          const newState = RichUtils.handleKeyCommand(editorState, command);

          if (newState) {
            updateEditorState(newState);
            return 'handled';
          }

          return 'not-handled';
        }}
        handlePastedText={handlePastedText}
      />
      {attachments && setMailAttachments && attachments.length > 0 && (
        <div className={styles.AttachmentContainer}>
          {attachments.map((attachment) => {
            return (
              <Tag
                key={attachment}
                filter
                onClose={() => {
                  setMailAttachments(attachments?.filter((a) => a !== attachment));
                }}
              >
                {attachment.indexOf('/') !== -1 ? attachment.substring(attachment.lastIndexOf('/') + 1) : attachment}
              </Tag>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MailEditor;
