import { useState, useRef, useEffect, Dispatch, SetStateAction, RefObject } from 'react';

/**
 * WCAG Guidelines for Toolbars: https://www.w3.org/TR/wai-aria-practices/examples/toolbar/toolbar.html
 */

export const useToolbarFocusRoving = (): {
  toolbarRef: RefObject<HTMLDivElement>;
  toolbarButtons: HTMLElement[] | null;
  selectedButtonState: [number, Dispatch<SetStateAction<number>>];
} => {
  const [toolbarButtons, setToolBarButtons] = useState<HTMLElement[] | null>(null);
  const selectedButtonState = useState<number>(-1);
  const [selectedToolBarButton] = selectedButtonState;
  const toolbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toolbarRef.current) {
      const children = toolbarRef.current.querySelectorAll<HTMLElement>('#toolbar button, #toolbar [role="button"]');
      setToolBarButtons(Array.from(children));
    }
  }, [toolbarRef]);

  useEffect(() => {
    if (
      selectedToolBarButton > -1 &&
      toolbarButtons &&
      toolbarButtons.length > 0 &&
      selectedToolBarButton < toolbarButtons.length
    ) {
      toolbarButtons.forEach((button, index) => {
        if (index === selectedToolBarButton) {
          button.tabIndex = 0;
          button.focus();
        } else {
          button.tabIndex = -1;
        }
      });
    }
  }, [toolbarButtons, selectedToolBarButton]);

  return {
    toolbarRef,
    toolbarButtons,
    selectedButtonState
  };
};
