export default `<div class="navi">
  <ul class="level_1">
    <li class="first">
      <span class="pagetitle"><a href="https://www.tum.de/die-tum/">Die TUM</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/die-tum/die-universitaet/">Die Universität</a></li>
        <li><a href="https://www.tum.de/die-tum/aktuelles/">Aktuelles</a></li>
        <li><a href="https://www.tum.de/die-tum/aktuelles/coronavirus/">Coronavirus</a></li>
        <li><a href="https://www.tum.de/die-tum/magazine/">Magazine</a></li>
        <li><a href="https://www.tum.de/die-tum/schools-fakultaeten/">Schools + Fakultäten</a></li>
        <li><a href="https://www.tum.de/die-tum/auszeichnungen/">Auszeich&#173;nungen und Ehrungen</a></li>
        <li><a href="https://www.tum.de/die-tum/kliniken/">Kliniken</a></li>
        <li><a href="http://www.lehren.tum.de/">Lehre und QM</a></li>
        <li><a href="https://www.tum.de/die-tum/arbeiten-an-der-tum/">Arbeiten an der TUM</a></li>
        <li><a href="https://www.tum.de/die-tum/spenden-und-foerdern/">Spenden und Fördern</a></li>
        <li><a href="https://www.tum.de/die-tum/fan-der-tum/">Fan der TUM?</a></li>
        <li><a href="https://www.tum.de/die-tum/kontakt-und-anfahrt/">Kontakt und Anfahrt</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/forschung/">Forschung</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/forschung/schools-fakultaeten/">Schools + Fakultäten</a></li>
        <li><a href="https://www.tum.de/forschung/forschungszentren/">Forschungs&#173;zentren</a></li>
        <li><a href="https://www.exzellenz.tum.de">Exzellenz&#173;strategie</a></li>
        <li><a href="https://www.tum.de/forschung/projekte/">Projekte</a></li>
        <li><a href="https://www.tum.de/forschung/kooperationen/">Kooperationen</a></li>
        <li><a href="https://www.forte.tum.de/">Forschungs&#173;förderung</a></li>
        <li><a href="http://www.gs.tum.de">Promotion</a></li>
        <li><a href="https://www.tum.de/forschung/postdocs/">Postdocs</a></li>
        <li><a href="https://www.tum.de/die-tum/arbeiten-an-der-tum/stellenangebote/">Stellenangebote</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/innovation/">Innovation</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/innovation/entrepreneurship/">Entrepre&#173;neurship</a></li>
        <li><a href="https://www.tum.de/innovation/technologietransfer/">Technologie&#173;transfer</a></li>
        <li><a href="https://www.tum.de/innovation/kooperationen/">Koopera­tionen</a></li>
        <li><a href="https://www.tum.de/innovation/kontakt/">Kontakt</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/studium/">Studium</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/studium/willkommen/">Willkommen</a></li>
        <li><a href="https://www.tum.de/studium/studienangebot/">Studienangebot</a></li>
        <li><a href="https://www.tum.de/studium/internationale-studierende/">Internationale Studierende</a></li>
        <li><a href="https://www.tum.de/studium/hilfe-und-beratung/">Hilfe&#160;&amp;&#160;Beratung</a></li>
        <li><a href="https://www.tum.de/studium/bewerbung/">Bewerbung</a></li>
        <li><a href="https://www.tum.de/studium/studienfinanzierung/">Studien&#173;finanzierung</a></li>
        <li><a href="https://www.tum.de/studium/im-studium/" target="_blank">Im Studium</a></li>
        <li><a href="https://www.tum.de/studium/studienabschluss/">Studienabschluss</a></li>
        <li><a href="https://www.tum.de/studium/campusleben/">Campusleben</a></li>
        <li><a href="https://www.tum.de/studium/hilfe-und-beratung/hilfe-im-studium/studieninformation/">Kontakt</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/lebenslanges-lernen/">Lebenslanges Lernen</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/lebenslanges-lernen/executive-and-professional-education/">Executive and Professional Education</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/alle-mitarbeiter-und-mitarbeiterinnen/">Alle Mitarbeiter*innen der TUM</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/wissenschaftliche-mitarbeiter-und-mitarbeiterinnen/">Wissenschaftliche Mitarbeiter*innen</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/wissenschaftsmanager-und-managerinnen/">Wissenschafts&#173;manager*innen</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/professoren-und-professorinnen/">Professor*innen</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/moocs/">MOOCs</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/publikationen-und-medienbeitraege/">Publikationen und Medienbeiträge</a></li>
        <li><a href="https://www.tum.de/lebenslanges-lernen/kontakt/">Kontakt</a></li>
      </ul>
    </li>
    <li>
      <span class="pagetitle"><a href="https://www.tum.de/global/">Global</a></span>
      <ul class="level_2">
        <li><a href="https://www.tum.de/global/standorte-weltweit/">Standorte weltweit</a></li>
        <li><a href="https://www.tum.de/global/tum-asia/">TUM Asia</a></li>
        <li><a href="https://www.tum.de/studium/internationale-studierende/">International studieren</a></li>
        <li><a href="http://www.international.tum.de/auslandsaufenthalte/">Austausch&#173;programme</a></li>
        <li><a href="http://www.international.tum.de/internationale-allianzen/">Internationale Allianzen</a></li>
        <li><a href="http://www.sprachenzentrum.tum.de/">Sprachen&#173;zentrum</a></li>
        <li><a href="https://www.tum.de/global/kontakt/">Kontakt</a></li>
      </ul>
    </li>
  </ul>
  <div style="clear: both"></div>
</div>
`;
