import { FC, useCallback, useEffect, useState } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'carbon-components-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './breadcrumb-navigation.module.scss';

// eslint-disable-next-line no-unused-vars
export type LabelResolver = (section: string) => string | null;

export interface BreadcrumbNavigationProps {
  basePath?: string;
  resolver?: (LabelResolver | null)[];
}

interface BreadCrumbProps {
  label: string | null;
  path: string;
}

export const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({ basePath, resolver }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [items, setItems] = useState<BreadCrumbProps[]>();

  const defaultLabelResolver: LabelResolver = useCallback(
    (section: string) => {
      return t(`routes.${section}`);
    },
    [t]
  );

  // recalculate items when the pathname changes
  useEffect(() => {
    const subPathSections = pathname
      // get the relative path after base path, if it is set
      .slice(pathname.indexOf(basePath || '') + (basePath?.length || 0))
      // split into section
      .split('/')
      // filter empty sections
      .filter((section) => section.length > 0);

    const reconstructedSubPaths: string[] = [];
    for (let index = 0; index < subPathSections.length; index++) {
      // get the full path including the current section
      reconstructedSubPaths.push((basePath || '') + '/' + subPathSections.slice(0, index + 1).join('/'));
    }

    const items: BreadCrumbProps[] = subPathSections
      // resolve path sections using the resolver functions if given
      .map((section, index) => {
        // return result of given resolver, or use the default one
        if (resolver && resolver.length > 0 && resolver[index] !== null && resolver[index] !== undefined) {
          return {
            // We explicitly check against null in the if condition...
            //
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            label: resolver[index](section),
            path: reconstructedSubPaths[index]
          };
        }
        return {
          label: defaultLabelResolver(section),
          path: reconstructedSubPaths[index]
        };
      });

    setItems(items);
  }, [pathname, basePath, resolver]);

  return (
    <div className="global-content-wrapper">
      <Breadcrumb noTrailingSlash className={styles.BreadcrumbBar}>
        {items &&
          items
            // do not show null labels in breadcrumbs
            .filter((item) => item.label !== null)
            .map((item, index) => (
              <BreadcrumbItem
                key={index}
                href={item.path}
                onClick={(event) => {
                  event.preventDefault();
                  navigate(item.path);
                }}
              >
                {item.label}
              </BreadcrumbItem>
            ))}
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbNavigation;
