import {
  Button,
  Checkbox,
  DatePicker,
  DatePickerInput,
  Dropdown,
  Form,
  FormGroup,
  MultiSelect,
  NumberInput
} from 'carbon-components-react';
import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUrlSearchParams } from 'use-url-search-params';
import { TagCategory, TUMLocation } from '../../../../../graphql/types/globalTypes';
import {
  AnyItem,
  anyItem,
  Availability,
  AvailabilityItem,
  availabilityItems,
  DistrictItem,
  ListingTypeItem,
  ListingVisibility,
  ORDERED_TAG_CATEGORIES,
  RentType,
  rentTypeItems,
  RoomItem
} from '../listings-list.filter-types';
import styles from './listings-list-filter.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../../../redux/store';
import {
  ListingsListFilterState,
  resetListingsFilter,
  setActivationStatus,
  setAvailableFrom,
  setAvailableFromLatest,
  setDeposit,
  setDistricts,
  setImmediatelyAvailable,
  setIsListingPublic,
  setLandlordIsManagement,
  setListingFilterState,
  setListingPublicFilterState,
  setOpenEnded,
  setSeekingDoctoralStudents,
  setSeekingGuestResearchers,
  setSeekingIncomings,
  setSeekingPostDoctoralStudents,
  setSeekingProfessors,
  setSeekingStudents,
  setSeekingTumEmployees,
  setTags,
  setVerificationStatus
} from './listings-list-filter.state';
import classNames from 'classnames';
import { FILTER_SEPARATOR, Param, UNIT_CURRENCY_EURO } from '../../../../../utils/constants';
import { getMultiSelectLabel } from '../../../../../utils/general';
import {
  dateForAvailability,
  getDistrictItemsByParam,
  getStateForParameter,
  getTagsFromUrl
} from '../listings-list.utils';
import { COST_INPUT_DEFAULT_PROPS } from '../../../../../utils/validation/input-validation';
import { useDropdownItemsFromEnumsOrConstants } from '../../listing/listing.hooks';
import { useUserPermission } from '../../../../../utils/hooks/user-data';
import { useCustomBreakpoints, useResponsiveBreakpoints } from '../../../../../utils/hooks/responsive.hooks';
import { CustomMultiSelectItem } from '../../../../../utils/types';
import RangeFilter, { RangeFilterChangeFunction } from '../../../../controls/range-filter/range-filter';
import { customListingsListBreakpointsDefinition } from '../listings-list.responsiveness';
import { useDistrictFilterItems, usePostNewSeekerSearchRequest } from '../listings-list.hooks';
import { ReactComponent as BookmarkAdd } from '../../../../../assets/svg/bookmark_add.svg';
import SaveBar from '../../../../building-blocks/save-bar/save-bar';

export interface ListingsListFilterProps {
  tumLocation: TUMLocation | null;
  isOpen: boolean;
  availabilityFilterState: [AnyItem | AvailabilityItem, Dispatch<SetStateAction<AnyItem | AvailabilityItem>>];
  roomFilterState: [RoomItem | AnyItem, Dispatch<SetStateAction<RoomItem | AnyItem>>];
  listingTypeFilterState: [ListingTypeItem | AnyItem, Dispatch<SetStateAction<ListingTypeItem | AnyItem>>];
  numberOfRoomsItems: CustomMultiSelectItem<number>[];
  listingTypeItems: CustomMultiSelectItem<string>[];
  handleSquareMeterInput: RangeFilterChangeFunction;
  handleRentInput: RangeFilterChangeFunction;
  setOpenSearchRequestSaveConfirmation: (isOpen: boolean) => void;
  setSideBarOpen: (isOpen: boolean) => void;
}

const ListingsListFilter: FC<ListingsListFilterProps> = (props: ListingsListFilterProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [params, setParams] = useUrlSearchParams({}, {});
  const filterState = useSelector<GlobalState, ListingsListFilterState>((state) => state.listingsListFilter);
  const [datePickerFromContainerRef, setDatePickerFromContainerRef] = useState<HTMLDivElement | null>(null);
  const [datePickerUntilContainerRef, setDatePickerUntilContainerRef] = useState<HTMLDivElement | null>(null);
  const permissions = useUserPermission();
  const breakpoints = useResponsiveBreakpoints();
  const customBreakPoints = useCustomBreakpoints(customListingsListBreakpointsDefinition);
  const { hasAdministrativeFunction, isSeeker } = permissions;

  const { tagsForCategories } = useDropdownItemsFromEnumsOrConstants(null);
  const districtItems = useDistrictFilterItems(props.tumLocation);

  const [forceFilterReRender, setForceFilterReRender] = useState(-1);
  const [availabilityFilter, setAvailabilityFilter] = props.availabilityFilterState;
  const [roomFilter, setRoomFilter] = props.roomFilterState;
  const [listingTypeFilter, setListingTypeFilter] = props.listingTypeFilterState;

  const postNewSeekerSearchRequest = usePostNewSeekerSearchRequest(
    filterState,
    props.tumLocation,
    roomFilter.id,
    listingTypeFilter.id
  );

  const getItemLabel = useCallback((item) => {
    return item.id === null ? `${t(item.text)}` : item.text;
  }, []);

  // Load filter from url initially
  useEffect(() => {
    // wait for districts to load from backend
    if (props.tumLocation && districtItems.length === 0) {
      return;
    }

    dispatch(resetListingsFilter());

    if (!props.isOpen) {
      setForceFilterReRender(-1);
    }

    // Set the filter state for management
    if (hasAdministrativeFunction) {
      dispatch(
        setListingFilterState({
          districts: getDistrictItemsByParam(params, districtItems),
          availableFrom: params[Param.AVAILABLE_FROM] ? String(params[Param.AVAILABLE_FROM]) : null,
          availableFromLatest: params[Param.AVAILABLE_LATEST] ? String(params[Param.AVAILABLE_LATEST]) : null,
          immediatelyAvailable:
            new Date(String(params[Param.AVAILABLE_LATEST])).toDateString() === new Date().toDateString(),
          openEnded: params[Param.OPEN_ENDED] ? String(params[Param.OPEN_ENDED]) === 'true' : null,
          deposit: params[Param.DEPOSIT] ? Number(params[Param.DEPOSIT]) : null,
          seekingGuestResearchers: String(params[Param.GUEST_RESEARCHERS]) === '1',
          seekingProfessors: String(params[Param.PROFESSORS]) === '1',
          seekingIncomings: String(params[Param.INCOMINGS]) === '1',
          seekingStudents: String(params[Param.STUDENTS]) === '1',
          seekingPostDoctoralStudents: String(params[Param.POST_DOC_STUDENTS]) === '1',
          seekingDoctoralStudents: String(params[Param.DOC_STUDENTS]) === '1',
          seekingTumEmployees: String(params[Param.TUM_EMPLOYEES]) === '1',
          landlordIsManagement: String(params[Param.LANDLORD_IS_MANAGEMENT]) === '1',
          verificationStatus: getStateForParameter(
            params[Param.CHECKED] ? String(params[Param.CHECKED]) : null,
            params[Param.UNCHECKED] ? String(params[Param.UNCHECKED]) : null,
            filterState.verificationStatus
          ),
          activationStatus: getStateForParameter(
            params[Param.ACTIVATED] ? String(params[Param.ACTIVATED]) : null,
            params[Param.INACTIVATED] ? String(params[Param.INACTIVATED]) : null,
            filterState.activationStatus
          ),
          tags: getTagsFromUrl(params, tagsForCategories),
          isListingPublic: params[Param.PUBLIC] ? String(params[Param.PUBLIC]) === '1' : null,
          rentFromFilter: params[Param.RENT_FROM] ? Number(params[Param.RENT_FROM]) : null,
          rentToFilter: params[Param.RENT_TO] ? Number(params[Param.RENT_TO]) : null,
          squareMeterFromFilter: params[Param.SQUARE_FROM] ? Number(params[Param.SQUARE_FROM]) : null,
          squareMeterToFilter: params[Param.SQUARE_TO] ? Number(params[Param.SQUARE_TO]) : null,
          isNewFilterConfiguration: false
        })
      );
    } else {
      // Set filter state for everyone else
      dispatch(
        setListingPublicFilterState({
          districts: getDistrictItemsByParam(params, districtItems),
          availableFrom: params[Param.AVAILABLE_FROM] ? String(params[Param.AVAILABLE_FROM]) : null,
          availableFromLatest: params[Param.AVAILABLE_LATEST] ? String(params[Param.AVAILABLE_LATEST]) : null,
          immediatelyAvailable:
            new Date(String(params[Param.AVAILABLE_LATEST])).toDateString() === new Date().toDateString(),
          openEnded: params[Param.OPEN_ENDED] ? String(params[Param.OPEN_ENDED]) === 'true' : null,
          deposit: params[Param.DEPOSIT] ? Number(params[Param.DEPOSIT]) : null,
          tags: getTagsFromUrl(params, tagsForCategories),
          rentFromFilter: params[Param.RENT_FROM] ? Number(params[Param.RENT_FROM]) : null,
          rentToFilter: params[Param.RENT_TO] ? Number(params[Param.RENT_TO]) : null,
          squareMeterFromFilter: params[Param.SQUARE_FROM] ? Number(params[Param.SQUARE_FROM]) : null,
          squareMeterToFilter: params[Param.SQUARE_TO] ? Number(params[Param.SQUARE_TO]) : null,
          isNewFilterConfiguration: false
        })
      );
    }

    // Render the components
    setTimeout(() => {
      setForceFilterReRender(1);
    }, 500);
  }, [tagsForCategories, params, districtItems, props.tumLocation]);

  if (forceFilterReRender !== -1) {
    return (
      <Form className={styles.Filter}>
        {/* --- District --- */}
        {props.tumLocation !== null && (
          <FormGroup legendText={`${t('views.listings.filter.place')}`} className={styles.filterLegend}>
            <MultiSelect
              id="district"
              titleText={t('formFields.district') as string}
              label={getMultiSelectLabel(t, filterState.districts, districtItems)}
              items={districtItems}
              itemToString={(item) => (item ? item.text : '')}
              onChange={({ selectedItems }: { selectedItems: DistrictItem[] }) => {
                dispatch(setDistricts(selectedItems));

                setParams({
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  [Param.DISTRICTS]:
                    selectedItems.length > 0 ? selectedItems.map((item) => item.id).join(FILTER_SEPARATOR) : undefined
                });
              }}
              initialSelectedItems={filterState.districts ? filterState.districts : []}
            />
          </FormGroup>
        )}
        {/* --- Move In Dates --- */}
        <FormGroup legendText={`${t('views.listings.filter.moveInDates')}`} className={styles.filterLegend}>
          {!breakpoints.isDesktop && (
            <Dropdown
              titleText={`${t('views.listings.filter.availableBy')}`}
              label={`${t('views.listings.filter.availableBy')}`}
              id={'availableBy'}
              onChange={({ selectedItem }: { selectedItem: AvailabilityItem | AnyItem }) => {
                setAvailabilityFilter(selectedItem);
                if (selectedItem.id === null) {
                  dispatch(setAvailableFrom(null));
                  dispatch(setAvailableFromLatest(null));
                  if (filterState.immediatelyAvailable) {
                    dispatch(setImmediatelyAvailable(false));
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.AVAILABLE_FROM]: undefined, [Param.AVAILABLE_LATEST]: undefined });
                } else {
                  const date = dateForAvailability(selectedItem.id).toISOString();
                  if (selectedItem.id === Availability.NOW) {
                    dispatch(setAvailableFrom(null));
                    dispatch(setAvailableFromLatest(date));
                    dispatch(setImmediatelyAvailable(true));
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setParams({ [Param.AVAILABLE_FROM]: undefined, [Param.AVAILABLE_LATEST]: date });
                  } else {
                    dispatch(setAvailableFromLatest(null));
                    dispatch(setAvailableFrom(date));
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setParams({ [Param.AVAILABLE_FROM]: date, [Param.AVAILABLE_LATEST]: undefined });
                  }
                }
              }}
              items={[anyItem, ...availabilityItems]}
              itemToString={(item) => `${t(item.text)}`}
              initialSelectedItem={availabilityFilter}
              className={styles.availableByFilter}
            />
          )}
          <div className={styles.datePicker}>
            <div>
              {datePickerFromContainerRef && (
                <DatePicker
                  datePickerType="single"
                  appendTo={datePickerFromContainerRef}
                  allowInput={false}
                  dateFormat={'d.m.Y'}
                  onChange={(dates) => {
                    const date = dates.length > 0 && dates[0] ? dates[0].toISOString() : null;
                    dispatch(setAvailableFrom(date));
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setParams({ [Param.AVAILABLE_FROM]: dates[0] ? dates[0].toISOString() : undefined });
                  }}
                  value={filterState.availableFrom || undefined}
                >
                  <DatePickerInput
                    id="availableFrom"
                    placeholder={t('datesAndTimes.datePlaceholder')}
                    labelText={t('views.listings.filter.earliest') as string}
                    disabled={filterState.immediatelyAvailable}
                  />
                </DatePicker>
              )}
              <Checkbox
                id="availableImmediately"
                labelText={t('formFields.immediately') as string}
                checked={filterState.immediatelyAvailable}
                onChange={(isChecked) => {
                  const now = isChecked ? new Date().toISOString() : null;
                  dispatch(setAvailableFrom(null));
                  dispatch(setAvailableFromLatest(now));
                  dispatch(setImmediatelyAvailable(isChecked));
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.AVAILABLE_FROM]: undefined, [Param.AVAILABLE_LATEST]: now || undefined });
                }}
                className={styles.Checkbox}
              />
              <div
                ref={(ref) => {
                  if (ref && ref !== datePickerFromContainerRef) {
                    setDatePickerFromContainerRef(null);
                    setTimeout(() => setDatePickerFromContainerRef(ref));
                  }
                }}
                className={styles.datePickerContainer}
              />
            </div>
            <div>
              {datePickerUntilContainerRef && (
                <DatePicker
                  datePickerType="single"
                  value={filterState.availableFromLatest || undefined}
                  appendTo={datePickerUntilContainerRef}
                  allowInput={false}
                  dateFormat={'d.m.Y'}
                  minDate={filterState.availableFrom ? filterState.availableFrom : undefined}
                  onChange={(dates) => {
                    const date = dates.length > 0 && dates[0] ? dates[0].toISOString() : null;
                    dispatch(setAvailableFromLatest(date));
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    setParams({ [Param.AVAILABLE_LATEST]: dates[0] ? dates[0].toISOString() : undefined });
                  }}
                >
                  <DatePickerInput
                    id="availableLatest"
                    placeholder={t('datesAndTimes.datePlaceholder')}
                    labelText={t('views.listings.filter.latest') as string}
                    disabled={filterState.immediatelyAvailable}
                  />
                </DatePicker>
              )}
              <div
                ref={(ref) => {
                  if (ref && ref !== datePickerUntilContainerRef) {
                    setDatePickerUntilContainerRef(null);
                    setTimeout(() => setDatePickerUntilContainerRef(ref));
                  }
                }}
                className={classNames(styles.datePickerContainer, styles.datePickerContainerRight)}
              />
            </div>
          </div>
        </FormGroup>
        <FormGroup
          legendText={`${t('views.listings.filter.object')}`}
          className={classNames(styles.filterLegend, styles.objectFilterGroup)}
        >
          {breakpoints.isMobile && (
            <>
              <Dropdown
                titleText={`${t('views.listings.filter.type')}`}
                label={`${t(listingTypeFilter.text)}`}
                id={'type'}
                onChange={({ selectedItem }: { selectedItem: ListingTypeItem }) => {
                  setListingTypeFilter(selectedItem);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.TYPE]: selectedItem.id !== null ? selectedItem.id : undefined });
                }}
                items={[anyItem, ...props.listingTypeItems]}
                itemToString={(item) => `${t(item.text)}`}
                initialSelectedItem={listingTypeFilter}
              />
              <Dropdown
                titleText={`${t('views.listings.filter.roomCount')}`}
                label={roomFilter.id === null ? `${t(roomFilter.text)}` : roomFilter.text}
                id={'roomCount'}
                onChange={({ selectedItem }: { selectedItem: RoomItem }) => {
                  setRoomFilter(selectedItem);
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.ROOMS]: selectedItem.id !== null ? selectedItem.id : undefined });
                }}
                items={[anyItem, ...props.numberOfRoomsItems]}
                itemToString={getItemLabel}
                initialSelectedItem={roomFilter}
              />
            </>
          )}

          {/* --- Rent Type --- */}
          <Dropdown
            titleText={`${t('views.listings.filter.rentType')}`}
            label={`${t(rentTypeItems.find((item) => item.id === filterState.openEnded)?.text || anyItem.text)}`}
            id={'rentType'}
            onChange={({ selectedItem }: { selectedItem: RentType }) => {
              dispatch(setOpenEnded(selectedItem.id));
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.OPEN_ENDED]: selectedItem.id !== null ? selectedItem.id : undefined });
            }}
            items={rentTypeItems}
            itemToString={(item) => t(item.text)}
            initialSelectedItem={rentTypeItems.find((item) => item.id === filterState.openEnded) || anyItem}
          />

          {customBreakPoints.isTabletSmall && (
            <>
              <FormGroup legendText={t('views.listings.filter.space') as string} className={styles.filterLegend}>
                <RangeFilter
                  id={'space'}
                  label={`${t('views.listings.filter.space')}`}
                  kind="area"
                  onChange={props.handleSquareMeterInput}
                  min={filterState.squareMeterFromFilter || undefined}
                  max={filterState.squareMeterToFilter || undefined}
                  showLabelAsPlaceholder={!breakpoints.isDesktop}
                  alignContent={breakpoints.isDesktop ? 'left' : 'right'}
                  inline
                />
              </FormGroup>
              <FormGroup legendText={t('views.listings.filter.rent') as string} className={styles.filterLegend}>
                <RangeFilter
                  id={'rent'}
                  label={`${t('views.listings.filter.rent')}`}
                  kind="currency"
                  onChange={props.handleRentInput}
                  min={filterState.rentFromFilter || undefined}
                  max={filterState.rentToFilter || undefined}
                  showLabelAsPlaceholder={!breakpoints.isDesktop}
                  alignContent={breakpoints.isDesktop ? 'left' : 'right'}
                  inline
                />
              </FormGroup>
            </>
          )}
        </FormGroup>
        {/* --- Seeking Target Group --- */}
        {hasAdministrativeFunction && (
          <FormGroup
            key="targetGroups"
            name="targetGroups"
            legendText={t('views.listings.filter.targetGroup') as string}
            className={styles.filterLegend}
          >
            <Checkbox
              id="seekingGuestResearchers"
              checked={!!filterState.seekingGuestResearchers}
              labelText={t('enums.targetGroup.GUEST_RESEARCHERS') as string}
              onChange={(checked) => {
                dispatch(setSeekingGuestResearchers(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.GUEST_RESEARCHERS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingProfessors"
              checked={!!filterState.seekingProfessors}
              labelText={t('enums.targetGroup.PROFESSORS') as string}
              onChange={(checked) => {
                dispatch(setSeekingProfessors(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.PROFESSORS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingIncomings"
              checked={!!filterState.seekingIncomings}
              labelText={t('enums.targetGroup.INCOMINGS') as string}
              onChange={(checked) => {
                dispatch(setSeekingIncomings(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.INCOMINGS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingStudents"
              checked={!!filterState.seekingStudents}
              labelText={t('enums.targetGroup.STUDENTS') as string}
              onChange={(checked) => {
                dispatch(setSeekingStudents(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.STUDENTS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingPostDoctoralStudents"
              checked={!!filterState.seekingPostDoctoralStudents}
              labelText={t('enums.targetGroup.POST_DOCTORAL_STUDENTS') as string}
              onChange={(checked) => {
                dispatch(setSeekingPostDoctoralStudents(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.POST_DOC_STUDENTS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingDoctoralStudents"
              checked={!!filterState.seekingDoctoralStudents}
              labelText={t('enums.targetGroup.DOCTORAL_STUDENTS') as string}
              onChange={(checked) => {
                dispatch(setSeekingDoctoralStudents(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.DOC_STUDENTS]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id="seekingTumEmployees"
              checked={!!filterState.seekingTumEmployees}
              labelText={t('enums.targetGroup.TUM_EMPLOYEES') as string}
              onChange={(checked) => {
                dispatch(setSeekingTumEmployees(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.TUM_EMPLOYEES]: checked ? 1 : undefined });
              }}
            />
          </FormGroup>
        )}
        {/* --- Deposit --- */}
        <FormGroup legendText={t('views.listings.filter.costs') as string} className={styles.filterLegend}>
          <NumberInput
            id="deposit"
            value={filterState.deposit || ''}
            label={`${t('views.listings.filter.deposit')} (${UNIT_CURRENCY_EURO})`}
            onChange={(
              event: ChangeEvent<HTMLInputElement> & {
                imaginaryTarget: HTMLInputElement;
              }
            ) => {
              const value = event.imaginaryTarget.value ? Number(event.imaginaryTarget.value) : null;
              dispatch(setDeposit(value));
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setParams({ [Param.DEPOSIT]: value > 0 ? value : undefined });
            }}
            {...COST_INPUT_DEFAULT_PROPS}
          />
        </FormGroup>
        {/* --- Landlord --- */}
        {hasAdministrativeFunction && (
          <FormGroup legendText={`${t('views.listings.filter.landlord')}`} className={styles.filterLegend}>
            <Checkbox
              id="landlord"
              checked={filterState.landlordIsManagement}
              labelText={t('views.listings.filter.landlordIsManagement') as string}
              onChange={(checked) => {
                dispatch(setLandlordIsManagement(checked));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.LANDLORD_IS_MANAGEMENT]: checked ? 1 : undefined });
              }}
            />
          </FormGroup>
        )}
        {/* --- Listings Settings --- */}
        {hasAdministrativeFunction && (
          <FormGroup
            legendText={`${t('views.listings.filter.listingSettings')}`}
            className={classNames([styles.filterLegend, styles.filterCheckboxGroup])}
            style={{ marginBottom: '-1rem' }}
          >
            <FormGroup legendText={`${t('views.listings.filter.checkedListings')}`} className={styles.checkboxLabel}>
              <Checkbox
                id="listings-checked"
                checked={filterState.verificationStatus ? filterState.verificationStatus.indexOf(true) !== -1 : false}
                labelText={t('views.listings.filter.checked') as string}
                onChange={(checked) => {
                  const state = filterState.verificationStatus;
                  if (checked) {
                    dispatch(setVerificationStatus(state ? [...state, true] : [true]));
                  } else {
                    dispatch(setVerificationStatus(state ? state.filter((item) => item !== true) : []));
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.CHECKED]: checked ? 1 : undefined });
                }}
              />
              <Checkbox
                id="listings-unchecked"
                checked={filterState.verificationStatus ? filterState.verificationStatus.indexOf(false) !== -1 : false}
                labelText={t('views.listings.filter.unchecked') as string}
                onChange={(checked) => {
                  const state = filterState.verificationStatus;
                  if (checked) {
                    dispatch(setVerificationStatus(state ? [...state, false] : [false]));
                  } else {
                    dispatch(setVerificationStatus(state ? state.filter((item) => item !== false) : []));
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.UNCHECKED]: checked ? 1 : undefined });
                }}
              />
            </FormGroup>
            <FormGroup legendText={`${t('views.listings.filter.activeListings')}`} className={styles.checkboxLabel}>
              <Checkbox
                id="listings-activated"
                checked={filterState.activationStatus ? filterState.activationStatus.indexOf(true) !== -1 : false}
                labelText={t('views.listings.filter.activated') as string}
                onChange={(checked) => {
                  const state = filterState.activationStatus;
                  if (checked) {
                    dispatch(setActivationStatus(state ? [...state, true] : [true]));
                  } else {
                    dispatch(setActivationStatus(state ? state.filter((item) => item !== true) : []));
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.ACTIVATED]: checked ? 1 : undefined });
                }}
              />
              <Checkbox
                id="listings-inactivated"
                checked={filterState.activationStatus ? filterState.activationStatus.indexOf(false) !== -1 : false}
                labelText={t('views.listings.filter.inactivated') as string}
                onChange={(checked) => {
                  const state = filterState.activationStatus;
                  if (checked) {
                    dispatch(setActivationStatus(state ? [...state, false] : [false]));
                  } else {
                    dispatch(setActivationStatus(state ? state.filter((item) => item !== false) : []));
                  }
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  setParams({ [Param.INACTIVATED]: checked ? 1 : undefined });
                }}
              />
            </FormGroup>
          </FormGroup>
        )}
        {/* --- Tags --- */}
        <FormGroup
          legendText={`${t('views.listings.filter.tags')}`}
          className={classNames([styles.filterLegend, styles.filterCheckboxGroup])}
        >
          <div className={styles.TagsSection}>
            {ORDERED_TAG_CATEGORIES.map((category) => (
              <FormGroup
                key={category}
                name={category}
                legendText={t(`enums.tagCategory.${category}`) as string}
                className={classNames(styles.checkboxLabel, {
                  [styles.MiscTags]: category === TagCategory.OTHER || undefined
                })}
              >
                {tagsForCategories.get(category)?.map((tag) => (
                  <Checkbox
                    id={tag.id}
                    key={tag.id}
                    name={tag.id}
                    value={tag.id}
                    labelText={tag.text}
                    checked={(filterState.tags && filterState.tags.indexOf(tag.id) > -1) || false}
                    onChange={(checked) => {
                      checked
                        ? dispatch(setTags([...filterState.tags, tag.id]))
                        : dispatch(setTags(filterState.tags.filter((tagListTag) => tagListTag !== tag.id)));
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      setParams({ [tag.id]: checked ? 1 : undefined });
                    }}
                  />
                ))}
              </FormGroup>
            ))}
          </div>
        </FormGroup>
        {hasAdministrativeFunction && (
          <FormGroup
            key="visibility"
            name="visibility"
            legendText={`${t('views.listings.filter.visibility')}`}
            className={classNames([styles.filterLegend])}
          >
            <Checkbox
              id={ListingVisibility.PUBLIC}
              labelText={t(`views.listings.filter.${ListingVisibility.PUBLIC}`) as string}
              checked={filterState.isListingPublic === true}
              onChange={(checked) => {
                dispatch(setIsListingPublic(checked ? true : null));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.PUBLIC]: checked ? 1 : undefined });
              }}
            />
            <Checkbox
              id={ListingVisibility.NOT_PUBLIC}
              checked={filterState.isListingPublic === false}
              labelText={t(`views.listings.filter.${ListingVisibility.NOT_PUBLIC}`) as string}
              onChange={(checked) => {
                dispatch(setIsListingPublic(checked ? false : null));
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setParams({ [Param.PUBLIC]: checked ? 0 : undefined });
              }}
            />
          </FormGroup>
        )}
        {isSeeker && (
          <SaveBar trigger={{}} className={styles.FilterSaveBar}>
            <Button
              className={styles.SaveButton}
              kind={'primary'}
              onClick={() => {
                postNewSeekerSearchRequest().then(() => {
                  props.setSideBarOpen(false);
                  props.setOpenSearchRequestSaveConfirmation(true);
                });
              }}
            >
              {t('actions.saveSearch')}
              <BookmarkAdd aria-hidden={true} />
            </Button>
          </SaveBar>
        )}
      </Form>
    );
  }
  return <Form />;
};

export default ListingsListFilter;
