import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styles from './listing-card.module.scss';
import { Card } from '../card/card';
import { useTranslation } from 'react-i18next';
import { ArrowForwardSVGIcon, PlaceSVGIcon } from '@react-md/material-icons';
import { Button, SkeletonText, Tag, TagSkeleton, Toggle } from 'carbon-components-react';
import { useNavigate } from 'react-router-dom';
import { ListingType } from '../../../graphql/types/globalTypes';
import ListingOverflowMenu from '../../building-blocks/listing-overflow-menu/listing-overflow-menu';
import { ConfirmationDialog } from '../../building-blocks/confirmation-dialog/confirmation-dialog';
import { useListingMutations } from '../../views/listings/listing/listing-edit/listing-edit.hooks';
import path from 'path-browserify';
import { useUserPermission } from '../../../utils/hooks/user-data';
import { ListingInfoPanel, ListingInfoPanelSkeleton } from '../listing-info-panel/listing-info-panel';
import placeholderImage from '../../../assets/images/listing-placeholder.jpg';
import { useCurrentWidth } from '../../../utils/hooks/responsive.hooks';
import { listingAddressString, listingLocationString, listingTypeString } from '../../../utils/listings';
import { StyleableProps } from '../../../utils/types';
import classNames from 'classnames';
import ImageGallery from '../image-gallery/image-gallery';
import { createPortal } from 'react-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import { FavoriteButton } from '../favorite-button/favorite-button';
import { useAddAndDeleteFavorites } from '../../views/seeker/seeker-view.hooks';
import { shareByMail } from '../../../utils/actions';
import { getThumbnailImagePath } from '../../../utils/listing-images';
import { LAZY_LOADING_CLASSNAME } from '../../../image-lazy-loading';

interface ListingCardImage {
  id: string;
  modifiedAt: string;
  description: string;
  descriptionEn: string;
}

export interface ListingProps {
  id: string;
  uuid: string;
  totalRent: number;
  type: ListingType;
  isActive: boolean;
  isBlocked: boolean;
  numberOfRooms: number | null;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  district: string | null;
  availableFrom: string;
  squareMeter: number;
  tags: string[] | null;
  previewImage: ListingCardImage | null;
  images?: ListingCardImage[] | null;
}

const ListingCardHeading: FC<{ headingLevel?: 'h2' | 'h3' }> = (props) =>
  props.headingLevel === 'h2' ? <h2>{props.children}</h2> : <h3>{props.children}</h3>;

export interface ListingCardProps extends ListingProps {
  onClick?: () => void;
  editable?: boolean;
  refetch?: () => void;
  headingLevel?: 'h2' | 'h3';
  favorable?: boolean;
  isFavorite?: boolean;
}

export const ListingCard: FC<ListingCardProps> = ({ headingLevel = 'h3', ...props }: ListingCardProps) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const permissions = useUserPermission();
  const { hasAdministrativeFunction, isSeeker } = permissions;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [listingActive, setListingActive] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [showPlaceholderImage, setShowPlaceholderImage] = useState<boolean>(true);
  const { activateListing, deactivateListing, deleteListing } = useListingMutations(true, props.id);
  const { addFavorite, deleteFavorite } = useAddAndDeleteFavorites(props.id);

  const linkRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<HTMLDivElement>(null);
  const currentWidth = useCurrentWidth();
  const [params] = useUrlSearchParams({}, {});

  useEffect(() => {
    const isDesktop = currentWidth > 790;
    if (isDesktop !== showPlaceholderImage && !isSeeker) {
      setShowPlaceholderImage(isDesktop);
    }
  }, [setShowPlaceholderImage, currentWidth]);

  useEffect(() => {
    setListingActive(props.isActive);
  }, [props.isActive]);

  const openLink = (): void => {
    if (hasAdministrativeFunction || isSeeker) {
      navigate(`../listings/${props.uuid}/view`, {
        state: {
          params
        }
      });
    } else {
      navigate(`./${props.uuid}/view`, {
        state: {
          params
        }
      });
    }
  };

  const hasImages = props.images && props.images.length > 0;
  const navigateToListing = useCallback(
    (event: SyntheticEvent) => {
      const element = event.target as HTMLDivElement;
      if (!editRef?.current?.contains(element) && !isMenuOpen && !openDeleteModal) {
        openLink();
      }
    },
    [isMenuOpen, openDeleteModal]
  );

  return (
    <>
      <Card className={styles.Listing} onClick={!hasImages ? navigateToListing : undefined} role="listitem">
        {props.favorable && (
          <FavoriteButton isFavorite={!!props.isFavorite} refetch={props.refetch} listingId={props.id} />
        )}
        {/* Only show preview image in case there are no other images */}
        {props.previewImage && !hasImages && (
          <img
            className={classNames(LAZY_LOADING_CLASSNAME, styles.previewImage)}
            data-src={getThumbnailImagePath(props.previewImage.id, props.previewImage.modifiedAt)}
            alt={i18n.language === 'de' ? props.previewImage.description : props.previewImage.descriptionEn}
          />
        )}
        {/* Show images when available */}
        {hasImages && (
          <ImageGallery
            images={
              props.images
                ? props.images.map((image) => ({
                    path: getThumbnailImagePath(image.id, image.modifiedAt),
                    description: image.description,
                    descriptionEn: image.descriptionEn
                  }))
                : []
            }
            showBullets
            showControlsOnHover
            className={styles.previewImage}
          />
        )}
        {/* Otherwise show a placeholder */}
        {!props.previewImage && !hasImages && showPlaceholderImage && (
          <img
            className={classNames(styles.previewImage, styles.placeholderImage)}
            src={placeholderImage}
            alt={t('listing.altNoImages')}
          />
        )}
        {/** A11y: We don't need a keyboard listener, since we have a link button within the card specifically for keyboard navigation */}
        {/* eslint-disable-next-line spellcheck/spell-checker */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
        <div className={styles.listingWrapper} onClick={hasImages ? navigateToListing : undefined}>
          {props.editable && (
            <div className={styles.editableContent} ref={editRef}>
              <Toggle
                size={'sm'}
                aria-label={t('listing.state')}
                id={'active-' + props.uuid}
                labelA={t('listing.inactive')}
                labelB={t('listing.active')}
                toggled={listingActive}
                onToggle={(checked) => {
                  setListingActive(!listingActive);
                  checked
                    ? activateListing().then(() => props.refetch && props.refetch())
                    : deactivateListing().then(() => props.refetch && props.refetch());
                }}
              />
              <ListingOverflowMenu
                id={props.id}
                uuid={props.uuid}
                onClose={() => setIsMenuOpen(false)}
                onOpen={() => setIsMenuOpen(true)}
                getShareByMailContent={() => ({
                  mailSubject: t('sharingMail.listing.sharingMailSubject', {
                    city: props.city,
                    type: listingTypeString(t, props.numberOfRooms, props.type)
                  }),
                  mailBody: t('sharingMail.listing.sharingMailBody', {
                    link: path.join(process.env.REACT_APP_PUBLIC_URL || '', `listings/${props.uuid}/view`)
                  })
                })}
                isActive={props.isActive}
                onActivate={() => {
                  activateListing().then(() => {
                    props.refetch && props.refetch();
                  });
                }}
                onDeactivate={() => {
                  deactivateListing().then(() => {
                    props.refetch && props.refetch();
                  });
                }}
                onDelete={() => {
                  setDeleteModal(true);
                }}
              />
            </div>
          )}
          {props.favorable && (
            <div className={styles.editableContent} ref={editRef}>
              <ListingOverflowMenu
                id={props.id}
                uuid={props.uuid}
                onClose={() => setIsMenuOpen(false)}
                onOpen={() => setIsMenuOpen(true)}
                isActive={props.isFavorite}
                getShareByMailContent={() => ({
                  mailSubject: t('sharingMail.listing.sharingMailSubject', {
                    city: props.city,
                    type: listingTypeString(t, props.numberOfRooms, props.type)
                  }),
                  mailBody: t('sharingMail.listing.sharingMailBody', {
                    link: path.join(process.env.REACT_APP_PUBLIC_URL || '', `listings/${props.uuid}/view`)
                  })
                })}
                onReport={() =>
                  shareByMail(
                    {
                      mailSubject: t('emailSubjects.suspiciousListing'),
                      mailBody: `${t('emailContent.suspiciousListing.title', {
                        link: path.join(process.env.REACT_APP_PUBLIC_URL || '', `listings/${props.uuid}/view`)
                      })}\n\n${t('emailContent.suspiciousListing.message')}`
                    },
                    process.env.REACT_APP_CONTACT_MAIL_SUSPICIOUS_LISTING
                  )
                }
                onActivate={() => {
                  addFavorite().then(() => props.refetch && props.refetch());
                }}
                onDeactivate={() => {
                  deleteFavorite().then(() => props.refetch && props.refetch());
                }}
              />
            </div>
          )}
          <div className={styles.listingContent}>
            <ListingCardHeading headingLevel={headingLevel}>
              <span>{listingTypeString(t, props.numberOfRooms, props.type)}</span>
              <br />
              <span>{listingLocationString(t, props.city, props.district)}</span>
            </ListingCardHeading>
            <p className={styles.address}>
              <span className="sr-only">{t('views.landlord.address')}</span>
              <PlaceSVGIcon />
              {listingAddressString(t, props.street, props.houseNumber, props.postalCode, props.city, null)}{' '}
            </p>
            {props.tags && (
              <div className={styles.tagContainer}>
                {props.tags.map((tag) => {
                  return <Tag key={tag}>{t(`enums.tags.${tag}`)}</Tag>;
                })}
                {currentWidth <= 460 && props.tags.length > 1 && (
                  <Tag className={styles.tagCounter}>+{props.tags.length - 1}</Tag>
                )}
                {currentWidth > 460 && props.tags.length > 3 && (
                  <Tag className={styles.tagCounter}>+{props.tags.length - 3}</Tag>
                )}
              </div>
            )}
            <ListingInfoPanel
              availableFrom={props.availableFrom}
              numberOfRooms={props.numberOfRooms}
              squareMeter={props.squareMeter}
              totalRent={props.totalRent}
              className={styles.cardInfoPanel}
            />
            <div className={styles.link} ref={linkRef}>
              <Button
                kind="secondary"
                size="field"
                type="button"
                renderIcon={ArrowForwardSVGIcon}
                hasIconOnly
                iconDescription={t('listing.open')}
                onClick={() => openLink()}
              />
            </div>
          </div>
        </div>
      </Card>
      {openDeleteModal &&
        createPortal(
          <ConfirmationDialog
            open={true}
            title={`${t('views.account.modalDelete.title')}`}
            headline={`${t('views.account.modalDelete.headline')}`}
            text={`${t('views.account.modalDelete.text')}`}
            primaryActionLabel={`${t('actions.deleteListing')}`}
            onPrimaryActionClick={() => {
              deleteListing().then(() => {
                props.refetch && props.refetch();
                setDeleteModal(false);
              });
            }}
            onCloseClick={() => {
              setDeleteModal(false);
            }}
          />,
          document.body
        )}
    </>
  );
};

export const ListingCardSkeleton: FC<StyleableProps> = () => {
  const [showPlaceholderImage, setShowPlaceholderImage] = useState<boolean>(true);
  const [mobile, setMobile] = useState<boolean>(false);
  const currentWidth = useCurrentWidth();

  useEffect(() => {
    const isDesktop = currentWidth > 790;
    if (isDesktop !== showPlaceholderImage) {
      setShowPlaceholderImage(isDesktop);
    }
    const isMobile = currentWidth <= 460;
    if (isMobile !== mobile) {
      setMobile(isMobile);
    }
  }, [setShowPlaceholderImage, currentWidth, setMobile]);

  return (
    <Card className={styles.Listing}>
      {showPlaceholderImage && <div className={styles.skeleton} style={{ width: '21.5rem', height: '16.25rem' }} />}
      <div className={styles.listingWrapper}>
        <div className={styles.listingContent}>
          <SkeletonText width={mobile ? '10rem' : '18rem'} />
          <SkeletonText width={mobile ? '12rem' : '16rem'} />
          <div className={styles.address}>
            <PlaceSVGIcon />
            <SkeletonText width={mobile ? '12rem' : '17rem'} />
          </div>
          <div className={styles.tagContainer}>
            <TagSkeleton />
            <TagSkeleton />
          </div>
          <ListingInfoPanelSkeleton className={styles.cardInfoPanel} />
          <div className={classNames(styles.link, styles.skeleton)} style={{ width: '2.5rem', height: '2.5rem' }} />
        </div>
      </div>
    </Card>
  );
};
