import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

export type ShareLinkGeneratorFunction = (uuid: string) => string;

export const useShareTableEntry = (): ShareLinkGeneratorFunction => {
  const { pathname } = useLocation();

  const getProfileShareLink = useCallback(
    (uuid: string): string => {
      return process.env.REACT_APP_PUBLIC_URL + pathname + `/${uuid}/view`;
    },
    [pathname]
  );

  return getProfileShareLink;
};
