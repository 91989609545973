import { FC, PropsWithChildren, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { Button, Modal } from 'carbon-components-react';
import { useTranslation } from 'react-i18next';

export interface ConfirmationDialogProps {
  open: boolean;
  onPrimaryActionClick: () => void;
  onCloseClick: () => void;
  onSecondaryActionClick?: () => void;
  title: string;
  primaryActionLabel: string;
  secondaryActionLabel?: string; // Default is cancel
  headline?: string;
  text: string;
  selectorPrimaryFocus?: string; // Default is modal-container
  className?: string;
  primaryButtonIcon?: ReactNode;
  secondaryButtonIcon?: ReactNode;
}

export const ConfirmationDialog: FC<PropsWithChildren<ConfirmationDialogProps>> = (
  props: PropsWithChildren<ConfirmationDialogProps>
) => {
  const { t } = useTranslation();

  return createPortal(
    <Modal
      className={props.className}
      passiveModal={true}
      size={'sm'}
      open={props.open}
      onRequestClose={props.onCloseClick}
      modalLabel={props.title}
      selectorPrimaryFocus={props.selectorPrimaryFocus || '.bx--modal-container'}
      iconDescription={t('actions.close')}
    >
      <div className="bx--modal-custom-content">
        {props.headline && <h3>{props.headline}</h3>}
        <p>{props.text}</p>
        {props.children}
      </div>
      <div className="bx--modal-custom-footer">
        {props.onSecondaryActionClick && (
          <Button
            kind={'secondary'}
            className={props.secondaryButtonIcon ? 'hasIcon' : ''}
            onClick={props.onSecondaryActionClick}
            renderIcon={props.secondaryButtonIcon}
          >
            {props.secondaryActionLabel || t('actions.cancel')}
          </Button>
        )}
        <Button
          kind={'primary'}
          className={props.primaryButtonIcon ? 'hasIcon' : ''}
          onClick={props.onPrimaryActionClick}
          renderIcon={props.primaryButtonIcon}
        >
          {props.primaryActionLabel}
        </Button>
      </div>
    </Modal>,
    document.body
  );
};
