import { FetchResult, MutationFunctionOptions, useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  DELETE_LISTING,
  POST_DUPLICATE_LISTING,
  POST_NEW_LISTING,
  PUT_ACTIVATE_LISTING,
  PUT_DEACTIVATE_LISTING,
  PUT_UPDATE_LISTING
} from '../../../../../graphql/queries/listings';
import { ListingImageInput } from '../../../../../graphql/types/globalTypes';
import { PostNewListing, PostNewListingVariables } from '../../../../../graphql/types/PostNewListing';
import { GlobalState } from '../../../../../redux/store';
import { useQueryResultToast } from '../../../../../utils/hooks/query-result-toast';
import { ListingFormDataState, LocalImageDescriptor } from '../listing.state';
import { UpdateListing, UpdateListingVariables } from '../../../../../graphql/types/UpdateListing';
import { DeactivateListing, DeactivateListingVariables } from '../../../../../graphql/types/DeactivateListing';
import { ActivateListing, ActivateListingVariables } from '../../../../../graphql/types/ActivateListing';
import {
  PostUploadListingImage,
  PostUploadListingImageVariables
} from '../../../../../graphql/types/PostUploadListingImage';
import { DeleteListingImage, DeleteListingImageVariables } from '../../../../../graphql/types/DeleteListingImage';
import {
  DeleteMultipleListingImages,
  DeleteMultipleListingImagesVariables
} from '../../../../../graphql/types/DeleteMultipleListingImages';
import { useUserPermission } from '../../../../../utils/hooks/user-data';
import { ManagementType } from '../listing.constants';
import { DeleteListing, DeleteListingVariables } from '../../../../../graphql/types/DeleteListing';
import { PostDuplicateListing, PostDuplicateListingVariables } from '../../../../../graphql/types/PostDuplicateListing';
import {
  DELETE_LISTING_IMAGE,
  DELETE_MULTIPLE_LISTING_IMAGES,
  POST_UPLOAD_LISTING_IMAGE,
  PUT_ROTATE_LISTING_IMAGE
} from '../../../../../graphql/queries/listing-images';
import {
  PutRotateListingImage,
  PutRotateListingImageVariables
} from '../../../../../graphql/types/PutRotateListingImage';

const transformImageDescriptor = (localImageDescriptor: LocalImageDescriptor): ListingImageInput => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { status, error, fileName, markedForDeletion, origin, modifiedAt, ...image } = localImageDescriptor;
  if (!image.description && image.descriptionEn) {
    // Set (German) description to English description if it is missing
    image.description = image.descriptionEn;
  } else if (!image.descriptionEn && image.description) {
    // Set English description to German if it is missing
    image.descriptionEn = image.description;
  }
  return image;
};

export const usePostNewListingMutations = (): [
  () => Promise<FetchResult<PostNewListing>>,
  () => Promise<FetchResult<PostNewListing>>
] => {
  const { landlord, images, ...variables } = useSelector<GlobalState, ListingFormDataState>(
    (state) => state.listingFormData
  );

  let coords = null;
  if (variables.coordinates !== null) {
    // Remove type name property for query
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, ...transformedCoords } = variables.coordinates;
    coords = transformedCoords;
  }

  const transformedVariables = Object.assign({}, variables, {
    landlord: landlord?.id,
    images: images.map(transformImageDescriptor),
    coordinates: coords
  });

  const [postNewListingAndSave, { data: saveData, error: saveError }] = useMutation<
    PostNewListing,
    PostNewListingVariables
  >(POST_NEW_LISTING, {
    variables: transformedVariables
  });

  const [postNewListingAndPublish, { data: publishData, error: publishError }] = useMutation<
    PostNewListing,
    PostNewListingVariables
  >(POST_NEW_LISTING, {
    variables: transformedVariables
  });

  useQueryResultToast(saveData, saveError, {
    success: {
      titleKey: 'notifications.listingCreationSuccess'
    },
    error: {
      titleKey: 'notifications.listingCreationError',
      subTitleKey: 'notifications.listingCreationErrorSubtitle'
    }
  });

  useQueryResultToast(publishData, publishError, {
    success: {
      titleKey: 'notifications.listingCreationPublishSuccess'
    },
    error: {
      titleKey: 'notifications.listingCreationPublishError',
      subTitleKey: 'notifications.listingCreationErrorSubtitle'
    }
  });

  return [postNewListingAndSave, postNewListingAndPublish];
};

export const useUploadListingImage = (): ((
  options?: MutationFunctionOptions<PostUploadListingImage, PostUploadListingImageVariables> | undefined
) => Promise<FetchResult<PostUploadListingImage>>) => {
  const [uploadListingImage] = useMutation<PostUploadListingImage, PostUploadListingImageVariables>(
    POST_UPLOAD_LISTING_IMAGE
  );

  return uploadListingImage;
};

export const useDeleteListingImage = (): ((
  options?: MutationFunctionOptions<DeleteListingImage, DeleteListingImageVariables> | undefined
) => Promise<FetchResult<DeleteListingImage>>) => {
  const [deleteListingImage] = useMutation<DeleteListingImage, DeleteListingImageVariables>(DELETE_LISTING_IMAGE);

  return deleteListingImage;
};

export const useRotateListingImage = (): ((
  options?: MutationFunctionOptions<PutRotateListingImage, PutRotateListingImageVariables> | undefined
) => Promise<FetchResult<PutRotateListingImage>>) => {
  const [rotateListingImage] = useMutation<PutRotateListingImage, PutRotateListingImageVariables>(
    PUT_ROTATE_LISTING_IMAGE
  );

  return rotateListingImage;
};

export const useDeleteMultipleListingImages = (): ((
  options?: MutationFunctionOptions<DeleteMultipleListingImages, DeleteMultipleListingImagesVariables> | undefined
) => Promise<FetchResult<DeleteMultipleListingImages>>) => {
  const [deleteMultipleListingImage] = useMutation<DeleteMultipleListingImages, DeleteMultipleListingImagesVariables>(
    DELETE_MULTIPLE_LISTING_IMAGES
  );

  return deleteMultipleListingImage;
};

export const useUpdateListingMutations = (
  hideSuccessToast?: boolean
): [() => Promise<unknown>, () => Promise<unknown>] => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { landlord, id: listingId, uuid, modifiedAt, publicationDate, images, ...listing } = useSelector<
    GlobalState,
    ListingFormDataState
  >((state) => state.listingFormData);

  let coords = null;
  if (listing.coordinates !== null) {
    // Remove type name property for query
    const { __typename, ...transformedCoords } = listing.coordinates;
    coords = transformedCoords;
  }

  const transformedListing = Object.assign({}, listing, {
    landlord: landlord?.id || null,
    id: listingId || '',
    coordinates: coords,
    images: images
      .filter((image) => !image.id.startsWith('local_'))
      .map(transformImageDescriptor)
      .map((image) => {
        return Object.assign({}, image, {
          listing: listingId || '',
          image: undefined
        });
      })
  });
  /* eslint-enable @typescript-eslint/no-unused-vars */

  const [updateListingSave, { data: saveData, error: saveError }] = useMutation<UpdateListing, UpdateListingVariables>(
    PUT_UPDATE_LISTING,
    { variables: { listing: transformedListing } }
  );

  const [updateListingPublish, { data: publishData, error: publishError }] = useMutation<
    UpdateListing,
    UpdateListingVariables
  >(PUT_UPDATE_LISTING, {
    variables: { listing: transformedListing }
  });

  useQueryResultToast(saveData, saveError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.listingUpdateSaveSuccess'
        },
    error: {
      titleKey: 'notifications.listingUpdateSaveError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(publishData, publishError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.listingUpdatePublishSuccess'
        },
    error: {
      titleKey: 'notifications.listingUpdatePublishError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  return [updateListingSave, updateListingPublish];
};

export const useListingMutations = (
  hideSuccessToast?: boolean,
  listingId?: string
): {
  activateListing: () => Promise<unknown>;
  deactivateListing: () => Promise<unknown>;
  deleteListing: () => Promise<unknown>;
  duplicateListing: () => Promise<FetchResult<PostDuplicateListing, Record<string, unknown>, Record<string, unknown>>>;
} => {
  const { id: stateId } = useSelector<GlobalState, ListingFormDataState>((state) => state.listingFormData);
  const id = listingId || stateId;

  const [activateListing, { data: activationData, error: activationError }] = useMutation<
    ActivateListing,
    ActivateListingVariables
  >(PUT_ACTIVATE_LISTING, id !== null ? { variables: { listingId: id } } : undefined);

  const [deactivateListing, { data: deactivationData, error: deactivationError }] = useMutation<
    DeactivateListing,
    DeactivateListingVariables
  >(PUT_DEACTIVATE_LISTING, id !== null ? { variables: { listingId: id } } : undefined);

  const [deleteListing, { data: deleteData, error: deleteError }] = useMutation<DeleteListing, DeleteListingVariables>(
    DELETE_LISTING,
    id !== null ? { variables: { listingId: id } } : undefined
  );

  const [duplicateListing, { data: duplicateData, error: duplicateError }] = useMutation<
    PostDuplicateListing,
    PostDuplicateListingVariables
  >(POST_DUPLICATE_LISTING, id !== null ? { variables: { listingId: id } } : undefined);

  useQueryResultToast(activationData, activationError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.listingActivationSuccess'
        },
    error: {
      titleKey: 'notifications.listingActivationError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(deactivationData, deactivationError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.listingDeactivationSuccess'
        },
    error: {
      titleKey: 'notifications.listingDeactivationError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(deleteData, deleteError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.listingDeleteSuccess'
        },
    error: {
      titleKey: 'notifications.listingDeleteError',
      subTitleKey: 'notifications.genericMutationErrorSubtitle'
    }
  });

  useQueryResultToast(duplicateData, duplicateError, {
    success: hideSuccessToast
      ? undefined
      : {
          titleKey: 'notifications.genericActionSuccess'
        },
    error: {
      titleKey: 'notifications.genericActionError'
    }
  });

  return {
    activateListing,
    deactivateListing,
    deleteListing,
    duplicateListing
  };
};

export const useManagementType = (
  formState: ListingFormDataState
): [ManagementType, Dispatch<SetStateAction<ManagementType>>] => {
  const { hasAdministrativeFunction } = useUserPermission();

  const managementTypeState = useState<ManagementType>(ManagementType.LANDLORD);
  const [, setManagementType] = managementTypeState;

  /**
   * Hook which automatically sets the management type based on the loaded listing data
   */
  useEffect(() => {
    if (hasAdministrativeFunction) {
      if (formState.landlord === null) {
        setManagementType(ManagementType.MANAGEMENT);
      } else {
        setManagementType(ManagementType.LANDLORD);
      }
    }
  }, [formState.landlord, hasAdministrativeFunction]);

  return managementTypeState;
};
