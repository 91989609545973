export const FILTER_SEPARATOR = ',';

// Unit values used to as number input labels
export const UNIT_CURRENCY_EURO = '€';
export const UNIT_AREA_SQUARE_METER = 'm²';

export const READ_ONLY_PROPS = {
  readOnly: true,
  tabIndex: -1
};

export const START_VIEW_PATH = process.env.REACT_APP_START_VIEW || '/';

export const IS_DEV_MODE = process.env.NODE_ENV === 'development';

/**
 * Error codes that can be received from the middleware
 */
export enum ErrorCodes {
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  FEATURE_DISABLED = 'FEATURE_DISABLED',
  EMAIL_SERVER_CONNECTION_ERROR = 'EMAIL_SERVER_CONNECTION_ERROR',
  LOGIN_FAILED_WRONG_USER_OR_PASSWORD = 'LOGIN_FAILED_WRONG_USER_OR_PASSWORD',
  LOGIN_FAILED_USER_BLOCKED = 'LOGIN_FAILED_USER_BLOCKED',
  LOGIN_FAILED_USER_NOT_CONFIRMED = 'LOGIN_FAILED_USER_NOT_CONFIRMED',
  CHANGE_PASSWORD_OLD_PASSWORD_INCORRECT = 'OLD_PASSWORD_INCORRECT',
  CHANGE_PASSWORD_NEW_PASSWORD_INSUFFICIENT = 'NEW_PASSWORD_INSUFFICIENT',
  OPERATION_FORBIDDEN_USER_BLOCKED = 'OPERATION_FORBIDDEN_USER_BLOCKED',
  CONFIRMATION_TOKEN_EXPIRED = 'CONFIRMATION_TOKEN_EXPIRED',
  CONFIRMATION_TOKEN_INVALID = 'CONFIRMATION_TOKEN_INVALID',
  RESET_PASSWORD_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED',
  RESET_PASSWORD_TOKEN_INVALID = 'RESET_PASSWORD_TOKEN_INVALID'
}

/**
 * URL parameter
 */
export enum Param {
  // Dialog
  REGISTER = 'register',
  LOGIN = 'login',
  CONFIRM = 'confirm',
  RESET_PASSWORD = 'reset-password',
  SETTINGS = 'settings',

  // Tab index for login dialog
  TAB = 'tab',

  // External Search Request
  DEPT = 'dept',
  LANG = 'lang',

  // Search Requests
  OPEN = 'open',
  AFFILIATION = 'affiliation',
  ROLE = 'role',

  // Filter Landlord
  APPROVAL = 'approval',
  // TYPE = 'type',
  SEARCH = 'search',

  VIEW_MODE = 'viewMode',

  // Filter Listing
  GUEST_RESEARCHERS = 'guestResearchers',
  PROFESSORS = 'professors',
  INCOMINGS = 'incomings',
  STUDENTS = 'students',
  POST_DOC_STUDENTS = 'postDoctoralStudents',
  DOC_STUDENTS = 'doctoralStudents',
  TUM_EMPLOYEES = 'tumEmployees',
  TYPE = 'type',
  ROOMS = 'rooms',
  SQUARE_FROM = 'squareFrom',
  SQUARE_TO = 'squareTo',
  RENT_FROM = 'rentFrom',
  RENT_TO = 'rentTo',
  AVAILABLE_FROM = 'availableFrom',
  AVAILABLE_LATEST = 'availableLatest',
  OPEN_ENDED = 'openEnded',
  TUM_LOCATION = 'tumLocation',
  DEPOSIT = 'deposit',
  DISTRICTS = 'districts',
  LANDLORD_IS_MANAGEMENT = 'landlordIsManagement',
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
  ACTIVATED = 'activated',
  INACTIVATED = 'inactivated',
  PUBLIC = 'public',

  // list pagination
  PAGE = 'page',

  RESULT_LIMIT = 'resultLimit',

  // Sharing token
  SHARE = 'share',

  // Image caching timestamp
  TIMESTAMP = 'ts'
}
