import { exportTable } from '../../../../utils/actions';
import { ApolloClient } from '@apollo/client';
import { TFunction } from 'i18next';
import {
  GetListingsExport,
  GetListingsExport_listings,
  GetListingsExport_listings_landlord,
  GetListingsExportVariables
} from '../../../../graphql/types/GetListingsExport';
import { GET_LISTINGS_EXPORT, GET_LISTINGS_EXPORT_LINK_ONLY } from '../../../../graphql/queries/listings';
import { formatDate } from '../../../../utils/intl/dates-and-times';
import {
  GetListingsExportLinkOnly,
  GetListingsExportLinkOnlyVariables
} from '../../../../graphql/types/GetListingsExportLinkOnly';

/**
 * Exports the listings list to csv file
 * @param t The translation function to translate headlines and types
 * @param apolloClient The apollo client to query the export data
 * @param filterProps The current set filter properties
 */
export const exportCsvData = (
  t: TFunction,
  apolloClient: ApolloClient<unknown>,
  filterProps: GetListingsExportVariables
): Promise<void> => {
  // Translation key for the table headlines
  const headlines = [
    'landlord',
    'type',
    'rooms',
    'availableFrom',
    'availableUntil',
    'postalCode',
    'city',
    'district',
    'street',
    'houseNumber',
    'totalRent',
    'deposit',
    'tags',
    'images',
    'lookingFor',
    'visibleFor',
    'verified',
    'links'
  ];
  // Return promise when query is finished
  return new Promise<void>((resolve, reject) => {
    // Reset cache and query the data for the landlord export
    apolloClient.cache.reset().then(() => {
      apolloClient
        .query<GetListingsExport, GetListingsExportVariables>({
          query: GET_LISTINGS_EXPORT,
          variables: filterProps
        })
        .then((result) => {
          // Create headlines for csv
          let formattedData = '';
          headlines.forEach((headline, index) => {
            formattedData += index > 0 ? process.env.REACT_APP_CSV_SEPARATOR : '';
            formattedData += t(`views.listings.export.headlines.${headline}`);
          });
          formattedData += '\n';
          if (result.data) {
            // Iterate over all listing data if available
            result.data.listings.forEach((listing) => {
              if (listing) {
                // Convert to types to translation keys if necessary
                const row = [
                  formatLandlord(listing.landlord),
                  t(`enums.listingType.${listing.type}`),
                  listing.numberOfRooms !== null ? listing.numberOfRooms.toLocaleString('de-DE') : '',
                  formatDate(listing.availableFrom),
                  formatDate(listing.availableUntil),
                  listing.postalCode,
                  listing.city,
                  listing.district ? t(`enums.district.${listing.district}`) : '',
                  listing.street,
                  listing.houseNumber,
                  listing.totalRent,
                  listing.deposit || 0,
                  formatTags(listing.tags, t),
                  listing.images ? listing.images.length : 0,
                  formatSeeking(listing, t),
                  t(`views.listings.export.visibleType.${listing.isListingPublic ? 'public' : 'management'}`),
                  t(`enums.verification.${listing.verifiedAt !== null ? 'VERIFIED' : 'NOT_VERIFIED'}`),
                  `${process.env.REACT_APP_PUBLIC_URL}/listings/${listing.uuid}/view` // Create link
                ];
                // Create row
                formattedData += row.join(process.env.REACT_APP_CSV_SEPARATOR) + '\n';
              }
            });
          }
          // Create csv file from the data
          exportTable(formattedData, `${t('views.listings.listings')}_${new Date().toISOString()}.csv`);
        })
        .catch(() => reject())
        .finally(() => resolve());
    });
  });
};

/**
 * Exports all links to public listings
 * @param apolloClient The apollo client to query the export data
 * @param filterProps The current set filter properties
 */
export const exportLinks = (
  apolloClient: ApolloClient<unknown>,
  filterProps: GetListingsExportLinkOnlyVariables
): Promise<string> => {
  // Return promise when query is finished
  return new Promise<string>((resolve, reject) => {
    if (filterProps.filter) {
      // only public listings
      filterProps.filter.isListingPublic = true;
      filterProps.filter.activationStatus = [true];
      filterProps.filter.verificationStatus = [true];
    }

    // Reset cache and query the data for the hyperlinks
    apolloClient.cache.reset().then(() => {
      apolloClient
        .query<GetListingsExportLinkOnly, GetListingsExportLinkOnlyVariables>({
          query: GET_LISTINGS_EXPORT_LINK_ONLY,
          variables: filterProps
        })
        .then((result) => {
          // Create a collection of links separated by line breaks
          let formattedLinks = '';
          if (result.data) {
            // Iterate over all listing data if available
            result.data.listings.forEach((listing) => {
              if (listing) {
                const link = `${process.env.REACT_APP_PUBLIC_URL}/listings/${listing.uuid}/view`; // Create link
                formattedLinks += link + '\n';
              }
            });
            resolve(formattedLinks);
          }
        })
        .catch(() => reject());
    });
  });
};

/**
 * Formats the information of the landlord
 * @param landlord The landlord to format
 */
const formatLandlord = (landlord: GetListingsExport_listings_landlord | null): string => {
  let landlordFormatted = '-';
  if (landlord) {
    landlordFormatted = '"';
    landlordFormatted += landlord.companyName ? `${landlord.companyName} | ` : '';
    landlordFormatted += `${landlord.lastName.toUpperCase()}, ${landlord.firstName}`;
    landlordFormatted += landlord.email ? ` | ${landlord.email}` : '';
    landlordFormatted += landlord.phone ? ` | ${landlord.phone}` : '';
    landlordFormatted += '"';
  }
  return landlordFormatted;
};

/**
 * Format and translate tags
 * @param tags List of all tags
 * @param t The t function for translations
 */
export const formatTags = (tags: Array<string | null> | null, t: TFunction): string => {
  let formattedTags = '';
  if (tags && tags.length > 0) {
    formattedTags += '"';
    tags.forEach((tag, index) => {
      formattedTags += (index > 0 ? ', ' : '') + t(`enums.tags.${tag}`);
    });
    formattedTags += '"';
  }
  return formattedTags;
};

/**
 * Formats and translate the seeking
 * @param listing The listing that contains the seeking information
 * @param t The t function for translations
 */
export const formatSeeking = (listing: GetListingsExport_listings, t: TFunction): string => {
  // Format and translate seeking
  const seeking = [];
  if (listing.seekingGuestResearchers) seeking.push(t('enums.targetGroup.GUEST_RESEARCHERS'));
  if (listing.seekingDoctoralStudents) seeking.push(t('enums.targetGroup.DOCTORAL_STUDENTS'));
  if (listing.seekingIncomings) seeking.push(t('enums.targetGroup.INCOMINGS'));
  if (listing.seekingPostDoctoralStudents) seeking.push(t('enums.targetGroup.POST_DOCTORAL_STUDENTS'));
  if (listing.seekingProfessors) seeking.push(t('enums.targetGroup.PROFESSORS'));
  if (listing.seekingStudents) seeking.push(t('enums.targetGroup.STUDENTS'));
  if (listing.seekingTumEmployees) seeking.push(t('enums.targetGroup.TUM_EMPLOYEES'));
  return `"${seeking.join(', ')}"`;
};
