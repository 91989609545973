import { FC } from 'react';
import styles from './listing-popup.module.scss';
import { useQuery } from '@apollo/client';
import { GET_LISTING_SHORT_BY_UUID } from '../../../graphql/queries/listings';
import placeholderImage from '../../../assets/images/listing-placeholder-small.jpg';
import { GetListingShortByUUID, GetListingShortByUUIDVariables } from '../../../graphql/types/GetListingShortByUUID';
import { listingAddressString, listingLocationString, listingTypeString } from '../../../utils/listings';
import { useTranslation } from 'react-i18next';
import { ChevronRightSVGIcon, PlaceSVGIcon } from '@react-md/material-icons';
import { ImageGallery } from '../image-gallery/image-gallery';
import { Link } from 'react-router-dom';
import { useUrlSearchParams } from 'use-url-search-params';
import { FavoriteButton } from '../favorite-button/favorite-button';
import { getImagePath, getThumbnailImagePath } from '../../../utils/listing-images';
import classNames from 'classnames';
import { LAZY_LOADING_CLASSNAME } from '../../../image-lazy-loading';

export interface ListingPopupProps {
  uuid: string;
  favorable?: boolean;
  isFavorite?: boolean;
  refetch?: () => void;
}

export const ListingPopup: FC<ListingPopupProps> = (props: ListingPopupProps) => {
  const { t, i18n } = useTranslation();
  const { loading, data } = useQuery<GetListingShortByUUID, GetListingShortByUUIDVariables>(GET_LISTING_SHORT_BY_UUID, {
    variables: { uuid: props.uuid },
    fetchPolicy: 'no-cache'
  });

  const listing = data?.listingByUUID;

  const hasImages = listing?.images && listing.images.length > 0;

  const [params] = useUrlSearchParams({}, {});

  // Return nothing until the listing is loaded
  if (loading) {
    return <></>;
  }

  return !loading && listing ? (
    <div className={styles.ListingPopup}>
      {props.favorable && (
        <div className={styles.favoriteButtonContainer}>
          <FavoriteButton isFavorite={!!props.isFavorite} refetch={props.refetch} listingId={listing.id} />
        </div>
      )}
      {/* Only show preview image in case there are no other images */}
      {listing.previewImage && !hasImages && (
        <img
          className={classNames(LAZY_LOADING_CLASSNAME, styles.previewImage)}
          data-src={getImagePath(listing.previewImage.id, listing.previewImage.modifiedAt)}
          alt={i18n.language === 'de' ? listing.previewImage.description : listing.previewImage.descriptionEn}
        />
      )}
      {/* Show images when available */}
      {hasImages && listing && (
        <ImageGallery
          images={
            listing.images
              ? listing.images.map((image) => ({
                  path: getThumbnailImagePath(image.id, image.modifiedAt),
                  description: image.description,
                  descriptionEn: image.descriptionEn
                }))
              : []
          }
          showBullets
          showControlsOnHover
          className={styles.previewImage}
        />
      )}
      {/* Otherwise show a placeholder */}
      {!listing.previewImage && !hasImages && (
        <img className={styles.previewImage} src={placeholderImage} alt={t('listing.altNoImages')} />
      )}
      <Link
        to={`/listings/${props.uuid}/view`}
        state={{
          params
        }}
        className={styles.listingContent}
      >
        <h2>{listingTypeString(t, listing.numberOfRooms, listing.type)}</h2>
        <h2>{listingLocationString(t, listing.city, listing.district)}</h2>
        <p className={styles.address}>
          <PlaceSVGIcon />
          {listingAddressString(t, listing.street, listing.houseNumber, listing.postalCode, null, null)}{' '}
        </p>
        <div className={styles.listingInfo}>
          <span>{listing.totalRent}&#8239;€</span>
          <span>{listing.squareMeter}&#8239;m²</span>
          <span>{listing.numberOfRooms}&nbsp;Zi.</span>
        </div>
        <ChevronRightSVGIcon className={styles.link} />
      </Link>
    </div>
  ) : (
    <div className={styles.listingError}>
      <img className={styles.previewImage} src={placeholderImage} alt={t('listing.altNoImages')} />
      <p>{t('errors.listingLoadError')}</p>
    </div>
  );
};
