import { FC } from 'react';
import styles from './component-view.module.scss';
import { FileUploadItem } from '../../controls/file-upload-item/file-upload-item';

export const ComponentView: FC = () => {
  const testImage =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAADICAYAAAAePETBAAABLElEQVR42u3RMQEAAAjDMFCO9GGCgyOV0HRSU3pTAwEiIEAEBIiAABEQIECACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiACAgQAQEiIEAEBIiAAAECRECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAERECACAgQAQEiIEAEBAgQIAICRECACAgQAQFiAxABASIgQAQEiIAAERABASIgQHTZAj238oD1i2+JAAAAAElFTkSuQmCC';

  const sideWaysImage =
    // eslint-disable-next-line max-len
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAABkCAYAAADDhn8LAAAA0ElEQVR42u3TMQEAAAgDIE1u9FnA1w860ElNAacWBAQBQUAQEAQEAUFAEBAEBAEEAUFAEBAEBAFBQBAQBAQBQQQBQUAQEAQEAUFAEBAEBAFBAEFAEBAEBAFBQBAQBAQBQUAQQBAQBAQBQUAQEAQEAUFAEEAQEAQEAUFAEBAEBAFBQBAQBBAEBAFBQBAQBAQBQUAQEAQQBAQBQUAQEAQEAUFAEBAEBAEEAUFAEBAEBAFBQBAQBAQBQQQBQUAQEAQEAUFAEBAEBAFBAEFAEBAEPi1Kgfk5y0MkngAAAABJRU5ErkJggg==';

  return (
    <div className="global-content-wrapper-block">
      <div className={styles.ComponentView}>
        <ol className={styles.FileUploadItemContainer}>
          <FileUploadItem
            id="test1"
            fileName="testfile.jpg"
            status="complete"
            image={testImage}
            description="desc"
            descriptionEn="descEN"
            onImageRotate={() => null}
          />
          <FileUploadItem
            id="test2"
            fileName="testfile2.jpg"
            status="complete"
            image={sideWaysImage}
            description="desc"
            descriptionEn="descEN"
            onImageRotate={() => null}
          />
          <FileUploadItem
            id="test24"
            fileName="really very unnecessarily long filename for an image test 3.jpg"
            status="complete"
            image={testImage}
            description="desc"
            descriptionEn="descEN"
          />
          <FileUploadItem
            id="test23"
            fileName="testfile3.jpg"
            status="uploading"
            description="desc"
            descriptionEn="descEN"
          />
          <FileUploadItem
            id="testError"
            fileName="testfile3.jpg"
            status="complete"
            description="desc"
            descriptionEn="descEN"
            error="Die Datei überschreitet die maximale Größe von 5MB."
          />
        </ol>
      </div>
    </div>
  );
};
