import { logger } from '../logger';
import { TFunction } from 'i18next';

export const defaultDateTimeFormat = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZone: process.env.NODE_ENV === 'test' ? 'UTC' : undefined
});

export const defaultDateFormat = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  timeZone: process.env.NODE_ENV === 'test' ? 'UTC' : undefined
});

export const defaultTimeFormat = new Intl.DateTimeFormat('de-DE', {
  hour: '2-digit',
  minute: '2-digit',
  timeZone: process.env.NODE_ENV === 'test' ? 'UTC' : undefined
});

const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const convertLocalDate = (date: Date | string | null): Date | undefined => {
  let localDate;
  if (date === null) {
    return undefined;
  } else if (typeof date === 'string') {
    localDate = new Date(date);
  } else {
    localDate = date;
  }
  return localDate;
};

export const formatDateTime = (date: Date | string | null, translations: { timeSuffix?: string }): string => {
  const localDate = convertLocalDate(date);
  if (localDate !== undefined) {
    try {
      return `${defaultDateTimeFormat.format(localDate)} ${translations.timeSuffix}`;
    } catch (error) {
      logger.error(error);
    }
  }
  return '\u2013';
};

export const formatDate = (date: Date | string | null): string => {
  const localDate = convertLocalDate(date);
  if (
    localDate !== undefined &&
    (date === null || typeof date === 'object' || (typeof date === 'string' && date.length > 0))
  ) {
    try {
      return defaultDateFormat.format(localDate);
    } catch (error) {
      logger.error(error);
    }
  }
  return '\u2013';
};

export const formatDateTimeWithToday = (
  date: Date | string | null,
  translations: { today: string; timeSuffix?: string }
): string => {
  const localDate = convertLocalDate(date);
  if (
    localDate !== undefined &&
    (date === null || typeof date === 'object' || (typeof date === 'string' && date.length > 0))
  ) {
    if (isToday(localDate)) {
      return `${translations.today}, ${defaultTimeFormat.format(localDate)} ${translations.timeSuffix}`;
    }
    return defaultDateFormat.format(localDate);
  }
  return '\u2013';
};

export const formatTime = (seconds: number, t: TFunction): string => {
  const hours = Math.floor(seconds / 60);
  const minutes = seconds % 60;
  const min = t('views.listings.viewSubSections.min');
  if (minutes === 0) {
    return `${hours} h`;
  } else if (hours === 0) {
    return `${minutes} ${min}`;
  }
  return `${hours} h ${minutes} ${min}`;
};

export const checkIfDateIsNow = (date: string): boolean => {
  const convertedDate = new Date(date);
  convertedDate.setHours(0, 0, 0, 0);
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return convertedDate <= now;
};
