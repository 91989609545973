import {
  ActionCreator,
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  createSlice,
  PayloadAction
} from '@reduxjs/toolkit';
import { TUMLocation, Gender, ListingType } from '../../../../graphql/types/globalTypes';

export interface SearchRequestObserver {
  email: string;
  role: string | null;
}

export interface SearchRequestsFormDataState {
  id: string | null;
  uuid: string | null;
  isActive: boolean;
  isNew: boolean;

  gender: Gender | null;
  lastName: string;
  firstName: string;
  email: string;
  phone: string;
  affiliationType: string;
  department: string | null;
  country: string;
  prefersEnglish: boolean;

  type: ListingType;
  numberOfRooms: number | null;
  squareMeterFrom: number;
  squareMeterTo: number | null;
  numberOfTenants: number;
  availableFrom: string;
  availableUntil: string | null;
  city: TUMLocation | null;
  districts: string[] | null;
  searchRadius: number;
  totalRentFrom: number;
  totalRentTo: number | null;
  depositMax: number | null;
  tags: string[];
  remarks: string | null;

  role: string;
  assignee: string | null;
  observers: SearchRequestObserver[];

  showResultToast: boolean;
}

export const initialSearchRequestsFormDataState: SearchRequestsFormDataState = {
  id: null,
  uuid: null,
  isActive: true,
  isNew: false,

  gender: Gender.DIVERSE,
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  affiliationType: '',
  department: null,
  country: 'DEUTSCHLAND',
  prefersEnglish: false,

  type: ListingType.APARTMENT,
  numberOfRooms: null,
  squareMeterFrom: 0,
  squareMeterTo: 0,
  numberOfTenants: 1,
  availableFrom: '',
  availableUntil: null,
  city: null,
  districts: null,
  searchRadius: 1,
  totalRentFrom: 0,
  totalRentTo: null,
  depositMax: null,
  tags: [],
  remarks: null,

  role: '',
  assignee: null,
  observers: [],

  showResultToast: false
};

export const slice = createSlice({
  name: 'SearchRequestsFormData',
  reducers: {
    setUuid: (state: SearchRequestsFormDataState, action: PayloadAction<string | null>) => {
      state.uuid = action.payload;
    },
    setIsActive: (state: SearchRequestsFormDataState, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setGender: (state: SearchRequestsFormDataState, action: PayloadAction<Gender>) => {
      state.gender = action.payload;
    },
    setFirstName: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setAffiliationType: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.affiliationType = action.payload;
    },
    setDepartment: (state: SearchRequestsFormDataState, action: PayloadAction<string | null>) => {
      state.department = action.payload;
    },
    setCountry: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setPrefersEnglish: (state: SearchRequestsFormDataState, action: PayloadAction<boolean>) => {
      state.prefersEnglish = action.payload;
    },
    setType: (state: SearchRequestsFormDataState, action: PayloadAction<ListingType>) => {
      state.type = action.payload;
    },
    setNumberOfRooms: (state: SearchRequestsFormDataState, action: PayloadAction<number | null>) => {
      state.numberOfRooms = action.payload;
    },
    setSquareMeterFrom: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.squareMeterFrom = action.payload;
    },
    setSquareMeterTo: (state: SearchRequestsFormDataState, action: PayloadAction<number | null>) => {
      state.squareMeterTo = action.payload;
    },
    setNumberOfTenants: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.numberOfTenants = action.payload;
    },
    setAvailableFrom: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.availableFrom = action.payload;
    },
    setAvailableUntil: (state: SearchRequestsFormDataState, action: PayloadAction<string | null>) => {
      state.availableUntil = action.payload;
    },
    setCity: (state: SearchRequestsFormDataState, action: PayloadAction<TUMLocation>) => {
      state.city = action.payload;
    },
    setDistricts: (state: SearchRequestsFormDataState, action: PayloadAction<string[] | null>) => {
      state.districts = action.payload;
    },
    setSearchRadius: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.searchRadius = action.payload;
    },
    setTotalRentFrom: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.totalRentFrom = action.payload;
    },
    setTotalRentTo: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.totalRentTo = action.payload;
    },
    setDeposit: (state: SearchRequestsFormDataState, action: PayloadAction<number>) => {
      state.depositMax = action.payload;
    },
    setTags: (state: SearchRequestsFormDataState, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setRemarks: (state: SearchRequestsFormDataState, action: PayloadAction<string | null>) => {
      state.remarks = action.payload;
    },
    setShowResultToast: (state: SearchRequestsFormDataState, action: PayloadAction<boolean>) => {
      state.showResultToast = action.payload;
    },
    setRole: (state: SearchRequestsFormDataState, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    setAssignee: (state: SearchRequestsFormDataState, action: PayloadAction<string | null>) => {
      state.assignee = action.payload;
    },
    setObservers: (state: SearchRequestsFormDataState, action: PayloadAction<SearchRequestObserver[]>) => {
      state.observers = action.payload;
    },
    resetForm: (state: SearchRequestsFormDataState) => {
      Object.assign(state, initialSearchRequestsFormDataState);
    },
    setSearchRequestsFormData: (
      state: SearchRequestsFormDataState,
      action: PayloadAction<SearchRequestsFormDataState>
    ) => {
      Object.assign(state, action.payload);
    }
  },
  initialState: initialSearchRequestsFormDataState
});

export const setUuid: ActionCreatorWithPayload<string | null> = slice.actions.setUuid;
export const setIsActive: ActionCreatorWithPayload<boolean> = slice.actions.setIsActive;
export const setGender: ActionCreatorWithPayload<Gender> = slice.actions.setGender;
export const setFirstName: ActionCreatorWithPayload<string> = slice.actions.setFirstName;
export const setLastName: ActionCreatorWithPayload<string> = slice.actions.setLastName;
export const setEmail: ActionCreatorWithPayload<string> = slice.actions.setEmail;
export const setPhone: ActionCreatorWithPayload<string> = slice.actions.setPhone;
export const setAffiliationType: ActionCreatorWithPayload<string> = slice.actions.setAffiliationType;
export const setDepartment: ActionCreatorWithPayload<string | null> = slice.actions.setDepartment;
export const setCountry: ActionCreatorWithPayload<string> = slice.actions.setCountry;
export const setPrefersEnglish: ActionCreatorWithPayload<boolean> = slice.actions.setPrefersEnglish;
export const setType: ActionCreatorWithPayload<ListingType> = slice.actions.setType;
export const setNumberOfRooms: ActionCreatorWithPayload<number | null> = slice.actions.setNumberOfRooms;
export const setSquareMeterFrom: ActionCreatorWithPayload<number> = slice.actions.setSquareMeterFrom;
export const setSquareMeterTo: ActionCreatorWithPayload<number | null> = slice.actions.setSquareMeterTo;
export const setNumberOfTenants: ActionCreatorWithPayload<number> = slice.actions.setNumberOfTenants;
export const setAvailableFrom: ActionCreatorWithPayload<string> = slice.actions.setAvailableFrom;
export const setAvailableUntil: ActionCreatorWithPayload<string | null> = slice.actions.setAvailableUntil;
export const setCity: ActionCreatorWithPayload<TUMLocation> = slice.actions.setCity;
export const setDistricts: ActionCreatorWithPayload<string[] | null> = slice.actions.setDistricts;
export const setSearchRadius: ActionCreatorWithPayload<number> = slice.actions.setSearchRadius;
export const setTotalRentFrom: ActionCreatorWithPayload<number> = slice.actions.setTotalRentFrom;
export const setTotalRentTo: ActionCreatorWithPayload<number> = slice.actions.setTotalRentTo;
export const setDeposit: ActionCreatorWithPayload<number> = slice.actions.setDeposit;
export const setTags: ActionCreatorWithPayload<string[]> = slice.actions.setTags;
export const setRemarks: ActionCreatorWithPayload<string | null> = slice.actions.setRemarks;
export const setRole: ActionCreatorWithPayload<string> = slice.actions.setRole;
export const setAssignee: ActionCreatorWithPayload<string | null> = slice.actions.setAssignee;
export const setObservers: ActionCreatorWithPayload<SearchRequestObserver[]> = slice.actions.setObservers;
export const setShowResultToast: ActionCreatorWithPayload<boolean> = slice.actions.setShowResultToast;
export const resetSearchRequestsForm: ActionCreatorWithoutPayload = slice.actions.resetForm;
export const setSearchRequestsFormData: ActionCreatorWithPayload<SearchRequestsFormDataState> =
  slice.actions.setSearchRequestsFormData;

export const searchRequestsFormDataReducer = slice.reducer;

export const searchRequestFormActionsTriggeringDirtyState = Object.values(slice.actions).filter(
  (value: ActionCreator<unknown>) =>
    value !== slice.actions.resetForm &&
    value !== slice.actions.setSearchRequestsFormData &&
    value !== slice.actions.setShowResultToast
);

export const searchRequestFormActionsResettingDirtyState = [
  slice.actions.resetForm,
  slice.actions.setSearchRequestsFormData
];
