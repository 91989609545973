import { FC, useEffect, useState } from 'react';
import styles from './mail-view.module.scss';
import classNames from 'classnames';
import { useDateTimeFormat } from '../../../utils/hooks/dateTimeFormat';
import { useTranslation } from 'react-i18next';
import { Button } from 'carbon-components-react';
import { AttachFileSVGIcon, ExpandLessSVGIcon, ExpandMoreSVGIcon, FileDownloadSVGIcon } from '@react-md/material-icons';
import { useApolloClient, useMutation } from '@apollo/client';
import { GetAttachmentById, GetAttachmentByIdVariables } from '../../../graphql/types/GetAttachmentById';
import { GET_ATTACHMENT_BY_ID, PUT_MARK_AS_SEEN } from '../../../graphql/queries/mail';
import { exportAttachment } from '../../../utils/actions';
import { GetMarkAsSeen, GetMarkAsSeenVariables } from '../../../graphql/types/GetMarkAsSeen';

export interface MailViewProps {
  id: number;
  from: string;
  cc?: string | null;
  to: string;
  date: Date;
  subject: string;
  text: string;
  attachment?: string[] | null;
  reply?: boolean;
  unseen?: boolean;
  open?: boolean;
  onSeen?: (id: number) => void;
}

export const MailView: FC<MailViewProps> = (props: MailViewProps) => {
  const { formatDateTimeWithSuffix } = useDateTimeFormat();
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const [open, setOpen] = useState(!!props.open);
  const [seen, setSeen] = useState(!props.unseen);
  const [markAsSeen] = useMutation<GetMarkAsSeen, GetMarkAsSeenVariables>(PUT_MARK_AS_SEEN, {
    variables: { id: props.id }
  });

  useEffect(() => {
    if (props.open !== undefined) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (open && !seen) {
      markAsSeen();
      setSeen(true);
      props.onSeen && props.onSeen(props.id);
    }
  }, [open]);

  useEffect(() => {
    if (props.reply && !seen) {
      markAsSeen();
      setSeen(true);
      props.onSeen && props.onSeen(props.id);
    }
  }, [props.reply]);

  const getAttachment = (fileName: string): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      apolloClient.cache.reset().then(() => {
        apolloClient
          .query<GetAttachmentById, GetAttachmentByIdVariables>({
            query: GET_ATTACHMENT_BY_ID,
            variables: {
              id: props.id,
              fileName
            }
          })
          .then((result) => {
            resolve(result.data.attachmentById || '');
          })
          .catch(() => reject());
      });
    });
  };

  return (
    <article
      data-testid="mail"
      className={classNames(styles.Mail, {
        [styles.reply]: !!props.reply,
        [styles.unseen]: !seen,
        [styles.close]: !open
      })}
    >
      <header className={styles.mailHeader}>
        <span className={styles.sender}>
          <strong>{props.from}</strong>,{' '}
          <time dateTime={props.date ? new Date(props.date).toISOString() : ''}>
            {formatDateTimeWithSuffix(props.date)}
          </time>
          <Button
            data-testid="button"
            renderIcon={open ? ExpandLessSVGIcon : ExpandMoreSVGIcon}
            kind="ghost"
            hasIconOnly
            iconDescription={t(`actions.${!open ? 'open' : 'close'}`)}
            tooltipPosition="bottom"
            onClick={() => {
              setOpen(!open);
            }}
          />
        </span>
        <h3 className={styles.subject}>
          {props.attachment && props.attachment.length > 0 && <AttachFileSVGIcon />}
          {props.subject}
        </h3>
        {open && (
          <span className={styles.receiver} data-testid="receiver">
            <span id="mail-to">{t('mail.to')}:</span>
            <address aria-labelledby="mail-to">{props.to}</address>
          </span>
        )}
        {open && props.cc && (
          <span className={styles.receiver} data-testid="cc">
            <span id="mail-cc">{t('mail.cc')}:</span>
            <address aria-labelledby="mail-cc">{props.cc}</address>
          </span>
        )}
      </header>
      <div role="region" className={styles.mailContent} dangerouslySetInnerHTML={{ __html: props.text }} />
      {props.attachment && open && (
        <div className={styles.mailAttachment} data-testid="attachment">
          {props.attachment.map((file) => {
            return (
              <Button
                data-testid={`attachment_${file}`}
                key={file}
                size="field"
                kind="secondary"
                renderIcon={FileDownloadSVGIcon}
                className="bx--btn--hasIcon"
                onClick={() => {
                  getAttachment(file).then((result) => {
                    if (result !== '') {
                      exportAttachment(result, file);
                    }
                  });
                }}
              >
                {file}
              </Button>
            );
          })}
        </div>
      )}
    </article>
  );
};
