import { gql } from '@apollo/client';

export const GET_EMAILS = gql`
  # Get all emails that belongs to the communication with the given email address
  query GetEmails($email: String!) {
    receiveEmails(email: $email) {
      id
      from
      cc
      to
      subject
      text
      date
      attachments
      unseen
    }
  }
`;

export const GET_MAIL_TEMPLATE = gql`
  # Gets the mail template for the respective department of the current management user
  query GetMailTemplate {
    emailTemplate {
      content
      subject
      attachments
    }
  }
`;

export const GET_ATTACHMENT_BY_ID = gql`
  # Gets the attachment from the given mail id, specified by filename
  query GetAttachmentById($id: Int!, $fileName: String!) {
    attachmentById(id: $id, fileName: $fileName)
  }
`;

export const PUT_MARK_AS_SEEN = gql`
  # Mark the specific email as seen
  mutation MarkAsSeen($id: Int!) {
    markMailAsSeen(id: $id)
  }
`;

export const POST_SEND_MAIL = gql`
  # Send the given email
  mutation SendMail($email: InputMail!) {
    sendMail(email: $email)
  }
`;
