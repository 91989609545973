import { Button, Checkbox, Form, InlineNotification, Link, Modal, Tab, Tabs, TextInput } from 'carbon-components-react';
import { Trans, useTranslation } from 'react-i18next';
import { FC, FormEvent, useEffect, useState } from 'react';
import { getApiBaseUrl } from '../../../utils/api';
import { createPortal } from 'react-dom';
import styles from './login.module.scss';
import { ChevronRightSVGIcon } from '@react-md/material-icons';
import { DialogBackNavigation } from '../../controls/dialog-back-navigation/dialog-back-navigation';
import classNames from 'classnames';
import { useMutation } from '@apollo/client';
import {
  PostRequestResetPasswordLink,
  PostRequestResetPasswordLinkVariables
} from '../../../graphql/types/PostRequestResetPasswordLink';
import { POST_REQUEST_CONFIRMATION_LINK, POST_REQUEST_RESET_PASSWORD_LINK } from '../../../graphql/queries/user-auth';
import { useQueryResultErrorMessage } from '../../../utils/hooks/query-result-notification';
import * as Yup from 'yup';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { useFetchUser } from '../../../app.hooks.auth';
import { ErrorCodes } from '../../../utils/constants';
import {
  PostRequestConfirmationLink,
  PostRequestConfirmationLinkVariables
} from '../../../graphql/types/PostRequestConfirmationLink';

const emailValidationSchema = Yup.object().shape(
  {
    email: Yup.string().typeError('errors.required').required('errors.required').email('errors.invalidMail')
  },
  []
);

export interface LoginProps {
  selectedTabIndex: number;
  open: boolean;
  closeModal: () => void;
}

export const Login: FC<LoginProps> = (props: LoginProps) => {
  const { t } = useTranslation();

  enum View {
    Login,
    PasswordReset,
    Success
  }

  const [view, setView] = useState<View>(View.Login);
  const [error, setError] = useState<string | null>(null);
  const [showRequestConfirmationLink, setShowRequestConfirmationLink] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState<boolean>(false);

  const fetchUser = useFetchUser();
  // Tab navigation
  const [tabIndex, setTabIndex] = useState(props.selectedTabIndex);
  const tabs = [
    {
      label: 'header.modalLogin.tabs.loginTum',
      subLabel: 'header.modalLogin.tabs.tumId'
    },
    {
      label: 'header.modalLogin.tabs.loginLandlord',
      subLabel: 'header.modalLogin.tabs.email'
    }
  ];

  // Reset password
  const [requestPassword, { error: requestPasswordError }] = useMutation<
    PostRequestResetPasswordLink,
    PostRequestResetPasswordLinkVariables
  >(POST_REQUEST_RESET_PASSWORD_LINK, { variables: { email } });

  const resetError = useQueryResultErrorMessage(requestPasswordError);

  const { getValidationPropsForField, validateState } = useFormValidation(
    emailValidationSchema,
    { email },
    {
      showToasts: false,
      scrollToInvalidField: false
    }
  );

  useEffect(() => {
    if (resetError) {
      setError(resetError);
    }
  }, [resetError]);

  useEffect(() => {
    if (tabIndex !== props.selectedTabIndex) {
      setTabIndex(props.selectedTabIndex);
    }
  }, [props.selectedTabIndex]);

  // Request confirmation link
  const [requestConfirmationLink] = useMutation<PostRequestConfirmationLink, PostRequestConfirmationLinkVariables>(
    POST_REQUEST_CONFIRMATION_LINK,
    { variables: { email } }
  );

  // Close modal
  const onClose = (): void => {
    props.closeModal();
    setTimeout(() => {
      setView(View.Login);
      setError(null);
    }, 200);
  };

  // On form submit to login
  const onLogin = (event: FormEvent): void => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = new URLSearchParams([...(new FormData(form) as any)]);
    // Set email address after form is submit once.
    // This is necessary to get the email address for requesting a new confirmation link.
    // Also sets the email automatically to the request a new password field if the form was submitted before.
    setEmail(params.get('username') || '');

    fetch(`${getApiBaseUrl()}api/login`, {
      method: form.method,
      body: params,
      redirect: 'follow'
    }).then((response) => {
      if (response.redirected) {
        // TODO match response url against base url
        window.location.href = response.url;
      } else if (response.ok) {
        setError(null);
        fetchUser();
        props.closeModal();
      } else {
        response.json().then((json) => {
          // Get error message
          let error = t(`errors.${json.message.error}`);
          // Check if the error message contains a count for login trials
          if (json.message.count !== undefined) {
            const maxTrials = Number(process.env.REACT_APP_MAX_LOGIN_ATTEMPTS) || 3;
            const trials = maxTrials - json.message.count - 1;
            if (trials > 0 && trials < maxTrials) {
              // Error with trials left
              error += ' ' + t('errors.loginTrials', { count: trials });
            } else if (trials === 0) {
              // No more trials
              error = t('errors.LOGIN_FAILED_USER_BLOCKED');
            }
          }
          if (json?.message?.error === ErrorCodes.LOGIN_FAILED_USER_NOT_CONFIRMED) {
            setShowRequestConfirmationLink(true);
          }
          setError(error);
        });
      }
    });
  };

  // On form submit to reset password
  const onPasswordReset = (event: FormEvent): void => {
    event.preventDefault();
    validateState(() => {
      requestPassword()
        .then((success) => {
          if (success) {
            setError(null);
            setView(View.Success);
          } else {
            setError(t('errors.default'));
          }
        })
        .catch(() => ({}));
    });
  };

  return createPortal(
    <Modal
      className="bx--modal--with-tabs"
      passiveModal={true}
      size={'sm'}
      open={props.open}
      onRequestClose={onClose}
      modalLabel={t('header.modalLogin.title')}
      selectorPrimaryFocus={'.bx--btn--primary'}
      iconDescription={t('actions.close')}
    >
      {/* Tab navigation */}
      <Tabs selected={0} className={'bx--tabs--scrollable--modal'}>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={
              <span>
                {t(tab.label)}
                <span> ({t(tab.subLabel)})</span>
              </span>
            }
            onClick={() => {
              setView(View.Login);
              setTabIndex(index);
            }}
            renderContent={() => null}
            className={
              'bx--tabs--scrollable__nav-item--modal' +
              (tabIndex === index ? ' bx--tabs--scrollable__nav-item--active' : '')
            }
          />
        ))}
      </Tabs>
      <div className="bx--tabs--border" />
      {/* Tab content for TUM Login */}
      <div style={{ display: tabIndex === 0 ? 'block' : 'none' }}>
        <div className="bx--modal-custom-content">
          <p>
            <Trans i18nKey="header.modalLogin.textTumId">
              Mit Ihrer persönlichen <strong>TUM-Kennung</strong> können Sie sich direkt anmelden und
              <strong>Immobilienangebote finden</strong>. Dabei werden Daten von TUMonline in Ihr persönliches Profil
              übernommen.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="header.modalLogin.explanationTumId">
              Das <strong>Inserieren</strong> ist jedoch ausschließlich mit einem
              <strong>Account für Vermietende (mit Email-Adresse)</strong> möglich. Bitte registrieren Sie Sich dazu als
              Vermieter:in.
            </Trans>
          </p>
          <p>
            <Trans i18nKey="header.modalLogin.textNoTumId">
              Wenn Sie keine TUM-Kennung besitzen, können Sie die Vorteile von TUM Living leider nicht in vollem Umfang
              nutzen. Bei Fragen oder Problemen wenden Sie sich bitte an die
              {
                <Link href="https://www.tum.de/studium/im-studium/wohnen-und-arbeiten/wohnen/" target="_blank">
                  Studienorganisation
                </Link>
              }
              .
            </Trans>
          </p>
          <Checkbox
            id="agreementExtLogin"
            labelText={
              <>
                <Trans i18nKey="views.registerLandlord.agreement">
                  Ich stimme der Speicherung und Verarbeitung meiner Daten in Übereinstimmung mit der
                  {
                    <Link href="https://living.tum.de/privacy-policy" target="_blank">
                      TUM Living Datenschutzerkärung
                    </Link>
                  }{' '}
                  zu.
                </Trans>
                <span className={styles.requiredMarker}>*</span>
              </>
            }
            onChange={(isChecked) => setPrivacyPolicyAccepted(isChecked)}
          />
        </div>
        <div className="bx--modal-custom-footer">
          <Button
            kind={'primary'}
            disabled={!privacyPolicyAccepted}
            onClick={(event) => {
              event.preventDefault();
              props.closeModal();
              window.location.href = `${getApiBaseUrl()}api/loginExt`;
            }}
          >
            {t('header.modalLogin.loginTum')}
          </Button>
        </div>
      </div>
      {/* Tab content for landlord login */}
      {view === View.Login && (
        <Form
          className={styles.Login}
          style={{ display: tabIndex === 1 ? 'block' : 'none' }}
          method="post"
          onSubmit={onLogin}
        >
          <div className="bx--modal-custom-content">
            <TextInput
              id="username"
              name="username"
              required={true}
              labelText={`${t('header.modalLogin.email')}`}
              placeholder={`${t('header.modalLogin.emailPlaceholder')}`}
              onChange={() => setError(null)}
            />
            <TextInput
              id="password"
              name="password"
              required={true}
              labelText={`${t('header.modalLogin.password')}`}
              type="password"
              placeholder={`${t('header.modalLogin.passwordPlaceholder')}`}
              onChange={() => setError(null)}
            />
            <Button kind="ghost" className={styles.passwordLost} onClick={() => setView(View.PasswordReset)}>
              <ChevronRightSVGIcon />
              {t('header.modalLogin.passwordLost')}
            </Button>
            <p className={styles.landlordExplanation}>
              <Trans i18nKey="header.modalLogin.landlordExplanation">
                Zum <strong>Inserieren</strong> ist die Anmeldung mit Ihrer <strong>Email-Adresse</strong> nötig. Mit
                der TUM-Kennung ist ausschließlich das Finden von Immobilienangeboten und dessen Vorteile möglich.
              </Trans>
            </p>
            <p>
              {t('header.modalLogin.noAccount')}&nbsp;
              <Link className={styles.RegisterLink} href={'?register=1'}>
                {t('header.modalLogin.registerHere')}
              </Link>
              <br />
              <br />
              <Link href="https://living.tum.de/privacy-policy" target="_blank">
                {t('main.privacyPolicy')}
              </Link>
            </p>
            {error && <InlineNotification kind="error" title={error} hideCloseButton={true} />}
            {error && showRequestConfirmationLink && (
              <Button
                type="button"
                kind="ghost"
                className={styles.passwordLost}
                onClick={() => requestConfirmationLink({ variables: { email } }).then(() => setView(View.Success))}
              >
                <ChevronRightSVGIcon />
                {t('header.modalLogin.requestConfirmationLink')}
              </Button>
            )}
          </div>
          <div className="bx--modal-custom-footer">
            <Button kind={'primary'} type="submit">
              {t('actions.login')}
            </Button>
          </div>
        </Form>
      )}
      {view === View.PasswordReset && (
        <Form className={styles.PasswordReset} onSubmit={onPasswordReset}>
          <div className="bx--modal-custom-content">
            <DialogBackNavigation
              headline={t('header.modalLogin.resetPassword')}
              onBackClick={() => {
                setView(View.Login);
                setError(null);
              }}
            />
            <p>{t('header.modalLogin.resetPasswordInfo')}</p>
            <TextInput
              id="username"
              name="username"
              required={true}
              labelText={`${t('header.modalLogin.email')}`}
              placeholder={`${t('header.modalLogin.emailPlaceholder')}`}
              value={email}
              {...getValidationPropsForField('email', (event) => {
                setEmail(event.target.value);
              })}
            />
            {error && <InlineNotification kind="error" title={error} hideCloseButton={true} />}
          </div>
          <div className="bx--modal-custom-footer">
            <Button kind={'primary'} type="submit" disabled={email.length === 0}>
              {t('actions.send')}
            </Button>
          </div>
        </Form>
      )}
      {view === View.Success && (
        <>
          <div className="bx--modal-custom-content">
            <p>{t('header.modalLogin.resetPasswordSuccess')}</p>
          </div>
          <div className={classNames('bx--modal-custom-footer', styles.alignLeft)}>
            <Button kind={'secondary'} onClick={onClose}>
              {t('actions.close')}
            </Button>
          </div>
        </>
      )}
    </Modal>,
    document.body
  );
};
