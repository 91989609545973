import { gql } from '@apollo/client';

export const POST_CONFIRM_ACCOUNT = gql`
  # Posts the token to confirm the landlord account
  mutation PostConfirmAccount($token: String!) {
    confirmAccount(token: $token)
  }
`;

export const PUT_UPDATE_PASSWORD = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export const POST_REQUEST_RESET_PASSWORD_LINK = gql`
  mutation PostRequestResetPasswordLink($email: String!) {
    requestResetPasswordLink(email: $email)
  }
`;

export const POST_NEW_PASSWORD_AFTER_RESET = gql`
  mutation PostNewPasswordAfterReset($password: String!, $token: String!) {
    setNewPasswordAfterReset(password: $password, token: $token)
  }
`;

export const POST_REQUEST_CONFIRMATION_LINK = gql`
  mutation PostRequestConfirmationLink($email: String!) {
    requestConfirmationLink(email: $email)
  }
`;
