import { AddSVGIcon, FavoriteSVGIcon } from '@react-md/material-icons';
import { Button, Tag } from 'carbon-components-react';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import { UserState } from '../../../redux/user.state';
import ContentView, { ContentViewSection } from '../../building-blocks/content-view/content-view';
import styles from './seeker-view.module.scss';
import { useLazyQuery } from '@apollo/client';
import { GetFavorites, GetFavoritesVariables } from '../../../graphql/types/GetFavorites';
import { GET_FAVORITES } from '../../../graphql/queries/favorites';
import { ListingCardContainer } from '../../building-blocks/listing-card-container/listing-card-container';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchRequestCard } from '../../controls/search-request-card/search-request-card';
import {
  GetSeekerSearchRequests,
  GetSeekerSearchRequestsVariables
} from '../../../graphql/types/GetSeekerSearchRequests';
import { GET_SEEKER_SEARCH_REQUESTS } from '../../../graphql/queries/seeker-search-requests';
import { useUpdateAndDeleteSearchRequest } from './seeker-view.hooks';
import { createPortal } from 'react-dom';
import { ConfirmationDialog } from '../../building-blocks/confirmation-dialog/confirmation-dialog';
import { buildSeekerSearchRequestFilter } from '../search-requests/search-request/search-request.utils';
import { useUserPermission } from '../../../utils/hooks/user-data';

const SeekerView: FC = () => {
  const navigate = useNavigate();
  // Check if the user is allowed to load this view and return to start view otherwise
  const permissions = useUserPermission();

  if (!permissions.isSeeker) {
    navigate('/listings');
  }

  const { t } = useTranslation();
  const { state } = useLocation();

  const userState = useSelector<GlobalState, UserState>((state) => state.user);

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [searchRequestCount, setSearchRequestCount] = useState(0);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [searchRequestIdToDelete, setSearchRequestIdToDelete] = useState('');

  // Fetch listings
  const [fetchFavorites, { loading, data, error, refetch }] = useLazyQuery<GetFavorites, GetFavoritesVariables>(
    GET_FAVORITES,
    {
      fetchPolicy: 'no-cache'
    }
  );

  // Scroll to search request if view was opened from dialog
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (state && state.linkTo !== '') {
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const id = state.linkTo.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView(true);
      }, 200);
    }
  }, [state]);

  useEffect(() => {
    if (!loading && userState.user?.email && !data && !error) {
      fetchFavorites({ variables: { emailAddress: userState.user.email } });
    } else if (data?.favorites && !loading) {
      setFavoriteCount(data.favorites.length);
    }
  }, [data, loading, error]);

  // Fetch search requests
  const [
    fetchSearchRequests,
    {
      loading: searchRequestsLoading,
      error: searchRequestError,
      data: searchRequestData,
      refetch: refetchSearchRequests
    }
  ] = useLazyQuery<GetSeekerSearchRequests, GetSeekerSearchRequestsVariables>(GET_SEEKER_SEARCH_REQUESTS, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!searchRequestsLoading && userState.user?.email && !searchRequestData && !searchRequestError) {
      fetchSearchRequests({ variables: { email: userState.user.email } });
    } else if (searchRequestData?.seekerSearchRequests && !searchRequestsLoading) {
      setSearchRequestCount(searchRequestData.seekerSearchRequests.length);
    }
  }, [searchRequestData, searchRequestsLoading, searchRequestError]);

  const { updateSeekerSearchRequest, deleteSeekerSearchRequest } = useUpdateAndDeleteSearchRequest();

  return (
    <>
      <div className={styles.Greeting}>
        <div className="global-content-wrapper">
          <h1>
            {t('main.greeting', {
              title: '',
              name: userState.user?.name || ''
            })}
          </h1>
        </div>
      </div>
      <ContentView className={classNames(styles.SeekerContent, 'global-content-wrapper-block')}>
        <ContentViewSection
          title={t('views.seeker.myFavourites')}
          anchorId="favourites"
          navTitle={t('views.seeker.navigation.favourites')}
          suffix={
            <Tag
              size="sm"
              className={classNames(styles.Tag, {
                [styles.InactiveTag]: favoriteCount === 0
              })}
            >
              {favoriteCount}
            </Tag>
          }
          className={styles.SeekerContentView}
        >
          {favoriteCount === 0 ? (
            <>
              <p>
                <Trans i18nKey="views.seeker.favoriteInfoText">
                  Finden Sie das passende Immobilienangebot auf der TUM Living Angebotsübersicht. Durch Merken des
                  Inserats bei Selektieren des <FavoriteSVGIcon /> erscheint das Angebot hier in der Favoritenliste und
                  Sie können schneller verfolgen, was mit diesem Inserat passiert.
                </Trans>
              </p>
              <Button
                onClick={() => {
                  navigate('/listings');
                }}
                renderIcon={FavoriteSVGIcon}
                className={styles.IconButton}
              >
                {t('actions.addNewFavorite')}
              </Button>
            </>
          ) : null}
          {data?.favorites && (
            <ListingCardContainer
              refetch={refetch}
              listings={data.favorites}
              favorable={true}
              favorites={data.favorites.map((favorite) => {
                return favorite.uuid;
              })}
              editable={false}
              headingLevel={'h3'}
            />
          )}
        </ContentViewSection>
        <ContentViewSection
          title={t('views.seeker.mySearchrequests')}
          anchorId="searchrequests"
          navTitle={t('views.seeker.navigation.searchrequests')}
          suffix={
            <Tag
              size="sm"
              className={classNames(styles.Tag, {
                [styles.InactiveTag]: searchRequestCount === 0
              })}
            >
              {searchRequestCount}
            </Tag>
          }
          className={styles.SeekerContentView}
        >
          <>
            {searchRequestCount === 0 ? (
              <>
                <p>{t('views.seeker.searchRequestInfoText')}</p>
              </>
            ) : (
              <>
                {refetchSearchRequests &&
                  searchRequestData?.seekerSearchRequests.map((searchRequest) => (
                    <SearchRequestCard
                      key={searchRequest.id}
                      numberOfResults={searchRequest.resultCount || 0}
                      resultsLink={`/listings${buildSeekerSearchRequestFilter(searchRequest)}`}
                      {...searchRequest}
                      onPauseClick={() => {
                        updateSeekerSearchRequest({
                          variables: {
                            email: userState.user?.email || '',
                            id: searchRequest.id,
                            isActive: !searchRequest.isActive
                          }
                        }).then(() => refetchSearchRequests());
                      }}
                      onDeleteClick={() => {
                        setDeleteModal(true);
                        setSearchRequestIdToDelete(searchRequest.id);
                      }}
                    />
                  ))}
              </>
            )}
            <Button
              onClick={() => {
                navigate('/listings');
              }}
              className={styles.IconButton}
              renderIcon={AddSVGIcon}
            >
              {t('actions.createNewSearchRequest')}
            </Button>
          </>
        </ContentViewSection>
      </ContentView>
      {openDeleteModal &&
        refetchSearchRequests &&
        createPortal(
          <ConfirmationDialog
            open={true}
            title={`${t('views.seeker.modalDelete.title')}`}
            headline={`${t('views.seeker.modalDelete.headline')}`}
            text={`${t('views.seeker.modalDelete.text')}`}
            primaryActionLabel={`${t('actions.delete')}`}
            onPrimaryActionClick={() => {
              deleteSeekerSearchRequest({
                variables: {
                  email: userState.user?.email || '',
                  id: searchRequestIdToDelete
                }
              }).then(() =>
                refetchSearchRequests().then(({ data: searchRequestData }) => {
                  if (searchRequestData?.seekerSearchRequests && !searchRequestsLoading) {
                    setSearchRequestCount(searchRequestData.seekerSearchRequests.length);
                  }
                })
              );
              setDeleteModal(false);
            }}
            onCloseClick={() => {
              setDeleteModal(false);
            }}
          />,
          document.body
        )}
    </>
  );
};

export default SeekerView;
