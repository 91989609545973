/**
 * List of valid phone numbers:
 * https://www.vorwahl-nummern.de/fundgrube/schreibweise_nummern_zahlen.php
 */
export const phoneRegExp = /^\(?[+]?([\d]+[ ]{0,2}[()-/+]{0,2}[ ]{0,2})*$/;
export const isNotDefinedOrEmpty = (val?: string): boolean => !val || val.length === 0;
export const isStringDefined = (val?: string | null): val is string =>
  val !== undefined && val !== null && val.length > 0;
export const isNumberDefined = (val?: number | null): val is number => val !== undefined && val !== null && val > 0;

export const testPhoneFormat = (val?: string | null): boolean => {
  if (isStringDefined(val)) {
    return phoneRegExp.test(val);
  }
  return true;
};

export const testOptionalStringLength = (minLength: number) => (val?: string | null): boolean => {
  if (isStringDefined(val)) {
    return val.length > minLength;
  }
  return true;
};

export const testOptionalLowerBoundary = (boundary: number) => (val?: number | null): boolean => {
  if (isNumberDefined(val)) {
    return val > boundary;
  }
  return true;
};
