import { Param } from './constants';

export const getImagePath = (imageId: string, modifiedAt: string, maxWidth?: number, shareToken?: string): string => {
  const imageUrl = new URL(
    `${process.env.REACT_APP_API_BASE_URL}api/image/${imageId}${maxWidth ? `/${maxWidth}` : ''}`
  );

  const timestamp = getTimeStamp(modifiedAt);
  if (timestamp !== '') {
    imageUrl.searchParams.append(Param.TIMESTAMP, timestamp);
  }
  if (shareToken) {
    imageUrl.searchParams.append(Param.SHARE, shareToken);
  }

  return imageUrl.toString();
};

export const getThumbnailImagePath = (imageId: string, modifiedAt: string): string => {
  const imageUrl = new URL(`${process.env.REACT_APP_API_BASE_URL}api/image/thumbnail/${imageId}`);
  const timestamp = getTimeStamp(modifiedAt);
  if (timestamp !== '') {
    imageUrl.searchParams.append(Param.TIMESTAMP, timestamp);
  }

  return imageUrl.toString();
};

const getTimeStamp = (date: string): string => {
  let timestamp = 0;
  try {
    timestamp = Date.parse(date);
  } catch (error) {
    // handle error
  }
  return timestamp > 0 ? timestamp + '' : '';
};
