import { FC, useCallback, useEffect, useState, ChangeEvent, useMemo } from 'react';
import styles from './admin.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TextInput,
  Dropdown,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'carbon-components-react';
import { DeleteSVGIcon } from '@react-md/material-icons';
import { FilterContainer } from '../../controls/filter-container/filter-container';
import { GetPermissionMappings_permissionMappings } from '../../../graphql/types/GetPermissionMappings';
import { Permission } from '../../../graphql/types/globalTypes';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../../redux/store';
import { UserState } from '../../../redux/user.state';
import { useAddAndDeleteMappings, useFetchMappings, useFetchRoleOptions } from './admin.hooks';
import { ConfirmationDialog } from '../../building-blocks/confirmation-dialog/confirmation-dialog';
import classNames from 'classnames';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { permissionValidationSchema } from './admin.validation';
import { useUserPermission } from '../../../utils/hooks/user-data';
import { useNavigate } from 'react-router-dom';

type PermissionMapping = GetPermissionMappings_permissionMappings;
type PermissionMappingKey = keyof GetPermissionMappings_permissionMappings;
type DropDownItem = {
  id: string;
  text: string;
};

const TABLE_HEADERS = [
  'views.admin.tableHeaders.emailAddress',
  'views.admin.tableHeaders.permissions',
  'views.admin.tableHeaders.role',
  ''
];

export const AdminView: FC = () => {
  // Check if the user is allowed to load this view and return to start view otherwise
  const userPermissions = useUserPermission();
  const navigate = useNavigate();

  if (!userPermissions.isAdmin) {
    navigate('/listings');
  }
  const { t } = useTranslation();
  const { addMapping, deleteMapping } = useAddAndDeleteMappings();
  const { refetch, data } = useFetchMappings();
  const { refetch: refetchRoleOptions } = useFetchRoleOptions();

  // display values
  const [roleOptions, setRoleOptions] = useState<DropDownItem[]>();

  // user input
  const [newEntryEmail, setNewEntryEmail] = useState<string>('');
  const [newEntryPermissionId, setNewEntryPermissionId] = useState<Permission>();
  const [newEntryRoleId, setNewEntryRoleId] = useState<string>();
  const [deleteId, setDeleteId] = useState<string>('');

  const userState = useSelector<GlobalState, UserState>((state) => state.user);
  const formState = useMemo(() => {
    return {
      newEntryEmail,
      newEntryPermissionId,
      newEntryRoleId
    };
  }, [newEntryEmail, newEntryPermissionId, newEntryRoleId]);

  const { getValidationPropsForField, validateState } = useFormValidation(permissionValidationSchema, formState, {
    scrollToInvalidField: false,
    showToasts: false
  });

  /**
   *  view content and fetching
   */

  // fetch values for role dropdown when the selected permission changes
  useEffect(() => {
    if (newEntryPermissionId) {
      setRoleOptions([]);
      setNewEntryRoleId(undefined);
      refetchRoleOptions({ permission: newEntryPermissionId }).then((result) => {
        if (result.data.rolesForPermission) {
          const filteredRoles = result.data.rolesForPermission.filter((role) => role !== null) as string[];
          setRoleOptions(
            filteredRoles.map((role) => ({
              id: role,
              text: t(`enums.role.${role}`)
            }))
          );
        }
      });
    }
  }, [newEntryPermissionId]);

  // create values for permission dropdown
  const permissions = Object.keys(Permission)
    .filter((key) => key === Permission.ADMINISTRATOR || key === Permission.MANAGEMENT)
    .map((key) => ({
      id: key,
      text: t(`enums.permission.${key}`)
    }));

  // translate roles/permission for the mapping rows
  const translateRowIfNecessary = (row: PermissionMapping, key: PermissionMappingKey): string => {
    if (['permission', 'role'].indexOf(key) !== -1) {
      return t(`enums.${key}.${row[key]}`);
    }
    return row[key];
  };

  /**
   *  User input callbacks
   */
  const onEmailInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setNewEntryEmail(event.target.value);
    },
    [setNewEntryEmail]
  );

  const onSelectedPermissionChange = useCallback(
    (id: Permission) => {
      setNewEntryPermissionId(id);
    },
    [setNewEntryPermissionId]
  );

  const onSelectedRoleChange = useCallback(
    (id: string) => {
      setNewEntryRoleId(id);
    },
    [setNewEntryRoleId]
  );

  const onAddMapping = useCallback(() => {
    validateState(() => {
      formState.newEntryPermissionId &&
        formState.newEntryRoleId &&
        addMapping({
          variables: {
            email: formState.newEntryEmail,
            permission: formState.newEntryPermissionId,
            role: formState.newEntryRoleId
          }
        }).then(() => {
          // trigger refetch of mappings
          refetch();
          // reset form
          setNewEntryEmail('');
          setNewEntryPermissionId(undefined);
          setNewEntryRoleId(undefined);
        });
    });
  }, [formState, validateState]);

  const onDeleteMapping = useCallback((mappingMail: string) => {
    deleteMapping({
      variables: {
        email: mappingMail
      }
    }).then(() => {
      refetch();
    });
  }, []);

  return (
    <div className={styles.AdminView}>
      <FilterContainer
        counter={data?.permissionMappings?.length ?? 0}
        headline={t('views.admin.administrativeUsers')}
        className={styles.AdminFilterContainer}
      >
        <TextInput
          id="email"
          labelText={`${t('main.username')}`}
          placeholder="max.muster@ergosign.de"
          value={newEntryEmail}
          {...getValidationPropsForField('newEntryEmail', onEmailInputChange)}
        />
        <Dropdown
          id="permission"
          titleText={`${t('views.admin.tableHeaders.permissions')}`}
          label={`${t('actions.select')}`}
          items={permissions}
          itemToString={(item) => (item ? item.text : '')}
          className="bx--form-item"
          selectedItem={permissions?.find((permission) => permission.id === newEntryPermissionId) || null}
          {...getValidationPropsForField(
            'newEntryPermissionId',
            (data) => data.selectedItem && onSelectedPermissionChange(data.selectedItem.id as Permission)
          )}
        />
        <Dropdown
          id="role"
          titleText={`${t('views.admin.tableHeaders.role')}`}
          label={`${t('actions.select')}`}
          items={roleOptions ?? []}
          itemToString={(item) => (item ? item.text : '')}
          className="bx--form-item"
          disabled={!newEntryPermissionId}
          selectedItem={roleOptions?.find((option) => option.id === newEntryRoleId) || null}
          {...getValidationPropsForField(
            'newEntryRoleId',
            (data) => data.selectedItem && onSelectedRoleChange(data.selectedItem.id)
          )}
        />
        <Button kind="primary" size="field" onClick={onAddMapping}>
          {t('main.add')}
        </Button>
      </FilterContainer>

      <div className={classNames(styles.content, 'global-content-wrapper-block')}>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEADERS.map((header) => (
                <TableHeader key={header}>{header.length > 0 && t(header)}</TableHeader>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.permissionMappings &&
              data.permissionMappings.map((row) => {
                return (
                  row && (
                    <TableRow key={row.email}>
                      {Object.keys(row)
                        .filter((key) => ['email', 'permission', 'role'].indexOf(key) >= 0)
                        .map((key) => {
                          return (
                            <TableCell key={key}>{translateRowIfNecessary(row, key as PermissionMappingKey)}</TableCell>
                          );
                        })}
                      <TableCell key={row.email}>
                        <Button
                          type="button"
                          kind="ghost"
                          renderIcon={DeleteSVGIcon}
                          iconDescription={t('tooltips.delete')}
                          hasIconOnly
                          onClick={() => setDeleteId(row.email)}
                          disabled={userState.user?.email === row.email}
                        />
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
        <ConfirmationDialog
          open={deleteId !== ''}
          title={`${t('views.admin.modalDelete.title')}`}
          headline={`${t('views.admin.modalDelete.headline')}`}
          text={`${t('views.admin.modalDelete.text')}`}
          primaryActionLabel={`${t('actions.delete')}`}
          onPrimaryActionClick={() => {
            onDeleteMapping(deleteId);
            setDeleteId('');
          }}
          onCloseClick={() => {
            setDeleteId('');
          }}
        />
      </div>
    </div>
  );
};
