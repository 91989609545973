import { gql } from '@apollo/client';

export const GET_TUM_LOCATIONS = gql`
  # Get the tum location for the given city
  query GetTumLocations {
    tumLocations {
      address
      city
      coordinates {
        x
        y
      }
    }
  }
`;

export const GET_ENVIRONMENT_INFO = gql`
  # Get all environmental information for the given listing
  query GetEnvironmentalInfo($uuid: String!) {
    environmentalInformationForListing(uuid: $uuid) {
      coordinates {
        x
        y
      }
      address
      category
      duration
      type
      name
    }
  }
`;
