import { FC, ReactElement, SVGProps } from 'react';
import { ReactComponent as AccessibleIcon } from '../../../../../assets/svg/accessible.svg';
import { ReactComponent as BalconyIcon } from '../../../../../assets/svg/balcony.svg';
import { ReactComponent as BathtubIcon } from '../../../../../assets/svg/bathtub.svg';
import { ReactComponent as CoffeeIcon } from '../../../../../assets/svg/coffee.svg';
import { ReactComponent as CounterTopsIcon } from '../../../../../assets/svg/countertops.svg';
import { ReactComponent as DeckIcon } from '../../../../../assets/svg/deck.svg';
import { ReactComponent as ElevatorIcon } from '../../../../../assets/svg/elevator.svg';
import { ReactComponent as KingBedIcon } from '../../../../../assets/svg/king_bed.svg';
import { ReactComponent as LocalFloristIcon } from '../../../../../assets/svg/local_florist.svg';
import { ReactComponent as LocalLaundryServiceIcon } from '../../../../../assets/svg/local_laundry_service.svg';
import { ReactComponent as PedalBikeIcon } from '../../../../../assets/svg/pedal_bike.svg';
import { ReactComponent as PetsIcon } from '../../../../../assets/svg/pets.svg';
import { ReactComponent as RoofingIcon } from '../../../../../assets/svg/roofing.svg';
import { ReactComponent as ShowerIcon } from '../../../../../assets/svg/shower.svg';
import { ReactComponent as SingleBedIcon } from '../../../../../assets/svg/single_bed.svg';
import { ReactComponent as StairsIcon } from '../../../../../assets/svg/stairs.svg';
import { ReactComponent as WcIcon } from '../../../../../assets/svg/wc.svg';
import { ReactComponent as FamilyIcon } from '../../../../../assets/svg/family.svg';
import { ReactComponent as SmokingIcon } from '../../../../../assets/svg/smoking.svg';
import { ReactComponent as FlatSharingIcon } from '../../../../../assets/svg/groups.svg';
import { ReactComponent as LocalParkingIcon } from '../../../../../assets/svg/local_parking.svg';

type TagIconMapping = {
  [key: string]: FC<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
};

export const TAG_ICON_MAPPING: TagIconMapping = {
  FURNISHED: KingBedIcon,
  PARTLY_FURNISHED: SingleBedIcon,
  BATHTUB: BathtubIcon,
  SHOWER: ShowerIcon,
  GUEST_TOILET: WcIcon,
  WASHING_MACHINE: LocalLaundryServiceIcon,
  DISHWASHER: CoffeeIcon,
  TERRACE: DeckIcon,
  BALCONY: BalconyIcon,
  GARDEN: LocalFloristIcon,
  CELLAR: StairsIcon,
  LIFT: ElevatorIcon,
  PETS_ALLOWED: PetsIcon,
  BICYCLE_CELLAR: PedalBikeIcon,
  ATTIC: RoofingIcon,
  BARRIER_FREE: AccessibleIcon,
  FITTED_KITCHEN: CounterTopsIcon,
  FAMILY_FRIENDLY: FamilyIcon,
  SMOKING: SmokingIcon,
  FLAT_SHARING_POSSIBLE: FlatSharingIcon,
  PARKING_SPACE: LocalParkingIcon
};

/**
 * Render function for tag icons, which sets the icon title according to the active state.
 * This aims to improve accessibility of the tag list for screen readers.
 *
 * @param tag the tag.
 * @param isActive the active state.
 * @param t the translation function.
 * @returns the rendered icon.
 */
export const renderTagIcon = (tag: string, isActive: boolean, t: (key: string) => string): ReactElement => {
  const Icon = TAG_ICON_MAPPING[tag];

  return <Icon title={isActive ? t(`enums.tags.${tag}`) : t(`enums.tags.inactive.${tag}`)}></Icon>;
};
