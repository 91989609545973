import { MailSVGIcon } from '@react-md/material-icons';
import { Button, Dropdown, Form, RadioButton, RadioButtonGroup, TextInput } from 'carbon-components-react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ContactType, Gender } from '../../../graphql/types/globalTypes';
import { DefaultMultiSelectItem } from '../../../utils/types';
import { useFormValidation } from '../../../utils/validation/validation-utils';
import { SaveBar } from '../../building-blocks/save-bar/save-bar';
import {
  resetForm,
  setCity,
  setCompanyName,
  setContactType,
  setFirstName,
  setGender,
  setHouseNumber,
  setLastName,
  setPostalCode,
  setStreet,
  setTelephoneNumber
} from '../landlord/landlord-view/landlord-view.state';
import { registerValidationSchema } from '../register-dialog/register-dialog.validation';
import { ProfileDialogViewMode, SetViewModeProps } from './profile-dialog';
import { useLandlordProfileChangeData } from './profile-dialog.hooks';
import styles from './profile-dialog.module.scss';
import { clearUpPhoneNumber } from '../../../utils/validation/input-validation';
import { useResponsiveBreakpoints } from '../../../utils/hooks/responsive.hooks';

export const ContactDataChangeView: FC<SetViewModeProps> = ({ setViewMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { formState, updateLandlord, errorNotification, refetchProfileData } = useLandlordProfileChangeData();

  const { isMobile } = useResponsiveBreakpoints();

  const { getValidationPropsForField, validateState } = useFormValidation(registerValidationSchema, formState, {
    showToasts: false,
    scrollToInvalidField: false
  });

  const contactTypeItems = Object.keys(ContactType).map((key) => ({
    text: t(`enums.contactType.${key}`),
    id: key
  }));

  const translatedInputLabelProps = useCallback(
    (key: string, optionalPlaceholder?: string) => ({
      labelText: t(key) as string,
      placeholder: optionalPlaceholder === '-' ? '-' : t(optionalPlaceholder || key)
    }),
    [t]
  );

  const salutationItems: DefaultMultiSelectItem[] = [
    { id: Gender.MALE, text: t(`enums.salutation.${Gender.MALE}`) },
    { id: Gender.FEMALE, text: t(`enums.salutation.${Gender.FEMALE}`) },
    { id: Gender.DIVERSE, text: '-' }
  ];

  return (
    <div>
      <h3 className={styles.SectionHeading}>{t('views.account.contactData')}</h3>
      <Form id="contact-data-change" className={styles.ChangeProfileData} onSubmit={(e) => e.preventDefault()}>
        <RadioButtonGroup
          name="ProfileType"
          valueSelected={formState.contactType}
          className={styles.ContactTypeSelection}
          onChange={(value) => dispatch(setContactType(value as ContactType))}
          orientation={isMobile ? 'vertical' : 'horizontal'}
        >
          {contactTypeItems.map((item) => (
            <RadioButton id={item.id} key={item.id} value={item.id} labelText={item.text} />
          ))}
        </RadioButtonGroup>
        {formState?.contactType === ContactType.PRIVATE ? null : (
          <TextInput
            id="company"
            value={formState.companyName || ''}
            {...translatedInputLabelProps(
              formState.contactType === ContactType.COMPANY ? 'formFields.company' : 'formFields.publicInstitution',
              formState.contactType === ContactType.COMPANY
                ? 'formFields.nameOfCompany'
                : 'formFields.nameOfPublicInstitution'
            )}
            {...getValidationPropsForField('companyName', (event) => {
              dispatch(setCompanyName(event.target.value));
            })}
          />
        )}
        <div className={classNames(styles.FormRow, styles.FormRowWrap)}>
          <Dropdown
            className={styles.Salutation}
            id="salutation"
            items={salutationItems}
            label={''}
            itemToString={(item) => (item ? item.text : '')}
            titleText={t('formFields.salutation') as string}
            selectedItem={formState.gender ? salutationItems.find((item) => item.id === formState.gender) : null}
            {...getValidationPropsForField(
              'salutation',
              ({ selectedItem }: { selectedItem: DefaultMultiSelectItem }) => {
                selectedItem && dispatch(setGender(selectedItem.id as Gender));
              }
            )}
          />
          <TextInput
            id="lastName"
            value={formState.lastName || ''}
            {...translatedInputLabelProps('formFields.lastName')}
            {...getValidationPropsForField('lastName', (event) => {
              dispatch(setLastName(event.target.value));
            })}
          />
          <TextInput
            id="firstName"
            value={formState.firstName || ''}
            {...translatedInputLabelProps('formFields.firstName')}
            {...getValidationPropsForField('firstName', (event) => {
              dispatch(setFirstName(event.target.value));
            })}
          />
        </div>
        <div className={styles.FormRow}>
          <TextInput
            id="tel"
            value={formState.phone || ''}
            {...translatedInputLabelProps('formFields.telephoneNumber')}
            {...getValidationPropsForField('phone', (event) => {
              dispatch(setTelephoneNumber(event.target.value));
            })}
          />
        </div>
        <div className={classNames(styles.FormRow, styles.street)}>
          <TextInput
            id="street"
            value={formState.street || ''}
            {...translatedInputLabelProps('formFields.street')}
            {...getValidationPropsForField('street', (event) => {
              dispatch(setStreet(event.target.value));
            })}
          />
          <TextInput
            id="house-number"
            value={formState.houseNumber || ''}
            {...translatedInputLabelProps('formFields.houseNumberAbbreviation', '-')}
            {...getValidationPropsForField('houseNumber', (event) => {
              dispatch(setHouseNumber(event.target.value));
            })}
          />
        </div>
        <div className={classNames(styles.FormRow, styles.city)}>
          <TextInput
            id="postal-code"
            value={formState.postalCode || ''}
            {...translatedInputLabelProps('formFields.postalCode', 'formFields.postalCodeAbbreviation')}
            {...getValidationPropsForField('postalCode', (event) => {
              dispatch(setPostalCode(event.target.value));
            })}
          />
          <TextInput
            id="city"
            value={formState.city || ''}
            {...translatedInputLabelProps('formFields.place')}
            {...getValidationPropsForField('city', (event) => {
              dispatch(setCity(event.target.value));
            })}
          />
        </div>
        <div className={styles.MailInfo}>
          <MailSVGIcon className={styles.Icon} />
          <div>
            <span aria-label={t('formFields.emailAddress')} className={styles.Mail}>
              {formState.email}
            </span>
            <span className={styles.Info}>{t('views.account.mailChangeInfoText')}</span>
          </div>
        </div>
        {errorNotification && <div>{errorNotification}</div>}
      </Form>
      <SaveBar trigger={{}} className={styles.SaveBar}>
        <Button
          kind={'secondary'}
          onClick={() => {
            dispatch(resetForm());
            setViewMode(ProfileDialogViewMode.OVERVIEW);
          }}
        >
          {t('actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            if (formState.phone) {
              // user is allowed to input anything, we afterwords clean up disallowed characters
              dispatch(setTelephoneNumber(clearUpPhoneNumber(formState.phone)));
            }

            validateState(() => {
              updateLandlord()
                .then(() => {
                  refetchProfileData().then(() => {
                    setViewMode(ProfileDialogViewMode.OVERVIEW);
                  });
                })
                .catch(() => ({}));
            });
          }}
          type="submit"
          form="contact-data-change"
        >
          {t('actions.save')}
        </Button>
      </SaveBar>
    </div>
  );
};

export default ContactDataChangeView;
