import { useEffect, useMemo, useState } from 'react';

const getWidth = (): number => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const useCurrentWidth = (): number => {
  // save current window width in the state object
  const [width, setWidth] = useState(getWidth());

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeoutId: any = null;
    const resizeListener = (): void => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return width;
};

export type Breakpoints = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
};

export enum Breakpoint {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP'
}

export const useResponsiveBreakpoints = (): Breakpoints => {
  const currentWidth = useCurrentWidth();

  const breakpoints = useMemo(() => {
    return {
      isMobile: currentWidth <= 460,
      isTablet: currentWidth <= 1024 && currentWidth > 460,
      isDesktop: currentWidth > 1024
    };
  }, [currentWidth]);

  return breakpoints;
};

export type CustomBreakPointsDefinition = {
  [key: string]: (currentWidth: number) => boolean;
};

export type CustomBreakPoints<T> = {
  [key in keyof T]?: boolean;
};

export const useCustomBreakpoints = <T extends CustomBreakPointsDefinition>(
  customBreakPointsDefinition: T
): CustomBreakPoints<T> => {
  const currentWidth = useCurrentWidth();

  const breakpoints = useMemo(() => {
    const customBreakPoints: CustomBreakPoints<T> = {};
    Object.keys(customBreakPointsDefinition).forEach((key: keyof T) => {
      customBreakPoints[key] = customBreakPointsDefinition[key](currentWidth);
    });
    return customBreakPoints;
  }, [currentWidth]);

  return breakpoints;
};

export const useCurrentBreakpoint = (): Breakpoint => {
  const breakpoints = useResponsiveBreakpoints();

  if (breakpoints.isMobile) {
    return Breakpoint.MOBILE;
  } else if (breakpoints.isTablet) {
    return Breakpoint.TABLET;
  }
  return Breakpoint.DESKTOP;
};
